/**
 * Converts a date range in the format "3-6 September 2024" to an object containing
 * the start and end dates in the "YYYY-MM-DD" format.
 *
 * @param {string} dateRange - A string representing the date range,
 *                             e.g., "3-6 September 2024".
 * @returns {Object} An object with two properties:
 *                   - startDate {string}: The start date in "YYYY-MM-DD" format.
 *                   - endDate {string}: The end date in "YYYY-MM-DD" format.
 */
const convertDateRange = (dateRange: any) => {
  try {
    // Split the date range into days and the rest
    const [days, month, year] = dateRange.split(' ');

    // Extract the start and end days
    const [startDay, endDay] = days.split('-');

    // Convert month name to number
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December'
    ];

    const monthIndex = monthNames.indexOf(month) + 1; // Get month number (1-12)

    // Format the days into YYYY-MM-DD
    const startDate = `${year}-${String(monthIndex).padStart(2, '0')}-${String(startDay).padStart(
      2,
      '0'
    )}`;
    const endDate = `${year}-${String(monthIndex).padStart(2, '0')}-${String(endDay).padStart(
      2,
      '0'
    )}`;
    return { startDate, endDate };
  } catch {
    return { startDate: '', endDate: '' };
  }
};

export default convertDateRange;
