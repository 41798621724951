/* eslint-disable no-undef */
/* eslint-disable camelcase */
import axios from 'axios';
import { Auth } from 'aws-amplify';

// basic authorization token
const Base = require('js-base64').Base64;

const tok = 'developers:>;JQwa[zL!EX+f{?3{fjnK';
const hash = Base.encode(tok);
const Basic = `Basic ${hash}`;

const API_URL = window.report?.REACT_APP_API_URL || process.env.REACT_APP_API_URL;

// eslint-disable-next-line no-undef
const getAuthToken = async path => {
  let res = null;
  try {
    // eslint-disable-next-line camelcase
    const api_list = ['/clignosis/health'];
    if (api_list.includes(path)) {
      res = Basic;
    } else {
      res = `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`;
    }
  } catch (err) {
    res = err.response;
  }
  // eslint-disable-next-line no-undef
  return res;
};

/**
 * Function to make requests to rest API
 * @param {string} path api endpoint path
 * @param {string} method type of request
 * @param {object} payload request body
 * @returns api response from backend
 */
const makeRequest = async (path, method, payload = {}, customHeaders = {}) => {
  let res = null;
  try {
    res = await axios({
      method,
      url: `${API_URL}${path}`,
      data: payload,
      headers: {
        'Content-type': 'application/json',
        // if we this makeRequest is getting called from report server, then use the token from window object.
        Authorization: window.report?.authToken || (await getAuthToken(path)),
        ...customHeaders
      }
    });
  } catch (err) {
    if (err?.response?.status === 429) {
      window.dispatchEvent(new Event('apiError'));
    }
    res = err.response;
  }

  return res;
};

export const makeRequestWithCancel = async (
  path,
  method,
  payload = {},
  customHeaders = {},
  cancelToken = null
) => {
  let res = null;
  try {
    res = await axios({
      method,
      url: `${API_URL}${path}`,
      data: payload,
      cancelToken,
      headers: {
        'Content-type': 'application/json',
        Authorization: await getAuthToken(path),
        ...customHeaders
      }
    });
  } catch (err) {
    if (err?.response?.status === 429) {
      window.dispatchEvent(new Event('apiError'));
    }
    res = err.response;
  }

  return res;
};

export const makeStreamRequestWithCancel = async (
  path,
  method,
  // eslint-disable-next-line default-param-last
  payload = {},
  // eslint-disable-next-line default-param-last
  customHeaders = {},
  // eslint-disable-next-line default-param-last
  cancelToken = null,
  onDownloadProgress,
  allowTooManyRequestsEvent = true
) => {
  let res = null;
  try {
    res = await axios({
      method,
      url: `${API_URL}${path}`,
      data: payload,
      cancelToken,
      onDownloadProgress,
      responseType: 'text',
      stream: true,
      headers: {
        'Content-type': 'application/json',
        accept: 'text/event-stream',
        Authorization: await getAuthToken(path),
        ...customHeaders
      }
    });
  } catch (err) {
    if (err?.response?.status === 429 && allowTooManyRequestsEvent) {
      window.dispatchEvent(new Event('apiError'));
    }
    res = err.response;
  }

  return res;
};

export const makePDFRequest = async (path, method, payload = {}, customHeaders = {}) => {
  let res = null;
  try {
    res = await axios({
      method,
      url: `${API_URL}${path}`,
      data: payload,
      responseType: 'blob',
      headers: {
        'Content-type': 'application/json',
        Authorization: await getAuthToken(path),
        ...customHeaders
      }
    });
  } catch (err) {
    if (err?.response?.status === 429) {
      window.dispatchEvent(new Event('apiError'));
    }
    res = err.response;
  }

  return res;
};

export const makeBlobRequestWithCancel = async (
  path,
  method,
  payload = {},
  customHeaders = {},
  cancelToken = null
) => {
  let res = null;
  try {
    res = await axios({
      method,
      url: `${API_URL}${path}`,
      data: payload,
      cancelToken,
      responseType: 'blob',
      headers: {
        'Content-type': 'application/json',
        Authorization: await getAuthToken(path),
        ...customHeaders
      }
    });
  } catch (err) {
    if (err?.response?.status === 429) {
      window.dispatchEvent(new Event('apiError'));
    }
    res = err.response;
  }

  return res;
};

export const makeArrayBufferRequest = async (path, method, payload = {}, customHeaders = {}) => {
  let res = null;
  try {
    res = await axios({
      method,
      url: `${API_URL}${path}`,
      data: payload,
      responseType: 'arraybuffer',
      headers: {
        'Content-type': 'application/json',
        Authorization: await getAuthToken(path),
        ...customHeaders
      }
    });
  } catch (err) {
    if (err?.response?.status === 429) {
      window.dispatchEvent(new Event('apiError'));
    }
    res = err.response;
  }

  return res;
};

export const makeFormDataRequest = async (path, method, payload, customHeaders = {}) => {
  let res = null;
  try {
    res = await axios({
      method,
      url: `${API_URL}${path}`,
      data: payload,
      headers: {
        // if we this makeRequest is getting called from report server, then use the token from window object.
        Authorization: window.report?.authToken || (await getAuthToken(path)),
        ...customHeaders
      }
    });
  } catch (err) {
    res = err.response;
  }

  return res;
};

export default makeRequest;
