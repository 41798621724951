import React, { useMemo } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { Divider, IconButton, Stack, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import styles from '../styles/nameConfirmationDialog.styles';

export const CloseComponent = ({
  title,
  handleDialogClose,
  buttonText,
  buttonHandler,
  handleDrawerClose
}: any) => {
  return (
    <Stack display='flex' flexDirection='column' alignItems='center' justifyContent='space-evenly'>
      <Stack direction='column' mb='10px' alignItems='center' width={286}>
        <Typography sx={styles.warningBoldText}>
          {`Save changes to report ${title} before closing?`}
        </Typography>
      </Stack>

      <Stack gap={1.5} display='flex' flexDirection='column' justifyContent='center' width={286}>
        <Button sx={{ ...styles.submitButton, mt: 4 }} onClick={buttonHandler}>
          {buttonText}
        </Button>
        <Button sx={styles.cancelButton} onClick={handleDrawerClose}>
          Don‘t Save
        </Button>

        <Button sx={{ ...styles.cancelButton, mt: 3 }} onClick={handleDialogClose}>
          Cancel
        </Button>
      </Stack>
    </Stack>
  );
};

export const ClearComponent = ({ handleDialogClose, buttonText, buttonHandler }: any) => {
  return (
    <Stack display='flex' flexDirection='column' alignItems='center' justifyContent='space-evenly'>
      <Stack direction='column' mb='10px' alignItems='center' width={286}>
        <Typography sx={styles.warningBoldText}>
          Do you want to clear all content from current report?
        </Typography>
      </Stack>

      <Stack gap={3} mt={4} display='flex' flexDirection='row' justifyContent='center' width={286}>
        <Button sx={styles.cancelButton} onClick={handleDialogClose}>
          Cancel
        </Button>

        <Button sx={styles.submitButton} onClick={buttonHandler}>
          {buttonText}
        </Button>
      </Stack>
    </Stack>
  );
};

export const UpdateComponent = ({ handleDialogClose, buttonText, buttonHandler }: any) => {
  return (
    <Stack display='flex' flexDirection='column' alignItems='center' justifyContent='space-evenly'>
      <Stack direction='column' mb='10px' alignItems='center' width={286}>
        <Typography sx={styles.warningBoldText}>
          Are you sure you want to change this report to ‘Active’ state?
        </Typography>
        <Typography sx={styles.warningSubText}>
          (Changing this report to ‘Active’, will not erase data from your previously active
          report.)
        </Typography>
      </Stack>

      <Stack gap={3} mt={4} display='flex' flexDirection='row' justifyContent='center' width={286}>
        <Button sx={styles.cancelButton} onClick={handleDialogClose}>
          Cancel
        </Button>

        <Button sx={styles.submitButton} onClick={buttonHandler}>
          {buttonText}
        </Button>
      </Stack>
    </Stack>
  );
};

const ClearReportDialog = ({
  open = false,
  title,
  action = 'close',
  onClose,
  onClear,
  onUpdateStatus,
  handleDialogClose,
  handleDrawerClose
}: any) => {
  const heading = useMemo(() => {
    if (action === 'close') {
      return 'Save Report';
    }
    if (action === 'clear') {
      return 'Clear Report';
    }
    return 'Are you sure';
  }, [action]);

  const renderContent = () => {
    switch (action) {
      case 'close':
        return (
          <CloseComponent
            title={title}
            handleDialogClose={handleDialogClose}
            buttonText='Save'
            buttonHandler={onClose}
            handleDrawerClose={handleDrawerClose}
          />
        );
      case 'clear':
        return (
          <ClearComponent
            handleDialogClose={handleDialogClose}
            buttonText='Clear'
            buttonHandler={onClear}
          />
        );
      case 'updateActiveStatus':
        return (
          <UpdateComponent
            handleDialogClose={handleDialogClose}
            buttonText='Continue'
            buttonHandler={onUpdateStatus}
          />
        );
      default:
        return null;
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleDialogClose}
      PaperProps={{
        onClick: (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()
      }}
      sx={styles.clearDialog}>
      <Stack direction='column' spacing='24px' padding='24px 24px 40px 24px'>
        <Stack alignItems='center' direction='column' justifyContent='center' spacing={3}>
          <DialogTitle sx={{ p: 0 }}>
            <Stack direction='column'>
              <Box sx={styles.boxContainer}>
                <Typography sx={styles.formHeading}>{heading}</Typography>
                <IconButton
                  size='small'
                  title='cancel'
                  onClick={handleDialogClose}
                  sx={styles.closeIcon}>
                  <Close />
                </IconButton>
              </Box>
              <Divider sx={styles.divider} />
            </Stack>
          </DialogTitle>
        </Stack>
        <DialogContent
          sx={{
            width: '100%',
            p: 0
          }}>
          {renderContent()}
        </DialogContent>
      </Stack>
    </Dialog>
  );
};

export default React.memo(ClearReportDialog);
