import { memo, useCallback, useContext, useEffect, useState, useMemo } from 'react';

// material ui
import { Box, Popover, Button, Divider } from '@mui/material';

import store from '../../../store/GuidanceDocuments';
import GlobalStore from '../../../store';
import actions from '../../../store/GuidanceDocuments/actions';

// hooks
import useGuidanceData from '../hooks/useGuidanceData';

// components
import GuidanceDetails from '../components/Details';
import ReportWidgets from '../../../components/Report/Widgets';
import SubHeader from '../../../components/SubHeader';
import BottomDrawer from '../../../components/CustomComponents/BottomDrawer';
import NewFilters from '../../../components/NewFilters';
import AskRiaButton from '../../../components/Buttons/AskRiaButton';
import AriaDrawerCore from '../../SearchResults/components/AriaDrawerCore';

// utils
import CompareDialog from '../components/CompareDialog';
import { GUIDANCES_FILTERS, PAGINATION_DEFAULT_OPTIONS } from '../const';
import { Stat } from '../../SearchResults/types';
import AskRiaButtonStyles from '../../../components/Buttons/styles/AskRiaButton.styles';
import useAriaShareHandler from '../../../components/AriaShare/useAriaShareHandler';
import { getGuidanceDocumentsSignedURL } from '../../../api/pages/GuidanceDocuments';
import truncateCountAboveMillions from '../../../utils/truncateCountAboveMillions';
import formatCount from '../../../utils/formatCount';
import Insights from '../../../components/Insights/Insights';
import VISUALIZE_CHART_MAPPING from '../../../components/Insights/const';
import ComparisonDrawer from '../components/ComparisonDrawer';
import { ComparisonIcon } from '../../../assets/svgs/Icons';
import tabsStyles from '../styles/Tabs';

// Not a True HOC.   #TODO compare this
const GuidanceTabs = () => {
  const { dispatch } = useContext(GlobalStore) as any;
  const {
    guidancefilters,
    isLoading,
    documentsToCompare,
    comparePopupOpen,
    openComparisonPopup,
    getGuidanceData,
    clearComparison,
    getDatawithDataGridFilter,
    sortFields,
    dataGridFilter
  } = useGuidanceData();
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const [ariaModalOpen, setAriaModalOpen] = useState(false);
  const { guidanceState, guidanceDispatch } = useContext(store);
  const [reportChoicesOpenAnchorElement, setReportChoiceAnchorElement] =
    useState<HTMLElement | null>(null);
  const [guidanceStats, setGuidanceStats] = useState<Stat[]>([]);
  const [sort, setSort] = useState([...sortFields]);
  const [pagination, setPagination] = useState({
    pageSize: PAGINATION_DEFAULT_OPTIONS.limit,
    page: PAGINATION_DEFAULT_OPTIONS.offset
  });
  const [isFilterUpdated, setIsFilterUpdated] = useState(false);
  const [compareItems, setCompareItems] = useState<Array<any>>([]);

  useEffect(() => {
    getGuidanceData([], pagination.pageSize, pagination.page, []);
  }, []);

  useEffect(() => {
    if (guidanceState?.guidanceStats) {
      const newStats: Stat[] = [];
      newStats.push({
        title: 'Total',
        value: guidanceState?.guidanceStats?.totalGuidanceCount || 0
      });
      newStats.push({
        title: 'Status Adopted',
        value: guidanceState?.guidanceStats?.totalCountStatusFinal || 0
      });
      newStats.push({
        title: 'Recently Published',
        value: guidanceState?.guidanceStats?.totalLastThirtyDays || 0
      });
      newStats.push({
        title: 'Docs/Page',
        value: guidanceState?.guidanceStats?.pdfCount,
        value2: guidanceState?.guidanceStats?.pageCount
      });

      setGuidanceStats(newStats);
    }
  }, [guidancefilters]);

  useEffect(() => {
    const fetchData = async () => {
      const formattedDocumentsToCompare = await Promise.all(
        documentsToCompare.map(async doc => {
          const pdfSignedUrl: any = await getGuidanceDocumentsSignedURL({
            source: doc.source,
            s3_path: doc.pdf_s3_url
          });
          const docTitle = `${doc.status}-${doc.issue_datetime}-${doc.title}`;
          return {
            title: `${docTitle.replace(/ \([\s\S]*?\)/g, '')}`,
            pdfLink: pdfSignedUrl.data.body.s3_url
          };
        })
      );
      setCompareItems(formattedDocumentsToCompare);
    };

    // check is added so that when user selects both the pdf only pdf url is fetched
    if (documentsToCompare.length === 2) {
      fetchData();
    }
  }, [documentsToCompare]);

  useAriaShareHandler(isLoading, ariaModalOpen, setAriaModalOpen);

  const handleFilterApply = useCallback(
    (currentfilters: any) => {
      setIsFilterUpdated(true);
      setPagination({ page: PAGINATION_DEFAULT_OPTIONS.offset, pageSize: pagination.pageSize });
      clearComparison();
      getGuidanceData(
        currentfilters,
        pagination.pageSize,
        // offset is set to default because whenever filter is applied user should be directed to first page
        PAGINATION_DEFAULT_OPTIONS.offset,
        sortFields
      );
    },
    [sortFields, pagination]
  );

  const getFiltersLabelList = () => {
    return GUIDANCES_FILTERS.reduce((acc: any, elem) => {
      acc[elem.value] = elem.label;
      return acc;
    }, {});
  };

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  const getAriaDocuments = () => {
    const ariaDocuments = guidanceState?.askRiaFilters?.map((item: any) => ({
      identifier: item
    }));
    return ariaDocuments || [];
  };

  const getInsightStatsList = useMemo(() => {
    if (guidanceState?.guidanceStats) {
      return [
        {
          text: 'Total',
          count: guidanceState?.guidanceStats?.totalGuidanceCount,
          toolTipValue: ''
        },
        {
          text: 'Status Final',
          count: guidanceState?.guidanceStats?.totalCountStatusFinal,
          toolTipValue: ''
        },
        {
          text: 'Approval in Last 30 Days',
          count: guidanceState?.guidanceStats?.totalLastThirtyDays,
          toolTipValue: ''
        },
        {
          text: 'Total Documents | Pages',
          count: `${truncateCountAboveMillions(
            guidanceState.guidanceStats.pdfCount
          )} | ${truncateCountAboveMillions(guidanceState.guidanceStats.pageCount)}`,
          toolTipValue: `${formatCount(guidanceState.guidanceStats.pdfCount)} | ${formatCount(
            guidanceState.guidanceStats.pageCount
          )}`
        }
      ];
    }
    return [];
  }, [guidanceState]);

  const getLayer1Charts = useMemo(() => {
    const guidanceGraph = VISUALIZE_CHART_MAPPING['fda-guidance']?.layer1;

    if (guidanceGraph && guidanceState.issueDateBar) {
      const updatedGraph = guidanceGraph.map((graph: any) => ({
        ...graph,
        data: guidanceState.issueDateBar
      }));

      return updatedGraph;
    }
    return guidanceGraph;
  }, [guidanceState.issueDateBar]);

  const getLayer2Charts = useMemo(() => {
    const guidanceGraph = [...VISUALIZE_CHART_MAPPING['fda-guidance'].layer2];

    if (guidanceGraph && guidanceState.donutCharts) {
      guidanceGraph.forEach(graph => {
        const donutItem = guidanceState.donutCharts.find(donut => donut?.apikey === graph?.id);
        if (donutItem) {
          // eslint-disable-next-line no-param-reassign
          graph.data = donutItem.children;
        }
      });

      return guidanceGraph;
    }
    return guidanceGraph;
  }, [guidanceState.donutCharts]);

  return (
    <>
      <SubHeader
        title='US FDA Guidelines'
        subTitle='Showing list of US FDA Guidances, MAPPS, SOPPS, and more'
        stats={guidanceStats}
        handleVisualizeOpen={handleVisualizeOpen}>
        <Button
          startIcon={<ComparisonIcon sx={tabsStyles.comparisonIcon} />}
          onClick={() => {
            guidanceDispatch({
              type: actions.SET_COMPARE_ENABLED,
              value: true
            });
          }}
          sx={tabsStyles.comparisonButton}>
          Compare Guidances
        </Button>
        <Divider orientation='vertical' sx={tabsStyles.divider} />
      </SubHeader>

      {!isLoading && (
        <Popover
          open={!!reportChoicesOpenAnchorElement}
          anchorEl={reportChoicesOpenAnchorElement}
          onClose={() => setReportChoiceAnchorElement(null)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
          sx={tabsStyles.popOver}>
          <Box>
            <ReportWidgets
              widgets={['pie-widget', 'bar-widget', 'table-widget']}
              title=''
              dataSource={{
                source: 'fda-guidance',
                moduleName: 'guidance',
                disableSelectionSource: true,
                filters: guidanceState.availableFilters,
                filterLabelList: getFiltersLabelList()
              }}
              pathMetaData={{
                module: 'fda_guidance',
                href: window.location.href
              }}
              setReportWidgetClose={setReportChoiceAnchorElement}
            />
          </Box>
        </Popover>
      )}

      {comparePopupOpen && (
        <CompareDialog
          open={comparePopupOpen}
          onClose={() => {
            guidanceDispatch({
              type: actions.SET_COMPARE_ENABLED,
              value: false
            });
            openComparisonPopup(false);
          }}
          changeGuidances={() => {
            openComparisonPopup(false);
          }}
          compareItems={compareItems}
          onNewCompare={clearComparison}
        />
      )}

      <GuidanceDetails
        pagination={pagination}
        setPagination={setPagination}
        sort={sort}
        setSort={setSort}
        sortFields={sortFields}
        getGuidanceData={getGuidanceData}
        isFilterUpdated={isFilterUpdated}
        setIsFilterUpdated={setIsFilterUpdated}
        getDatawithDataGridFilter={getDatawithDataGridFilter}
        dataGridFilter={dataGridFilter}
      />

      <ComparisonDrawer
        open={guidanceState.compareEnabled}
        onClose={() =>
          guidanceDispatch({
            type: actions.SET_COMPARE_ENABLED,
            value: false
          })
        }>
        <GuidanceDetails
          pagination={pagination}
          setPagination={setPagination}
          sort={sort}
          setSort={setSort}
          sortFields={sortFields}
          getGuidanceData={getGuidanceData}
          isFilterUpdated={isFilterUpdated}
          setIsFilterUpdated={setIsFilterUpdated}
          getDatawithDataGridFilter={getDatawithDataGridFilter}
          dataGridFilter={dataGridFilter}
          isCheckboxSelectionEnabled
          isComparison={guidanceState.compareEnabled}
          openComparisonPopup={openComparisonPopup}
        />
      </ComparisonDrawer>

      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        handleReportChoiceAnchorElement={setReportChoiceAnchorElement}>
        <Insights
          source='fda-guidance'
          searchType='guidance'
          appliedFilter={guidanceState?.availableFilters}
          statsList={getInsightStatsList}
          layer1Component={getLayer1Charts}
          layer2Component={getLayer2Charts}
          isLoading={isLoading}
        />
      </BottomDrawer>

      {ariaModalOpen && (
        <AriaDrawerCore
          applications={guidanceState.isFiltersApplied ? getAriaDocuments() : []}
          sources={{ us: ['guidance'] }}
          groupId='entity_id'
          docCount={guidanceState?.guidanceStats?.pdfCount}
          docPageCount={guidanceState?.guidanceStats?.pageCount}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          dispatch={dispatch}
        />
      )}

      <NewFilters data={guidancefilters} applyFilters={handleFilterApply} isLoading={isLoading} />

      <Box sx={AskRiaButtonStyles.bottomFilterPosition}>
        <AskRiaButton
          iconId='aria'
          setOpenAriaSearch={setAriaModalOpen}
          text='Ask RIA'
          disabled={isLoading}
        />
      </Box>
    </>
  );
};

export default memo(GuidanceTabs);
