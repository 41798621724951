import React, { useContext, useMemo } from 'react';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { Checkbox } from '@mui/material';
import { useHistory } from 'react-router-dom';

import CircularAvatar from './CircularAvatar';

import styles from '../styles/ApplicationCard.styles';

import { CardComponentProps, CardMetadata } from '../types';
import {
  LABEL_COLOR,
  sourceFlagMapping,
  sourceFlagMappingTooltip,
  LABEL_TOOLTIP_TITLE
} from '../constants';
import CardActionsMenu from './CardActionsMenu';
import {
  Discontinued,
  DocumentOutlined,
  Tentative,
  SaveIconFilled,
  SubscribeIconFilled
} from '../../../assets/svgs/Icons';

import { getSearchParams } from '../../../hooks/useBreadcrumbs';
import showReg360 from '../utils/showReg360';
import { elasticQueryToAdvancedSearch } from '../../../components/Header/utils/advanceSearchHelpers';
import { encodeJSONtoString } from '../../../helpers/backToEncoding';
import { RegulatoryInformationStateProvider } from '../../../store/RegulatoryInformation';
import FavoriteAndProjectActions from '../../../components/FavoritesAndProjectsAction/FavoriteAndProjectActions';

// store
import ResultsStore from '../../../store/SearchResults';
import SubscriptionActions from '../../../components/Subscription/SubscriptionActions';

const CardComponent = ({
  data,
  showDottedTitle,
  hideApplication,
  triggerChatRIA,
  setFavoriteInHook,
  setInProjectInHook,
  setSubscriptionInHook,
  handleCompareView,
  inCompare,
  showCompare,
  disableCompare,
  viewMode,
  handleLabelSection,
  inLabelSection,
  hideCardOptions
}: CardComponentProps) => {
  const {
    title,
    titleOriginal,
    identifier,
    metadata,
    approvalTracks,
    source,
    labels,
    groupTitle,
    groupTitleOriginal,
    documentAvailableMessage,
    showDiscontinued,
    showTentative,
    subGroupTitle
  } = data;

  const FlagComponent = sourceFlagMapping[source];
  const flagTooltipTitle = sourceFlagMappingTooltip[source];
  const { resultsState } = useContext(ResultsStore);
  const { application_type: type, s3_label_url: s3LabelUrl } = data as any;
  const inProjects = (data as any)?.in_projects ?? (data as any)?.inProjects;
  const history = useHistory();
  const {
    location: { pathname: locationPathName, search: locationSearch }
  } = window;
  const urlParams = new URLSearchParams(locationSearch);
  const advSearchData = elasticQueryToAdvancedSearch(urlParams);
  // Gets the text in the card based on the original text toggle
  const titleText = useMemo(
    () => (resultsState.originalTextEnabled ? titleOriginal ?? title : title),
    [resultsState.originalTextEnabled, titleOriginal, title]
  );

  const groupTitleText = useMemo(
    () => (resultsState.originalTextEnabled ? groupTitleOriginal ?? groupTitle : groupTitle),
    [resultsState.originalTextEnabled, titleOriginal, title]
  );

  const metadataValue = (cardMetaData: CardMetadata) =>
    resultsState.originalTextEnabled
      ? cardMetaData.valueOriginal ?? cardMetaData.value
      : cardMetaData.value;

  // TBD: show alert if reg360 not available
  const shouldShowReg360 = showReg360(data, source);

  const handleReg360 = (isOpenedFromMenu: boolean = false) => {
    // return if reg360 not available or in view mode
    if (!shouldShowReg360 || viewMode) {
      return;
    }
    const module = 'core';
    const backToObject = {
      module,
      path: locationPathName,
      search: getSearchParams(locationSearch || ''),
      name: 'Search Results'
    };
    const backToString = encodeJSONtoString(backToObject);
    const applicationNumber = encodeURIComponent(identifier);
    let dateText = '';
    if (advSearchData?.dateRange?.startDate && advSearchData?.dateRange?.endDate) {
      dateText = `&startDate=${advSearchData.dateRange.startDate}&endDate=${advSearchData.dateRange.endDate}`;
    }
    try {
      const navigation = JSON.parse((localStorage.getItem('navigation') as any) ?? '{}');
      if (source?.toLowerCase() === 'us') {
        navigation.regulatory = {
          url: `/regulatory360/${module}/${applicationNumber}/${source}?type=${type}&backTo=${backToString}${dateText}`,
          value: `Regulatory360:${type}${applicationNumber}`
        };
      } else {
        navigation.regulatory = {
          url: `/regulatory360/${module}/${applicationNumber}/${source}?backTo=${backToString}${dateText}`,
          value: `Regulatory360:${applicationNumber}`
        };
      }
      localStorage.setItem('navigation', JSON.stringify(navigation));
      if (source?.toLowerCase() === 'us') {
        // eslint-disable-next-line no-unused-expressions
        isOpenedFromMenu
          ? window.open(
              `/regulatory360/${module}/${applicationNumber}/${source?.toLowerCase()}?type=${type}&tabname=approvalHistory&backTo=${backToString}${dateText}`,
              '_blank'
            )
          : history.push(
              `/regulatory360/${module}/${applicationNumber}/${source?.toLowerCase()}?type=${type}&tabname=approvalHistory&backTo=${backToString}${dateText}`
            );
      } else {
        // eslint-disable-next-line no-unused-expressions
        isOpenedFromMenu
          ? window.open(
              `/regulatory360/${module}/${applicationNumber}/${source}?tabname=approvalHistory&backTo=${backToString}${dateText}`,
              '_blank'
            )
          : history.push(
              `/regulatory360/${module}/${applicationNumber}/${source}?tabname=approvalHistory&backTo=${backToString}${dateText}`
            );
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const handleAddToCompareView = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    // @ts-ignore
    handleCompareView(data, checked);
  };

  const handleAddToLabelSection = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = event.target;
    // @ts-ignore
    handleLabelSection(data, checked);
  };

  const disableComparison = () => {
    if (!inCompare && disableCompare) {
      return true;
    }

    if (type === 'EUA') {
      return true;
    }

    if (['us-devices', 'ct'].includes(source)) {
      return true;
    }

    if (source === 'au' && s3LabelUrl === null) {
      return true;
    }

    return false;
  };

  return (
    <Box width='100%'>
      {!viewMode && (
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Box display='flex' alignItems='center' width='100%'>
            {showCompare && (
              <Checkbox
                disabled={disableComparison()}
                checked={inCompare}
                onChange={handleAddToCompareView}
                sx={styles.cardCheckbox}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            )}
            {resultsState.collatedSummaryApplications.length !== 0 && (
              <Checkbox
                checked={inLabelSection}
                onChange={handleAddToLabelSection}
                sx={styles.cardCheckbox}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            )}
            <Tooltip
              title={`Generic Name: ${groupTitleText}`}
              disableInteractive
              disableFocusListener
              disableTouchListener>
              <Typography sx={{ ...styles.overflowEllipsis, ...styles.cardGroupTitle }}>
                {showDottedTitle ? '...' : groupTitleText}
              </Typography>
            </Tooltip>
            {subGroupTitle && (
              <Typography
                fontSize={14}
                ml='auto'
                fontWeight={800}>{`(${subGroupTitle})`}</Typography>
            )}
          </Box>
        </Stack>
      )}
      <Box
        width='100%'
        p={2}
        pb={1}
        sx={viewMode ? styles.cardContainerViewMode : styles.cardContainer}
        onClick={() => {
          // for label comparison make entire card clickable instead of checkbox
          if (showCompare && handleCompareView) {
            if (!disableComparison()) {
              handleCompareView(data, !inCompare);
            }
            return;
          }
          handleReg360(false);
        }}>
        <Box display='flex' flexDirection='row' width='100%'>
          <Box display='flex' alignItems='center' flex={1} maxWidth={100}>
            <Tooltip
              title={`Product Name: ${titleText}`}
              disableInteractive
              disableFocusListener
              disableTouchListener>
              <Typography
                color='primary.600'
                fontWeight='800'
                fontSize={16}
                maxHeight={24}
                sx={styles.overflowEllipsis}>
                {titleText}
              </Typography>
            </Tooltip>
            {showDiscontinued && (
              <Tooltip
                title='Discontinued'
                disableInteractive
                disableFocusListener
                disableTouchListener>
                <Discontinued sx={{ fontSize: 16, ml: 1 }} />
              </Tooltip>
            )}
            {showTentative && (
              <Tooltip
                title='Tentative'
                disableInteractive
                disableFocusListener
                disableTouchListener>
                <Tentative sx={{ fontSize: 16, ml: 1 }} />
              </Tooltip>
            )}
          </Box>
          <Box flex={1} textAlign='end'>
            <Box display='flex' alignItems='center' justifyContent='end'>
              {documentAvailableMessage && (
                <Tooltip
                  title={documentAvailableMessage}
                  disableInteractive
                  disableFocusListener
                  disableTouchListener>
                  <DocumentOutlined sx={{ fontSize: 16 }} />
                </Tooltip>
              )}
              <CircularAvatar avatarList={approvalTracks} onCard />
            </Box>
          </Box>
        </Box>
        <Box display='flex' flexWrap='nowrap' pt={1}>
          {metadata.slice(0, 2).map((dataVal: CardMetadata, index: number) => (
            <>
              <Tooltip
                title={`${dataVal.key}: ${metadataValue(dataVal)}`}
                disableInteractive
                disableFocusListener
                disableTouchListener>
                <Typography
                  maxWidth='50%'
                  maxHeight={20}
                  sx={styles.overflowEllipsis}
                  fontWeight={500}>
                  {metadataValue(dataVal)}
                </Typography>
              </Tooltip>
              {index < metadata.length - 1 && (
                <Typography pl={1} pr={1} pt={0.5} fontSize={6}>
                  &#x2022;
                </Typography>
              )}
            </>
          ))}
        </Box>
        <Box display='flex'>
          {metadata.slice(2, metadata.length).map((dataVal: CardMetadata, index: number) => (
            <>
              <Tooltip
                title={`${dataVal.key}: ${metadataValue(dataVal)}`}
                disableInteractive
                disableFocusListener
                disableTouchListener>
                <Typography
                  maxWidth='50%'
                  maxHeight={20}
                  sx={styles.overflowEllipsis}
                  fontWeight={500}>
                  {metadataValue(dataVal)}
                </Typography>
              </Tooltip>
              {index < metadata.length - 3 && (
                <Typography pl={1} pr={1} pt={0.5} fontSize={6} maxWidth='50%'>
                  &#x2022;
                </Typography>
              )}
            </>
          ))}
        </Box>
        <hr />
        <Stack direction='row' justifyContent='space-between' alignItems='center'>
          <Box display='flex' alignItems='center'>
            <Tooltip
              title={flagTooltipTitle}
              disableInteractive
              disableFocusListener
              disableTouchListener>
              <FlagComponent sx={{ width: '18px', height: '13px' }} />
            </Tooltip>
            {labels &&
              labels.map((label: string) => {
                const labelKey = label.toLowerCase();
                const labelColor = LABEL_COLOR[labelKey];
                const labelTooltipTitle = LABEL_TOOLTIP_TITLE[labelKey];
                return labelColor ? (
                  <Tooltip
                    title={labelTooltipTitle}
                    disableInteractive
                    disableFocusListener
                    disableTouchListener>
                    <Chip
                      label={label}
                      key={label}
                      sx={{
                        ...styles.labelChip,
                        bgcolor: labelColor
                      }}
                    />
                  </Tooltip>
                ) : null;
              })}
            <Tooltip
              title={`Application Number: ${identifier}`}
              disableInteractive
              disableFocusListener
              disableTouchListener>
              <Typography px={1} fontWeight={500} fontSize={12} alignSelf='center' color='gray.700'>
                {identifier}
              </Typography>
            </Tooltip>
          </Box>
          {!viewMode && (
            <RegulatoryInformationStateProvider>
              <Box display='flex' alignItems='center'>
                {(data.isFavorite || inProjects?.length > 0) &&
                  (hideCardOptions ? (
                    <Tooltip title='Currently this option is disabled'>
                      <Box p={1} height={32}>
                        <SaveIconFilled sx={styles.filledIcons} />
                      </Box>
                    </Tooltip>
                  ) : (
                    <FavoriteAndProjectActions
                      invokedBy='applications'
                      isButton
                      setFavoriteInHook={setFavoriteInHook}
                      source={data.source}
                      sourceIndex={(data as any)?.source_index}
                      id={data.identifier}
                      isFavorite={data.isFavorite}
                      inProjects={inProjects}
                      setInProjectInHook={setInProjectInHook}
                    />
                  ))}
                {data.isSubscribed &&
                  (hideCardOptions ? (
                    <Tooltip title='Currently this option is disabled'>
                      <Box p={1} height={32}>
                        <SubscribeIconFilled sx={styles.filledIcons} />
                      </Box>
                    </Tooltip>
                  ) : (
                    <SubscriptionActions
                      invokedBy='application'
                      isButton
                      setSubscriptionInHook={setSubscriptionInHook}
                      source={data.source}
                      id={data.identifier}
                      isSubscribed={data.isSubscribed}
                    />
                  ))}
                {hideCardOptions ? (
                  <Box height={32} width={0} />
                ) : (
                  <CardActionsMenu
                    data={data}
                    hideApplication={hideApplication}
                    handleReg360={handleReg360}
                    triggerChatRIA={triggerChatRIA}
                    setFavoriteInHook={setFavoriteInHook}
                    setInProjectInHook={setInProjectInHook}
                    setSubscriptionInHook={setSubscriptionInHook}
                  />
                )}
              </Box>
            </RegulatoryInformationStateProvider>
          )}
        </Stack>
      </Box>
    </Box>
  );
};

CardComponent.defaultProps = {
  showDottedTitle: false,
  applicationIndex: -1,
  applicationGroup: null,
  viewMode: false,
  hideCardOptions: false
};

export default React.memo(CardComponent);
