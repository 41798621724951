import React, { createContext, useReducer } from 'react';
import reducers from './reducers';
import {
  DocumentSearchSubscription,
  ParentNotification,
  SearchData
} from '../../pages/Notifications/components/NotificationInterface';
import { INITIAL_SELECTED_NOTIFICATION } from '../../pages/Notifications/constants';

type Props = {
  children: React.ReactNode;
};

export interface NotificationsContextInterface {
  subscribedApplications: any[];
  subscribedSearches: SearchData[];
  subscribedDocumentSearches: DocumentSearchSubscription[];
  subscribedSources: Record<string, boolean>;
  selectedNotification: ParentNotification;
}

const initialState = {
  subscribedApplications: [],
  subscribedSearches: [],
  subscribedDocumentSearches: [],
  subscribedSources: {},
  selectedNotification: { ...INITIAL_SELECTED_NOTIFICATION }
};

const NotificationsStore = createContext<{
  notificationsState: NotificationsContextInterface;
  notificationsDispatch: React.Dispatch<any>;
}>({ notificationsState: initialState, notificationsDispatch: () => null });
const { Provider } = NotificationsStore;

export const NotificationsStateProvider = ({ children }: Props) => {
  const [notificationsState, notificationsDispatch] = useReducer(reducers, initialState);
  return <Provider value={{ notificationsState, notificationsDispatch }}>{children}</Provider>;
};

export default NotificationsStore;
