import React, { useContext, useEffect, useState, useMemo } from 'react';
import capitalize from 'lodash/capitalize';

// Other components
import Insights from '../../Insights/Insights';
import { getBlaStatsSubset } from '../../../api/pages/Biologics';

// Context provider
import ResultStore from '../../../store/SearchResults';
import VISUALIZE_CHART_MAPPING from '../../Insights/const';

const getPieChartData = (data: { [key: string]: any }) => {
  return Object.keys(data).map(key => ({
    id: capitalize(key),
    label: capitalize(key),
    value: data[key]
  }));
};

interface Card {
  text: string;
  count: number;
}

interface LineGraphData {
  year: string;
  count: number;
  mean: number;
}

interface BusinessIntelligenceProps {
  payload: Array<any>;
}
const BusinessIntelligence = ({ payload = [] }: BusinessIntelligenceProps) => {
  const { resultsState } = useContext(ResultStore);
  const [percentages, setPercentages] = useState<Record<string, Array<any>>>({});

  const [linegraph, setLineGraph] = useState<Record<string, LineGraphData[]>>({
    approval_time: [],
    approval_year: []
  });
  const [cards, setCards] = useState<Card[]>([]);
  const [isLoading, setLoading] = useState<boolean>(false);

  const parseApprovalTimeGraphData = (businessIntelligence: { [key: string]: any }) => {
    return businessIntelligence?.median_approval_time
      ?.map(({ year, count }: any) => {
        const mean = businessIntelligence?.approvaltimeGraph.find(
          (elem: { year: any }) => elem?.year === year
        )?.count;
        return {
          year,
          count: parseInt(count, 10),
          mean: mean ? parseInt(mean, 10) : 0
        };
      })
      ?.sort(
        (a: { year: string }, b: { year: string }) => parseInt(a.year, 10) - parseInt(b.year, 10)
      );
  };

  const parseApprovalYearGraphData = (businessIntelligence: { [key: string]: any }) => {
    return businessIntelligence?.approvalyearGraph
      ?.map(({ year, count }: any) => ({
        year,
        count: parseInt(count, 10)
      }))
      ?.sort(
        (a: { year: string }, b: { year: string }) => parseInt(a.year, 10) - parseInt(b.year, 10)
      );
  };

  // Main data fetching and state updates
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        let response: any = {};
        if (payload?.length) {
          response = await getBlaStatsSubset(payload);
        }

        const businessIntelligence: any = response?.data?.Success?.business_intelligence;
        if (!businessIntelligence) return;

        setPercentages({
          accelerated_approval: getPieChartData(businessIntelligence?.Accelerated_Approval),
          breakthrough_status: getPieChartData(businessIntelligence?.Breakthrough_designation),
          fast_track: getPieChartData(businessIntelligence?.Fast_track),
          orphan_status: getPieChartData(businessIntelligence?.Orphan_status)
        });

        const approvaltimeGraphData = parseApprovalTimeGraphData(businessIntelligence);
        const approvalyearGraphData = parseApprovalYearGraphData(businessIntelligence);

        setLineGraph({
          approval_time: approvaltimeGraphData,
          approval_year: approvalyearGraphData
        });

        setCards([
          { text: 'Applications', count: businessIntelligence?.bla_count || 0 },
          { text: 'Active Ingredients', count: businessIntelligence?.activeIngredients || 0 },
          { text: 'Formulations', count: businessIntelligence?.formulations || 0 }
        ]);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      } finally {
        setLoading(false);
      }
    };

    getData();
  }, [payload]);

  const getLayer1Charts = useMemo(() => {
    const graphList = VISUALIZE_CHART_MAPPING['usfda-business-intelligence'].layer1;
    if (linegraph && graphList) {
      return graphList.map((graph: { apikey: string }) => ({
        ...graph,
        data: linegraph[graph.apikey] || []
      }));
    }
    return graphList;
  }, [linegraph]);

  const getLayer2Charts = useMemo(() => {
    const graphList = VISUALIZE_CHART_MAPPING['usfda-business-intelligence'].layer2;
    if (percentages) {
      return graphList.map((graph: { apikey: string }) => ({
        ...graph,
        data: percentages[graph.apikey] || []
      }));
    }
    return graphList;
  }, [percentages]);

  return (
    <Insights
      source='us'
      searchType='quicksearch'
      appliedFilter={{}}
      statsList={cards}
      searchTerm={resultsState?.decryptedPayload?.query}
      layer1Component={getLayer1Charts}
      layer2Component={getLayer2Charts}
      isLoading={isLoading}
      searchId={resultsState.searchIds.applicationSearchId}
    />
  );
};

export default React.memo(BusinessIntelligence);
