/* eslint-disable react/require-default-props */
import React, { useContext, useMemo, useState } from 'react';
import {
  Box,
  Chip,
  Grow,
  IconButton,
  Link,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';

import {
  ThreeDotIcon,
  ShareIcon,
  Reg360Icon,
  AriaResponseSelected,
  AriaResponseDefault
} from '../../../assets/svgs/Icons';

import GlobalStore from '../../../store';

import ShareLinks from '../../Dialogs/ShareLink';

import styles from './styles/AriaCard.styles';

import FavoriteAndProjectActions from '../../FavoritesAndProjectsAction/FavoriteAndProjectActions';
import { RoundedMenu } from '../Menu';

import AriaResponse from '../../../pages/SearchResults/components/AriaResponse';

type AriaCardProps = {
  isSelected?: boolean;
  handleResultClick: any;
  handleShareClick: any;
  searchId: string;
  handleFavoriteClick: Function;
  handleProjectClick: Function;
  handleAriaResponseClick: any;
  documentCardDetails: any;
  applicationType?: React.ReactNode | null;
  flag?: React.ReactNode | null;
  cderOrCdrhChip?: React.ReactNode | null;
  scoreConfidence?: React.ReactNode | null;
  ariaResponse?: any;
  isPdfViewOpen?: boolean;
  showAriaResponseBtn?: boolean;
  ariaResponseLoading?: boolean;
  originalQuestion?: string;
  documentDataSourceChipOptions?: any;
  showFavoriteAndProjectActions?: boolean;
  allowShareThroughCollaborate?: boolean;
  openReg360InNewTab?: boolean;
  increaseTagSize?: boolean;
  hideMoreMenu?: boolean;
  notificationView?: boolean;
};

const AriaCard: React.FC<AriaCardProps> = ({
  isSelected = false,
  handleResultClick,
  documentCardDetails,
  handleShareClick,
  handleFavoriteClick,
  handleProjectClick,
  handleAriaResponseClick,
  ariaResponse,
  flag = null,
  cderOrCdrhChip = null,
  scoreConfidence = null,
  searchId,
  isPdfViewOpen,
  showAriaResponseBtn = true,
  ariaResponseLoading = false,
  originalQuestion = '',
  documentDataSourceChipOptions = {},
  allowShareThroughCollaborate = true,
  showFavoriteAndProjectActions = true,
  openReg360InNewTab = false,
  increaseTagSize = false,
  hideMoreMenu = false,
  notificationView = false
}) => {
  const { state } = useContext(GlobalStore) as any;

  const [documentsDotToolsOpen, setDocumentsDotToolsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleToolsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setDocumentsDotToolsOpen(!documentsDotToolsOpen);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getBgColor = () => {
    if (documentCardDetails.isSuggestedAnswer) return 'primary.0';
    if (isSelected) return 'gray.50';
    return 'none';
  };

  const tooltipTitle =
    documentCardDetails.reg360 && !notificationView
      ? 'Open Regulatory 360 view'
      : `${documentCardDetails.documentTitle} ${
          documentCardDetails.subtitle ? `(${documentCardDetails.subtitle})` : ''
        }`;

  const renderMetadataItems = useMemo(
    () =>
      documentCardDetails.metadataList.map((tags: any) => (
        <>
          <Tooltip key={tags.id} title={`${tags.label}: ${tags.value}`} placement='bottom-end'>
            <Typography
              noWrap
              variant='body1'
              sx={increaseTagSize ? styles.largeTagText : styles.tagText}>
              {tags.value}
            </Typography>
          </Tooltip>
          <Typography color='gray.500'>&#x2022;</Typography>
        </>
      )),
    [documentCardDetails.metadataList]
  );

  const renderDocumentDataSource = useMemo(
    () => (
      <>
        <Typography color='gray.500'>&#x2022;</Typography>
        <Chip
          size='small'
          label={documentDataSourceChipOptions?.label}
          sx={{ ...styles.labelChip, ...documentDataSourceChipOptions?.styles }}
        />
      </>
    ),
    [documentCardDetails?.dataSource]
  );

  return (
    <Grow in>
      <Stack
        spacing={1.5}
        sx={{
          borderBottom: '1px solid #EEEEEE',
          bgcolor: getBgColor(),
          ...styles.cardContainer,
          ...(notificationView && { py: '20px' })
        }}>
        {/* paragraph text */}
        <Stack spacing={2} direction='row' justifyContent='center' alignItems='center'>
          <Tooltip
            title={
              notificationView ? (
                <Box dangerouslySetInnerHTML={{ __html: documentCardDetails.fullParagraphText }} />
              ) : (
                ''
              )
            }
            placement={notificationView ? 'left' : 'bottom'}>
            <Typography
              variant='subtitle2'
              maxHeight={110}
              sx={{
                ...(isSelected ? styles.descriptionSelected : styles.description),
                ...(notificationView && styles.notificationView) // Add styles for notification view
              }}
              align='justify'
              overflow='hidden'
              textOverflow='ellipsis'
              onClick={(e: any) => {
                e.stopPropagation();
                handleResultClick({
                  resultId: documentCardDetails.resultId,
                  resultDetails: documentCardDetails,
                  pdfUrl: documentCardDetails.pdfUrl,
                  pdfUrlOriginal: documentCardDetails.pdfUrlOriginal
                });
              }}
              dangerouslySetInnerHTML={{ __html: documentCardDetails.paragraphText }}
            />
          </Tooltip>

          {showAriaResponseBtn && (
            <Tooltip title='Generate an AI-powered summary of this result. Get key insights in seconds.'>
              <IconButton
                size='small'
                sx={{
                  '&:hover': {
                    background: 'none'
                  }
                }}
                disabled={ariaResponseLoading}
                onClick={(e: any) => {
                  e.stopPropagation();
                  if (!isPdfViewOpen) {
                    handleAriaResponseClick(documentCardDetails);
                  } else {
                    handleResultClick({
                      resultId: documentCardDetails.resultId,
                      resultDetails: documentCardDetails,
                      pdfUrl: documentCardDetails.pdfUrl,
                      pdfUrlOriginal: documentCardDetails.pdfUrlOriginal,
                      triggerAriaResponse: true
                    });
                  }
                }}>
                {ariaResponse && ariaResponse?.resultId === documentCardDetails.resultId ? (
                  <AriaResponseSelected />
                ) : (
                  <AriaResponseDefault />
                )}
              </IconButton>
            </Tooltip>
          )}
        </Stack>

        {/* aria response */}
        {isPdfViewOpen && ariaResponse?.resultId === documentCardDetails.resultId && (
          <AriaResponse
            shouldInitialize={originalQuestion !== ''}
            originalQuestion={originalQuestion}
          />
        )}

        {/* main heading row */}
        <Stack>
          <Tooltip
            title={
              notificationView &&
              !tooltipTitle.includes('Open Regulatory 360 view') &&
              documentCardDetails.reg360 ? (
                <>
                  {tooltipTitle}
                  <Box fontWeight='bold' mt={0.5}>
                    Click to open regulatory 360 view
                  </Box>
                </>
              ) : (
                tooltipTitle
              )
            }
            placement={notificationView ? 'left' : 'bottom'}>
            <Link
              href={documentCardDetails.reg360 || documentCardDetails.meetingLink || '#'}
              underline='hover'
              variant='h4'
              noWrap
              target={openReg360InNewTab ? '_blank' : '_self'}
              onClick={event => {
                if (!documentCardDetails.reg360 && !documentCardDetails.meetingLink) {
                  event.preventDefault(); // Prevent navigation
                }
              }}
              sx={{
                ...styles.title,
                ...(!(documentCardDetails?.reg360 || documentCardDetails.meetingLink) &&
                  styles.nonReg360Title),
                ...(notificationView && { maxWidth: '100%', fontSize: '16px !important' })
              }}>
              {documentCardDetails.documentTitle}{' '}
              {documentCardDetails.subtitle ? `(${documentCardDetails.subtitle})` : ''}
            </Link>
          </Tooltip>
          {/* metadata tags */}
          <Stack direction='row' display='flex' justifyContent='space-between'>
            <Stack
              direction='row'
              spacing={1}
              alignItems='center'
              useFlexGap
              flexWrap='wrap'
              sx={{ overflowX: 'hidden' }}>
              {renderMetadataItems}
              {flag}
              {documentCardDetails.applicationType && (
                <Tooltip title={documentCardDetails.applicationType.tooltipTitle}>
                  <Chip
                    size='small'
                    label={documentCardDetails.applicationType.label}
                    key={documentCardDetails.applicationType.label}
                    sx={{
                      ...styles.labelChip,
                      bgcolor: documentCardDetails.applicationType.color
                    }}
                  />
                </Tooltip>
              )}
              {cderOrCdrhChip}
              {Object.keys(documentDataSourceChipOptions)?.length > 0 && renderDocumentDataSource}
            </Stack>
            <Stack direction='row' spacing={2} alignItems='center'>
              {showFavoriteAndProjectActions &&
                (documentCardDetails.item?.isFavorite ||
                  documentCardDetails.item?.inProjects?.length > 0) && (
                  <FavoriteAndProjectActions
                    source={documentCardDetails.sourceName}
                    id={documentCardDetails.resultId}
                    documentSearchIDInResult={searchId}
                    invokedBy='ariaResult'
                    sourceIndex=''
                    isButton
                    isFavorite={documentCardDetails.item?.isFavorite}
                    inProjects={documentCardDetails.item?.inProjects}
                    setFavoriteInHook={handleFavoriteClick}
                    setInProjectInHook={handleProjectClick}
                    ariaResultData={documentCardDetails?.item}
                  />
                )}
              <Stack>{scoreConfidence}</Stack>
              {!hideMoreMenu &&
                (documentCardDetails.reg360 ||
                  allowShareThroughCollaborate ||
                  showFavoriteAndProjectActions) && (
                  <Tooltip title='More options'>
                    <IconButton size='small' onClick={handleToolsClick}>
                      <ThreeDotIcon fontSize='small' />
                    </IconButton>
                  </Tooltip>
                )}
              {state.shareLink?.open &&
                state.shareLink?.ariaResultId === documentCardDetails?.resultId && <ShareLinks />}

              <RoundedMenu
                id='basic-menu'
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'basic-button'
                }}
                sx={styles.roundedMenu}>
                {documentCardDetails.reg360 && (
                  <Link
                    href={documentCardDetails.reg360 ? documentCardDetails.reg360 : '#'}
                    target='_blank'
                    onClick={handleClose}
                    sx={{ textDecoration: 'none' }}>
                    <MenuItem sx={styles.menuItem}>
                      <ListItemIcon sx={styles.listItemIcon}>
                        <Reg360Icon sx={styles.svgIcon} />
                      </ListItemIcon>
                      <ListItemText sx={styles.listItemText}>
                        {documentCardDetails?.sourceName === 'ct'
                          ? 'Open Trial 360'
                          : 'Open Reg 360'}
                        &deg;
                      </ListItemText>
                    </MenuItem>
                  </Link>
                )}
                {allowShareThroughCollaborate && (
                  <MenuItem
                    onClick={() =>
                      handleShareClick({
                        resultId: documentCardDetails.resultId,
                        resultDetails: documentCardDetails,
                        pdfUrl: documentCardDetails.pdfUrl
                      })
                    }
                    sx={styles.menuItem}>
                    <ListItemIcon sx={styles.listItemIcon}>
                      <ShareIcon sx={styles.svgIcon} />
                    </ListItemIcon>
                    <ListItemText sx={styles.listItemText}>Share through collaborate</ListItemText>
                  </MenuItem>
                )}
                {showFavoriteAndProjectActions && (
                  <FavoriteAndProjectActions
                    source={documentCardDetails.sourceName}
                    id={documentCardDetails.resultId}
                    documentSearchIDInResult={searchId}
                    invokedBy='ariaResult'
                    sourceIndex=''
                    isButton={false}
                    isFavorite={documentCardDetails.item?.isFavorite}
                    inProjects={documentCardDetails.item?.inProjects}
                    setFavoriteInHook={handleFavoriteClick}
                    setInProjectInHook={handleProjectClick}
                    ariaResultData={documentCardDetails?.item}
                  />
                )}
              </RoundedMenu>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Grow>
  );
};

export default AriaCard;
