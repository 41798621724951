export const UTCtoLocalTime = (dateString: string) => {
  const date = new Date(dateString);
  const offset = new Date().getTimezoneOffset() / 60;
  date.setHours(date.getHours() + offset + 1);
  return date;
};

const getDateString = (dateUTCString: string) => {
  const date = UTCtoLocalTime(dateUTCString);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };
  const formattedDate = date.toLocaleDateString('en-US', options);
  return formattedDate;
};

export default getDateString;
