import groupBy from 'lodash/groupBy';
import { useCallback, useState } from 'react';
import { getDocumentsApi } from '../../../api/pages/cdp';

const useDocuments = (
  application: string,
  submission: string,
  source?: string,
  approvalDate?: any
) => {
  const [isLoading, setLoading]: [boolean, Function] = useState(false);
  const [errorMessage, setErrorMessage]: [string, Function] = useState('');
  const [documents, setDocuments]: [any, Function] = useState([]);

  const getDocuments = useCallback(async () => {
    setLoading(true);
    setErrorMessage('');
    setDocuments([]);
    try {
      const docSource = source === 'us' ? 'fda' : 'eu';
      const response: any = await getDocumentsApi(application, submission, docSource, approvalDate);
      if (response?.data?.Success) {
        let docs = response?.data?.Success;
        // eslint-disable-next-line no-underscore-dangle
        docs = docs.sort((a: any, b: any) => a._category.localeCompare(b._category));
        const groupedDocs = groupBy(docs, 'category_bucket');

        const sorted: any = {};

        const sortedKeys = Object.keys(groupedDocs).sort((a: string, b: string) =>
          a.localeCompare(b)
        );
        sortedKeys.forEach((key: string) => {
          sorted[key] = groupedDocs[key];
        });

        setDocuments(sorted);
      } else {
        setErrorMessage('Could not get the Clinical Trials Documents');
      }
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      setErrorMessage('Could not get the Clinical Trials Documents');
    }
  }, [application, submission]);

  return {
    isLoading,
    errorMessage,
    documents,
    getDocuments
  };
};

export default useDocuments;
