/* eslint-disable no-restricted-syntax */
import { SECTIONS } from './constants';

export interface ComparisonData {
  signedUrl: string;
  date: string;
  type: string;
  brandName?: string;
  source: string;
  fileName: string;
}
interface Topic {
  value: string;
  label: string;
}

interface Subsections {
  'Main Discussion': Topic[];
  'Annex I': Topic[];
}

type Sections = Record<string, Subsections>;

export const findSectionByValue = (value: string): string | undefined => {
  for (const [section, subsections] of Object.entries(SECTIONS as Sections)) {
    for (const topics of Object.values(subsections)) {
      if (topics.some((topic: any) => topic.value === value)) {
        return section;
      }
    }
  }

  return undefined;
};

export const shortDateFormat = (date: string) =>
  new Date(date).toLocaleString('en-US', {
    month: 'short',
    year: 'numeric'
  });

export const formatDate = (date: string) =>
  new Date(date).toLocaleString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });

export const isEmptyObject = (data: any) => Object.values(data).every(value => value === '');

export const isTopicValid = (topic: any): boolean => {
  const fields = ['summary', 'action', 'background', 'discussion'] as const;

  return fields.some(field => {
    const value = topic?.[field];
    return value && value !== 'NA';
  });
};
export const getDetailsText = (
  summary: string,
  action: string,
  background: string,
  discussion: string,
  isExpanded: boolean
) => {
  const isNoDetails = [summary, action, background, discussion].every(
    text => !text || text === 'NA'
  );

  if (isNoDetails) {
    return 'No Details available from this Source';
  }
  if (isExpanded) {
    return 'Hide Details';
  }
  return 'View Details';
};
