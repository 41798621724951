import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Typography, Divider, Stack, Tooltip, Box } from '@mui/material';

import RegulatoryInformationStore from '../../../store/RegulatoryInformation';

import styles from './styles';
import ReportButton from '../../../components/Report/ReportButton';
import useReportingHandler from '../../../components/Report/hooks/useReportingHandler';

interface SafetyDataItem {
  id: string;
  sample_size: string;
  description: string;
  // Add any additional fields present in your safety data items
}

interface SafetyDataMetadata {
  sample_size: string;
  description: string;
  // Define any additional fields present in your metadata
}

interface DataItem {
  snapshot?: any;
  center?: string;
  submission_num?: string;
  submission_type?: string;
  // Include other properties of DataItem as needed
}

interface LabelValueRowProps {
  label: string;
  value: string | number;
}

interface SafetyDataRowProps {
  data: SafetyDataItem & { index: number }; // Combining SafetyDataItem with an index
  metadata: SafetyDataMetadata;
  lastItem: boolean;
}

const LabelValueRow: React.FC<LabelValueRowProps> = ({ label, value }) => (
  <Stack direction='row' spacing={2} alignItems='center'>
    <Typography variant='subtitle2' sx={styles.snapshotLabel}>
      {label}:
    </Typography>
    <Typography variant='subtitle2' sx={styles.snapshotText}>
      {value}
    </Typography>
  </Stack>
);

const SafetyDataRow: React.FC<SafetyDataRowProps> = ({ data, metadata, lastItem }) => (
  <>
    <LabelValueRow label={metadata.sample_size} value={data.sample_size} />
    <LabelValueRow label={metadata.description} value={data.description} />
    {!lastItem && <Divider />}
  </>
);

interface SubmissionSummaryProps {
  dataItem: DataItem;
  selectedTabIndex: number;
}

const SubmissionSummary: React.FC<SubmissionSummaryProps> = ({ dataItem, selectedTabIndex }) => {
  const { source, number } = useParams<any>();
  const { regulatoryState } = useContext<any>(RegulatoryInformationStore);
  const { snapshot } = dataItem;
  const selectedTab = Object.keys(snapshot)[selectedTabIndex];
  const { updateReportData } = useReportingHandler();

  const addToReport = (_: any, reportData: any) => {
    const sectionLayout = {
      id: reportData?.reportId,
      sectionType: 'SNAPSHOT',
      data: {
        number,
        document_id: snapshot[selectedTab]?.document_id,
        source,
        submission_num: dataItem?.submission_num,
        submission_type: dataItem?.submission_type,
        summaryType: 'SUBMISSION_SUMMARY'
      },
      style: {
        placement: {
          h: 50,
          w: 12,
          i: reportData?.reportId,
          minW: 8,
          moved: false,
          static: false,
          x: 0,
          y: 0
        },
        title: `Submission Summary - ${
          typeof selectedTab === 'string'
            ? selectedTab
            : regulatoryState?.applicationDetails?.product_name
        } - ${dataItem?.submission_type} - ${dataItem?.submission_num}`
      }
    };

    updateReportData(reportData?.id, sectionLayout);
  };

  return (
    <Stack
      spacing={2}
      sx={{
        bgcolor: 'white.main',
        borderRadius: '12px',
        p: '20px'
      }}>
      <Box display='flex' justifyContent='space-between'>
        <Tooltip title='Provides an overview at the submission level' placement='top-start'>
          <Typography variant='subtitle1' sx={styles.snapshotHeading}>
            Submission Summary
          </Typography>
        </Tooltip>
        {snapshot[selectedTab]?.safety_data?.data &&
          snapshot[selectedTab]?.safety_data?.data?.length > 0 && (
            <Tooltip title='Add to Report'>
              <ReportButton
                data={{}}
                addToReport={addToReport}
                prefixId={`snapshot_submission_summary_${source}_${number}_${dataItem?.submission_type}_${dataItem?.submission_num}_${snapshot[selectedTab]?.document_id}`}
                sectionType='snapshot'
              />
            </Tooltip>
          )}
      </Box>

      <LabelValueRow
        label='No. of indications'
        value={snapshot[selectedTab]?.clinical_summary?.length || 0}
      />
      <Divider />

      {(snapshot[selectedTab]?.safety_data?.data?.length ?? 0) === 0 &&
        dataItem.center === 'CBER' && (
          <Typography variant='subtitle2' sx={styles.snapshotText}>
            CBER Extraction is in progress...
          </Typography>
        )}

      {(snapshot[selectedTab]?.safety_data?.data?.length ?? 0) > 0 && (
        <Typography variant='subtitle2' sx={styles.snapshotSubHeading}>
          Clinical trial safety database summary used to support product safety
        </Typography>
      )}

      {snapshot[selectedTab]?.safety_data?.data?.map((item: any, index: any, array: any) => (
        <SafetyDataRow
          key={item.id}
          data={{ ...item, index }}
          metadata={snapshot[selectedTab].safety_data!.metadata} // Asserting metadata is non-null
          lastItem={index === array.length - 1}
        />
      ))}
    </Stack>
  );
};

export default SubmissionSummary;
