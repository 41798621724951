import React from 'react';
import { Box, LinearProgress } from '@mui/material';
import LogoIcon from '../../../../assets/svgs/Icons/LogoIcon';

const Loading = () => {
  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='center'
      alignItems='center'
      height='40vh'>
      <LogoIcon sx={{ fontSize: 60 }} />
      <Box width={240} mt={2} mb={3}>
        <LinearProgress
          sx={{
            borderRadius: 4,
            color: 'primary.avatar',
            bgcolor: 'gray.300'
          }}
        />
      </Box>
    </Box>
  );
};

export default Loading;
