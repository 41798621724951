import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { isEmpty } from '../../../helpers/base';
// mui v5

// componentns
import AskRIAButton from '../../../components/Buttons/AskRiaButtonAdcomm';
import AriaDrawerCore from '../../SearchResults/components/AriaDrawerCore';
import BottomDrawer from '../../../components/CustomComponents/BottomDrawer';
import Meetings from './Meetings';
import Header from '../../EMAMeetings/components/common/Header';
import NewFilters from '../../../components/NewFilters';

// Store
import GlobalStore from '../../../store';

// hooks
import usePDCOData from '../hooks/usePDCOData';

import { Stat } from '../../SearchResults/types';

import { saveAriaCache } from '../../Aria/utils/ariaCache';
import AskRiaButtonStyles from '../../../components/Buttons/styles/AskRiaButton.styles';
import useAriaShareHandler from '../../../components/AriaShare/useAriaShareHandler';
import HeaderTitle from '../../EMAMeetings/components/common/HeaderTitle';
import HomePage from '../../EMAMeetings/components/common/HomePage';

const PDCOTabs = () => {
  const { dispatch } = useContext(GlobalStore) as any;
  const [pdcoStat, setPdcoStat] = useState<Stat[]>([]);
  // eslint-disable-next-line no-unused-vars
  const [appliedFilters, setAppliedFilters] = useState({});
  const [ariaModalOpen, setAriaModalOpen] = React.useState(false);
  const [isVisualizeOpen, setIsVisualizeOpen] = useState(false);
  const {
    getPDCOMeetings,
    meetingsList,
    meetingsStats,
    meetingsListLoading,
    meetingDetailsLoading,
    pdcoFilters,
    selectedResources,
    selectedMeeting,
    handleSelectMeeting,
    meetingType,
    handleSelectMeetingType,
    isFilterApplied
  } = usePDCOData();

  useEffect(() => {
    getPDCOMeetings();
  }, []);

  useEffect(() => {
    if (meetingsStats) {
      const newStats: Stat[] = [];
      newStats.push({
        title: 'Total Meetings',
        value: meetingsStats?.total_meetings || 0
      });
      newStats.push({
        title: 'Documents',
        value: meetingsStats?.total_documents || 0
      });
      newStats.push({
        title: 'Pages',
        value: meetingsStats?.total_pages || 0
      });
      setPdcoStat(newStats);
    }
  }, [pdcoFilters, meetingsStats]);
  const [ariaOption, setAriaOption] = useState<string>('all');

  useEffect(() => {
    if (!isEmpty(selectedResources)) {
      setAriaOption('current');
    } else {
      setAriaOption('all');
    }
  }, [selectedResources]);

  const handleAriaNavigate = (ariaModalOpenValue = true) => {
    const meetingIds: string[] = Object.values(meetingsList)
      .flatMap(values => values)
      .filter((obj: any) => 'meeting_id' in obj)
      .map((obj: any) => obj.meeting_id);

    saveAriaCache(
      [],
      `/pdco`,
      'PDCO',
      isFilterApplied
        ? {
            'group-id': meetingIds
          }
        : {}
    );
    setAriaModalOpen(ariaModalOpenValue);
  };

  const handleFilterApply = useCallback((currentfilters: any) => {
    getPDCOMeetings(currentfilters);
    setAppliedFilters(currentfilters);
  }, []);

  useAriaShareHandler(false, ariaModalOpen, setAriaModalOpen);

  const getAriaDocuments = () => {
    if (ariaOption === 'current') {
      return [{ identifier: selectedMeeting?.meeting_stats?.meeting_id }];
    }
    if (!isFilterApplied) {
      return [];
    }
    const ariaDocuments = Object.values(meetingsList)
      .flatMap(values => values)
      ?.map((item: any) => ({
        identifier: item.meeting_id
      }));
    return ariaDocuments || [];
  };

  const getDocumentStats = (choice: string): any => {
    if (choice === 'document_count') {
      if (ariaOption === 'current') {
        const docCount = Object.values(selectedResources).flatMap(values => values).length;
        return docCount || 0;
      }
      return meetingsStats?.total_documents;
    }
    if (choice === 'page_count') {
      if (ariaOption === 'current') {
        const pageCount = Object.values(selectedResources)
          .flatMap(values => values)
          .reduce((acc: any, obj: any) => acc + (obj as { page_count: number }).page_count, 0);
        return pageCount || 0;
      }
      return meetingsStats?.total_pages;
    }
    return '';
  };

  const handleVisualizeOpen = () => {
    setIsVisualizeOpen(true);
  };

  const closeDrawer = () => {
    setIsVisualizeOpen(false);
  };

  return (
    <>
      <Header
        title={
          <HeaderTitle
            meetingName='Paediatric Committee (PDCO)'
            tooltipContent="The Paediatric Committee (PDCO) is the European Medicines Agency's (EMA) scientific committee responsible for activities on medicines for children and to support the development of such medicines in the European Union by providing scientific expertise and defining paediatric needs."
          />
        }
        stats={pdcoStat}
        handleVisualizeOpen={handleVisualizeOpen}
      />
      <Meetings
        allMeetingsByYear={meetingsList}
        selectedMeeting={selectedMeeting}
        resources={selectedResources}
        handleSelectMeeting={handleSelectMeeting}
        meetingListLoading={meetingsListLoading}
        meetingDetailsLoading={meetingDetailsLoading}
        meetingType={meetingType}
        handleSelectMeetingType={handleSelectMeetingType}
      />
      <BottomDrawer
        isDrawerOpen={isVisualizeOpen}
        closeDrawer={closeDrawer}
        hideReportIcon
        hideDivider>
        <HomePage
          stats={{ ...meetingsStats, statsTiles: pdcoStat }}
          loading={meetingsListLoading}
        />
      </BottomDrawer>
      <Box px={2.8}>
        <NewFilters
          data={pdcoFilters}
          applyFilters={handleFilterApply}
          isLoading={meetingsListLoading}
          showSearchKeywordInBottomFilters
          source='pdco'
        />
      </Box>
      {ariaModalOpen && (
        <AriaDrawerCore
          applications={getAriaDocuments()}
          sources={{ eu: ['pdco'] }}
          groupId='meeting-year'
          docCount={getDocumentStats('document_count')}
          docPageCount={getDocumentStats('page_count')}
          modalOpen={ariaModalOpen}
          setModalOpen={setAriaModalOpen}
          dispatch={dispatch}
        />
      )}
      <Box sx={AskRiaButtonStyles.bottomFilterPosition}>
        <AskRIAButton
          setOpenAriaSearch={handleAriaNavigate}
          ariaOption={ariaOption}
          setAriaOption={setAriaOption}
          disabled={meetingsList.length === 0}
          showCurrentMeeting={!isEmpty(selectedResources)}
          currentMeeting={getDocumentStats('document_count')}
        />
      </Box>
    </>
  );
};

export default PDCOTabs;
