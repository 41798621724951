import React from 'react';
import { Stack, Box, Divider } from '@mui/material';
import SectionHeader from './SectionHeader';
import SectionDetails from './SectionDetails';
import SectionAccordion from './SectionAccordion';
import styles from '../../../EMAMeetings/styles/Meeting.styles';

interface SectionProps {
  topic: any;
  index: number;
  expanded: string | false;
  // eslint-disable-next-line no-unused-vars
  handleChange: (id: string) => void;
  meetingId: string;
}

const Section: React.FC<SectionProps> = ({ topic, index, expanded, handleChange, meetingId }) => (
  <Stack
    key={`${topic.sub_section_number}-${index}`}
    spacing={1}
    aria-label='section-item-card'
    width='100%'
    sx={{ margin: '0px !important' }}>
    {topic.application_details.map((details: any, idx: any) => {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <Box sx={styles.cardBox} key={idx}>
          <SectionHeader
            topicIndex={index}
            index={idx}
            details={details}
            topic={topic}
            meetingId={meetingId}
          />
          <Box sx={styles.cardSection}>
            <SectionDetails scope={topic.scope} applicants={details.applicants} />
            <Divider
              orientation='horizontal'
              sx={{ pt: 1.5, borderColor: 'gray.200', backgroundColor: 'transparent !important' }}
            />
            <SectionAccordion
              topic={topic}
              details={details}
              expanded={expanded}
              subSectionNumber={topic.sub_section_number}
              idx={idx}
              handleChange={handleChange}
            />
          </Box>
        </Box>
      );
    })}
  </Stack>
);

export default React.memo(Section);
