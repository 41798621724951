import React, { useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// component
import DocumentListing from '../../../components/Dialogs/DocumentListing';

// utils
import { DocumentItem, DocumentsGroup } from '../../../components/Dialogs/DocumentListing/types';
import getDateString from '../../../utils/getDateString';
import { getPediatricsDocumentsSignedURL } from '../../../api/pages/Pediatrics';

// styles
import styles from '../../EMAGuidance/styles/DocumentHistory.styles';

// Icons
import { FolderIcon } from '../../../assets/svgs/Icons';

const GridCellDocuments: React.FC<any> = ({ data }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenPopup = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  const getPDFUrlFromS3Path = async (pdfLink: string) => {
    const res: any = await getPediatricsDocumentsSignedURL({
      source: 'pips',
      s3_path: pdfLink
      // eslint-disable-next-line no-console
    }).catch(err => console.error(err));
    return res?.data?.body?.s3_url;
  };

  const documents: DocumentsGroup[] = useMemo(() => {
    const docs: DocumentItem[] = data.file_names.map((item: any, index: number) => {
      return {
        title: item.split('.pdf')[0].replaceAll('_', ' '),
        title_link: data.s3_paths[index],
        getPdfUrl: getPDFUrlFromS3Path
      };
    });

    const group: DocumentsGroup = {
      id: 'documents',
      categoryTitle: 'Documents',
      documents: docs
    };

    return [group];
  }, [data]);

  const chatRIAMetadata = useMemo(() => {
    return {
      decision_date: data?.decision_date ?? '',
      'decision-number': data?.decision_number ?? '',
      formulation: data?.formulation ?? '',
      route: data?.route ?? '',
      active_ingredient: data?.active_ingredient ?? ''
    };
  }, [data]);

  const metadata = useMemo(() => {
    return [data?.active_ingredient, data?.decision_number].filter(
      (title: string) => !!title && title !== 'NA'
    );
  }, [data]);

  return (
    <Box sx={styles.textAlignment}>
      <Box alignItems='center' sx={{ cursor: 'pointer' }}>
        {data && (
          <Stack
            direction='row'
            spacing={0.5}
            alignItems='center'
            sx={styles.linkItem}
            onClick={e => handleOpenPopup(e)}>
            <FolderIcon sx={{ fontSize: 12 }} />
            <Typography variant='subtitle2' sx={styles.pdfCellText}>
              {data?.file_names?.length}
            </Typography>
          </Stack>
        )}
      </Box>

      {open && (
        <DocumentListing
          defaultDocuments={documents}
          defaultSelectedEntityId=''
          title={data?.decision_date ? getDateString(data?.decision_date) : data?.title}
          onClose={() => setOpen(false)}
          chatRIAMetadata={chatRIAMetadata}
          source='ema-pips'
          metadata={metadata}
          infoComponent={null}
          disableChatRia={false}
          secondaryDrawerTitle=''
        />
      )}
    </Box>
  );
};

const DocumentHistory = ({ row }: any) => {
  return <GridCellDocuments data={row} />;
};

export default DocumentHistory;
