import React from 'react';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';

interface TabButtonProps {
  // eslint-disable-next-line react/require-default-props
  disabled?: boolean;
  size: 'medium' | 'small' | 'large' | 'xsmall';
  title: string;
  // eslint-disable-next-line no-unused-vars
  onClick: (e: any) => void;
  selected: boolean;
  // eslint-disable-next-line react/require-default-props
  tooltipText?: string;
  // eslint-disable-next-line react/require-default-props
  icon?: any;
}

const selectedStyle = {
  color: 'primary.700',
  fontWeight: 700,
  borderColor: 'primary.50',
  backgroundColor: 'primary.50',
  '&:hover': {
    borderColor: 'primary.50',
    backgroundColor: 'primary.50'
  }
};

const sizeStyleMapping: { [key: string]: any } = {
  xsmall: {
    px: 1.5,
    py: 0.5,
    height: 28
  },
  small: {
    px: 3,
    py: 0.75,
    height: 32
  },
  medium: {
    px: 3,
    py: 1,
    height: 36
  }
};

const TabButton = ({
  disabled = false,
  size = 'medium',
  title,
  onClick,
  selected = false,
  tooltipText = '',
  icon = null
}: TabButtonProps) => {
  return (
    <Tooltip title={tooltipText}>
      <Button
        disabled={disabled}
        onClick={onClick}
        variant='outlined'
        endIcon={icon}
        sx={{
          py: 1,
          px: 3,
          borderRadius: 2,
          border: '1px solid',
          borderColor: 'gray.lightVariant8',
          color: 'gray.800',
          fontSize: 14,
          fontWeight: 500,
          lineHeight: 1,
          letterSpacing: '0.2px',
          textTransform: 'capitalize',
          minWidth: 'auto',
          '& .MuiSvgIcon-root': {
            fontSize: 14
          },
          '&:hover': {
            borderColor: 'gray.lightVariant8',
            backgroundColor: 'gray.100'
          },
          ...(sizeStyleMapping[size] || {}),
          ...(selected ? selectedStyle : {})
        }}>
        {title}
      </Button>
    </Tooltip>
  );
};

export default React.memo(TabButton);
