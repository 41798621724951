import { normalizeDate } from '../../utils/normalizeDate';

export const horizontalBarDefaultConfig = {
  alignmentgroup: 'True',
  orientation: 'h',
  showlegend: true,
  textposition: 'left',
  xaxis: 'x',
  yaxis: 'y',
  type: 'bar'
  // Example when Horizontal bar is selecetd.
  // marker: {
  //   color: '#239652',
  //   line: {
  //     width: '1',
  //     color: '#ff9b4e'
  //   }
  // }
};
export const horizontalReviewBarDefaultConfig = {
  alignmentgroup: 'True',
  orientation: 'h',
  showlegend: true,
  textposition: 'left',
  yaxis: 'y2',
  xaxis: 'x',
  type: 'bar',
  // Example when Horizontal bar is selecetd.
  marker: {
    color: '#e376cb'
  }
};

export const meetingDataConfig = {
  alignmentgroup: 'True',
  orientation: 'h',
  showlegend: true,
  textposition: 'bottom-center',
  yaxis: 'y2',
  xaxis: 'x',
  type: 'scatter',
  // Example when Horizontal bar is selecetd.
  marker: {
    color: '#616161',
    symbol: 'triangle-down',
    size: 14
  }
};

export const horizontalBlackShapeDefaultConfig = {
  line: {
    color: 'Black',
    width: 2,
    dash: 'dot'
  },
  name: 'hello',
  type: 'line',
  text: 'abc',
  hovertemplate: 'abccc'
};

export const SUBMISSION_FLAG_COLORS = ['#2d9cdb', '#ec4b9d'];

export const BAR_COLOR_WAY = ['#D4D4E0'];

export const OPP_BAR_COLOR_WAY: { [key: string]: string } = {
  '#EEF2F4': '#D4D4E0',
  '#D4D4E0': '#EEF2F4',
  '#83D0C4': '#E1F3F1'
};

export const verticalGreenBarDefaultConfig = {
  line: {
    color: SUBMISSION_FLAG_COLORS[0],
    width: 2,
    dash: 'dot'
  },
  opacity: 1,
  type: 'rect',
  xref: 'x',
  y0: 0,
  y1: 1,
  yref: 'y domain'
};

export const todayVerticalLineDefaultConfig = {
  line: {
    color: SUBMISSION_FLAG_COLORS[0],
    width: 2
  },
  opacity: 1,
  type: 'rect',
  xref: 'x',
  y0: 0,
  y1: 1,
  yref: 'y domain'
};

export const todayAnnotationsDefaultConfig = {
  xanchor: 'center',
  xref: 'x',
  y: 1.02,
  showarrow: true,
  ay: -1,
  ax: 0,
  yanchor: 'center',
  yref: 'y domain',
  font: {
    color: 'red'
  }
};

export const TRIALS_FILTERS = [
  {
    id: 'agency_class',
    label: 'Agency Class',
    field: 'sponsors.agency_class',
    fieldType: 'Array',
    filterType: 'checkbox',
    options: [
      // By Default we need to select 'INDUSTRY' filter.
      {
        label: 'INDUSTRY',
        value: 'INDUSTRY',
        selected: true
      }
    ]
  },
  {
    id: 'indications_and_usage',
    label: 'Conditions',
    field: 'conditions.name',
    fieldType: 'Array',
    filterType: 'checkbox'
  },
  {
    id: 'active_ingredients',
    label: 'Interventions',
    field: 'interventions.name',
    fieldType: 'Array',
    filterType: 'checkbox'
  },
  {
    id: 'sponsor_name',
    label: 'Sponsors',
    field: 'sponsors.name',
    fieldType: 'Array',
    filterType: 'checkbox'
  },
  {
    id: 'overall_status',
    label: 'Overall Status',
    field: 'overall_status',
    fieldType: 'String',
    filterType: 'checkbox'
  },
  {
    id: 'study_type',
    label: 'Study Type',
    field: 'study_type',
    fieldType: 'String',
    filterType: 'checkbox'
  },
  {
    id: 'start_date',
    label: 'Start Date',
    field: 'start_date',
    fieldType: 'String',
    filterType: 'year_range'
  },
  {
    id: 'primary_completion_date',
    label: 'Primary Completion Date',
    field: 'primary_completion_date',
    fieldType: 'String',
    filterType: 'year_range'
  },
  {
    id: 'phase',
    label: 'Phase',
    field: 'phase',
    fieldType: 'String',
    filterType: 'checkbox'
  },
  {
    id: 'gender',
    label: 'Gender',
    field: 'gender',
    fieldType: 'String',
    filterType: 'checkbox'
  },
  {
    id: 'healthy_volunteers',
    label: 'Healthy Volunteers',
    field: 'healthy_volunteers',
    fieldType: 'String',
    filterType: 'checkbox'
  },
  {
    id: 'minimum_age',
    label: 'Min Age',
    field: 'minimum_age',
    fieldType: 'String',
    filterType: 'checkbox'
  },
  {
    id: 'maximum_age',
    label: 'Max Age',
    field: 'maximum_age',
    fieldType: 'String',
    filterType: 'checkbox'
  }
];
export const EU_TRIALS_FILTERS = [
  {
    id: 'agency_class',
    label: 'Agency Class',
    field: 'protocols.sponsors.agency_class',
    fieldType: 'Array',
    filterType: 'checkbox',
    // options: [
    //   // By Default we need to select 'INDUSTRY' filter.
    //   {
    //     label: 'Commercial',
    //     value: 'commercial',
    //     selected: true
    //   }
    // ]
  },
  {
    id: 'indications_and_usage',
    label: 'Conditions',
    field: 'conditions.name',
    fieldType: 'Array',
    filterType: 'checkbox'
  },
  {
    id: 'overall_status',
    label: 'Overall Status',
    field: 'protocols.overall_status',
    fieldType: 'Array',
    filterType: 'checkbox'
  },
  {
    id: 'phase',
    label: 'Phase',
    field: 'phase',
    fieldType: 'String',
    filterType: 'checkbox'
  },
  {
    id: 'gender',
    label: 'Gender',
    field: 'gender',
    fieldType: 'String',
    filterType: 'checkbox'
  },
  {
    id: 'start_date',
    label: 'Start Date',
    field: 'start_date',
    fieldType: 'String',
    filterType: 'year_range'
  },
  {
    id: 'primary_completion_date',
    label: 'Primary Completion Date',
    field: 'primary_completion_date',
    fieldType: 'String',
    filterType: 'year_range'
  },
  {
    id: 'study_first_submitted_date',
    label: 'Study First Submitted Date',
    field: 'study_first_submitted_date',
    fieldType: 'String',
    filterType: 'year_range'
  }
];

export const GRAPH_ENTITY_DATA_MAPPING: { [key: string]: any } = {
  submission: { name: 'Type' },
  title: { name: 'Class' },
  actionDetails: { name: 'Action Details' },
  nctId: { name: 'NCT ID' },
  ctId: { name: 'Clinical Trial ID' },
  startDate: {
    name: 'Start Date',
    getValue: (val: string) => normalizeDate(val)
  },
  endDate: {
    name: 'End Date',
    getValue: (val: string) => normalizeDate(val)
  },
  primaryCompletionDate: {
    name: 'Primary Completion Date',
    getValue: (val: string) => normalizeDate(val)
  },
  sampleSize: { name: 'Sample Size' },
  phase: { name: 'Phase' },
  studyTitle: { name: 'Study Title' }
};

export const selectedBarMarker = {
  // color: '#239652', // TEMP: Commented. Will add after feedbacks
  line: {
    width: '2',
    color: '#FF0000'
  }
};

export const selectedApprovalBarMarker = {
  line: {
    width: '2',
    color: '#F79802'
  }
};

export const selectedMeetingMarker = {
  line: {
    width: '3',
    color: '#FF0000'
  }
};

export const GRAPH_ENTITIES = {
  CLINICAL_TRIAL: 'CLINICAL_TRIAL',
  FDA_APPROVAL: 'FDA_APPROVAL',
  REG_INFO_MEETING: 'REG_INFO_MEETING'
};

export const FDA_FILTERS = [
  {
    id: 'submission_class_code_description',
    label: 'Submission Class',
    field: 'title',
    fieldType: 'String',
    filterType: 'checkbox',
    options: []
  },
  {
    id: 'approval_date',
    label: 'Approval Date',
    field: 'approval_date',
    fieldType: 'String',
    filterType: 'year_range'
  },
  {
    id: 'accelerated_approval_flag',
    label: 'Accelerated Approval',
    value: 'all',
    filterType: 'radio_group',
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
      {
        label: 'All',
        value: 'all'
      }
    ]
  },
  {
    id: 'bt_status',
    label: 'Breakthrough',
    value: 'all',
    filterType: 'radio_group',
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
      {
        label: 'All',
        value: 'all'
      }
    ]
  },
  {
    id: 'fast_track_flag',
    label: 'Fast Track',
    value: 'all',
    filterType: 'radio_group',
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
      {
        label: 'All',
        value: 'all'
      }
    ]
  },
  {
    id: 'orphan_status',
    label: 'Orphan Status',
    value: 'all',
    filterType: 'radio_group',
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
      {
        label: 'All',
        value: 'all'
      }
    ]
  },
  {
    id: 'rtor_flag',
    label: 'RTOR',
    value: 'all',
    filterType: 'radio_group',
    options: [
      {
        label: 'Yes',
        value: 'yes'
      },
      {
        label: 'No',
        value: 'no'
      },
      {
        label: 'All',
        value: 'all'
      }
    ]
  }
];

export const EMA_FILTERS = [
  {
    id: 'approval_date',
    label: 'Approval Date',
    field: 'approval_date',
    fieldType: 'String',
    filterType: 'year_range'
  }
];
export const SUBMISSION_CLASS_COLOR: { [key: string]: string } = {
  '7': '#A1C7E2',
  '12': '#9CCDD7',
  '15': '#A1B8CE',
  '2': '#FFEBF5',
  '19': '#DDDDC5',
  others: '#D7D7D7'
};

export const SUBMISSION_CLASS_COLOR_DARK: { [key: string]: string } = {
  '7': '#57BAFF',
  '12': '#39D8E8',
  '15': '#498FCC',
  '2': '#FF75B8',
  '19': '#D9DA3F',
  others: '#A1A1A1'
};

export const FDA_LABEL_OPTIONS: any = [
  {
    label: 'NCT ID',
    id: 'nct_id',
    customDataIndex: 0,
    selected: false,
    prefix: ''
  },
  {
    label: 'Start Date',
    id: 'start_date',
    customDataIndex: 4,
    selected: false,
    prefix: 'Start'
  },
  {
    label: 'End Date',
    id: 'end_date',
    customDataIndex: 5,
    selected: false,
    prefix: 'End'
  },
  {
    label: 'Primary Completion Date',
    id: 'primary_completion_date',
    customDataIndex: 7,
    selected: false,
    prefix: 'Primary'
  },
  {
    label: 'Sample Size',
    id: 'sample_size',
    customDataIndex: 1,
    selected: true,
    prefix: 'N'
  },
  {
    label: 'Phase',
    id: 'phase',
    customDataIndex: 2,
    selected: true,
    prefix: ''
  },
  {
    label: 'Status',
    id: 'status',
    customDataIndex: 8,
    selected: false,
    prefix: ''
  }
];

export const MEETING_TEXT_MAP: { [key: string]: string } = {
  'Pre-IND': 'P-IND',
  'Pre-BLA': 'P-BLA',
  'Pre-NDA': 'P-NDA',
  Other: 'Other',
  EOP1: 'EOP1',
  EOP2: 'EOP2',
  'Pre-Phase 3': 'P-Phase 3',
  'Type C': 'Type C',
  'Type A': 'Type A',
  'Type D': 'Type D',
  INTERACT: 'Interact'
};
export const EMA_LABEL_OPTIONS: any = [
  {
    label: 'Clinical trial ID',
    id: 'nct_id',
    customDataIndex: 0,
    selected: false,
    prefix: ''
  },
  {
    label: 'Start Date',
    id: 'start_date',
    customDataIndex: 4,
    selected: false,
    prefix: 'Start'
  },
  {
    label: 'End Date',
    id: 'end_date',
    customDataIndex: 5,
    selected: false,
    prefix: 'End'
  },
  {
    label: 'Primary Completion Date',
    id: 'primary_completion_date',
    customDataIndex: 7,
    selected: false,
    prefix: 'Primary'
  },
  {
    label: 'Sample Size',
    id: 'sample_size',
    customDataIndex: 1,
    selected: true,
    prefix: 'N'
  },
  {
    label: 'Phase',
    id: 'phase',
    customDataIndex: 2,
    selected: true,
    prefix: ''
  },
  {
    label: 'Status',
    id: 'status',
    customDataIndex: 8,
    selected: false,
    prefix: ''
  }
];
