import React from 'react';
import { Box, Typography, Tooltip, Divider } from '@mui/material';
import SectionDetails from '../MeetingDetails/SectionDetails';
import TimelineTrackHeader from './TimelineTrackHeader';
import styles from '../../styles/Timeline.styles';

interface TimelineOverviewProps {
  topic: any;
  details: any;
  // eslint-disable-next-line no-unused-vars
  setOpenDocument: (openDocument: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setDocumentPath: (documentPath: string) => void;
  // eslint-disable-next-line no-unused-vars
  setDocumentTitle: (documentTitle: string) => void;
  // eslint-disable-next-line no-unused-vars
  setDocumentStartDate: (documentStartDate: string) => void;
  selectedLeftDrawerData: any;
  // eslint-disable-next-line no-unused-vars
  setSelectedLeftDrawerData: (selectedLeftDrawerData: any) => void;
}

const TimelineOverview = ({
  topic,
  details,
  selectedLeftDrawerData,
  setSelectedLeftDrawerData,
  setDocumentPath,
  setDocumentTitle,
  setOpenDocument,
  setDocumentStartDate
}: TimelineOverviewProps) => {
  return (
    <Box sx={styles.timelineOverview.container}>
      <SectionDetails scope={topic?.scope} applicants={details?.applicants} />
      <Box display='flex' alignItems='center'>
        <Typography sx={styles.common.labelText}>Rapporteur:</Typography>
        <Tooltip title={`Rapporteur: ${topic?.rapporteur || 'NA'}`} placement='top'>
          <Typography sx={{ ...styles.common.valueText, pl: 1.5 }}>
            {topic?.rapporteur || 'NA'}
          </Typography>
        </Tooltip>
        {topic?.co_rapporteur && topic?.co_rapporteur !== 'NA' && (
          <>
            <Divider orientation='vertical' sx={styles.common.divider} />
            <Typography fontSize={14} color='gray.600'>
              Co-Rapporteur:
            </Typography>
            <Tooltip title={`Co-Rapporteur: ${topic?.co_rapporteur || 'NA'}`} placement='top'>
              <Typography sx={styles.common.valueText}>{topic?.co_rapporteur || 'NA'}</Typography>
            </Tooltip>
            <Divider orientation='vertical' sx={styles.common.divider} />
          </>
        )}
      </Box>
      {topic?.lead_member_states && topic?.lead_member_states !== 'NA' && (
        <Box display='flex'>
          <Typography sx={styles.common.labelText}>Lead Member State(s):</Typography>
          <Tooltip
            title={`Lead Member State(s): ${
              Array.isArray(topic?.lead_member_states)
                ? topic?.lead_member_states?.join(', ')
                : topic?.lead_member_state ?? 'NA'
            }`}
            placement='top'>
            <Typography sx={styles.common.valueText}>
              {Array.isArray(topic?.lead_member_states)
                ? topic?.lead_member_states?.join(', ')
                : topic?.lead_member_states}
            </Typography>
          </Tooltip>
        </Box>
      )}
      {details?.applicants?.length === 1 && (
        <>
          <Divider orientation='horizontal' sx={styles.timelineOverview.divider} />
          <TimelineTrackHeader
            applicant={details?.applicants[0]}
            selectedLeftDrawerData={selectedLeftDrawerData}
            setSelectedLeftDrawerData={setSelectedLeftDrawerData}
            setOpenDocument={setOpenDocument}
            setDocumentPath={setDocumentPath}
            setDocumentTitle={setDocumentTitle}
            setDocumentStartDate={setDocumentStartDate}
          />
        </>
      )}
    </Box>
  );
};

export default React.memo(TimelineOverview);
