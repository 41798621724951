import React, { useState, useEffect, useContext } from 'react';
import { Drawer } from '@mui/material';
import styles from '../../styles/Timeline.styles';
import usePRACData from '../../hooks/usePRACdata';
import DocumentViewer from './DocumentViewer';
import TimelineLeftDrawer from './TimelineLeftDrawer';
import TimelineHeader from './TimelineHeader';
import TimelineOverview from './TimelineOverview';
import TimelineDetails from './TimelineDetails';
import Loader from './Loader';
import GlobalStore from '../../../../store';

interface TimelineProps {
  details: any;
  topic: any;
  setShowTimeline: any;
  meetingId: string;
}

const Timeline = ({ details, topic, setShowTimeline, meetingId }: TimelineProps) => {
  const [openDocument, setOpenDocument] = useState(false);
  const [documentTitle, setDocumentTitle] = useState('');
  const [documentPath, setDocumentPath] = useState('');
  const [documentStartDate, setDocumentStartDate] = useState('');
  const [expandedApplicants, setExpandedApplicants] = useState<string[]>([]);
  const [selectedLeftDrawerData, setSelectedLeftDrawerData] = useState({
    type: '',
    data: []
  });
  const { state } = useContext(GlobalStore) as any;
  const { getPRACTimelineDetails, timelineDetailsLoading, timelineDetails } = usePRACData();

  useEffect(() => {
    const modifiedProductNumbers: string[] = details?.applicants
      ?.map((applicant: any) => {
        return applicant?.modified_product_number && applicant?.modified_product_number !== 'NA'
          ? applicant.modified_product_number
          : undefined;
      })
      .filter((number: any): number is string => number !== undefined);
    if (modifiedProductNumbers?.length > 0) {
      getPRACTimelineDetails(modifiedProductNumbers);
    }
  }, [details]);

  const getDrawerWidth: any = () => {
    if (openDocument) {
      return '46%';
    }
    if (state?.chatRiaOpen) {
      return '0';
    }
    return '50%';
  };

  return (
    <Drawer
      anchor='right'
      open
      transitionDuration={500}
      hideBackdrop={false}
      sx={{
        ...styles.timeline.drawer,
        '& >.MuiDrawer-paper': {
          width: {
            md: getDrawerWidth()
          },
          overflow: 'hidden',
          boxShadow: '-0.1em 0.1em 5em #33b1874a'
        }
      }}>
      <TimelineHeader
        setShowTimeline={setShowTimeline}
        activeIngredient={details?.active_ingredient}
      />
      <TimelineOverview
        topic={topic}
        details={details}
        selectedLeftDrawerData={selectedLeftDrawerData}
        setSelectedLeftDrawerData={setSelectedLeftDrawerData}
        setOpenDocument={setOpenDocument}
        setDocumentPath={setDocumentPath}
        setDocumentTitle={setDocumentTitle}
        setDocumentStartDate={setDocumentStartDate}
      />
      {timelineDetailsLoading ? (
        <Loader />
      ) : (
        <TimelineDetails
          timelineDetails={timelineDetails}
          details={details}
          setOpenDocument={setOpenDocument}
          setDocumentPath={setDocumentPath}
          setDocumentTitle={setDocumentTitle}
          setDocumentStartDate={setDocumentStartDate}
          expandedApplicants={expandedApplicants}
          setExpandedApplicants={setExpandedApplicants}
          selectedLeftDrawerData={selectedLeftDrawerData}
          setSelectedLeftDrawerData={setSelectedLeftDrawerData}
          meetingId={meetingId}
        />
      )}
      {selectedLeftDrawerData?.data?.length > 0 && (
        <TimelineLeftDrawer
          selectedData={selectedLeftDrawerData}
          handleClose={() => setSelectedLeftDrawerData({ type: '', data: [] })}
        />
      )}
      {openDocument && (
        <DocumentViewer
          openDocument={openDocument}
          setOpenDocument={setOpenDocument}
          documentTitle={documentTitle}
          setDocumentTitle={setDocumentTitle}
          documentPath={documentPath}
          documentStartDate={documentStartDate}
        />
      )}
    </Drawer>
  );
};

export default React.memo(Timeline);
