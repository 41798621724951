const styles = {
  resultContainer: {
    padding: 2,
    borderRadius: '15px',
    position: 'relative',
    overflowX: 'visible'
  },
  suggestedResultContainer: {
    padding: 2,
    borderRadius: '15px',
    position: 'relative',
    overflowX: 'visible',
    backgroundColor: 'primary.background'
  },
  userProfileResultContainer: {
    padding: 2,
    borderRadius: '15px',
    position: 'relative',
    overflowX: 'visible',
    backgroundColor: 'white.aliceBlue'
  },
  resultContainerHover: {
    padding: 2,
    borderRadius: '15px',
    position: 'relative',
    overflowX: 'visible',
    backgroundColor: '#f9f9f9'
  },
  suggestedResultContainerHover: {
    padding: 2,
    borderRadius: '15px',
    position: 'relative',
    overflowX: 'visible',
    backgroundColor: 'primary.backgroundLightVariant'
  },
  userProfileResultContainerHover: {
    padding: 2,
    borderRadius: '15px',
    position: 'relative',
    overflowX: 'visible',
    backgroundColor: 'white.aliceBlueDark'
  },

  infoIcon: {
    cursor: 'pointer',
    color: 'gray.light',
    marginLeft: '5px'
  },

  description: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  descriptionSelected: {
    cursor: 'pointer',
    color: 'primary.main',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  title: {
    color: 'grey.800',
    fontFamily: 'Mulish',
    fontSize: '18px !important',
    fontWeight: 700,
    textTransform: 'capitalize'
  },
  subtitle: {
    maxWidth: '30%',
    color: 'primary.dark',
    textTransform: 'uppercase',
    fontWeight: '600'
  },
  tagText: {
    color: 'gray.750',
    fontFamily: 'Mulish',
    fontSize: '12px',
    fontWeight: 400
  },
  selectedTagText: {
    width: '85%',
    fontWeight: 'bold',
    color: 'primary.main'
  },
  lowIcon: {
    fontSize: '12px',
    color: 'red.main'
  },
  mediumIcon: {
    fontSize: '12px',
    color: 'orange.main'
  },
  highIcon: {
    fontSize: '12px',
    color: 'primary.light'
  },
  veryHighIcon: {
    fontSize: '12px',
    color: 'primary.darkVariant'
  },
  noConfidence: {
    fontSize: 0
  },
  shareIcon: {
    color: 'gray.contrastText',
    fontSize: 'inherit'
  },
  text: {
    color: 'gray.550',
    fontFamily: 'Mulish',
    fontSize: '12px',
    fontWeight: 400,
    mx: '5px'
  },
  metadataChip: {
    ml: 1,
    fontSize: 10,
    height: 13,
    borderRadius: 1,
    fontWeight: 800,
    color: 'white.main',
    '& span': {
      px: 0.5
    }
  }
};

export default styles;
