// This function checks if a report with the same ID exists.
// If it does, it verifies whether the indication studies already exist.
// If they do, those studies are ignored. Otherwise, it merges the two indication studies and displays a combined report.

const updateSnapshotData = (prevLayout: any, updatedLayout: any) => {
  const updatedData: Array<any> = [];

  prevLayout?.sections?.forEach((item: { id: any; data: { indicationStudies: any } }) => {
    if (item.id === updatedLayout?.id) {
      const { indicationStudies } = item.data;
      const newIndicationStudies = { ...indicationStudies };

      Object.keys(updatedLayout?.data?.indicationStudies).forEach(key => {
        if (!newIndicationStudies[key]) {
          newIndicationStudies[key] = [];
        }

        updatedLayout?.data?.indicationStudies[key].forEach((study: any) => {
          if (!newIndicationStudies[key].includes(study)) {
            newIndicationStudies[key].push(study);
          }
        });
      });

      const formattedData = {
        ...item,
        data: { ...item.data, indicationStudies: newIndicationStudies }
      };
      updatedData.push(formattedData);
    } else {
      updatedData.push(item);
    }
  });
  const updatedLayoutData = {
    ...prevLayout,
    sections: updatedData
  };

  return updatedLayoutData;
};

export default updateSnapshotData;
