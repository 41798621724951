import React from 'react';
import { Box, Typography } from '@mui/material';
import Timeline from '@mui/lab/Timeline';
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem';
import CircleIcon from '@mui/icons-material/Circle';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineCard from './TimelineCard';
import styles from '../../styles/Timeline.styles';

interface TimelineTrackProps {
  timelineTrack: any;
  applicants: any;
  // eslint-disable-next-line no-unused-vars
  setDocumentPath: (documentPath: string) => void;
  // eslint-disable-next-line no-unused-vars
  setDocumentTitle: (documentTitle: string) => void;
  // eslint-disable-next-line no-unused-vars
  setOpenDocument: (openDocument: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setDocumentStartDate: (documentStartDate: string) => void;
  brandName: string;
  // eslint-disable-next-line no-unused-vars
  setSelectedLeftDrawerData: (selectedLeftDrawerData: any) => void;
  meetingId: string;
  hasMultipleApplicants: boolean;
}

const TimelineTrack = ({
  timelineTrack,
  applicants,
  setDocumentPath,
  setDocumentTitle,
  setOpenDocument,
  setDocumentStartDate,
  brandName,
  setSelectedLeftDrawerData,
  meetingId,
  hasMultipleApplicants
}: TimelineTrackProps) => {
  return (
    <Box>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0
          },
          m: 0,
          py: 0,
          px: 0
        }}>
        {timelineTrack?.length > 0 && (
          <Typography
            fontSize={14}
            fontWeight={700}
            color='gray.900'
            mb={1.75}
            mt={hasMultipleApplicants ? 1.25 : 0}>{`Timeline ${
            brandName && brandName !== 'NA' ? `of "${brandName}"` : ''
          } with PRAC`}</Typography>
        )}
        {timelineTrack?.length > 0 &&
          timelineTrack.map((timelineFact: any, index: number) => {
            const isLastItem = index === timelineTrack.length - 1;
            return (
              <TimelineItem key={`${timelineFact?.product_number}-${timelineFact?.start_date}`}>
                <TimelineSeparator sx={{ mt: 0.6 }}>
                  <CircleIcon sx={{ m: 0, fontSize: 8, color: 'gray.400' }} />
                  <TimelineConnector
                    sx={{
                      width: '1px',
                      height: isLastItem ? 0 : '100%',
                      mb: -1.25,
                      flexGrow: 0
                    }}
                  />
                </TimelineSeparator>
                <Box display='flex' flex={1} flexDirection='column' maxWidth='98%'>
                  <Typography sx={styles.timelineTrack.sectionText}>
                    {timelineFact?.section_name}
                  </Typography>
                  <TimelineContent sx={styles.timelineTrack.contentContainer}>
                    <TimelineCard
                      section={timelineFact}
                      applicants={applicants}
                      setDocumentPath={setDocumentPath}
                      setDocumentTitle={setDocumentTitle}
                      setOpenDocument={setOpenDocument}
                      setDocumentStartDate={setDocumentStartDate}
                      setSelectedLeftDrawerData={setSelectedLeftDrawerData}
                      meetingId={meetingId}
                    />
                  </TimelineContent>
                </Box>
              </TimelineItem>
            );
          })}
      </Timeline>
    </Box>
  );
};

export default React.memo(TimelineTrack);
