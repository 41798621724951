import React from 'react';
import { Box, Button, Typography } from '@mui/material';

import Stats from '../../../SearchResults/components/Stats';
import styles from '../../styles/Header.styles';
import { Stat } from '../../../SearchResults/types';
import { ReportIcon, SubHeaderPipeGreyIcon } from '../../../../assets/svgs/Icons';

type SubHeaderProps = {
  title: any;
  stats: Stat[];
  handleVisualizeOpen: () => void;
};

const Header = ({ title, stats, handleVisualizeOpen }: SubHeaderProps) => {
  return (
    <Box display='flex' bgcolor='white.main' px={5}>
      <p>
        <SubHeaderPipeGreyIcon sx={styles.subHeaderPipeGreyIcon} />
      </p>
      <Box sx={styles.statsContainer}>
        <Typography sx={styles.title}>{title}</Typography>
        <Box sx={styles.contentContainer}>
          <Stats stats={stats} />
          <Button
            onClick={handleVisualizeOpen}
            id='visualize-button'
            startIcon={<ReportIcon sx={styles.primaryButtonIcon} />}
            sx={styles.primaryButton}>
            Visualize & Report
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(Header);
