export const ROUTE_ARIA_MAIN = '/aria/:module/:source';
export const ROUTE_ARIA_MAIN_SEARCH_ID = '/aria/:module/:source/:searchId';
export const ROUTE_QUICK_SEARCH = '/search/:searchId';
export const ROUTE_XCOMPARE_SEARCH = '/:module/fda_vs_eu/:category/:search';
export const ROUTE_XCOMPARE_LANDING_SEARCH = '/:module/fda_vs_eu?source=:source&category=:category';
export const ROUTE_CLINICAL_TRIAL = '/ct/:module/:category/:search';
export const ROUTE_REGULATORY_360 = '/regulatory360/:module/:number/:source';
export const ROUTE_REGULATORY = '/regulat360/:module/:number/:source';
export const ROUTE_CLINICAL_TRIAL_ADVANCED_SEARCH = '/ct/:module/:searchType';
export const ROUTE_CDP = '/cdp/:module/:number/:source/:active_ingredients';
export const INPUT_TYPE_TEXT = 'text';
export const INPUT_TYPE_SELECT = 'select';
export const ROUTE_SPECIALITY_DESIGNATION = '/designation';
export const ROUTE_SPECIALITY_DESIGNATION_SEARCH = '/designation/:search';
export const ROUTE_CHMP_MODULE = '/chmp';
export const BREADCRUMB_ROUTES = {
  [ROUTE_REGULATORY_360]: {
    name: 'Reg 360'
  },
  [ROUTE_ARIA_MAIN]: {
    name: 'ARIA Results'
  },
  [ROUTE_ARIA_MAIN_SEARCH_ID]: {
    name: 'ARIA Results'
  },
  [ROUTE_CDP]: {
    name: 'CDP'
  }
};
export const ROUTE_SEARCH_RESULTS = '/results';

export const RESTRICT_DOWNLOADS_BY_ORGANIZATION = [];
export const CLOUDFRONT_S3_URL = 'https://d1fziiyze1kdfo.cloudfront.net';

export const ROUTE_ADVANCE_SEARCH = '/search/:searchType/:module/:source';

export const ROUTE_XCOMPARE_SEARCH_HEADER = '/comparison/:sources/:category/:term';

export const API_RESPONSE_CODE = {
  SUCCESS: 200,
  BAD_REQUEST: 400,
  CONTENT_NOT_FOUND: 204,
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  INTERNAL_SERVER_ERROR: 500
};
