import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import TimelineIcon from '../../../../assets/svgs/PDCO/TimelineIcon';
import styles from '../../../EMAMeetings/styles/Meeting.styles';
import { formatStringWithSpaces } from '../../../EMAMeetings/utils/common/getFilterOptions';
import { SectionHeaderProps } from '../../types';

const SectionHeader: React.FC<SectionHeaderProps> = ({
  index,
  headerText,
  isTimelineAvailable,
  onTimelineClick,
  Metadata
}) => {
  const getHeaderText = () =>
    headerText && headerText !== 'NA'
      ? formatStringWithSpaces(headerText.replace(/\b\w/g, (char: string) => char.toUpperCase()))
      : 'NA';

  return (
    <Box sx={{ ...styles.cardHeader, gap: 3 }}>
      <Tooltip title={`Active Ingredient: ${getHeaderText()}`} placement='top'>
        <Typography sx={styles.cardHeaderText}>{`${index + 1}. ${getHeaderText()}`}</Typography>
      </Tooltip>
      <Box ml='auto' display='flex'>
        {!!Metadata && Metadata}
        <Tooltip title={`${isTimelineAvailable ? '' : 'No Timeline Available'}`} placement='top'>
          <Box
            display='flex'
            alignItems='center'
            ml={2}
            sx={{ cursor: `${isTimelineAvailable ? 'pointer' : 'auto'}` }}
            onClick={e => isTimelineAvailable && onTimelineClick?.(e)}>
            <TimelineIcon
              sx={{ fontSize: 14, color: isTimelineAvailable ? 'blue.background' : 'gray.500' }}
            />
            <Box marginLeft='4px'>
              <Typography
                sx={{
                  ...styles.cardHeaderTimelineText,
                  color: isTimelineAvailable ? 'secondary.700' : 'gray.500'
                }}>
                Timeline
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      </Box>
    </Box>
  );
};

export default React.memo(SectionHeader);
