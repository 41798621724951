const styles = {
  drawer: {
    '& >.MuiDrawer-paper': {
      height: '98%',
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      position: 'absolute',
      backgroundColor: 'white.main'
    }
  }
};

export default styles;
