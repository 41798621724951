import React, { useState } from 'react';
import { Stack, Box, Divider } from '@mui/material';
import SectionHeader from './SectionHeader';
import SectionDetails from './SectionDetails';
import SectionAccordion from './SectionAccordion';
import styles from '../../../EMAMeetings/styles/Meeting.styles';
import { SectionProps } from '../../types';
import Timeline from '../Timeline/Timeline';

const Section: React.FC<SectionProps> = ({
  headerText,
  Metadata,
  topic,
  index,
  expanded,
  productNumber,
  onSectionExpand,
  meetingId,
  actionDetails,
  sectionDetails
}) => {
  const [showTimeline, setShowTimeline] = useState<boolean>(false);

  return (
    <>
      <Stack
        key={`${topic.sub_section_number}-${index}`}
        spacing={1}
        aria-label='section-item-card'
        width='100%'
        sx={{ margin: '0px !important' }}>
        <Box sx={styles.cardBox}>
          <SectionHeader
            index={index}
            headerText={headerText}
            isTimelineAvailable={!!topic?.product_number && topic?.product_number !== 'NA'}
            onTimelineClick={() => setShowTimeline(true)}
            Metadata={Metadata}
          />
          <Box sx={styles.cardSection}>
            <SectionDetails data={sectionDetails} />
            <Divider
              orientation='horizontal'
              sx={{ pt: 1.5, borderColor: 'gray.200', backgroundColor: 'transparent !important' }}
            />
            <SectionAccordion
              expanded={expanded}
              subSectionNumber={topic.sub_section_number}
              idx={index}
              onSectionExpand={onSectionExpand}
              productNumber={productNumber}
              actionDetails={actionDetails}
            />
          </Box>
        </Box>
      </Stack>
      {showTimeline && (
        <Timeline
          headerText={headerText}
          sectionData={sectionDetails}
          meetingId={meetingId}
          setShowTimeline={setShowTimeline}
          details={topic}
        />
      )}
    </>
  );
};

export default React.memo(Section);
