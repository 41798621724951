import { SECTIONS } from "../../constants";

const calculateSectionCounts = (selectedMeetingSections: any) => {
    const sectionsKeyCount: { [key: string]: number } = {};
    if (selectedMeetingSections) {
      Object.keys(selectedMeetingSections).forEach(key => {
        let sectionCount = 0
        selectedMeetingSections[key]?.map((data:any)=>{
          sectionCount += data?.application_details?.length
          return sectionCount
        })
        sectionsKeyCount[key] = sectionCount;
      });
    }
    const subSectionCount = {} as any;
  
    Object.keys(SECTIONS).forEach(subSectionKey => {
      subSectionCount[subSectionKey]= {}
      Object.keys(SECTIONS[subSectionKey]).forEach(discussionType => {
        let sectionDiscussionCount = 0;
        SECTIONS[subSectionKey][discussionType].map((section: any) => {
          if (section.value in sectionsKeyCount) {
            sectionDiscussionCount += sectionsKeyCount[section.value];
          }
          return sectionDiscussionCount
        });
        subSectionCount[subSectionKey][discussionType] = sectionDiscussionCount;
      });
    });
  
    const mainSectionCount = {} as any;
    Object.keys(subSectionCount).forEach(subSectionKey => {
      mainSectionCount[subSectionKey] =
        subSectionCount[subSectionKey]['Main Discussion'] + subSectionCount[subSectionKey]['Annex I'];
    });
  
    return { sectionsKeyCount, subSectionCount, mainSectionCount };
  };

  export default calculateSectionCounts