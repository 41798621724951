import { createSvgIcon } from '@mui/material';

const PositiveTag = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
    <path
      d='M6 0C2.694 0 0 2.694 0 6C0 9.306 2.694 12 6 12C9.306 12 12 9.306 12 6C12 2.694 9.306 0 6 0ZM8.868 4.62L5.466 8.022C5.38164 8.1063 5.26728 8.15358 5.148 8.15358C5.02872 8.15358 4.91436 8.1063 4.83 8.022L3.132 6.324C3.04831 6.23934 3.00138 6.12504 3.00138 6.006C3.00138 5.88696 3.04831 5.77266 3.132 5.688C3.306 5.514 3.594 5.514 3.768 5.688L5.148 7.068L8.232 3.984C8.406 3.81 8.694 3.81 8.868 3.984C9.042 4.158 9.042 4.44 8.868 4.62Z'
      fill='#109E8A'
    />
  </svg>,
  'PositiveTag'
);

export default PositiveTag;
