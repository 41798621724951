import { createSvgIcon } from '@mui/material';

const RedWarningIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
    <path
      d='M15.5 8.5C15.5 12.642 12.1424 15.9997 8.00054 16C3.86004 15.9955 0.504631 12.6402 0.5 8.49973C0.500144 4.35772 3.85795 1 8 1C12.1421 1 15.5 4.35786 15.5 8.5ZM8 3.33334C7.35567 3.33334 6.83334 3.85567 6.83334 4.5V9.83334C6.83334 10.4777 7.35567 11 8 11C8.64433 11 9.16666 10.4777 9.16666 9.83334V4.5C9.16666 3.85567 8.64433 3.33334 8 3.33334ZM9.16666 13.1667C9.16666 12.5223 8.64433 12 8 12C7.35567 12 6.83334 12.5223 6.83334 13.1667C6.83334 13.811 7.35567 14.3333 8 14.3333C8.64433 14.3333 9.16666 13.811 9.16666 13.1667Z'
      fill='#DC5A59'
      stroke='#DC5A59'
    />
  </svg>,
  'RedWarningIcon'
);

export default RedWarningIcon;
