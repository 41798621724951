import React, { useContext, useState } from 'react';
import capitalize from 'lodash/capitalize';
import {
  Box,
  Tooltip,
  Typography,
  Button,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Chip,
  Radio,
  Badge,
  Stack
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';

import { styled } from '@mui/material/styles';
import { CaretDownIcon } from '../../../assets/svgs/Icons';
import CaretLeftIcon from '../../../assets/svgs/ChatRIA/CaretLeftIcon';

import Card from '../../../pages/SearchResults/components/Card';
import {
  LABEL_COLOR,
  LABEL_TOOLTIP_TITLE,
  sourceFlagMapping,
  sourceFlagMappingTooltip
} from '../../../pages/SearchResults/constants';
import {
  accondionSummary,
  applicationContainer,
  applicationHeader,
  applicationScroll,
  brandNameText,
  reportText,
  accordionTitle,
  chipMetadata
} from '../styles/styles';
import Store from '../../../store';

const StyledAccordion = styled(Accordion)({
  boxShadow: 'none',
  '&:before': {
    display: 'none'
  },
  width: '100%',
  '& .MuiPaper-root': {
    width: '100%'
  }
});

const SectionSelector = ({
  brandName,
  applicationNameList,
  selectedApplication,
  handleApplicationChange,
  reportCount,
  handleReportClick,
  handleVerifyAction,
  setOpenApplicationOption,
  openApplicationOption
}: any) => {
  const { state } = useContext(Store) as any;
  const [expanded, setExpanded] = useState(null);

  const handleExpandClick = (applno: any) => {
    setExpanded(expanded !== applno ? applno : null);
  };

  const handleRadioChange = (event: any, app: any) => {
    event.stopPropagation();
    handleApplicationChange(app);
    setOpenApplicationOption(false);
  };
  return (
    <>
      <Box
        mt={0.5}
        display='flex'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          p: '16px',
          width: '100%'
        }}>
        <Stack
          display='flex'
          flexDirection='row'
          alignItems='center'
          onClick={event => {
            event.stopPropagation();
            if (applicationNameList && applicationNameList.length !== 0) {
              setOpenApplicationOption(true);
              handleVerifyAction();
            }
          }}>
          {brandName && (
            <>
              <CaretLeftIcon sx={{ width: '16px', height: '16px', color: '#06705F' }} />
              <Typography sx={brandNameText}>{capitalize(brandName)}</Typography>
            </>
          )}
        </Stack>

        <Tooltip title='Reports'>
          <Stack
            display='flex'
            flexDirection='row'
            alignItems='center'
            onClick={event => {
              event.stopPropagation();
              handleReportClick();
            }}>
            <Badge variant='dot' invisible={state?.cartItems === 0} color='error'>
              <IconButton id='reportdownloadIcon' sx={{ p: 0 }}>
                <DescriptionIcon
                  sx={{
                    width: '14px',
                    height: '14px',
                    mr: reportCount ? '4px' : '0px'
                  }}
                />
              </IconButton>
            </Badge>
            <Typography sx={reportText}>Report</Typography>
          </Stack>
        </Tooltip>
      </Box>
      {openApplicationOption && (
        <Box sx={applicationContainer}>
          <Box height={60} sx={applicationHeader}>
            <Typography fontSize={20} color='gray.0' fontWeight={500}>
              Select Application
            </Typography>
            <Button
              onClick={() => {
                setOpenApplicationOption(false);
              }}
              sx={{ p: 0, minWidth: 0, mr: 0.5 }}>
              <CloseIcon
                sx={{
                  color: 'gray.0',
                  cursor: 'pointer'
                }}
              />
            </Button>
          </Box>
          <Divider />
          <Box maxHeight='100vh' sx={applicationScroll}>
            {applicationNameList &&
              applicationNameList.map((appl: any) => {
                const FlagComponent = sourceFlagMapping[appl?.source];
                const flagTooltipTitle = sourceFlagMappingTooltip[appl?.source];
                return (
                  <Box
                    key={appl.appNumber}
                    display='flex'
                    flexDirection='row'
                    justifyContent='space-between'>
                    <StyledAccordion
                      disableGutters
                      key={appl.appNumber}
                      expanded={expanded === appl.appNumber}
                      onChange={event => {
                        event.stopPropagation();
                        handleExpandClick(appl.appNumber);
                      }}>
                      <AccordionSummary sx={accondionSummary}>
                        <IconButton
                          sx={{ width: '16px', height: '16px' }}
                          onClick={event => {
                            event.stopPropagation();
                            handleExpandClick(appl.appNumber);
                          }}>
                          <CaretDownIcon
                            sx={{
                              fontSize: 16,
                              rotate: expanded === appl.appNumber ? '180deg' : '0deg',
                              color: 'gray.800'
                            }}
                          />
                        </IconButton>
                        <Radio
                          checked={selectedApplication.appNumber === appl.appNumber}
                          onChange={event => handleRadioChange(event, appl)}
                          value='a'
                          name='radio-buttons'
                          sx={{
                            '& .MuiSvgIcon-root': {
                              fontSize: 18
                            }
                          }}
                        />
                        <Tooltip title={capitalize(appl.label)}>
                          <Typography sx={accordionTitle}>{capitalize(appl.label)}</Typography>
                        </Tooltip>
                        <Tooltip title={flagTooltipTitle}>
                          {FlagComponent && (
                            <FlagComponent sx={{ width: '18px', height: '13px', ml: '12px' }} />
                          )}
                        </Tooltip>
                        {appl.labels &&
                          appl.labels.map((label: string) => {
                            const labelKey = label.toLowerCase();
                            const labelColor = LABEL_COLOR[labelKey];
                            const labelTooltipTitle = LABEL_TOOLTIP_TITLE[labelKey];
                            return labelColor ? (
                              <Tooltip title={labelTooltipTitle}>
                                <Chip
                                  label={label}
                                  key={label}
                                  sx={{
                                    ...chipMetadata,
                                    bgcolor: labelColor
                                  }}
                                />
                              </Tooltip>
                            ) : null;
                          })}
                      </AccordionSummary>
                      <AccordionDetails sx={{ p: '0px 16px' }}>
                        <Typography ml={1}>
                          <Card
                            data={appl}
                            hideApplication={() => {}}
                            triggerChatRIA={() => {}}
                            setFavoriteInHook={() => {}}
                            setInProjectInHook={() => {}}
                            setSubscriptionInHook={() => {}}
                          />
                          <Divider
                            sx={{ mt: '16px', width: '100%', height: '1px', background: 'grey.30' }}
                          />
                        </Typography>
                      </AccordionDetails>
                    </StyledAccordion>
                  </Box>
                );
              })}
          </Box>
        </Box>
      )}
    </>
  );
};
export default React.memo(SectionSelector);
