import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import TimelineIcon from '../../../../assets/svgs/PDCO/TimelineIcon';
import styles from '../../../EMAMeetings/styles/Meeting.styles';
import Timeline from '../Timeline/Timeline';
import { formatStringWithSpaces } from '../../../EMAMeetings/utils/common/getFilterOptions';

interface SectionHeaderProps {
  topicIndex: any;
  index: number;
  details: any;
  topic: any;
  meetingId: string;
}

const SectionHeader: React.FC<SectionHeaderProps> = ({
  topicIndex,
  index,
  details,
  topic,
  meetingId
}) => {
  const [showTimeline, setShowTimeline] = React.useState(false);
  const getHeaderText = () =>
    details?.active_ingredient && details?.active_ingredient !== 'NA'
      ? formatStringWithSpaces(
          details?.active_ingredient.replace(/\b\w/g, (char: string) => char.toUpperCase())
        )
      : 'NA';

  const isTimelineAvailable = details?.applicants?.some(
    (applicant: any) =>
      applicant?.modified_product_number && applicant?.modified_product_number !== 'NA'
  );

  return (
    <>
      <Box sx={styles.cardHeader}>
        <Tooltip title={`Active Ingredient: ${getHeaderText()}`} placement='top'>
          <Typography sx={styles.cardHeaderText}>{`${topicIndex + 1}.${
            index + 1
          }. ${getHeaderText()}`}</Typography>
        </Tooltip>
        <Tooltip title={`${isTimelineAvailable ? '' : 'No Timeline Available'}`} placement='top'>
          <Box
            display='flex'
            alignItems='center'
            sx={{ cursor: `${isTimelineAvailable ? 'pointer' : 'auto'}` }}
            onClick={() => isTimelineAvailable && setShowTimeline(!showTimeline)}>
            <TimelineIcon
              sx={{ fontSize: 14, color: isTimelineAvailable ? 'blue.background' : 'gray.500' }}
            />
            <Box marginLeft='4px'>
              <Typography
                sx={{
                  ...styles.cardHeaderTimelineText,
                  color: isTimelineAvailable ? 'secondary.700' : 'gray.500'
                }}>
                Timeline
              </Typography>
            </Box>
          </Box>
        </Tooltip>
      </Box>
      {showTimeline && (
        <Timeline
          details={details}
          topic={topic}
          setShowTimeline={setShowTimeline}
          meetingId={meetingId}
        />
      )}
    </>
  );
};

export default React.memo(SectionHeader);
