import { useState } from 'react';
import { useHistory } from 'react-router-dom';
// api
import {
  fetchPRACMeetings,
  fetchPRACMeetingDetails,
  fetchPRACTimelineDetails
} from '../../../api/pages/PRAC';
// utils
import useQuery from '../../../helpers/customHooks/queryParameter';
import getFilterOptions from '../../EMAMeetings/utils/common/getFilterOptions';
import { PRAC_FILTERS } from '../constants';

const usePRACData = () => {
  const query: any = useQuery();
  const history = useHistory();
  const [allMeetingsList, setAllMeetingsList] = useState<any>({});
  const [meetingsList, setMeetingsList] = useState<any>({});
  const [meetingsStats, setMeetingsStats] = useState<any>({});
  const [meetingsListLoading, setMeetingsListLoading] = useState<boolean>(false);
  const [selectedMeeting, setSelectedMeeting] = useState<any>({});
  const [pracFilters, setPracFilters] = useState<any>([]);
  const [selectedResources, setSelectedResources] = useState<any>({});
  const [meetingDetailsLoading, setMeetingDetailsLoading] = useState<boolean>(false);
  const [isFilterApplied, setIsFilterApplied]: [boolean, Function] = useState(false);
  const [filtersApplied, setFiltersApplied] = useState<any>({});
  const [meetingType, setMeetingType] = useState<'past' | 'upcoming'>('past');
  const [timelineDetailsLoading, setTimelineDetailsLoading] = useState(false);
  const [timelineDetails, setTimelineDetails] = useState();

  // Utility to group resources by their type (category bucket)
  const groupResourcesByType = (resources: Array<Record<string, string>>) => {
    return resources.reduce((acc: any, item: any) => {
      const categoryBucket = item.category_bucket;
      const originalFileName = item?.s3_url.split('/').pop()?.split('?')[0];
      const modifiedItem = { ...item, originalFileName };
      acc[categoryBucket] = acc[categoryBucket]
        ? [...acc[categoryBucket], modifiedItem]
        : [modifiedItem];
      return acc;
    }, {});
  };

  // Select the default meeting based on the filters or the latest one
  const selectDefaultMeeting = (meetings: any) => {
    const sortedKeys = Object.keys(meetings)
      .map(key => parseInt(key, 10))
      .sort((a, b) => b - a);
    const selectedYear = parseInt(query.get('meetingId')?.split(' ')[1], 10);
    if (query.get('meetingId') && meetings[selectedYear]) {
      return meetings[selectedYear]?.find(
        (meeting: any) => meeting.meeting_id === query.get('meetingId')
      );
    }
    return meetings[sortedKeys[0]]?.find((meeting: any) => meeting?.total_document_count > 0);
  };

  // Reset meetings and meeting data
  const resetMeetings = () => {
    setMeetingsList({});
    setSelectedMeeting({});
    history.push('/prac');
  };

  // Update the browser URL with the selected meeting ID
  const updateURL = (meetingId: string) => {
    const queryParams = new URLSearchParams();
    queryParams.set('meetingId', meetingId);
    history.push(`?${queryParams.toString()}`);
  };

  // Fetch the details for a selected meeting
  const fetchMeetingDetails = async (meetingId: string, appliedFilters: any = {}) => {
    try {
      const res = await fetchPRACMeetingDetails(meetingId, appliedFilters);
      if (res?.status === 200) {
        const meetingDetails = res.data.Success;
        const sections = meetingDetails?.sections || {};
        const meetingStats = meetingDetails?.meeting_stats || {};
        const documentResources = groupResourcesByType(
          meetingDetails?.meeting_stats?.documents || []
        );

        setSelectedMeeting({ sections, meeting_stats: meetingStats });
        setSelectedResources(documentResources);

        return true;
      }
    } catch (error) {
      console.error(error);
    }

    setSelectedMeeting({});
    return false;
  };

  // Filter and sort meetings based on the meeting type
  const filterMeetingsByType = (meetings: any, type: 'past' | 'upcoming') => {
    const filteredMeetings: { [year: string]: any[] } = {};
    Object.keys(meetings).forEach(year => {
      const completedMeetings = meetings[year].filter(
        (meeting: any) => meeting.meeting_type === type
      );
      if (completedMeetings.length > 0) {
        filteredMeetings[year] = completedMeetings;
      }
    });
    return filteredMeetings;
  };

  // Handle PRAC meetings response, filtering and selecting default meeting
  const handlePRACMeetingsResponse = async (res: any, appliedFilters: any) => {
    const meetingsData = res.data.Success?.meetings?.meetings || {};
    const statsData = res.data.Success?.stats || {};
    const filtersData = res.data.Success?.meetings?.filters || [];

    setAllMeetingsList(meetingsData);
    setMeetingsStats(statsData);

    const filteredMeetings = filterMeetingsByType(meetingsData, meetingType);
    setMeetingsList(filteredMeetings);

    const filterOptions = getFilterOptions(PRAC_FILTERS, filtersData);
    setPracFilters(filterOptions);

    const defaultMeeting = selectDefaultMeeting(filteredMeetings);
    if (defaultMeeting) {
      const success = await fetchMeetingDetails(defaultMeeting?.meeting_id, appliedFilters);
      if (success) {
        updateURL(defaultMeeting?.meeting_id);
      }
    } else {
      resetMeetings();
    }
  };

  // Fetch PRAC meetings
  const getPRACMeetings = async (appliedFilters: any = {}) => {
    try {
      setMeetingsListLoading(true);
      setMeetingDetailsLoading(true);
      setIsFilterApplied(Object.keys(appliedFilters).length > 0);
      setFiltersApplied(appliedFilters);

      const res = await fetchPRACMeetings(appliedFilters);
      if (res?.status === 200) {
        await handlePRACMeetingsResponse(res, appliedFilters);
      } else {
        resetMeetings();
      }
    } catch (err) {
      console.error(err);
      resetMeetings();
    } finally {
      setMeetingsListLoading(false);
      setMeetingDetailsLoading(false);
    }
  };

  // Handle selecting a meeting by ID
  const handleSelectMeeting = async (meetingId: string) => {
    setMeetingDetailsLoading(true);
    const success = await fetchMeetingDetails(meetingId, filtersApplied);
    if (success) {
      updateURL(meetingId);
    } else {
      resetMeetings();
    }
    setMeetingDetailsLoading(false);
  };

  // Handle selecting meeting type (past or upcoming)
  const handleSelectMeetingType = async (selectedType: 'past' | 'upcoming') => {
    try {
      setMeetingsListLoading(true);
      setMeetingDetailsLoading(true);
      setMeetingType(selectedType);

      if (Object.keys(allMeetingsList).length > 0) {
        const filteredMeetings = filterMeetingsByType(allMeetingsList, selectedType);
        setMeetingsList(filteredMeetings);
        const sortedKeys = Object.keys(filteredMeetings)
          .map(key => parseInt(key, 10))
          .sort((a, b) => b - a);
        const defaultMeeting = filteredMeetings[sortedKeys[0]][0];

        if (defaultMeeting) {
          const success = await fetchMeetingDetails(defaultMeeting?.meeting_id, {});
          if (success) {
            updateURL(defaultMeeting?.meeting_id);
          }
        } else {
          resetMeetings();
        }
      } else {
        resetMeetings();
      }
    } catch (err) {
      setMeetingsList({});
      setSelectedMeeting({});
      console.error(err);
    } finally {
      setMeetingsListLoading(false);
      setMeetingDetailsLoading(false);
    }
  };

  const getPRACTimelineDetails = async (modifiedProductNumbers: any = {}) => {
    try {
      setTimelineDetailsLoading(true);
      const payload = { filters: { product_number: modifiedProductNumbers } };
      const res = await fetchPRACTimelineDetails(payload);
      if (res?.status === 200) {
        setTimelineDetails(res?.data?.Success);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setTimelineDetailsLoading(false);
    }
  };

  return {
    getPRACMeetings,
    meetingsList,
    meetingsStats,
    selectedMeeting,
    pracFilters,
    selectedResources,
    setSelectedMeeting,
    handleSelectMeeting,
    isFilterApplied,
    meetingsListLoading,
    meetingDetailsLoading,
    meetingType,
    handleSelectMeetingType,
    getPRACTimelineDetails,
    timelineDetailsLoading,
    timelineDetails
  };
};

export default usePRACData;
