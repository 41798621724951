export const EXPANDED_SUBSECTIONS = {
  'Urgent EU procedure': {
    'Main Discussion': true
  },
  'Other EU Referrals': {
    'Main Discussion': true
  },
  'Signals assessment and prioritisation ': {
    'Main Discussion': true,
    'Annex I': true
  },
  'Risk management plans (RMPs)': {
    'Main Discussion': true,
    'Annex I': true
  },
  'Periodic safety update reports (PSURs)': {
    'Main Discussion': true,
    'Annex I': true
  },
  'Post-authorisation safety studies (PASS)': {
    'Main Discussion': true,
    'Annex I': true
  },
  'Authorization Renewals and reassessments': {
    'Main Discussion': true,
    'Annex I': true
  },
  'Other safety Issues': {
    'Main Discussion': true,
    'Annex I': true
  }
};

export const EXPANDED_SECTIONS = {
  'Other EU Referrals': true,
  'Urgent EU procedure': true,
  'Signals assessment and prioritisation ': true,
  'Risk management plans (RMPs)': true,
  'Periodic safety update reports (PSURs)': true,
  'Post-authorisation safety studies (PASS)': true,
  'Authorization Renewals and reassessments': true,
  'Other safety Issues': true
};
