export const shortDateFormat = (date: string) =>
  new Date(date).toLocaleString('en-US', {
    month: 'short',
    year: 'numeric'
  });

export const getDetailsText = (isSummaryAvailable: boolean, isExpanded: boolean) => {
  if (!isSummaryAvailable) {
    return 'No Details available from this Source';
  }
  if (isExpanded) {
    return 'Hide Details';
  }
  return 'View Details';
};
