import { createSvgIcon } from '@mui/material/utils';

const TableChartIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='15' height='16' viewBox='0 0 15 16' fill='none'>
    <g clipPath='url(#clip0_16321_16353)'>
      <path
        d='M13.125 0.5H1.875C1.37772 0.5 0.900805 0.697544 0.549175 1.04917C0.197544 1.40081 0 1.87772 0 2.375L0 15.5H15V2.375C15 1.87772 14.8025 1.40081 14.4508 1.04917C14.0992 0.697544 13.6223 0.5 13.125 0.5V0.5ZM13.75 2.375V4.25H11.25V1.75H13.125C13.2908 1.75 13.4497 1.81585 13.5669 1.93306C13.6842 2.05027 13.75 2.20924 13.75 2.375ZM8.125 4.25V1.75H10V4.25H8.125ZM10 5.5V7.375H8.125V5.5H10ZM6.875 4.25H5V1.75H6.875V4.25ZM6.875 5.5V7.375H5V5.5H6.875ZM3.75 7.375H1.25V5.5H3.75V7.375ZM3.75 8.625V10.5H1.25V8.625H3.75ZM5 8.625H6.875V10.5H5V8.625ZM6.875 11.75V14.25H5V11.75H6.875ZM8.125 11.75H10V14.25H8.125V11.75ZM8.125 10.5V8.625H10V10.5H8.125ZM11.25 8.625H13.75V10.5H11.25V8.625ZM11.25 7.375V5.5H13.75V7.375H11.25ZM1.875 1.75H3.75V4.25H1.25V2.375C1.25 2.20924 1.31585 2.05027 1.43306 1.93306C1.55027 1.81585 1.70924 1.75 1.875 1.75ZM1.25 11.75H3.75V14.25H1.25V11.75ZM11.25 14.25V11.75H13.75V14.25H11.25Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_16321_16353'>
        <rect width='15' height='15' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'TableChartIcon'
);
export default TableChartIcon;
