import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
// mui
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Autocomplete from '@mui/material/Autocomplete';
import { capitalize } from 'lodash';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import { debounce, Grid, MenuItem } from '@mui/material';
import { FormControl } from '@material-ui/core';
import Select from '@mui/material/Select';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { styled } from '@mui/material/styles';
import VivproReportSelect from './ReportSelect';

// store
import Store from '../../store';
import Actions from '../../store/actions';
// constants
import { CHART_TYPE_TITLE_SUFFIX, FONTSIZES, RIA_GRAPHS } from './constants';
import widgetStyles from './styles/widgets.styles';
import CustomCheckbox from '../CustomComponents/Checkbox';

export const CustomAutocomplete = styled(Autocomplete)(() => ({
  ...widgetStyles.customStyled
}));

export const CustomSelect = styled(Select)(() => ({
  fontSize: 14,
  height: 40,
  ...widgetStyles.customStyled
}));

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />;
const checkedIcon = <CheckBoxIcon fontSize='small' />;

const AGG_FUNCTIONS: { [key: string]: any } = {
  us: {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage', 'mean', 'median']
  },
  ct: {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage', 'mean', 'median']
  },
  'fda-guidance': {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage']
  },
  'ema-guidance': {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage']
  },
  'us-adcomm': {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage']
  },
  hta: {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage']
  },
  chmp: {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage', 'mean', 'median']
  },
  ca: {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage', 'mean', 'median']
  },
  eu: {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage', 'mean', 'median']
  },
  'us-devices': {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage', 'mean', 'median']
  },
  pmr: {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage']
  },
  'fda-written-requests': {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage']
  },
  'ema-pips': {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage']
  },
  'fda-letters': {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage']
  },
  hpra: {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage']
  },
  hma: {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage']
  },
  'canada-guidance': {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage']
  },
  'canada-consultation': {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage']
  },
  jp: {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage']
  },
  'dd-tools': {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage']
  },
  euctr: {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage']
  },
  ema_orphan: {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage']
  },
  au: {
    pie: ['count', 'percentage'],
    bar: ['count', 'percentage', 'mean', 'median']
  }
};

const NUMBER_PROPS: { [key: string]: any } = {
  us: ['approval_time'],
  ct: ['enrollment']
};

const ALLOWED_SOURCES = [
  { name: 'US FDA Drugs & Biologics', id: 'us' },
  { name: 'EU EMA Drugs & Biologics', id: 'eu' },
  { name: 'CT GOV - Clinical Trials', id: 'ct' },
  { name: 'CA HPFB Drugs & Biologics', id: 'ca' },
  { name: 'US FDA - Devices', id: 'us-devices' },
  { name: 'US FDA Guidances', id: 'fda-guidance' },
  { name: 'EU EMA Guidances', id: 'ema-guidance' },
  { name: 'CA Guidances', id: 'canada-guidance' },
  { name: 'CA Consultations', id: 'canada-consultation' },
  { name: 'PMR', id: 'pmr' },
  { name: 'US Advisory Committee', id: 'us-adcomm' },
  { name: 'HTA', id: 'hta' },
  { name: 'EU EMA CHMP', id: 'chmp' },
  { name: 'FDA Written Requests', id: 'fda-written-requests' },
  { name: 'FDA Warnings and Untitled Letters', id: 'fda-letters' },
  { name: 'EMA PIPs', id: 'ema-pips' },
  { name: 'IE HPRA - Drugs & Biologics', id: 'hpra' },
  { name: 'EU HMA - Drugs & Biologics', id: 'hma' },
  { name: 'JP PMDA', id: 'jp' },
  { name: 'Drug Development Tools', id: 'dd-tools' },
  { name: 'EU Clinical Trials Register', id: 'euctr' },
  { name: 'EU EMA Orphan Designations', id: 'ema_orphan' },
  { name: 'PDCO', id: 'pdco' },
  { name: 'PRAC', id: 'prac' },
  { name: 'Australia TGA', id: 'au' }
];
const RIAGRAPHS_MODULE = [
  'quicksearch',
  'written_request',
  'pips',
  'surveillance',
  'impact',
  'adcomm',
  'chmp',
  'guidance'
];
const DatapointPicker = ({
  defaultDataSource = { source: 'us', moduleName: 'quicksearch', disableSelectionSource: false },
  graphType = 'bar',
  referenceMetaData,
  titleSetting,
  onTitleSettingChanges,
  onSourceSettingsChanges,
  onReferenceConfigChanges,
  widgetId,
  handleRiaGraphChanges,
  properties,
  property,
  setProperty,
  riaGraphSelected,
  setRiaGraphSelected,
  isRiaGraphEnabled,
  setIsRiaGraphEnabled,
  limit,
  setLimit,
  setStyleFilters,
  stylesFilters,
  isStylesFiltersApplied
}: any) => {
  const { dispatch } = useContext(Store) as any;

  const titleRef = useRef<any>();
  const showTitleRef = useRef<any>();
  const titleFontSizeRef = useRef<any>();
  const showReferenceTextRef = useRef<any>();
  const showRiaGraphRef = useRef<any>();

  const [source, setSource] = useState<string>(defaultDataSource.source || 'us');
  const moduleName = defaultDataSource.moduleName || 'quicksearch';
  const [aggFunc, setAggFunc] = useState<string>(defaultDataSource.aggFunc || 'count');
  const [showLimit] = useState<boolean>(graphType === 'pie' || graphType === 'bar');
  const [yAxisOptions, setYAxisOptions] = useState<Array<string>>();
  const [yAxis, setYAxis] = useState<Array<string>>(defaultDataSource.yAxis);
  const columnOptions = properties.filter((col: any) => !col?.identifier);
  const [tableSelectedProperty, setTableSelectedProperty] = useState<Array<any>>(
    columnOptions.slice(0, 5)
  );
  const getSource = (sourceId: string) => {
    if (sourceId === 'pmr') {
      return moduleName;
    }
    return sourceId;
  };

  const getAllowedSource = () => {
    if (defaultDataSource.sourceList) {
      return ALLOWED_SOURCES.filter(item => defaultDataSource.sourceList.includes(item.id));
    }
    return ALLOWED_SOURCES;
  };

  const riaGraphs =
    RIA_GRAPHS[getSource(source)]?.filter((graph: any) => graph.widget === widgetId) || [];
  const [selectedGraphConfig, setSelectedGraphConfig] = useState<any>(
    riaGraphSelected || riaGraphs[0]
  );
  const defaulTitleSuffix = CHART_TYPE_TITLE_SUFFIX[`${graphType}-widget`];
  const [graphTitle, setGraphTitle] = useState(`${properties[0]?.label} ${defaulTitleSuffix}`);
  const [riaGraphTitle, setRiaGraphTitle] = useState(
    // eslint-disable-next-line no-nested-ternary
    widgetId !== 'table-widget' && riaGraphs.length > 0
      ? riaGraphSelected
        ? `${riaGraphSelected.title} ${defaulTitleSuffix}`
        : `${riaGraphs[0].title} ${defaulTitleSuffix}`
      : ''
  );
  const [fontSize, setFontSize] = useState(titleSetting.fontSize);

  useEffect(() => {
    if (defaultDataSource.yAxis && defaultDataSource.aggFunc) {
      let y = [];
      if (['mean', 'median'].includes(defaultDataSource.aggFunc) && NUMBER_PROPS[source]) {
        y = NUMBER_PROPS[source];
      }
      setYAxisOptions(y);
      setYAxis(defaultDataSource.yAxis);
    }
    if (defaultDataSource.aggFunc) {
      setAggFunc(defaultDataSource.aggFunc);
    }
  }, [source, defaultDataSource.aggFunc, defaultDataSource.yAxis]);

  const handleGraphSelect = (event: any) => {
    const selectedGraph = RIA_GRAPHS[getSource(source)]?.find(
      (graph: any) => graph.id === event.target.value
    );
    const titleSuffix = CHART_TYPE_TITLE_SUFFIX[`${graphType}-widget`];
    const selectedRiaGraphTitle = selectedGraph ? `${selectedGraph.title} ${titleSuffix}` : '';
    setSelectedGraphConfig(selectedGraph);
    let graphStyle = stylesFilters;
    if ('graphStyle' in selectedGraph && !isStylesFiltersApplied) {
      graphStyle = selectedGraph.graphStyle;
      setStyleFilters(selectedGraph.graphStyle);
    }
    handleRiaGraphChanges({
      ...selectedGraph,
      graphStyle,
      property: selectedGraph.query['x-axis'],
      aggFunc: selectedGraph.query.agg_function,
      yAxis: selectedGraph.query['y-axis']
    });

    onTitleSettingChanges({
      show: showTitleRef.current?.checked,
      title: selectedRiaGraphTitle,
      fontSize: titleFontSizeRef.current?.value
    });
    setRiaGraphTitle(selectedRiaGraphTitle);
    setRiaGraphSelected(selectedGraph);
  };

  const handleFontSizeChange = (event: any) => {
    setFontSize(event.target.value);
    onTitleSettingChanges({
      show: showTitleRef.current?.checked,
      title: titleRef.current?.value,
      fontSize: event.target?.value
    });
  };

  const handleOnTitleConfigChange = useCallback(() => {
    if (isRiaGraphEnabled) {
      setRiaGraphTitle(titleRef.current?.value);
    } else {
      setGraphTitle(titleRef.current?.value);
    }
    onTitleSettingChanges({
      show: showTitleRef.current?.checked,
      title: titleRef.current?.value,
      fontSize: titleFontSizeRef.current?.value
    });
  }, [isRiaGraphEnabled]);

  const handleShowRefTextChange = () => {
    onReferenceConfigChanges?.({
      ...referenceMetaData,
      source,
      show: showReferenceTextRef.current?.checked
    });
  };

  const handleSourceChange = (event: any) => {
    setSource(event.target.value);
    onSourceSettingsChanges?.({
      source: event.target.value,
      property,
      aggFunc,
      yAxis,
      showLimit,
      limit
    });
    onReferenceConfigChanges?.({
      ...referenceMetaData,
      source: event.target.value,
      show: showReferenceTextRef.current?.checked
    });
  };

  const handlePropertyChange = (index: number, event: any) => {
    const newProps = [...property];
    newProps[index] = event.target.value;
    setProperty(newProps);
    const propTitle = properties.find((item: any) => item.id === newProps[0]);
    const titleSuffix = CHART_TYPE_TITLE_SUFFIX[`${graphType}-widget`];
    const selectedPropertyTitle = propTitle ? `${propTitle.label} ${titleSuffix}` : '';
    setGraphTitle(selectedPropertyTitle);
    onTitleSettingChanges({
      show: showTitleRef.current?.checked,
      title: selectedPropertyTitle,
      fontSize: titleFontSizeRef.current?.value
    });
    onSourceSettingsChanges?.({
      source,
      property: newProps,
      aggFunc,
      yAxis,
      showLimit,
      limit
    });
  };
  const handleAggFuncChange = (index: number, event: any) => {
    setAggFunc(event.target.value);
    let y = [];
    if (['mean', 'median'].includes(event.target.value) && NUMBER_PROPS[source]) {
      y = NUMBER_PROPS[source];
    }
    setYAxisOptions(y);
    setYAxis([y[0]]);
    onSourceSettingsChanges?.({
      source,
      property,
      aggFunc: event.target.value,
      ...{ yAxis: [y[0]] },
      showLimit,
      limit
    });
  };

  const handleAggByChange = (index: number, event: any) => {
    setYAxis([event.target.value]);
    onSourceSettingsChanges?.({
      source,
      property,
      aggFunc,
      yAxis: [event.target.value],
      showLimit,
      limit
    });
  };

  const handleEnableRiaGrpahs = () => {
    setIsRiaGraphEnabled(showRiaGraphRef.current?.checked);
    if (showRiaGraphRef.current?.checked) {
      const selectedGraph = RIA_GRAPHS[getSource(source)]?.find(
        (graph: any) => graph.id === selectedGraphConfig?.id
      );
      setSelectedGraphConfig(selectedGraph);
      handleRiaGraphChanges({
        ...selectedGraph,
        property: selectedGraph.query['x-axis'],
        aggFunc: selectedGraph.query.agg_function,
        yAxis: selectedGraph.query['y-axis']
      });
      if ('graphStyle' in selectedGraph) {
        setStyleFilters(selectedGraph.graphStyle);
      }
      onTitleSettingChanges({
        show: true,
        title: riaGraphTitle,
        fontSize: titleFontSizeRef.current?.value
      });
    } else {
      onSourceSettingsChanges?.({
        source,
        property,
        aggFunc,
        yAxis,
        showLimit,
        limit: parseInt(limit, 10)
      });
      onTitleSettingChanges({
        show: showTitleRef.current?.checked,
        title: graphTitle,
        fontSize: titleFontSizeRef.current?.value
      });
    }
  };

  const handleTextChange = useCallback(
    (newValue: string | null) => {
      if (newValue == null) {
        return;
      }
      let text = parseInt(limit, 10);
      if (/^\d*$/.test(newValue)) {
        text = parseInt(newValue, 10);
      }
      setLimit(newValue);
      if (isRiaGraphEnabled) {
        const selectedGraph = RIA_GRAPHS[getSource(source)]?.find(
          (graph: any) => graph.id === selectedGraphConfig?.id
        );
        let graphStyle = stylesFilters;
        if ('graphStyle' in selectedGraph && !isStylesFiltersApplied) {
          graphStyle = selectedGraph.graphStyle;
          setStyleFilters(selectedGraph.graphStyle);
        }
        handleRiaGraphChanges({
          ...selectedGraph,
          property: selectedGraph.query['x-axis'],
          aggFunc: selectedGraph.query.agg_function,
          graphStyle,
          yAxis: selectedGraph.query['y-axis'],
          limit: text,
          showLimit
        });
      } else {
        onSourceSettingsChanges?.({
          source,
          property,
          aggFunc,
          yAxis,
          limit: text,
          showLimit
        });
      }
    },
    [source, property, aggFunc, yAxis, showLimit, limit, isRiaGraphEnabled]
  );
  const debouncedChangeHandler = useMemo(
    () => debounce(handleTextChange, 1000),
    [handleTextChange]
  );

  const handleAutoCompleteChange = async (value: any, details: any, reason: any) => {
    const anchorColumns = properties.filter((col: any) => col?.identifier);
    const anchorProps =
      anchorColumns.length > 0 ? anchorColumns.map((col: any) => col.id) : [properties[0].id];
    if (reason === 'clear') {
      setTableSelectedProperty([]);
      setProperty(anchorProps);
      onSourceSettingsChanges?.({
        source,
        property: anchorProps,
        aggFunc,
        yAxis,
        showLimit,
        limit
      });
      return;
    }
    const newProps = value.map((prop: any) => prop.id);
    if (reason === 'removeOption') {
      setTableSelectedProperty(value);
      setProperty([...anchorProps, ...newProps]);
      onSourceSettingsChanges?.({
        source,
        property: [...anchorProps, ...newProps],
        aggFunc,
        yAxis,
        showLimit,
        limit
      });
      return;
    }
    if (tableSelectedProperty.length <= 4) {
      setTableSelectedProperty(value);
      setProperty([...anchorProps, ...newProps]);
      onSourceSettingsChanges?.({
        source,
        property: [...anchorProps, ...newProps],
        aggFunc,
        yAxis,
        showLimit,
        limit
      });
    } else {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { status: true, message: `You can't add more than 5 columns`, color: 'error' }
      });
    }
  };
  const isRiaGraphEnable = useCallback(() => {
    return graphType !== 'table' && riaGraphs.length > 0 && RIAGRAPHS_MODULE.includes(moduleName);
  }, [graphType, moduleName]);
  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {property.length > 0 && (
        <Box>
          <Box id='datapicker-container'>
            {/* ------------------ ria graphs start  */}
            {isRiaGraphEnable() && (
              <Box display='flex' flexDirection='row' justifyContent='space-between'>
                <FormControlLabel
                  sx={widgetStyles.checkBox}
                  control={
                    <CustomCheckbox
                      size='small'
                      defaultChecked={isRiaGraphEnabled}
                      sx={widgetStyles.checkBox}
                      inputRef={showRiaGraphRef}
                    />
                  }
                  label={
                    <Typography sx={widgetStyles.propertiesHeading}>
                      Use Recommended RIA Graphs:
                    </Typography>
                  }
                  onChange={handleEnableRiaGrpahs}
                />
                <Box sx={widgetStyles.riaGraphBoxContainer}>
                  <VivproReportSelect
                    // @ts-ignore
                    disabled={!isRiaGraphEnabled}
                    options={
                      isRiaGraphEnabled
                        ? riaGraphs.map((graph: any) => ({
                            label: graph.title,
                            value: graph.id
                          })) || []
                        : []
                    }
                    value={selectedGraphConfig?.id}
                    handleChange={handleGraphSelect}
                  />
                </Box>
              </Box>
            )}
            {/* ------------------ ria graphs Ends  */}
            {graphType !== 'table' && <Box sx={widgetStyles.dividerContainer} />}
            <Box ml={1}>
              {graphType !== 'table' &&
                property.map((key: React.Key | null | undefined, index: number) => (
                  <Grid key={key} container lg={12} alignItems='center' width='100%'>
                    <>
                      <Grid item lg={6}>
                        {index === 0 && (
                          <VivproReportSelect
                            // @ts-ignore
                            disabled={defaultDataSource.disableSelectionSource}
                            options={getAllowedSource().map((src: any) => ({
                              label: src.name,
                              value: src.id
                            }))}
                            value={source}
                            label='Source'
                            handleChange={handleSourceChange}
                          />
                        )}
                      </Grid>
                      <Grid item lg={6}>
                        <VivproReportSelect
                          // @ts-ignore
                          disabled={isRiaGraphEnabled}
                          options={properties.map((prop: any) => ({
                            label: prop.label,
                            value: prop.id
                          }))}
                          value={property[index]}
                          label='Property'
                          handleChange={(event: any) => handlePropertyChange(index, event)}
                        />
                      </Grid>
                      {AGG_FUNCTIONS[source][graphType] && (
                        <Grid item lg={6} mt={0.75}>
                          <VivproReportSelect
                            // @ts-ignore
                            disabled={isRiaGraphEnabled}
                            options={AGG_FUNCTIONS[source][graphType].map((prop: string) => ({
                              label: capitalize(prop),
                              value: prop
                            }))}
                            value={aggFunc}
                            label='Value Type'
                            handleChange={(event: any) => handleAggFuncChange(index, event)}
                          />
                        </Grid>
                      )}
                      {yAxisOptions && yAxisOptions.length > 0 && (
                        <Grid item lg={6}>
                          <VivproReportSelect
                            // @ts-ignore
                            disabled={isRiaGraphEnabled}
                            options={NUMBER_PROPS[source].map((prop: string) => ({
                              label: capitalize(prop),
                              value: prop
                            }))}
                            value={yAxis?.[0]}
                            label='Aggregate By'
                            handleChange={(event: any) => handleAggByChange(index, event)}
                          />
                        </Grid>
                      )}
                      <Grid item lg={6} mt={0.75}>
                        <Stack display='flex' flexDirection='column'>
                          <Typography mb={0.5} sx={widgetStyles.propertiesHeading}>
                            Category Limit
                          </Typography>
                          <CustomAutocomplete
                            id='category-limt'
                            value={limit}
                            options={['5', '10', '20', '25', '30']}
                            onInputChange={(
                              event: React.ChangeEvent<{}>,
                              newValue: string | null
                            ) => {
                              debouncedChangeHandler(newValue);
                            }}
                            renderInput={params => (
                              <TextField
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                sx={{ mr: 1, mb: 1, width: '95%' }}
                              />
                            )}
                            size='small'
                            ListboxProps={{ sx: { fontSize: 14 } }}
                            sx={{
                              '& .MuiAutocomplete-input, & .MuiInputLabel-root': {
                                fontSize: 14
                              }
                            }}
                          />
                        </Stack>
                      </Grid>
                    </>
                  </Grid>
                ))}
            </Box>

            {graphType === 'table' && (
              <Box padding={1} display='flex' flexDirection='column'>
                <Grid item lg={6}>
                  <VivproReportSelect
                    // @ts-ignore
                    disabled={defaultDataSource.disableSelectionSource}
                    options={getAllowedSource().map((src: any) => ({
                      label: src.name,
                      value: src.id
                    }))}
                    value={source}
                    label='Source'
                    handleChange={handleSourceChange}
                  />
                </Grid>
                <Box sx={{ display: 'flex', alignItems: 'center', flex: 3 }}>
                  <Typography sx={widgetStyles.propertiesHeading}>Columns</Typography>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    direction: 'column',
                    alignItems: 'center',
                    flex: 8
                  }}>
                  <CustomAutocomplete
                    multiple
                    sx={widgetStyles.tableDropDown}
                    value={tableSelectedProperty}
                    id='checkboxes-tags-demo'
                    options={properties.filter((col: any) => !col?.identifier)}
                    disableCloseOnSelect
                    getOptionLabel={(option: any) => option.label}
                    onChange={(
                      event: React.ChangeEvent<{}>,
                      value: any,
                      reason: any,
                      details?: any
                    ) => {
                      handleAutoCompleteChange(value, details, reason);
                    }}
                    renderOption={(props, option: any, { selected }) => {
                      return (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <li {...props}>
                          <Checkbox
                            icon={icon}
                            checkedIcon={checkedIcon}
                            style={{ marginRight: 8 }}
                            checked={selected}
                          />
                          {option?.label}
                        </li>
                      );
                    }}
                    style={{ width: 500 }}
                    renderInput={params => (
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      <TextField {...params} placeholder='Select Up to 5 columns' size='small' />
                    )}
                  />
                </Box>
              </Box>
            )}
          </Box>
          {/* Combo -> Chart Title Checkbox  */}
          <Box padding={1}>
            <Box display='flex' justifyContent='flex-end'>
              <Typography mb={0.5} mr={3.5} sx={widgetStyles.propertiesHeading}>
                Size:
              </Typography>
            </Box>
            <Box display='flex' justifyContent='space-between'>
              <FormControlLabel
                sx={widgetStyles.checkBox}
                control={
                  <CustomCheckbox
                    defaultChecked={titleSetting?.show}
                    size='small'
                    inputRef={showTitleRef}
                    sx={widgetStyles.checkBox}
                  />
                }
                label={<Typography sx={widgetStyles.propertiesHeading}>Chart Title</Typography>}
                onChange={handleOnTitleConfigChange}
              />
              <Box
                id='multipleTextFieldContainer'
                gap={2}
                width={360}
                display='flex'
                alignItems='center'
                justifyContent='flex-end'>
                <TextField
                  disabled={!titleSetting?.show}
                  // className={classes.multipleText}
                  id='graph-title'
                  value={titleSetting?.title}
                  variant='outlined'
                  sx={widgetStyles.textField}
                  onChange={handleOnTitleConfigChange}
                  inputRef={titleRef}
                  size='small'
                />
                <FormControl>
                  <CustomSelect
                    inputRef={titleFontSizeRef}
                    defaultValue={fontSize}
                    id='font-size'
                    onChange={handleFontSizeChange}
                    disabled={!titleSetting?.show}>
                    {FONTSIZES.map((opt: any) => (
                      <MenuItem key={String(opt.value)} value={opt.value}>
                        {opt.label}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>
              </Box>
            </Box>
          </Box>
          {/* Combo -> SHOW Reference Text */}
          <Box padding={1} display='flex' justifyContent='space-between'>
            <FormControlLabel
              sx={widgetStyles.checkBox}
              control={
                <CustomCheckbox
                  defaultChecked={referenceMetaData.show}
                  size='small'
                  inputRef={showReferenceTextRef}
                />
              }
              label={<Typography sx={widgetStyles.propertiesHeading}>Reference Text</Typography>}
              onChange={handleShowRefTextChange}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center'
              }}>
              <TextField
                disabled
                fullWidth
                id='reference-text'
                type='text'
                variant='outlined'
                value={referenceMetaData.text}
                sx={{
                  ...widgetStyles.textField,
                  '& .MuiOutlinedInput-root': {
                    width: 335,
                    height: 40,
                    fontSize: 14
                  }
                }}
                size='small'
              />
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default React.memo(DatapointPicker);
