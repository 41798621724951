const styles = {
  comparisonIcon: { height: '16px', width: '16px', color: 'primary.700' },
  comparisonButton: {
    fontSize: 14,
    textTransform: 'none',
    fontWeight: '400',
    ml: '4px',
    color: 'primary.700'
  },
  divider: { height: '16px', borderColor: 'gray.300' },
  popOver: {
    '& .MuiPopover-paper': {
      borderRadius: '16px !important',
      backgroundColor: 'none'
    }
  }
};

export default styles;
