const modifiedProductNumber = {
  moreButton: {
    fontSize: 12,
    fontWeight: 500,
    lineHeight: '150%',
    textDecoration: 'underline',
    textTransform: 'none',
    color: 'gray.700',
    justifyContent: 'flex-start',
    padding: 0,
    paddingBottom: 0.5,
    minWidth: 0
  },
  popup: {
    fontSize: 12,
    fontWeight: 600,
    textTransform: 'uppercase',
    color: 'gray.600',
    p: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    borderRadius: 2,
    boxShadow: '0px 1px 12px 0px #E0E0E0'
  },
  accordionPipNumberText: {
    color: 'gray.550',
    fontSize: 12,
    fontWeight: '500',
    lineHeight: '150%',
    textTransform: 'uppercase'
  }
};

export default modifiedProductNumber;
