import titleCase from '../../../utils/titleCase';
import { CardData } from '../types';
import getApprovalTracks from './getApprovalTracks';

interface JpPIApps {
  application_type: string;
  approval_date: string;
  document_count: number;
  document_total_page_count: number;
  formulation: string | Array<string>;
  generic_name: string | Array<string>;
  generic_name_jp: string | Array<string>;
  inProjects: Array<any>;
  indications_and_usage: string;
  indications_and_usage_jp: string;
  is_favorite: boolean;
  is_subscribed: boolean;
  matched_synonyms: Array<string>;
  package_insert_num: string;
  price: string;
  product_name: string | Array<string>;
  product_name_jp: string | Array<string>;
  route: string | Array<string>;
  s3_bucket: string;
  s3_label_url: string;
  approval_year: string;
  sponsor_name: string;
  sponsor_name_jp: string;
  yj_code: string;
  source_index: string;
}

export const JP_IDENTIFIER_KEY = 'yj_code';

const mapJpApps = (apiData: JpPIApps) => {
  const approvalTracks = getApprovalTracks(apiData, 'jp');
  const cardProps: CardData = {
    source: 'jp',
    title: titleCase(
      Array.isArray(apiData.product_name) ? apiData.product_name.join(' ') : apiData.product_name
    ),
    titleOriginal: titleCase(
      Array.isArray(apiData.product_name_jp)
        ? apiData.product_name_jp.join(' ')
        : apiData.product_name_jp
    ),
    groupTitle: titleCase(
      Array.isArray(apiData.generic_name) ? apiData.generic_name.join(' ') : apiData.generic_name
    ),
    groupTitleOriginal: titleCase(
      Array.isArray(apiData.generic_name_jp)
        ? apiData.generic_name_jp.join(' ')
        : apiData.generic_name_jp
    ),
    // using application_number as fallback for yj_code (used for reg360 header popup card)
    identifier: apiData.yj_code ?? (apiData as any)?.application_number,
    showDiscontinued: false,
    isFavorite: apiData.is_favorite,
    isSubscribed: apiData.is_subscribed,
    metadata: [
      {
        key: 'Sponsor Name',
        value: titleCase(
          (Array.isArray(apiData.sponsor_name)
            ? apiData.sponsor_name.join(';')
            : apiData.sponsor_name) || '---'
        ),
        valueOriginal: titleCase(
          (Array.isArray(apiData.sponsor_name_jp)
            ? apiData.sponsor_name_jp.join(';')
            : apiData.sponsor_name_jp) || '---'
        )
      },
      {
        key: 'Formulation',
        value: titleCase(
          (Array.isArray(apiData.formulation)
            ? apiData.formulation.join(';')
            : apiData.formulation) || '---'
        )
      },
      {
        key: 'Route',
        value: titleCase(
          (Array.isArray(apiData.route) ? apiData.route.join(' ') : apiData.route) || '---'
        )
      },
      { key: 'Latest Submission Year', value: apiData.approval_year }
    ],
    labels: [],
    colorScheme: apiData?.source_index,
    approvalTracks,
    ...apiData
  };
  return cardProps;
};

export default mapJpApps;
