import React, { memo } from 'react';

// mui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

import PRACHomePage from './components/PRACHomePage';

// store
import useUpdateModule from '../../hooks/useUpdateModule';

const PRAC = () => {
  useUpdateModule('PDCO');

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <PRACHomePage />
    </ThemeProvider>
  );
};

export default memo(PRAC);
