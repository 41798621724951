import React from 'react';
import { animated } from '@react-spring/web';
import { getformatLabel } from './utils';

interface ArcLinkLabelProps {
  datum: any;
  labelCharacterLimit: number;
}

const ArcLinkLabel = ({ datum, labelCharacterLimit }: ArcLinkLabelProps) => {
  const wrappedLabel = getformatLabel(datum?.label ?? 'NA', labelCharacterLimit);

  return (
    <animated.g opacity={datum.style.opacity}>
      <animated.path
        fill='none'
        stroke={datum.style.linkColor}
        strokeWidth={datum.style.thickness}
        d={datum.style.path}
      />
      {wrappedLabel.map((line, i) => (
        <animated.text
          y={i * 12}
          // eslint-disable-next-line react/no-array-index-key
          key={`arcLinkLabel_line_${i}`}
          transform={datum.style.textPosition}
          textAnchor={datum.style.textAnchor}
          dominantBaseline='central'
          style={{
            fontWeight: 600,
            fill: '#616161',
            fontSize: 12
          }}>
          {line}
        </animated.text>
      ))}
    </animated.g>
  );
};

export default React.memo(ArcLinkLabel);
