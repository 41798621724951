const styles = {
  drawer: {
    '& >.MuiDrawer-paper': {
      height: '98%',
      borderTopLeftRadius: 16,
      borderTopRightRadius: 16,
      position: 'absolute',
      backgroundColor: 'white.smokeDark'
    }
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%'
  },
  headerWrapper: {
    backgroundColor: 'primary.600',
    display: 'flex',
    height: 48,
    flexDirection: 'column'
  },
  titleWrapper: {
    backgroundColor: 'primary.600',
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center',
    pt: 3,
    pb: 1.25,
    height: 48,
    py: 1.5,
    px: 3
  },
  title: {
    flexGrow: 1,
    textAlign: 'center',
    alignSelf: 'center',
    fontWeight: '700',
    fontSize: 20,
    color: 'gray.0'
  },
  actionsWrapper: {
    position: 'absolute',
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    ml: -5.6,
    alignItems: 'center'
  },
  separator: {
    borderLeft: (theme: any) => `1px solid ${theme.palette.gray.lightVariant3}`,
    height: 18,
    margin: 'auto 8px'
  },
  dividerWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center'
  },
  divider: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: 2,
    mb: 2,
    width: 64
  },
  verticalDivider: { height: 20, borderColor: 'gray.400' },
  childrenWrapper: { p: 3, display: 'flex' },
  buttonIcon: { height: 16, width: 16, color: 'gray.700', cursor: 'pointer' },
  primaryButtonIcon: { height: 16, width: 16, color: 'gray.0' },
  primaryButton: {
    fontSize: 14,
    textTransform: 'none',
    fontWeight: '400',
    ml: 0.25,
    color: 'gray.0',
    width: 'max-content'
  },
  closeButton: {
    border: '2px solid',
    width: 24,
    height: 24,
    color: 'white.main',
    borderColor: 'white.main',
    padding: '3px !important',
    ml: 1,
    '& > svg': {
      fontSize: 20
    },
    '&:hover': {
      color: 'primary.main',
      borderColor: 'primary.main',
      backgroundColor: 'white.main'
    }
  },
  downloadbuttonIcon: { height: '20px', width: '20px', color: 'gray.700', cursor: 'pointer' },
  headerContainer: { width: '100%', backgroundColor: 'gray.0', py: 2, px: 3 },
  chip: {
    display: 'flex',
    fontSize: 14,
    height: 32,
    py: 1,
    px: 3,
    backgroundColor: 'gray.0',
    border: '1px solid',
    borderColor: 'gray.300',
    borderRadius: 2,
    '&:hover': {
      backgroundColor: 'gray.100',
      border: '1px solid',
      borderColor: 'gray.300',
      color: 'gray.800',
      fontWeight: 500,
      borderRadius: 2
    },
    '&.MuiChip-filled': {
      backgroundColor: 'primary.50',
      color: 'primary.700',
      fontWeight: 700,
      border: 'none',
      borderRadius: 2
    }
  }
};

export default styles;