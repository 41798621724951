import React, { useMemo, useState } from 'react';

import { Accordion, AccordionDetails, AccordionSummary, Divider, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import styles from './SummaryCard.style';
import { AngleDownIcon } from '../../../assets/svgs/Icons';
import { SummaryCardProps, SummaryDetails } from '../types';

const SummaryCard: React.FC<SummaryCardProps> = ({ details }) => {
  const [expanded, setExpanded] = useState<boolean>(false);

  const expandable = useMemo(() => {
    return details[0]?.content?.length > 1 || details.length > 1;
  }, [details]);
  return (
    <Box sx={styles.container}>
      <Typography sx={styles.summaryTitle}>Summary</Typography>
      <Divider orientation='horizontal' sx={styles.divider} />
      {details.slice(0, 1).map(({ title, content }: SummaryDetails) => {
        return (
          <React.Fragment key={title}>
            <Typography sx={styles.contentTitle}>{title}</Typography>
            {content.slice(0, !expanded ? 1 : content.length).map((text: string) => (
              <Typography
                key={text}
                dangerouslySetInnerHTML={{
                  __html: `${text}${
                    !expanded && (content.length > 0 || details.length > 0) ? '..' : ''
                  }`
                }}
                sx={styles.contentText}
              />
            ))}
          </React.Fragment>
        );
      })}
      {expandable && (
        <Accordion
          sx={styles.cardSummaryAccordion}
          onChange={() => setExpanded((prev: boolean) => !prev)}>
          <AccordionSummary sx={styles.cardSummaryAccordionSummary}>
            <Divider orientation='horizontal' sx={{ borderColor: 'gray.200', mt: 1 }} />
            <Box display='flex' mx='auto' mt={1} alignItems='center'>
              <AngleDownIcon
                sx={{ fontSize: 8, rotate: expanded ? '180deg' : '0deg', color: 'primary.700' }}
              />
              <Typography px={0.5} color='primary.700' fontWeight={500}>
                {!expanded ? 'Show' : 'Hide'}
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={styles.accordionDetails}>
            {details.slice(1).map(({ title, content }: SummaryDetails) => {
              return (
                <React.Fragment key={title}>
                  <Typography sx={styles.contentTitle} mt={3}>
                    {title}
                  </Typography>
                  {content.map((text: string) => (
                    <Typography
                      key={text}
                      dangerouslySetInnerHTML={{ __html: text }}
                      sx={styles.contentText}
                    />
                  ))}
                </React.Fragment>
              );
            })}
          </AccordionDetails>
        </Accordion>
      )}
    </Box>
  );
};

export default React.memo(SummaryCard);
