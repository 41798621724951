import { createSvgIcon } from '@mui/material';

const NegativeTag = createSvgIcon(
  <svg width='12' height='12' viewBox='0 0 12 12' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C11.9964 2.68777 9.31223 0.00358594 6 0ZM7.99999 7.29349C8.20317 7.4805 8.2163 7.79681 8.02929 7.99999C7.84228 8.20317 7.52597 8.2163 7.32279 8.02929C7.31262 8.01994 7.30287 8.01016 7.29349 7.99999L6 6.70699L4.707 7.99999C4.50837 8.19183 4.19184 8.18632 4.00001 7.98769C3.81288 7.79393 3.81288 7.48676 4.00001 7.293L5.29301 6L4.00001 4.707C3.80817 4.50837 3.81368 4.19184 4.01231 4.00001C4.20607 3.81288 4.51324 3.81288 4.707 4.00001L6 5.29301L7.29349 4.00001C7.4805 3.79683 7.79681 3.7837 7.99999 3.97071C8.20317 4.15772 8.2163 4.47403 8.02929 4.67721C8.01994 4.68738 8.01016 4.69713 7.99999 4.70651L6.70699 6L7.99999 7.29349Z'
      fill='#DC5A59'
    />
  </svg>,
  'NegativeTag'
);

export default NegativeTag;
