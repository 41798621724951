import React, { Suspense, useContext, useMemo, useState } from 'react';

import { Box, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

import ResultsStore from '../../../store/SearchResults';
import Insights from '../../Insights/Insights';
import VISUALIZE_CHART_MAPPING from '../../Insights/const';
import SuspenseCircularLoader from '../../../helpers/SuspenseCircularLoader';

const Plot = React.lazy(() => import('react-plotly.js'));

const USCTGraphs = ({ payload }: any) => {
  const { resultsState } = useContext(ResultsStore);
  const [openModal, setOpenModal] = useState(false);

  const handleDialog = () => {
    if (payload?.enrollment_by_region?.length > 0) {
      setOpenModal(true);
    }
  };

  const formatdata = (data: any[]) => {
    const categories = data?.map((item: { nctId: any; nct_id: any }) => item.nctId || item.nct_id);
    const keys = data?.reduce((ele: string[], item: { [x: string]: number }) => {
      Object.keys(item).forEach(key => {
        if (key !== 'nct_id' && !ele.includes(key) && item[key] !== 0) {
          ele.push(key);
        }
      });
      return ele;
    }, []);

    const traces = keys?.map((key: string | number) => ({
      x: categories,
      y: data.map((item: { [x: string]: any }) => item[key] || 0),
      name: key,
      type: 'bar'
    }));
    return traces;
  };

  const getLayer1Charts = useMemo(() => {
    const ctGraph = VISUALIZE_CHART_MAPPING['clinical-trials-us']?.layer1;

    if (ctGraph) {
      ctGraph.forEach(graph => {
        if (graph?.id === 'number_of_trials_by_completion_year') {
          // eslint-disable-next-line no-param-reassign
          graph.data = payload?.year_graph;
        }
        if (graph?.id === 'number_of_trials_by_phase') {
          // eslint-disable-next-line no-param-reassign
          graph.data = payload?.phase_graph;
        }
      });

      return ctGraph;
    }
    return ctGraph;
  }, [payload]);

  const getLayer2Charts = useMemo(() => {
    const ctGraph = VISUALIZE_CHART_MAPPING['clinical-trials-us']?.layer2;

    if (ctGraph) {
      ctGraph.forEach(graph => {
        if (graph?.id === 'number_of_trials_by_recruitment_status') {
          // eslint-disable-next-line no-param-reassign
          graph.data = payload?.status;
        }
        if (graph?.id === 'number_of_patients_by_trials') {
          // eslint-disable-next-line no-param-reassign
          graph.data = formatdata(payload?.enrollment_by_trial ?? []);
        }
      });

      return ctGraph;
    }
    return ctGraph;
  }, [payload]);

  return (
    <>
      <Insights
        statsList={[]}
        isStats={false}
        isLoading={resultsState.ctBusinessIntelligenceLoading || resultsState.loading}
        source='ct'
        searchType='quicksearch'
        searchTerm={resultsState?.decryptedPayload?.query}
        appliedFilter={{}}
        layer1Component={getLayer1Charts}
        layer2Component={getLayer2Charts}
        searchId={resultsState.searchIds.ctSearchId}
        handleExpandDialog={handleDialog}
      />

      <Dialog
        open={openModal}
        fullWidth
        maxWidth='lg'
        aria-labelledby='customized-dialog-title'
        sx={{ height: '100vh' }}
        onClose={() => setOpenModal(false)}>
        <DialogTitle>
          <Box display='flex' justifyContent='flex-end' alignItems='flex-start' flexDirection='row'>
            <Box>
              <IconButton
                aria-label='delete'
                onClick={() => setOpenModal(false)}
                sx={{ fontSize: '30px' }}>
                <CancelIcon fontSize='inherit' />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ height: '79vh', margin: 2 }}>
          <Box display='flex' width='100%' height='100%'>
            {payload?.enrollment_by_region?.length > 0 ? (
              <Suspense fallback={<SuspenseCircularLoader />}>
                <Plot
                  data={formatdata(payload?.enrollment_by_region).map((dataset: any) => ({
                    ...dataset,
                    x: dataset.y,
                    y: dataset.x,
                    orientation: 'h'
                  }))}
                  layout={{
                    title: { text: 'Number of Patients by Region of Enrollment' },
                    barmode: 'stack',
                    autosize: true,
                    margin: { t: 32, r: 140, l: 120 },
                    xaxis: {
                      tickangle: 90,
                      showticklabels: true,
                      color: '#9E9E9E',
                      tickfont: {
                        size: 12,
                        color: '#757575',
                        family: 'Roboto, Arial, sans-serif'
                      }
                    },
                    orientation: 90,
                    dragmode: 'pan',
                    showlegend: true
                  }}
                  style={{ width: '100%', height: '100%' }}
                  useResizeHandler
                  config={{
                    toImageButtonOptions: {
                      filename: `number_of_patients_by_region_of_enrollment`
                    },
                    scrollZoom: true
                  }}
                />
              </Suspense>
            ) : (
              'No Data'
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default React.memo(USCTGraphs);
