import React from 'react';
import { Typography, Tooltip } from '@mui/material';
import styles from '../../styles/SectionDetails.styles';

interface ApplicantProps {
  sponsorName: string;
  brandName: string;
  authorizationPartner: string;
}

const Applicant = ({ sponsorName, brandName, authorizationPartner }: ApplicantProps) => {
  // Check if all values are either null or "NA"
  const isAllNA =
    (!sponsorName || sponsorName === 'NA') &&
    (!brandName || brandName === 'NA') &&
    (!authorizationPartner || authorizationPartner === 'NA');

  if (isAllNA) {
    return <Typography sx={styles.valueText}>NA</Typography>;
  }

  const applicantTooltipText = () => {
    let text = '';
    if (sponsorName && sponsorName !== 'NA') {
      text += sponsorName;
    }
    if (brandName && brandName !== 'NA') {
      text += ` (${brandName})`;
    }
    if (authorizationPartner && authorizationPartner !== 'NA') {
      text += ` ${authorizationPartner}`;
    }
    return text;
  };

  return (
    <Tooltip title={`Applicant: ${isAllNA ? 'NA' : applicantTooltipText()}`} placement='top'>
      <Typography sx={styles.valueText} key={sponsorName}>
        {sponsorName && sponsorName !== 'NA' && <span>{sponsorName}</span>}
        {brandName && brandName !== 'NA' && (
          <span style={styles.brandText as React.CSSProperties}>{`(${brandName})`}</span>
        )}
        {authorizationPartner && authorizationPartner !== 'NA' && (
          <span style={styles.partnerText as React.CSSProperties}>{authorizationPartner}</span>
        )}
      </Typography>
    </Tooltip>
  );
};

export default React.memo(Applicant);
