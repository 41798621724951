const styles = {
  headerWrapper: {
    bgcolor: 'primary.600',
    alignSelf: 'stretch',
    p: '12px 24px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  changeGuidancesButtonContainer: {
    cursor: 'pointer',
    position: 'absolute',
    display: 'flex',
    left: '2%',
    alignItems: 'center'
  },
  leftArrowIcon: { height: '14px', width: '14px', color: 'white.main' },
  changeGuidancesText: {
    fontFamily: 'Mulish',
    fontSize: '14px',
    color: 'white.main',
    fontWeight: 600
  },
  guidanceComparisonContainer: {
    flexGrow: 1,
    textAlign: 'center',
    alignSelf: 'center',
    justifyContent: 'center'
  },
  guidanceComparisonText: {
    fontSize: 20,
    fontWeight: 700,
    color: 'white.main',
    fontFamily: 'Mulish',
    lineHeight: 'normal'
  },
  iconButton: { position: 'absolute', display: 'flex', left: '97.25%', p: 0 }
};

export default styles;
