import React, { useEffect, useState } from 'react';

import { Divider, Stack } from '@mui/material';
import { RoundedMenu, MenuWithTickOptions } from '../../../components/ui/Menu';
import TabButton from '../../../components/ui/TabButton';

import styles from './MeetingDetails.style';
import { TabButtonProps, TabOption } from '../types';

const GroupTabs = ({
  tabs,
  onChange
}: {
  tabs: TabButtonProps[];
  // eslint-disable-next-line no-unused-vars
  onChange: (tab: string, group: string) => void;
}) => {
  const [tab, setTab] = useState<string>('All');
  const [, setSelectedGroup] = useState<string>('All');

  const [moreOptions, setMoreOptions] = useState<Array<TabOption>>([]);

  const [anchorEl, setAnchorEl] = useState<any>();

  useEffect(() => {
    let options: any = [];
    tabs.forEach((t: TabButtonProps) => {
      if (t.id === 'more') {
        options = t.options;
      }
    });
    setMoreOptions(options);
  }, [tabs]);

  const handleMoreOptionSelect = (option: TabOption) => {
    // eslint-disable-next-line no-param-reassign
    const newOptions = moreOptions.map((opt: TabOption) => ({
      ...opt,
      selected: opt.actualLabel === option.actualLabel
    }));
    setMoreOptions(newOptions);
    setTab('More');
    setSelectedGroup(option.actualLabel);
    setAnchorEl(null);
    onChange?.('More', option.actualLabel);
  };

  const handleAllGroupClick = () => {
    setTab('All');
    onChange?.('All', 'All');

    const newOptions = moreOptions.map((opt: any) => ({
      ...opt,
      selected: false
    }));
    setMoreOptions(newOptions);
  };

  const handleOptionSelect = (e: React.SyntheticEvent, button: TabButtonProps) => {
    if (button.label === 'More') {
      setAnchorEl(e.target);
    } else {
      setTab(button.label);
      setSelectedGroup(button.label);
      setMoreOptions(moreOptions.map((opt: TabOption) => ({ ...opt, selected: false })));
      onChange?.(button.label, button.label);
    }
  };

  return (
    <Stack
      spacing={1.5}
      direction='row'
      alignItems='center'
      flexWrap='wrap'
      sx={styles.tabContainer}>
      <TabButton title='All' size='xsmall' onClick={handleAllGroupClick} selected={tab === 'All'} />
      <Divider orientation='vertical' sx={styles.tabDivider} />
      {tabs.map((button: any) => (
        <React.Fragment key={button.id}>
          <TabButton
            title={`${button.label} (${button.count})`}
            size='xsmall'
            onClick={(e: React.SyntheticEvent) => handleOptionSelect(e, button)}
            selected={tab === button.label}
            icon={button.icon}
            disabled={button.count === 0}
          />
          {button.label === 'More' && (
            <RoundedMenu open={!!anchorEl} anchorEl={anchorEl} onClose={() => setAnchorEl(null)}>
              <MenuWithTickOptions
                options={moreOptions}
                isNestedMenu
                onOptionSelect={handleMoreOptionSelect}
              />
            </RoundedMenu>
          )}
        </React.Fragment>
      ))}
    </Stack>
  );
};

export default React.memo(GroupTabs);
