import React, { useMemo } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { Close } from '@mui/icons-material';
import styles from '../styles/nameConfirmationDialog.styles';

const NameConfirmationDialog = ({
  name,
  heading,
  buttonText,
  description,
  onNameChange,
  onDescriptionChange,
  onSave,
  onClose,
  open
}: any) => {
  const disableSubmit = useMemo(() => {
    return name.length === 0;
  }, [name]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        onClick: (e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()
      }}
      sx={styles.dialog}>
      <Stack direction='column' spacing='24px' sx={styles.container}>
        <Stack alignItems='center' direction='column' justifyContent='center' spacing={3}>
          <DialogTitle sx={{ padding: '0px' }}>
            <Stack direction='column'>
              <Box sx={styles.boxContainer}>
                <Typography sx={styles.formHeading}>{heading}</Typography>
                <IconButton size='small' title='Close' onClick={onClose} sx={styles.closeIcon}>
                  <Close />
                </IconButton>
              </Box>
              <Divider sx={styles.divider} />
            </Stack>
          </DialogTitle>
        </Stack>
        <DialogContent sx={styles.dialogContainer}>
          <Stack display='flex' flexDirection='column' justifyContent='space-evenly'>
            <Stack direction='column' mb='10px'>
              <Typography sx={styles.fieldTitle}>Name*</Typography>
              <TextField
                key='name'
                size='small'
                fullWidth
                required
                id='name'
                variant='outlined'
                defaultValue={name}
                value={name}
                onChange={onNameChange}
                helperText={`${name.length}/32 characters`}
                sx={styles.textField}
                autoFocus
                inputProps={{
                  maxLength: 32
                }}
              />
            </Stack>

            <Stack mb='20px' direction='column'>
              <Typography sx={styles.fieldTitle}>Description</Typography>
              <TextField
                fullWidth
                size='small'
                id='description'
                name='description'
                variant='outlined'
                multiline
                defaultValue={description}
                value={description}
                onChange={onDescriptionChange}
                inputProps={{ maxLength: 48 }}
                minRows={4}
                helperText={`${description.length}/48 characters`}
                sx={styles.textField}
              />
            </Stack>
            <Stack gap={1.5} display='flex' flexDirection='row' justifyContent='center'>
              <Button sx={styles.cancelButton} onClick={onClose}>
                close
              </Button>
              <Button
                variant='contained'
                disabled={disableSubmit}
                sx={styles.submitButton}
                onClick={onSave}>
                {buttonText}
              </Button>
            </Stack>
          </Stack>
        </DialogContent>
      </Stack>
    </Dialog>
  );
};

export default React.memo(NameConfirmationDialog);
