import { createSvgIcon } from '@mui/material/utils';

const BarChartIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='15' height='16' viewBox='0 0 15 16' fill='none'>
    <path
      d='M15 15.5H1.875C1.37772 15.5 0.900805 15.3025 0.549175 14.9508C0.197544 14.5992 0 14.1223 0 13.625L0 0.5H1.25V13.625C1.25 13.7908 1.31585 13.9497 1.43306 14.0669C1.55027 14.1842 1.70924 14.25 1.875 14.25H15V15.5Z'
      fill='currentColor'
    />
    <path d='M10 7.37549H8.75V13.0005H10V7.37549Z' fill='currentColor' />
    <path d='M5 7.37549H3.75V13.0005H5V7.37549Z' fill='currentColor' />
    <path d='M12.5 4.25H11.25V13H12.5V4.25Z' fill='currentColor' />
    <path d='M7.5 4.25H6.25V13H7.5V4.25Z' fill='currentColor' />
  </svg>,
  'BarChartIcon'
);
export default BarChartIcon;
