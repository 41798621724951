import * as React from 'react';
import { useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import { Button, Snackbar, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import Header from '../Header/Header';
import useMoxoSdkServices from '../../hooks/useMoxoSdkServices';
import { getAppVersion } from '../../api/pages/HomePage';
import MODULE_PATHNAME_STYLE from './const';
import WhatsNewModal from '../WhatsNew/WhatsNewModal';

const Main = styled('main', { shouldForwardProp: prop => prop !== 'open' })(
  // @ts-ignore
  ({ theme }) => ({
    flexGrow: 1,
    backgroundColor: window.location.pathname.includes('aria') ? 'white' : 'none',
    padding:
      window.location.pathname.includes('collaborate') ||
      window.location.pathname.includes('account')
        ? 0
        : theme.spacing(0, 3)
  })
);

const currentVersion = process.env.REACT_APP_VERSION || '';

const poll = async ({ fn, interval }: any) => {
  const executePoll = async (resolve: any, reject: any) => {
    await fn();
    setTimeout(executePoll, interval, resolve, reject);
  };
  return new Promise(executePoll);
};

const AppLayout = ({ children }: any) => {
  useMoxoSdkServices();
  const [updateMessage, setUpdateMessage] = React.useState<boolean>(false);

  const windowLocationPathname = window.location.pathname?.toLowerCase() ?? '';

  const moduleStyle = () =>
    MODULE_PATHNAME_STYLE.find((each: any) =>
      windowLocationPathname.includes(each?.path?.toLowerCase())
    )?.style;

  const handleUpdateClose = () => {
    setUpdateMessage(false);
  };
  const checkAppVersion = async () => {
    const versionResponse = await getAppVersion();
    const fetchedVersion = versionResponse?.data?.Success?.body?.version || '';
    if (currentVersion && fetchedVersion > currentVersion) {
      setUpdateMessage(true);
    }
  };
  const handleAppUpdate = () => {
    window.location.reload();
  };

  useEffect(() => {
    poll({ fn: checkAppVersion, interval: 14400000 });
  }, []);

  return (
    <Stack>
      <Header />
      <Main sx={moduleStyle()}>{children}</Main>
      <Snackbar
        open={updateMessage}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        onClose={() => handleUpdateClose()}
        sx={{
          left: 'auto',
          width: 500,
          '&.MuiSnackbar-root': {
            color: '#fff '
          },
          '& .MuiSnackbarContent-action': {
            position: 'absolute !important',
            top: `10px !important`,
            right: 10
          }
        }}
        message={
          <Box style={{ textAlign: 'left' }} p={3}>
            <Typography variant='h6' sx={{ color: 'gold.head' }}>
              <b>A new version of RIA is available</b>
            </Typography>
            <Typography variant='body1' mt={1}>
              Refresh to get the latest version.
            </Typography>
            <Button
              variant='text'
              sx={{
                color: 'white !important',
                textTransform: 'none',
                border: '1px solid white',
                borderRadius: '5px',
                mt: 4,
                ml: 1
              }}
              onClick={() => {
                handleAppUpdate();
              }}>
              Refresh
            </Button>
          </Box>
        }
      />
      <WhatsNewModal />
    </Stack>
  );
};
export default AppLayout;
