import { FILTER_TYPE } from '../../components/NewFilters/constants';

export const SECTIONS: Record<any, any> = {
  'Urgent EU procedure': {
    'Main Discussion': [
      { value: '2.1. Newly triggered procedures', label: '2.1. Newly Triggered' },
      { value: '2.2. Ongoing procedures', label: '2.2. Ongoing' },
      {
        value: '2.3. Procedures for finalisation',
        label: '2.3. Finalisation'
      }
    ],
    'Annex I': []
  },
  'Other EU Referrals': {
    'Main Discussion': [
      { value: '3.1. Newly triggered procedures', label: '3.1. Newly Triggered' },
      { value: '3.2. Ongoing procedures', label: '3.2. Ongoing' },
      {
        value: '3.3. Procedures for finalisation',
        label: '3.3. Finalisation'
      },
      {
        value: '3.4. Re-examination procedures',
        label: '3.4. Re-examination'
      },
      {
        value: '3.5. Others',
        label: '3.5. Others'
      }
    ],
    'Annex I': []
  },
  'Signals assessment and prioritisation ': {
    'Main Discussion': [
      {
        value:
          '4.1. New signals detected from EU spontaneous reporting systems and/or other sources',
        label: '4.1. New signals detected'
      },
      { value: '4.2. Signals follow-up and prioritisation', label: '4.2. Signals follow-up' },
      {
        value: '4.3. Variation procedure(s) resulting from signal evaluation',
        label: '4.3. Variation procedure(s)'
      }
    ],
    'Annex I': [
      {
        value: '14.1. New signals detected from EU spontaneous reporting systems',
        label: '14.1. EU - New Signals detected'
      },
      {
        value: '14.2. New signals detected from other source',
        label: '14.2. Others - New Signal detected'
      }
    ]
  },
  'Risk management plans (RMPs)': {
    'Main Discussion': [
      { value: '5.1. Medicines in the pre-authorisation phase', label: '5.1. Pre-authorisation' },
      {
        value: '5.2. Medicines in the post-authorisation phase - PRAC-led procedures',
        label: '5.2. PRAC-led procedures - post-authorisation'
      },
      {
        value: '5.3. Medicines in the post-authorisation phase - CHMP-led procedures',
        label: '5.3. CHMP-led procedures - post-authorisation'
      }
    ],
    'Annex I': [
      { value: '15.1. Medicines in the pre-authorisation phase', label: '15.1. Pre-authorisation' },
      {
        value: '15.2. Medicines in the post-authorisation phase - PRAC-led procedures',
        label: '15.2. PRAC-led procedures - post-authorisation'
      },
      {
        value: '15.3. Medicines in the post-authorisation phase - CHMP-led procedures',
        label: '15.3. CHMP-led procedures - post-authorisation'
      }
    ]
  },
  'Periodic safety update reports (PSURs)': {
    'Main Discussion': [
      {
        value:
          '6.1. PSUR single assessment (PSUSA) procedures including centrally authorised products (CAPs) only',
        label: '6.1. CAPs only'
      },
      {
        value:
          '6.2. PSUR single assessment (PSUSA) procedures including centrally authorised products (CAPs) and nationally authorised products (NAPs)',
        label: '6.2. CAPs and NAPs both'
      },
      {
        value:
          '6.3. PSUR single assessment (PSUSA) procedures including nationally authorised products (NAPs) only',
        label: '6.3. NAPs only'
      },
      { value: '6.4. Follow-up to PSUR/PSUSA procedures', label: '6.4. Follow-up"' },
      {
        value: '6.5. Variation procedure(s) resulting from PSUSA evaluation"',
        label: '6.5. Variation procedure(s)'
      },
      {
        value: '6.6. Expedited summary safety reviews',
        label: '6.6. Expedited summary'
      }
    ],
    'Annex I': [
      {
        value:
          '16.1. PSUR single assessment (PSUSA) procedures including centrally authorised products (CAPs) only',
        label: '16.1. CAPs only'
      },
      {
        value:
          '16.2. PSUR single assessment (PSUSA) procedures including centrally authorised products (CAPs) and nationally authorised products (NAPs)',
        label: '16.2. CAPs and NAPs both'
      },
      {
        value:
          '16.3. PSUR single assessment (PSUSA) procedures including nationally authorised products (NAPs) only',
        label: '16.3. NAPs only'
      },
      { value: '16.4. Follow-up to PSUR/PSUSA procedures', label: '16.4. Follow-up"' },
      {
        value: '16.5. Variation procedure(s) resulting from PSUSA evaluation"',
        label: '16.5. Variation procedure(s)'
      },
      {
        value: '16.6. Expedited summary safety reviews',
        label: '16.6. Expedited summary'
      }
    ]
  },
  'Post-authorisation safety studies (PASS)': {
    'Main Discussion': [
      {
        value: '7.1. Protocols of PASS imposed in the marketing authorisation(s)',
        label: '7.1. Protocols imposed'
      },
      {
        value: '7.2. Protocols of PASS non-imposed in the marketing authorisation(s)',
        label: '7.2. Protocols non-imposed'
      },
      {
        value: '7.3. Results of PASS imposed in the marketing authorisation(s)',
        label: '7.3. Results imposed'
      },
      {
        value: '7.4. Results of PASS non-imposed in the marketing authorisation(s)',
        label: '7.4. Results non-imposed'
      },
      {
        value:
          '7.5. Interim results of imposed and non-imposed PASS submitted before the entry into force of the revised variation regulation',
        label: '7.5. Interim results of imposed and non-imposed'
      },
      {
        value: '7.6. Others',
        label: '7.6. Others'
      }
    ],
    'Annex I': [
      {
        value: '17.1. Protocols of PASS imposed in the marketing authorisation(s)',
        label: '17.1. Protocols imposed'
      },
      {
        value: '17.2. Protocols of PASS non-imposed in the marketing authorisation(s)',
        label: '17.2. Protocols non-imposed'
      },
      {
        value: '17.3. Results of PASS imposed in the marketing authorisation(s)',
        label: '17.3. Results imposed'
      },
      {
        value: '17.4. Results of PASS non-imposed in the marketing authorisation(s)',
        label: '17.4. Results non-imposed'
      },
      {
        value:
          '17.5. Interim results of imposed and non-imposed PASS submitted before the entry into force of the revised variation regulation',
        label: '17.5. Interim results of imposed and non-imposed'
      },
      {
        value: '17.6. Others',
        label: '17.6. Others'
      }
    ]
  },
  'Authorization Renewals and reassessments': {
    'Main Discussion': [
      {
        value: '8.1. Annual reassessments of the marketing authorisation',
        label: '8.1. Annual reassessments'
      },
      {
        value: '8.2. Conditional renewals of the marketing authorisation',
        label: '8.2. Conditional renewals'
      },
      {
        value: '8.3. Renewals of the marketing authorisation',
        label: '8.3. Renewals'
      }
    ],
    'Annex I': [
      {
        value: '18.1. Annual reassessments of the marketing authorisation',
        label: '18.1. Annual reassessments'
      },
      {
        value: '18.2. Conditional renewals of the marketing authorisation',
        label: '18.2. Conditional renewals'
      },
      {
        value: '18.3. Renewals of the marketing authorisation',
        label: '18.3. Renewals'
      }
    ]
  },
  'Other safety Issues': {
    'Main Discussion': [
      {
        value: '11.1. Safety related variations of the marketing authorisation',
        label: '11.1. Safety related variations'
      },
      { value: '11.2. Other requests', label: '11.2. Other requests' }
    ],
    'Annex I': []
  }
};

export const PRAC_FILTERS = [
  {
    id: 1,
    label: 'Active Ingredient',
    value: 'active_ingredient',
    elasticSearchType: 'active_ingredient',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Brand Name',
    value: 'brand_name',
    elasticSearchType: 'brand_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Rapporteur',
    value: 'rapporteur',
    elasticSearchType: 'rapporteur',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Meeting Year',
    value: 'year',
    elasticSearchType: 'year',
    filterType: FILTER_TYPE.YEAR_RANGE,
    showInBottomFilter: true
  }
];
