import { memo, useContext, useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  Tooltip
} from '@mui/material';
import { isEmpty } from 'lodash';
import { getSubscribedNotifications } from '../../../api/modules/userNotification';
import NotificationLoading from './NotificationLoading';
import NoNotification from './NoNotification';
import NotificationsStore from '../../../store/Notifications';
import NotificationsActions from '../../../store/Notifications/actions';
import notificationsStyles from './Notifications.styles';
import notificationsDetailStyles from './NotificationsDetail.styles';
import SubscriptionActions from '../../../components/Subscription/SubscriptionActions';
import notificationsListStyles from './NotificationsList.styles';
import { handleDocumentSearchClick } from '../utils';
import NotificationFlag from './NotificationFlag';
import NotificationAvatar from './NotificationAvatar';
import { DocumentSearchSubscription } from './NotificationInterface';
import { getFilterText } from '../../UserProfile/utils';
import styles from '../../UserProfile/styles/UserProfileListItem.styles';
import { FilterEmptyIcon } from '../../../assets/svgs/Icons';

const DocumentSearchesSubscription = () => {
  const { notificationsState, notificationsDispatch } = useContext(NotificationsStore);
  const [loading, setLoading] = useState(isEmpty(notificationsState.subscribedDocumentSearches));

  const fetchSubscribedDocumentSearches = async () => {
    try {
      setLoading(true);
      const response = await getSubscribedNotifications('document_search');
      notificationsDispatch({
        type: NotificationsActions.SET_SUBSCRIBED_DOCUMENT_SEARCHES,
        value: response.data.body
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error fetching document searches', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubscription = ({ isSubscribed, id }: { isSubscribed: boolean; id: string }) => {
    const searches = notificationsState?.subscribedDocumentSearches ?? [];
    const selectedSearchIndex = searches?.findIndex(x => x.subscription_search_id === id);
    if (selectedSearchIndex === -1) return;

    const newSearches = [...searches];
    // isSubscribed = true cannot be case here
    if (isSubscribed) return;
    newSearches.splice(selectedSearchIndex, 1);
    notificationsDispatch({
      type: NotificationsActions.SET_SUBSCRIBED_DOCUMENT_SEARCHES,
      value: newSearches
    });
  };

  const subscribedSearchClick = (searchInfo: DocumentSearchSubscription) => {
    handleDocumentSearchClick(searchInfo);
  };

  useEffect(() => {
    // fetch searches
    if (isEmpty(notificationsState.subscribedDocumentSearches)) {
      fetchSubscribedDocumentSearches();
    }
  }, []);

  if (loading) {
    return <NotificationLoading />;
  }
  if (isEmpty(notificationsState.subscribedDocumentSearches) && !loading) {
    return <NoNotification type='subscription' channelType='document_search' />;
  }
  return (
    <List sx={notificationsStyles.searchSubscription}>
      {notificationsState.subscribedDocumentSearches.map(search => {
        return (
          <ListItem
            sx={notificationsDetailStyles.topSection}
            key={`document_search_${search.document_search_subscription_id}`}
            onClick={() => subscribedSearchClick(search)}>
            <ListItemAvatar sx={notificationsListStyles.itemAvatar}>
              <Avatar sx={notificationsListStyles.avatar}>
                <NotificationAvatar
                  notificationType='document_search'
                  source={Object.keys(search.subscription_search_data.source ?? {})}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={search.subscription_search_data.term}
              secondary={
                <Stack
                  direction='row'
                  alignItems='center'
                  sx={{
                    textTransform: 'none',
                    '& >svg': { height: '12px', width: '18px', mr: '4px' },
                    mt: '4px'
                  }}>
                  <NotificationFlag
                    source={search?.subscription_search_data.source ?? {}}
                    withTooltip
                  />
                  {!isEmpty(search?.subscription_search_data?.filters ?? {}) && (
                    <Tooltip
                      title={
                        <>
                          Filters Applied:
                          {getFilterText(
                            search?.subscription_search_data?.filters,
                            search?.subscription_search_data?.source
                          )
                            .split('\n')
                            .map(line => (
                              <div key={line}>{line}</div>
                            ))}
                        </>
                      }>
                      <Box sx={{ ...styles.iconBox, height: 'auto' }}>
                        <FilterEmptyIcon sx={styles.filterIcon} />
                      </Box>
                    </Tooltip>
                  )}
                </Stack>
              }
              sx={notificationsDetailStyles.headerTextWithHover}
            />
            {loading ? (
              <CircularProgress size={20} />
            ) : (
              <Box mr='8px'>
                <SubscriptionActions
                  invokedBy='document_search'
                  id={search.subscription_search_id}
                  isButton
                  isSubscribed
                  setSubscriptionInHook={({ isSubscribed }: { isSubscribed: boolean }) =>
                    handleSubscription({ isSubscribed, id: search?.subscription_search_id })
                  }
                  subscribedAriaSearch={search}
                />
              </Box>
            )}
          </ListItem>
        );
      })}
    </List>
  );
};
export default memo(DocumentSearchesSubscription);
