/* eslint-disable import/prefer-default-export */
import { GridColDef } from '@mui/x-data-grid-pro';
import renderCellExpand from '../../../components/Datagrid/DatagridPopover';
import DatagridColorNumber from '../../../components/PMRImpact/components/DatagridColorNumber/DatagridColorNumber';
import DatagridTooltip from '../../../components/Datagrid/DatagridTooltip';
import DocumentHistory from '../components/DocumentsHistory';

export const capitalize = (data: string) => {
  return data
    .replace(/^\s+|\s+$/gm, '')
    .split(' ')
    .map((word: string) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(' ');
};

type CustomGridColDef = GridColDef & {
  capitalCasing?: Boolean;
};

export const COLUMN_MAPPING: CustomGridColDef[] = [
  {
    field: 's3_links',
    headerName: '',
    width: 50,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    valueGetter: (params: { row: any }) => {
      return params.row;
    }
  },
  {
    field: 's3_paths',
    headerName: 'Documents',
    width: 100,
    filterable: false,
    capitalCasing: false,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DocumentHistory
  },
  {
    field: 'application_type',
    headerName: 'Type',
    width: 100,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: (params: any) => DatagridColorNumber(params.row.application_type)
  },
  {
    field: 'application_number',
    headerName: 'Application Number',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    renderCell: DatagridTooltip
  },
  {
    field: 'active_ingredient',
    headerName: 'Active ingredients',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridTooltip
  },
  {
    field: 'determination_date',
    headerName: 'Determination Date',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridTooltip
  },
  {
    field: 'indications',
    headerName: 'Indications and Usage',
    width: 240,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    renderCell: renderCellExpand
  },
  {
    field: 'drug_name',
    headerName: 'Drug Name',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    renderCell: DatagridTooltip
  },
  {
    field: 'formulation',
    headerName: 'Formulation',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${capitalize(row.formulation)}`
  },
  {
    field: 'route',
    headerName: 'Route',
    width: 150,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${capitalize(row.route)}`
  },
  {
    field: 'wr_sponsor',
    headerName: 'Written Requests Sponsor',
    width: 220,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: false,
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.wr_sponsor.toUpperCase()}`
  },
  {
    field: 'sponsor_name',
    headerName: 'Sponsor name',
    width: 180,
    headerClassName: 'table-header',
    cellClassName: 'ria-MuiDataGrid-cell',
    capitalCasing: true,
    renderCell: DatagridTooltip,
    valueGetter: ({ row }) => `${row.sponsor_name.toUpperCase()}`
  }
];
