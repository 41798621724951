import React, { useCallback, useMemo, useState } from 'react';

// MUI Imports
import Tooltip from '@mui/material/Tooltip';

import { ListItemText, MenuItem } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import styles from './styles';
import buttonStyles from '../../SearchResults/styles/Buttons.styles';
import { DownloadIcon } from '../../../assets/svgs/Icons';
import RoundedMenu from '../../../components/ui/Menu/RoundedMenu';
import useSnapshotExcel from '../useSnapshotExcel';
import DownloadLoader from '../../SearchResults/components/DownloadLoader';
import { getFileName } from '../../../helpers/getFileName';

const DownloadOptions = ({
  isSnapshotDataAvaialble,
  isCSVDataAvaialble,
  onCsvDownload,
  applicationNumber,
  filePrefix,
  source,
  submissionNumber = '',
  submissionType = ''
}: any) => {
  const [anchorEl, setAnchorEl] = useState<any>();
  const [donePageDataLoadingAnimation, setDonePageDataLoadingAnimation] = useState(true);
  const { loader, errorMsg, setLoader, setErrorMsg, getSnapshotExcel, cancelRequest } =
    useSnapshotExcel();
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getSnapshotData = useCallback(async () => {
    try {
      const payload: string[] | string = submissionType ? applicationNumber : [applicationNumber];

      setLoader(true);
      setDonePageDataLoadingAnimation(false);

      const day = new Date();
      const fileName = getFileName({
        prefix: filePrefix,
        module: source,
        postfix: `${day.getMonth() + 1}-${day.getDate()}-${day.getFullYear()}`,
        additional: '_clinical_summary_snapshot',
        extension: 'xlsx'
      });
      await getSnapshotExcel(payload, fileName, submissionType, submissionNumber);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setErrorMsg(true);
    }
  }, [applicationNumber, filePrefix]);

  const handleClick = (event: React.SyntheticEvent) => {
    if (isSnapshotDataAvaialble && isCSVDataAvaialble) {
      setAnchorEl(event.target);
    } else if (isCSVDataAvaialble) {
      onCsvDownload?.();
    } else if (isSnapshotDataAvaialble) {
      getSnapshotData();
    }
  };

  const handleCancelCall = useCallback(async () => {
    setDonePageDataLoadingAnimation(true);
    cancelRequest();
  }, [cancelRequest]);

  const handleLoadingAnimationComplete = () => {
    setDonePageDataLoadingAnimation(true);
    handleClose();
  };

  const snapshotTooltipText = `Download Clinical Summary Snapshot${
    !submissionType ? ' (ORIG only)' : ''
  } as excel (.xlsx) file`;

  const snapshotButtonTitle = `Clinical Summary Snapshot${!submissionType ? ' (ORIG only)' : ''}`;

  const buttonTooltipText = useMemo(() => {
    if (isSnapshotDataAvaialble && isCSVDataAvaialble) {
      return '';
    }
    if (isCSVDataAvaialble) {
      return 'Download Approval History as CSV (.csv) file';
    }

    if (isSnapshotDataAvaialble) {
      return snapshotTooltipText;
    }

    return 'Download';
  }, [isSnapshotDataAvaialble, isCSVDataAvaialble, snapshotTooltipText]);

  return (
    <>
      <Tooltip title={buttonTooltipText}>
        <IconButton onClick={handleClick}>
          <DownloadIcon sx={buttonStyles.buttonIcon} />
        </IconButton>
      </Tooltip>

      {isSnapshotDataAvaialble && isCSVDataAvaialble && (
        <RoundedMenu
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={handleClose}
          sx={styles.roundedMenu}>
          {isCSVDataAvaialble && (
            <Tooltip title='Download Approval History as CSV (.csv) file' placement='left'>
              <MenuItem onClick={onCsvDownload}>
                <ListItemText primary='Approval History' />
              </MenuItem>
            </Tooltip>
          )}
          {isSnapshotDataAvaialble && (
            <Tooltip title={snapshotTooltipText} placement='left'>
              <MenuItem onClick={getSnapshotData}>
                <ListItemText primary={snapshotButtonTitle} />
              </MenuItem>
            </Tooltip>
          )}
        </RoundedMenu>
      )}
      {isSnapshotDataAvaialble && !donePageDataLoadingAnimation && (
        <DownloadLoader
          progressMessage='Please be patient. It may take a few seconds to compile Clinical Summary.'
          downloadSuccessMessage='Clinical Summary file has been downloaded successfully'
          onRetry={() => {
            setErrorMsg(false);
            getSnapshotData();
          }}
          onCancel={handleCancelCall}
          loader={loader}
          onDoneAnimation={handleLoadingAnimationComplete}
          errorMsg={errorMsg}
          onEndLoading={() => {
            handleCancelCall();
            setDonePageDataLoadingAnimation(true);
            setLoader(false);
          }}
          onDoneClick={() => {
            handleLoadingAnimationComplete();
            setLoader(false);
          }}
        />
      )}
    </>
  );
};

export default React.memo(DownloadOptions);
