import React from 'react';

import { Box, Typography } from '@mui/material';
import styles from './DocumentMetadata.styles';
import { MetaData } from '../types';

const DocumentMetadata = ({ data = [] }: { data: string[] | MetaData[] }) => {
  return (
    <Box display='flex' alignItems='center' mb={0.5} flexWrap='wrap'>
      {data.map((value: string | MetaData, index: number) => {
        if (typeof value === 'string') {
          return (
            <React.Fragment key={value}>
              <Typography fontSize={13} color='gray.600' fontWeight={600}>
                {value}
              </Typography>
              {data.length - 1 > index && <Box sx={styles.grayDot} />}
            </React.Fragment>
          );
        }

        return (
          <React.Fragment key={value.key}>
            <Typography fontSize={13} color='gray.600' fontWeight={600}>
              {value.key ? `${value.key}: ${value.value}` : value.value}
            </Typography>

            {data.length - 1 > index && <Box sx={styles.grayDot} />}
          </React.Fragment>
        );
      })}
    </Box>
  );
};

export default React.memo(DocumentMetadata);
