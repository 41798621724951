const tagText = {
  maxWidth: '30%',
  color: 'gray.700',
  textAlign: 'center',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '21.98px'
};
const styles = {
  cardContainer: {
    borderRadius: '8px',
    py: 1.5,
    px: 2
  },
  description: {
    color: 'gray.800',
    cursor: 'pointer',
    lineHeight: '22px',
    '&:hover': {
      color: 'primary.650',
      textDecoration: 'underline'
    }
  },
  descriptionSelected: {
    color: 'primary.650',
    lineHeight: '22px',
    textDecorationLine: 'underline'
  },
  title: {
    color: 'primary.650',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'capitalize',
    maxWidth: '80%',
    fontSize: '18px !important'
  },
  nonReg360Title: {
    cursor: 'default',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  subtitle: {
    color: 'primary.650',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    textTransform: 'capitalize'
    // maxWidth: '20%'
  },
  divider: {
    bgcolor: 'primary.main',
    fontSize: 18,
    fontStyle: 'normal',
    fontWeight: 300,
    lineHeight: '24px',
    textTransform: 'capitalize'
  },
  reg360Text: {
    color: 'primary.textVariant',
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    cursor: 'pointer',
    textTransform: 'capitalize'
  },
  tagText,
  largeTagText: {
    ...tagText,
    maxWidth: '50%'
  },
  labelChip: {
    fontSize: 10,
    height: 13,
    minWidth: 31,
    borderRadius: 1,
    fontWeight: 800,
    color: 'white.main',
    '& span': {
      px: 0.5
    }
  },
  roundedMenu: {
    '& .MuiPaper-root': { padding: '8px 0' },
    '& .MuiList-root': { padding: 0 }
  },
  menuItem: {
    padding: '8px 24px !important'
  },
  listItemIcon: {
    minWidth: '14px !important'
  },
  svgIcon: { height: '14px', width: '14px', color: 'gray.750' },
  listItemText: {
    fontFamily: 'Mulish',
    fontSize: '12px',
    fontWeight: '400',
    ml: '8px',
    color: 'primary.darkVariant1'
  },
  notificationView: {
    maxHeight: 100,
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 3, // Adjust to the number of lines that fit in the maxHeight
    WebkitBoxOrient: 'vertical',
    textOverflow: 'ellipsis',
    whiteSpace: 'normal' //
  }
};

export default styles;
