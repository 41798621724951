import palette from '../../../themev5/palette';

const rootBase = {
  paddingBottom: '56px',
  '& .cellValue': {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    '& .fda_org_cell': {
      height: '26px',
      '& .fda_org_cell__more-text': {
        display: 'inline !important'
      }
    }
  },
  '& .MuiDataGrid-root': {
    width: '100%',
    '& .MuiDataGrid-columnHeader': {
      color: palette.primary.main,
      fontWeight: 'bold',
      bgcolor: palette.primary.backgroundDark
    },
    '& .MuiDataGrid-columnsContainer': {
      color: palette.primary.lightBluish,
      background: '#F7F7F7'
    },
    '& .MuiDataGrid-main': {
      background: '#fff'
    }
  }
};

const styles = {
  root: {
    ...rootBase,
    height: 'calc(100vh - 210px)'
  },
  rootWithComparisonEnabled: {
    ...rootBase,
    height: 'calc(100vh - 275px)'
  },
  drawerNav: {
    justifyContent: 'space-between',
    px: '28px',
    height: '30px'
  },
  textField: {
    '& .MuiInputBase-root': { borderRadius: '32px', height: '30px', width: '300px' }
  },
  searchIcon: { height: '14px', width: '14px' },
  selectedText: { color: 'gray.500', fontSize: '14px', fontWeight: 600 },
  resetText: { color: 'gray.500', fontSize: '14px', fontWeight: 600, cursor: 'default' },
  compareButton: {
    height: '30px',
    padding: '15px 24px',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '16px',
    backgroundColor: 'primary.650',
    color: 'white.main',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 700,
    '&:hover': {
      backgroundColor: 'white.main',
      border: 1,
      borderColor: 'primary.main',
      borderStyle: 'solid',
      color: 'primary.600'
    },
    '&:disabled': {
      backgroundColor: 'gray.550',
      color: 'gray.background'
    }
  },
  hrDivider: { my: '16px', mx: '28px' },
  verticalDivider: { height: 16 },
  statsContainer: {
    justifyContent: 'space-between',
    px: '28px'
  },
  statsWrapper: {
    borderRadius: '6px',
    p: '2px 8px',
    backgroundColor: 'gray.100',
    height: '21px'
  },
  statsText: { color: 'gray.700', fontFamily: 'Mulish', fontSize: '12px' }
};

export default styles;
