import makeRequest from '../client';

export const getUserNotifications = (channel: string, page: number) => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  let requestURL = `/user-profile/subscriptions/notifications?page=${page}&timezone=${timezone}`;
  if (channel) requestURL += `&notification_channel=${channel}`;
  return makeRequest(requestURL, 'GET');
};

export const getUserNotificationsCount = () => {
  return makeRequest('/user-profile/subscriptions/unread-notifications-count', 'GET');
};

export const markIdsAsClicked = (ids: Array<number>) => {
  return makeRequest('/user-profile/subscriptions/viewed-notifications', 'PUT', { ids });
};

export const getSubscribedNotifications = (entityType: any) => {
  return makeRequest(`/user-profile/subscriptions/subscribed/${entityType}`, 'GET');
};

//
export const getSubscriptionEmailPreference = () =>
  makeRequest('/user-profile/subscriptions/email-preference', 'GET');

export const updateSubscriptionEmailPreference = (frequency: string, subscribed: boolean) =>
  makeRequest('/user-profile/subscriptions/email-preference', 'PUT', {
    email_frequency: parseInt(frequency, 10),
    subscribed
  });

export const addSubscriptionEmailPreference = (frequency: string, subscribed: boolean) =>
  makeRequest('/user-profile/subscriptions/email-preference', 'POST', {
    email_frequency: parseInt(frequency, 10),
    subscribed
  });

export const getSubscriptionSourcePreference = () =>
  makeRequest('/user-profile/subscriptions/source-preference', 'GET');

export const updateSubscriptionSourcePreference = (data: any) =>
  makeRequest('/user-profile/subscriptions/source-preference', 'PUT', data);

export const addSubscriptionSourcePreference = () =>
  makeRequest('/user-profile/subscriptions/source-preference', 'POST');

export const addSubscription = ({
  source,
  entityId,
  entityType,
  subscribedDocumentSearchPayload = {},
  subscribedDocumentSearchId = ''
}: {
  source: string;
  entityId: string;
  entityType: string;
  subscribedDocumentSearchPayload?: any;
  subscribedDocumentSearchId?: string;
}) =>
  makeRequest(`/user-profile/subscriptions/subscribe`, 'POST', {
    source,
    entity_id: entityId,
    entity_type: entityType,
    subscribed_document_search_payload: subscribedDocumentSearchPayload,
    subscribed_document_search_id: subscribedDocumentSearchId
  });

export const removeSubscription = ({
  source,
  entityId,
  entityType
}: {
  source: string;
  entityId: string;
  entityType: string;
}) =>
  makeRequest(`/user-profile/subscriptions/subscribe`, 'DELETE', {
    source,
    entity_id: entityId,
    entity_type: entityType
  });

export const getSubscriptionPreferences = ({
  source,
  entityType,
  entityId
}: {
  source: string | undefined;
  entityId: string;
  entityType: string;
}) => {
  const params = source
    ? `?source=${source}&entity_type=${entityType}&entity_id=${entityId}`
    : `?entity_type=${entityType}&entity_id=${entityId}`;
  return makeRequest(`/user-profile/subscriptions/preferences${params}`, 'GET');
};

export const updateSubscriptionPreferences = ({
  source,
  entityType,
  entityId,
  preferenceId,
  preferenceValue
}: {
  source: string;
  entityId: string;
  entityType: string;
  preferenceId: string;
  preferenceValue: boolean;
}) =>
  makeRequest(`/user-profile/subscriptions/preferences`, 'POST', {
    source,
    entity_type: entityType,
    entity_id: entityId,
    preference_id: preferenceId,
    preference_value: preferenceValue
  });

export const getSubscriptionStatus = ({
  source,
  entityType,
  entityId,
  subscriptionSearchId
}: {
  source: string;
  entityId: string;
  entityType: string;
  subscriptionSearchId?: string;
}) => {
  let url = `/user-profile/subscriptions/subscription-status?source=${source}&entity_type=${entityType}&entity_id=${entityId}`;
  if (subscriptionSearchId) url += `&subscription_search_id=${subscriptionSearchId}`;
  return makeRequest(url, 'GET');
};

export const getDocumentSearchKeywords = (searchQuery: string) =>
  makeRequest(
    `/user-profile/subscriptions/get-document-search-keywords?search_query=${encodeURIComponent(
      searchQuery
    )}`,
    'GET'
  );
