import React, { useCallback } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

// muiv5
import {
  DataGridPro,
  GridColDef,
  GridRowSelectionModel,
  GridRowParams
} from '@mui/x-data-grid-pro';
import { GridSortModel } from '@mui/x-data-grid/models';
import LinearProgress from '@mui/material/LinearProgress';

// components
import DatagridToolbar from './DatagridToolbar';
import DatagridFooter from './DatagridFooter';
import DatagridNoRowsOverlay from './DatagridNoRowsOverlay';

// utils
import { DISABLED_COLUMN_ENABLING, HIDDEN_COLUMNS } from './constants';

import styles from './Datagrid.styles';

interface DatagridProps {
  rows: Array<object>; // data that will be displayed in the datagrid
  columnsMapping: GridColDef[]; // column definitions for the data
  rowId: string; // unique identifier for the rows in datagrid
  // eslint-disable-next-line react/require-default-props
  csvFileName?: string;
  noRowMessage?: string;
  // eslint-disable-next-line react/require-default-props
  loading?: boolean;
  useQuickSearch?: boolean;
  pagination?: {
    pageSize: number;
    page: number;
  };
  // eslint-disable-next-line react/require-default-props
  rowCount?: number;
  // eslint-disable-next-line react/require-default-props
  isPagination?: boolean;
  // eslint-disable-next-line no-unused-vars, react/require-default-props
  setPagination?: (paginationModel: { pageSize: number; page: number }) => void;
  sortingModel?: Array<any>;
  // eslint-disable-next-line no-unused-vars, react/require-default-props
  handleSortModelChange?: (model: GridSortModel) => void;
  // eslint-disable-next-line react/require-default-props
  hiddenCollumns?: any;
  isServerSideExport?: boolean;
  isFilterModeServer?: boolean;
  // eslint-disable-next-line no-unused-vars, react/require-default-props
  handleFilterModelChange?: (model: any) => void;
  allowFilterPanelWithLogicOperators?: boolean;
  isCheckboxSelectionEnabled?: boolean;
  // eslint-disable-next-line no-unused-vars
  handleCheckBoxSelection?: (selectedRow: any) => void;
  rowSelectionModel?: any;
  dataGridRowSelectionLimit?: number;
  showToolBar?: boolean;
  showExportOption?: boolean;
  apiRef?: any;
  disableFilters?: boolean;
}

const getTogglableColumns = (columns: GridColDef[]) => {
  return columns
    .filter(column => !DISABLED_COLUMN_ENABLING.includes(column.field))
    .map(column => column.field);
};

const VivproDatagrid = ({
  rows,
  columnsMapping,
  rowId,
  csvFileName,
  // eslint-disable-next-line no-unused-vars
  noRowMessage = 'No Rows',
  loading,
  useQuickSearch = false,
  isPagination = false,
  pagination = {
    pageSize: 20,
    page: 0
  },
  rowCount,
  setPagination,
  sortingModel = [],
  hiddenCollumns,
  handleSortModelChange,
  isServerSideExport = false,
  isFilterModeServer = false,
  handleFilterModelChange,
  allowFilterPanelWithLogicOperators = false,
  isCheckboxSelectionEnabled = false,
  handleCheckBoxSelection = () => {},
  rowSelectionModel = [],
  dataGridRowSelectionLimit = 2,
  showToolBar = true,
  showExportOption = true,
  apiRef = undefined,
  disableFilters = false
}: DatagridProps) => {
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 20,
    page: 0
  });

  const handleRowSelectionChange = useCallback(
    (newRowSelectionModel: GridRowSelectionModel) => {
      if (newRowSelectionModel.length <= dataGridRowSelectionLimit) {
        const selectedIDs = new Set(newRowSelectionModel);
        const selectedRows = rows.filter((row: any) => selectedIDs.has(row[rowId]));
        handleCheckBoxSelection(selectedRows);
      } else {
        toast.warn(`You can only select up to ${dataGridRowSelectionLimit} rows.`, {
          position: 'bottom-center',
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });
      }
    },
    [rows, rowId, handleCheckBoxSelection, dataGridRowSelectionLimit]
  );

  const showAlertOnExport = (alertMessage: any) => {
    const columnNames = alertMessage.erroredColumns
      .map((columnName: string) => `'${columnName}'`)
      .join(', ');
    toast.warn(
      `One or more cells in the ${columnNames} column${
        alertMessage.erroredColumns.length > 1 ? 's' : ''
      } contain more than 32,767 characters. To ensure compatibility with Microsoft Excel, these cells have been trimmed.`,
      {
        position: 'bottom-center',
        autoClose: 15000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined
      }
    );
  };

  return (
    <>
      <DataGridPro
        sx={styles.datagrid}
        initialState={{
          columns: {
            // Hide columns status and traderName, the other columns will remain visible
            columnVisibilityModel: hiddenCollumns || HIDDEN_COLUMNS
          }
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(apiRef && { apiRef })}
        rowCount={rowCount}
        paginationModel={isPagination ? pagination : paginationModel}
        onPaginationModelChange={isPagination ? setPagination : setPaginationModel}
        rowSpacingType='margin'
        sortingOrder={['desc', 'asc']}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(isCheckboxSelectionEnabled && {
          checkboxSelection: true,
          onRowSelectionModelChange: handleRowSelectionChange,
          isRowSelectable: (params: GridRowParams) =>
            rowSelectionModel.length < dataGridRowSelectionLimit ||
            rowSelectionModel.includes(params.id),
          rowSelectionModel
        })}
        density='standard'
        rows={rows}
        columns={columnsMapping}
        getRowId={row => row[rowId]}
        pageSizeOptions={[10, 20, 40, 60, 80, 100]}
        pagination
        loading={loading}
        paginationMode={isPagination ? 'server' : 'client'}
        getRowClassName={() => `table-rows`}
        slotProps={{
          toolbar: {
            fileName: csvFileName,
            useQuickSearch,
            showAlertOnExport,
            isServerSideExport,
            showExportOption
          },
          columnsPanel: {
            getTogglableColumns
          },
          filterPanel: {
            ...(allowFilterPanelWithLogicOperators && { logicOperators: [] })
          }
        }}
        slots={{
          ...(showToolBar && { toolbar: DatagridToolbar }),
          loadingOverlay: LinearProgress,
          footer: DatagridFooter,
          noRowsOverlay: DatagridNoRowsOverlay
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(isPagination
          ? {
              sortingMode: 'server',
              sortModel: sortingModel,
              onSortModelChange: model => {
                if (handleSortModelChange) {
                  handleSortModelChange(model);
                }
              }
            }
          : {})}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(isFilterModeServer
          ? {
              filterMode: 'server',
              onFilterModelChange: handleFilterModelChange
            }
          : {})}
        disableColumnFilter={disableFilters}
      />
      <ToastContainer
        style={{
          fontSize: 16,
          fontWeight: 'bold',
          fontFamily: 'Mulish',
          width: 560
        }}
      />
    </>
  );
};

VivproDatagrid.defaultProps = {
  noRowMessage: 'No Rows',
  useQuickSearch: false,
  pagination: {
    pageSize: 20,
    page: 0
  },
  sortingModel: [],
  isServerSideExport: false,
  isFilterModeServer: false,
  allowFilterPanelWithLogicOperators: false,
  isCheckboxSelectionEnabled: false,
  handleCheckBoxSelection: () => {},
  rowSelectionModel: [],
  dataGridRowSelectionLimit: 2,
  showToolBar: true,
  showExportOption: true,
  apiRef: undefined,
  disableFilters: false
};

export default React.memo(VivproDatagrid);
