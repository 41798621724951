import { useEffect, useState } from 'react';
import { Box, Button, Divider, Popover, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { isEqual } from 'lodash';
import AutoSearchOptions from './AutoSearchOptions';
import FilterSidebar from './FilterSidebar';
import { Category, FilterProps, SelectedCategoryOptions } from './types';
import { FILTER_TYPE, BOTTOM_FILTER_SEARCH_KEYWORD_PLACEHOLDER_TEXT } from './constants';
import NumberRangeOptions from './NumberRangeOptions';
import RadioGroupsOptions from './RadioGroupsOptions';
import DateYearRangeOptions from './DateYearRangeOptions';
import { filterStyles, styles } from './styles';
import ApplyFilterAlert from './ApplyFilterAlert';
import palette from '../../themev5/palette';
import SearchKeywordOptions from './SearchKeywordOptions';
import BottomSearchKeywordOptions from './BottomSearchKeywordOptions';
import MultiSearchKeywordOptions from './MultiSearchKeywordOptions';
import { FilterFilledIcon } from '../../assets/svgs/Icons';

const NewFilters = ({
  data,
  applyFilters,
  isLoading,
  selectedFilters = {},
  showSearchKeywordInBottomFilters = false,
  source
}: FilterProps) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [showFilterSidebar, setShowFilterSidebar] = useState(false);
  const [selectedCategoryOptions, setSelectedCategoryOptions] = useState<SelectedCategoryOptions>(
    {}
  );
  const [previousSelectedCategoryOptions, setPreviousSelectedCategoryOptions] =
    useState<SelectedCategoryOptions>({});
  const [formattedData, setFormattedData] = useState<Category[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const selectedFiltersSize = Object.keys(selectedFilters)?.length;

  useEffect(() => {
    setSelectedCategoryOptions(selectedFilters);
    setPreviousSelectedCategoryOptions(selectedFilters);
  }, [selectedFiltersSize]);

  // Utility function to find and move search object to the start
  const moveSearchFilterToStart = (tempBottomFilters: Category[]) => {
    let formattedBottomFilters = tempBottomFilters;
    // check if search keyword filter is at first of bottom filters
    const searchObjectIndex = tempBottomFilters.findIndex(
      (each: Category) => each.filterType === FILTER_TYPE.SEARCH
    );
    // Find the search type filter and add it to the start of bottom filters
    const searchObject = tempBottomFilters[searchObjectIndex];

    // if search keyword filter is not at first of bottom filters then add it to the first
    if (searchObjectIndex !== 0 && searchObjectIndex !== -1) {
      // get other filters except search keyword filters
      const updatedData = tempBottomFilters.filter(
        (each: Category) => each.filterType !== FILTER_TYPE.SEARCH
      );
      // udpate the formattedBottomFilters with searchKeyword filter at first
      formattedBottomFilters = [searchObject, ...updatedData];
    }

    // calculate last index till which we need to display in bottom filters
    // we also need to consider the search text added
    const lastIndexToSlice =
      (selectedCategoryOptions?.filter_keywords?.length || 0) > 2
        ? 1
        : 3 - (selectedCategoryOptions?.filter_keywords?.length || 0);
    return formattedBottomFilters?.slice(0, lastIndexToSlice);
  };

  const addSearchKeywordInBottomFilters = (
    tempFilteredData: Category[],
    filteredData: Category[]
  ) => {
    let formattedFilterData = tempFilteredData;
    // check if search keyword filter is already available in bottom filters
    const isSearchKeywordAvailable = tempFilteredData?.some(
      (each: Category) => each.filterType === FILTER_TYPE.SEARCH
    );

    if (!isSearchKeywordAvailable) {
      // Find the search keyword filter
      const searchObject = filteredData.find(
        (each: Category) => each.filterType === FILTER_TYPE.SEARCH
      );
      if (searchObject) {
        // calculate last index till which we need to display in bottom filters
        // we also need to consider the search text added
        const lastIndexToSlice =
          (selectedCategoryOptions?.filter_keywords?.length || 0) > 2
            ? 1
            : 3 - (selectedCategoryOptions?.filter_keywords?.length || 0);
        formattedFilterData = [searchObject, ...formattedFilterData].slice(0, lastIndexToSlice);
      }
    } else {
      formattedFilterData = moveSearchFilterToStart(formattedFilterData);
    }
    return formattedFilterData;
  };

  const getInitialBottomFilters = (tempBottomFilters: Category[], filteredData: Category[]) => {
    let formattedBottomFilters = tempBottomFilters;
    // Find the search type filter and add it to the start of bottom filters
    const searchObject = filteredData.find(
      (each: Category) => each.filterType === FILTER_TYPE.SEARCH
    );
    if (searchObject) {
      formattedBottomFilters = [searchObject, ...formattedBottomFilters].slice(0, 3);
    }
    return formattedBottomFilters;
  };

  // Utility function to filter data based on selected options
  const filterDataBasedOnSelectedOptions = (
    filteredData: Category[],
    selectedCategoryOptionsKeys: string[]
  ) => {
    return filteredData.filter((each: Category) => {
      if (each.filterType === FILTER_TYPE.MULTI_SEARCH) {
        return each.options?.some((item: any) =>
          selectedCategoryOptionsKeys?.slice(0, 3)?.includes(item.value)
        );
      }
      return selectedCategoryOptionsKeys
        ?.slice(0, 3)
        ?.includes(each.elasticSearchType || each.value);
    });
  };

  const getSelectedBottomFilters = (
    filteredData: Category[],
    selectedCategoryOptionsKeys: string[]
  ) => {
    // Calculate the bottom filters that need to be displayed
    let formattedBottomFilters = filterDataBasedOnSelectedOptions(
      filteredData,
      selectedCategoryOptionsKeys
    );

    if (showSearchKeywordInBottomFilters) {
      formattedBottomFilters = addSearchKeywordInBottomFilters(
        formattedBottomFilters,
        filteredData
      );
    }
    return formattedBottomFilters;
  };

  // Utility function to check if any label is present
  const isLabelPresentInFilteredData = (
    selectedCategoryOptionsKeys: string[],
    filteredData: Category[]
  ) =>
    selectedCategoryOptionsKeys.some((label: string) =>
      filteredData.some(
        (filter: Category) => filter?.value === label || filter?.elasticSearchType === label
      )
    );

  const getBottomFilters = (
    tempBottomFilters: Category[],
    filteredData: Category[],
    selectedCategoryOptionsKeys: string[]
  ) => {
    let formattedBottomFilters = tempBottomFilters;
    // second condition in if statement is to handle the filters not part of bottom filters i.e. synonyms filter only being applied
    // bottom filters not able to display selected filters i.e. synonyms filter due to this filter is not part of bottom filters
    if (
      selectedCategoryOptionsKeys.length &&
      isLabelPresentInFilteredData(selectedCategoryOptionsKeys, filteredData)
    ) {
      formattedBottomFilters = getSelectedBottomFilters(filteredData, selectedCategoryOptionsKeys);
    }
    // initial state where no filters are selected added to handle use case
    // for displaying new search keyword in bottom filters
    else if (showSearchKeywordInBottomFilters) {
      formattedBottomFilters = getInitialBottomFilters(formattedBottomFilters, filteredData);
    }
    return formattedBottomFilters;
  };

  useEffect(() => {
    // Filter the bottom filters according to selectedCategoryOptions
    if (!anchorEl || !formattedData?.length) {
      const selectedCategoryOptionsKeys = Object.keys(selectedCategoryOptions);
      const filteredData = data?.filter((each: Category) => each.showInBottomFilter);
      const tempBottomFilters = filteredData?.slice(0, 3);

      const bottomFilters = getBottomFilters(
        tempBottomFilters,
        filteredData,
        selectedCategoryOptionsKeys
      );

      setFormattedData(bottomFilters);
    }
  }, [data, selectedCategoryOptions, anchorEl]);

  const handleCategoryClick = (
    value: (EventTarget & HTMLButtonElement) | null = null,
    category: Category | null = null
  ) => {
    setAnchorEl(prev => (prev !== value ? value : null));
    setSelectedCategory(category);
  };

  const handleClose = () => {
    if (isEqual(selectedCategoryOptions, previousSelectedCategoryOptions)) {
      handleCategoryClick();
      setShowAlert(false);
    } else {
      setShowAlert(true);
    }
  };

  const handleAlertClose = () => {
    setAnchorEl(null);
    setShowAlert(false);
  };

  const handleApplyClose = () => {
    handleAlertClose();
    setPreviousSelectedCategoryOptions(selectedCategoryOptions);
  };

  const handleReset = async () => {
    setSelectedCategoryOptions({});
    setPreviousSelectedCategoryOptions({});
    setShowFilterSidebar(false);
    await applyFilters({});
  };

  const handleCountClick = () => {
    if (!isLoading) {
      setShowFilterSidebar(!showFilterSidebar);
    }
  };

  const handleRemoveSearchText = (filterKey: any, valueToBeRemoved: string) => {
    let formattedFilters: any = selectedCategoryOptions;
    const remainingFilterKeyValue = selectedCategoryOptions[filterKey]?.filter(
      item => item !== valueToBeRemoved
    );
    if (remainingFilterKeyValue?.length) {
      formattedFilters = { ...selectedCategoryOptions, [filterKey]: remainingFilterKeyValue };
    } else {
      delete formattedFilters[filterKey];
    }
    setSelectedCategoryOptions(formattedFilters);
    setPreviousSelectedCategoryOptions(formattedFilters);
    applyFilters(formattedFilters);
  };

  // calculate total number of bottom filters added considering the search keyword options
  const totalBottomFilters = () => {
    // if we have search keyword filter displaying in bottom filters then count should be 1 otherwise 0
    let count = formattedData?.some(
      (each: any) => showSearchKeywordInBottomFilters && each.filterType === FILTER_TYPE.SEARCH
    )
      ? 1
      : 0;

    // if we have selected category option other than search keyword filter then add count for that category to final count
    const totalSelectedCategoryOptionsCount = Object.keys(selectedCategoryOptions)?.length;
    if (totalSelectedCategoryOptionsCount) {
      count +=
        showSearchKeywordInBottomFilters &&
        Object.keys(selectedCategoryOptions)?.includes('filter_keywords')
          ? // need to remove the search keyword count if displaying in bottom filters
            totalSelectedCategoryOptionsCount - 1
          : totalSelectedCategoryOptionsCount;
    }

    // if we are displaying the search keyword in any module then we calculate count of search text added and add it into total count
    if (showSearchKeywordInBottomFilters) {
      const totalSearchKeywordFilterOptions = selectedCategoryOptions?.filter_keywords?.length ?? 0;
      if (totalSearchKeywordFilterOptions) {
        count += totalSearchKeywordFilterOptions;
      }
    }

    return count;
  };

  const renderCategoryButton = (categoryData: any, filterKey: string) => {
    let selectedOptionCount = 0;

    if (categoryData.filterType === FILTER_TYPE.MULTI_SEARCH) {
      selectedOptionCount = categoryData.options?.reduce((count: number, item: any) => {
        return count + (selectedCategoryOptions[item.value]?.length || 0);
      }, 0);
    } else {
      selectedOptionCount = selectedCategoryOptions[filterKey]?.length || 0;
    }
    const isCategoryOptionSelected = selectedOptionCount > 0;
    return (
      <Button
        disabled={isLoading}
        size='small'
        key={categoryData.value}
        sx={{
          ...filterStyles.categoryButton,
          color: isCategoryOptionSelected ? 'white.main' : 'gray.900',
          border:
            selectedCategory?.value === categoryData.value
              ? `1px solid ${palette.primary[600]}`
              : 'none',
          height: 28
        }}
        style={{ backgroundColor: isCategoryOptionSelected ? '#0D917D' : '#FFFFFF' }}
        endIcon={isCategoryOptionSelected ? null : <AddIcon />}
        onClick={ev => handleCategoryClick(ev.currentTarget, categoryData)}>
        {isCategoryOptionSelected &&
        ![
          FILTER_TYPE.DATE_RANGE,
          FILTER_TYPE.YEAR_RANGE,
          FILTER_TYPE.NUMBER_RANGE,
          FILTER_TYPE.RADIO
        ].includes(categoryData.filterType)
          ? `${categoryData.label} (${selectedOptionCount})`
          : categoryData.label}
      </Button>
    );
  };

  const renderSearchButtons = (filterKey: string) => {
    return selectedCategoryOptions[filterKey]?.slice(0, 2)?.map((value: string) => (
      <Button
        disabled={isLoading}
        size='small'
        key={value}
        sx={{
          ...filterStyles.categoryButton,
          ...filterStyles.searchKeywordButton
        }}
        style={{ backgroundColor: '#0D917D' }}
        endIcon={
          <IconButton onClick={() => handleRemoveSearchText(filterKey, value)}>
            <ClearIcon sx={{ fontSize: 12, color: isLoading ? 'black.disable' : 'gray.0' }} />
          </IconButton>
        }>
        {value}
      </Button>
    ));
  };

  const renderBottomFiltersContent = () => {
    return formattedData?.map(each => {
      const valueKey = each?.elasticSearchType || each?.value;

      const isSearchFilter =
        showSearchKeywordInBottomFilters && each.filterType === FILTER_TYPE.SEARCH;

      return (
        <Box
          gap={2}
          key={each.elasticSearchType || each.value}
          display='flex'
          justifyContent='center'
          alignItems='center'
          alignContent='center'>
          {isSearchFilter ? (
            <>
              <BottomSearchKeywordOptions
                selectedCategoryOptions={selectedCategoryOptions}
                setSelectedCategoryOptions={setSelectedCategoryOptions}
                setPreviousSelectedCategoryOptions={setPreviousSelectedCategoryOptions}
                handleApplyFilters={applyFilters}
                isLoading={isLoading}
                placeholderText={
                  source
                    ? BOTTOM_FILTER_SEARCH_KEYWORD_PLACEHOLDER_TEXT[source]
                    : BOTTOM_FILTER_SEARCH_KEYWORD_PLACEHOLDER_TEXT.default
                }
              />
              {renderSearchButtons(valueKey)}
            </>
          ) : (
            renderCategoryButton(each, valueKey)
          )}
        </Box>
      );
    });
  };

  return (
    <>
      <Box sx={filterStyles.outerContainer}>
        <Box sx={filterStyles.filterIconBox}>
          <FilterFilledIcon sx={filterStyles.filterIcon} />
        </Box>
        <Box sx={filterStyles.innerContainer}>
          {renderBottomFiltersContent()}
          {totalBottomFilters() > 3 && (
            <Box display='flex' justifyContent='center' alignItems='center' alignContent='center'>
              <Box
                sx={{
                  ...(showSearchKeywordInBottomFilters
                    ? filterStyles.countRectangle
                    : filterStyles.countCircle),
                  opacity: isLoading ? 0.38 : 1
                }}
                onClick={handleCountClick}>
                {`+${totalBottomFilters() - 3}`}
              </Box>
            </Box>
          )}
          {Object.keys(selectedCategoryOptions)?.length > 0 && data?.length > 0 && (
            <Button
              disabled={isLoading}
              size='small'
              sx={{ ...styles.clearAll, color: 'gray.800' }}
              onClick={handleReset}>
              Reset
            </Button>
          )}
          <Divider
            orientation='vertical'
            flexItem
            sx={{
              borderRight: `1px solid ${palette.gray[900]}`,
              height: 24,
              mt: 0.5,
              alignSelf: 'center'
            }}
          />
          <Button
            disabled={isLoading || data?.length === 0}
            size='small'
            sx={filterStyles.allFiltersButton}
            endIcon={<MoreVertIcon sx={{ color: 'gray.800' }} />}
            onClick={() => setShowFilterSidebar(!showFilterSidebar)}>
            All Filters
          </Button>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            sx={{ mt: '-14px' }}>
            {selectedCategory?.filterType === FILTER_TYPE.SELECT_OPTIONS && (
              <AutoSearchOptions
                selectedCategory={selectedCategory}
                selectedCategoryOptions={selectedCategoryOptions}
                setSelectedCategoryOptions={setSelectedCategoryOptions}
                handleClose={handleClose}
                handleApplyClose={handleApplyClose}
                applyFilters={applyFilters}
                isLoading={isLoading}
                setShowFilterSidebar={setShowFilterSidebar}
                previousSelectedCategoryOptions={previousSelectedCategoryOptions}
              />
            )}

            {(selectedCategory?.filterType === FILTER_TYPE.DATE_RANGE ||
              selectedCategory?.filterType === FILTER_TYPE.YEAR_RANGE) && (
              <DateYearRangeOptions
                selectedCategory={selectedCategory}
                selectedCategoryOptions={selectedCategoryOptions}
                setSelectedCategoryOptions={setSelectedCategoryOptions}
                handleClose={handleClose}
                handleApplyClose={handleApplyClose}
                applyFilters={applyFilters}
                isLoading={isLoading}
                setShowFilterSidebar={setShowFilterSidebar}
                previousSelectedCategoryOptions={previousSelectedCategoryOptions}
                setPreviousSelectedCategoryOptions={setPreviousSelectedCategoryOptions}
              />
            )}

            {selectedCategory?.filterType === FILTER_TYPE.NUMBER_RANGE && (
              <NumberRangeOptions
                selectedCategory={selectedCategory}
                selectedCategoryOptions={selectedCategoryOptions}
                setSelectedCategoryOptions={setSelectedCategoryOptions}
                isLoading={isLoading}
                applyFilters={applyFilters}
                handleClose={handleClose}
                handleApplyClose={handleApplyClose}
                setShowFilterSidebar={setShowFilterSidebar}
                minValue={selectedCategory.min}
                maxValue={selectedCategory.max}
              />
            )}

            {selectedCategory?.filterType === FILTER_TYPE.RADIO && (
              <RadioGroupsOptions
                selectedCategory={selectedCategory}
                selectedCategoryOptions={selectedCategoryOptions}
                setSelectedCategoryOptions={setSelectedCategoryOptions}
                isLoading={isLoading}
                applyFilters={applyFilters}
                handleClose={handleClose}
                handleApplyClose={handleApplyClose}
                setShowFilterSidebar={setShowFilterSidebar}
                categoryOption={selectedCategory?.optionsList ?? []}
              />
            )}

            {!showSearchKeywordInBottomFilters &&
              selectedCategory?.filterType === FILTER_TYPE.SEARCH && (
                <SearchKeywordOptions
                  selectedCategory={selectedCategory}
                  selectedCategoryOptions={selectedCategoryOptions}
                  setSelectedCategoryOptions={setSelectedCategoryOptions}
                  applyFilters={applyFilters}
                  isLoading={isLoading}
                  setShowFilterSidebar={setShowFilterSidebar}
                  previousSelectedCategoryOptions={previousSelectedCategoryOptions}
                  handleApplyClose={handleApplyClose}
                  handleClose={handleClose}
                  isBottomFilterView
                />
              )}
            {selectedCategory?.filterType === FILTER_TYPE.MULTI_SEARCH && (
              <MultiSearchKeywordOptions
                selectedCategory={selectedCategory}
                selectedCategoryOptions={selectedCategoryOptions}
                setSelectedCategoryOptions={setSelectedCategoryOptions}
                applyFilters={applyFilters}
                isLoading={isLoading}
                setShowFilterSidebar={setShowFilterSidebar}
                previousSelectedCategoryOptions={previousSelectedCategoryOptions}
                handleApplyClose={handleApplyClose}
                handleClose={handleClose}
                isBottomFilterView
              />
            )}
          </Popover>
        </Box>
      </Box>
      <ApplyFilterAlert
        showAlert={showAlert}
        applyFilters={applyFilters}
        selectedCategoryOptions={selectedCategoryOptions}
        previousSelectedCategoryOptions={previousSelectedCategoryOptions}
        setSelectedCategoryOptions={setSelectedCategoryOptions}
        handleClose={handleAlertClose}
      />
      {showFilterSidebar && (
        <FilterSidebar
          showFilterSidebar={showFilterSidebar}
          setShowFilterSidebar={() => setShowFilterSidebar(false)}
          data={data}
          selectedCategoryOptions={selectedCategoryOptions}
          setSelectedCategoryOptions={setSelectedCategoryOptions}
          applyFilters={applyFilters}
          isLoading={isLoading}
          previousSelectedCategoryOptions={previousSelectedCategoryOptions}
          setPreviousSelectedCategoryOptions={setPreviousSelectedCategoryOptions}
        />
      )}
    </>
  );
};

export default NewFilters;
