/* eslint-disable no-restricted-syntax */
/* eslint-disable react/prop-types */
import React, { Suspense, useContext, useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CancelIcon from '@mui/icons-material/Cancel';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
// store
import Skeleton from '@material-ui/lab/Skeleton';
import ResultsStore from '../../../store/SearchResults';

import ClinicalTrialsStyles from './Graphs.styles';
import CustomBarGraph from '../../CustomComponents/Graphs/CustomBarGraph';
import CT_REPORT_QUERIES from './constant';

// context
import GlobalStore from '../../../store';
import SuspenseCircularLoader from '../../../helpers/SuspenseCircularLoader';
import { BAR_CHART_COLOR } from '../../CustomComponents/Graphs/constant';
import ReportButton from '../../Report/ReportButton';
import useReportingHandler from '../../Report/hooks/useReportingHandler';

const Plot = React.lazy(() => import('react-plotly.js'));

const Graphs = ({ payload }) => {
  const { resultsState } = useContext(ResultsStore);
  const { dispatch } = useContext(GlobalStore);
  const classes = ClinicalTrialsStyles();
  const [phaseGraph, setPhaseGraph] = useState([]);
  const [statusGraph, setstatusGraph] = useState([]);
  const [yearGraph, setYearGraph] = useState([]);
  const [enrollmentData, setEnrollmentData] = useState([]);
  const [nctIdEnrollmentData, setNctIdEnrollmentData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const { updateReportData } = useReportingHandler();
  useEffect(() => {
    async function getData() {
      setstatusGraph(payload.status);
      setYearGraph(payload.year_graph);
      setPhaseGraph(payload.phase_graph);
      setEnrollmentData(payload.enrollment_by_trial);
      setNctIdEnrollmentData(payload.enrollment_by_region);
    }
    getData();
  }, [payload]);

  const handleDialog = () => {
    if (nctIdEnrollmentData?.length > 0) {
      setOpenModal(true);
    }
  };

  const formatdata = data => {
    const categories = data.map(item => item.nctId || item.nct_id);
    const keys = data.reduce((ele, item) => {
      Object.keys(item).forEach(key => {
        if (key !== 'nct_id' && !ele.includes(key) && item[key] !== 0) {
          ele.push(key);
        }
      });
      return ele;
    }, []);

    const traces = keys.map(key => ({
      x: categories,
      y: data.map(item => item[key] || 0),
      name: key,
      type: 'bar'
    }));
    return traces;
  };

  const addToReport = React.useCallback(
    async (key, reportData) => {
      const addedReport = CT_REPORT_QUERIES.find(report => report.id === key);
      let referenceText = 'CT GOV - Clinical Trials';
      if (resultsState?.decryptedPayload?.query) {
        referenceText += ` / ${resultsState?.decryptedPayload?.query}`;
      }
      const sectionLayout = {
        templateType: 'custom',
        chartType: addedReport.graph,
        sectionType: 'CHART',
        id: reportData?.reportId,
        style: {
          placement: {
            h: 40,
            w: 12,
            i: reportData?.reportId,
            minW: 8,
            moved: false,
            static: false,
            x: 0,
            y: 0
          },
          references: {
            show: true,
            href: window.location.href,
            text: `${referenceText}`
          },
          graphStyle: addedReport.graphStyle,
          title: addedReport.title
        },
        data: {
          source: 'ct',
          search_type: 'quicksearch',
          search_id: resultsState.searchIds.ctSearchId,
          query: addedReport.query
        }
      };
      updateReportData(reportData?.id, sectionLayout);
    },
    [dispatch, resultsState.searchIds.ctSearchId]
  );

  return (
    <Grid
      container
      spacing={2}
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      className={classes.root}>
      <Grid
        item
        className={classes.rootinside}
        container
        spacing={5}
        style={{ marginLeft: 0, marginRight: 0, marginTop: 'unset' }}>
        <Grid item xs={6} lg={6} style={{ padding: 18 }}>
          {resultsState.ctBusinessIntelligenceLoading || resultsState.loading ? (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-end'
              alignItems='space-between'
              className={classes.loaderBox}>
              <Box
                display='flex'
                justifyContent='space-between'
                flexWrap='wrap-reverse'
                className={classes.barGraphRect}>
                <Skeleton variant='rect' height='90%' width='.2%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
                <Skeleton variant='rect' height='30%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
                <Skeleton variant='rect' height='30%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='90%' width='3%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
              </Box>
              <Skeleton variant='rect' height='.2%' width='100%' />
            </Box>
          ) : (
            <Box className={classes.container}>
              <Box display='flex' justifyContent='space-between'>
                <Typography className={classes.heading}>
                  Number of Trials by Year of Completion
                </Typography>
                <ReportButton
                  data='number_of_trials_by_completion_year'
                  addToReport={addToReport}
                  prefixId='ct_ria_graphs'
                />
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                className={classes.graph}>
                {yearGraph?.length > 0 ? (
                  <CustomBarGraph
                    keys={['data']}
                    index='name'
                    colors={BAR_CHART_COLOR}
                    data={yearGraph}
                    paddings={0.7}
                    className={classes.bar}
                    margins={{
                      top: 20,
                      right: 20,
                      bottom: 40,
                      left: 30
                    }}
                    borderRadius={4}
                    tickRotationValue={0}
                    labelTextColor='#ffffff'
                    axisText='#9E9E9E'
                  />
                ) : (
                  'No Data'
                )}
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={6} lg={6} style={{ padding: 18 }}>
          {resultsState.ctBusinessIntelligenceLoading || resultsState.loading ? (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-end'
              alignItems='space-between'
              className={classes.loaderBox}>
              <Box
                display='flex'
                justifyContent='space-between'
                flexWrap='wrap-reverse'
                className={classes.barGraphRect}>
                <Skeleton variant='rect' height='90%' width='.2%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
                <Skeleton variant='rect' height='30%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
              </Box>
              <Skeleton variant='rect' height='.2%' width='100%' />
            </Box>
          ) : (
            <Box className={classes.container}>
              <Box display='flex' justifyContent='space-between'>
                <Typography className={classes.heading}>Number of Trials by Phase</Typography>
                <ReportButton
                  data='number_of_trials_by_phase'
                  addToReport={addToReport}
                  prefixId='ct_ria_graphs'
                />
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                className={classes.graph}>
                {phaseGraph?.length > 0 ? (
                  <CustomBarGraph
                    keys={['data']}
                    index='name'
                    colors={BAR_CHART_COLOR}
                    data={phaseGraph}
                    paddings={0.5}
                    className={classes.bar}
                    margins={{
                      top: 20,
                      right: 20,
                      bottom: 40,
                      left: 100
                    }}
                    borderRadius={4}
                    tickRotationValue={0}
                    labelTextColor='#ffffff'
                    axisText='#9E9E9E'
                    layout='horizontal'
                  />
                ) : (
                  'No Data'
                )}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid
        className={classes.rootinside}
        item
        container
        spacing={5}
        style={{ marginLeft: 0, marginRight: 0, marginBottom: 0 }}>
        <Grid item xs={6} lg={6} style={{ padding: 18 }}>
          {resultsState.ctBusinessIntelligenceLoading || resultsState.loading ? (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-end'
              alignItems='space-between'
              className={classes.loaderBox}>
              <Box
                display='flex'
                justifyContent='space-between'
                flexWrap='wrap-reverse'
                className={classes.barGraphRect}>
                <Skeleton variant='rect' height='90%' width='.2%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
                <Skeleton variant='rect' height='30%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
              </Box>
              <Skeleton variant='rect' height='.2%' width='100%' />
            </Box>
          ) : (
            <Box className={classes.container}>
              <Box display='flex' justifyContent='space-between'>
                <Typography className={classes.heading}>Number of Patients by Trial</Typography>
                <Tooltip
                  title={
                    nctIdEnrollmentData?.length === 0
                      ? 'No data available'
                      : 'View Number of Patients by Region of Enrollment'
                  }>
                  <span>
                    <Button
                      onClick={handleDialog}
                      variant='text'
                      fullWidth={false}
                      disabled={nctIdEnrollmentData?.length === 0}
                      className={classes.add}
                      startIcon={<OpenInNewIcon sx={{ fontSize: 14 }} />}>
                      <Typography align='right' variant='subtitle' className={classes.addText}>
                        By Region
                      </Typography>
                    </Button>
                  </span>
                </Tooltip>
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                mb={1}
                className={classes.graph}>
                {enrollmentData?.length > 0 ? (
                  <Suspense fallback={<SuspenseCircularLoader />}>
                    <Plot
                      data={formatdata(enrollmentData).map(dataset => ({
                        ...dataset,
                        marker: {
                          color: BAR_CHART_COLOR[0]
                        }
                      }))}
                      layout={{
                        barmode: 'stack',
                        autosize: true,
                        margin: { t: 32, r: 140 },
                        showlegend: false,
                        xaxis: {
                          tickangle: 90,
                          showticklabels: true,
                          color: '#9E9E9E',
                          tickfont: {
                            size: 12,
                            color: '#757575',
                            family: 'Roboto, Arial, sans-serif',
                            weight: 400
                          }
                        },
                        orientation: 90,
                        dragmode: 'pan',
                        bargap: 0.1,
                        bargroupgap: 0.5
                      }}
                      style={{ width: '100%', height: '100%' }}
                      useResizeHandler
                      config={{
                        toImageButtonOptions: {
                          filename: `number_of_patients_by_trial`
                        },
                        scrollZoom: true
                      }}
                    />
                  </Suspense>
                ) : (
                  'No Data'
                )}
              </Box>
            </Box>
          )}
        </Grid>
        <Grid item xs={6} lg={6} style={{ padding: 18 }}>
          {resultsState.ctBusinessIntelligenceLoading || resultsState.loading ? (
            <Box
              display='flex'
              flexDirection='column'
              justifyContent='flex-end'
              alignItems='space-between'
              className={classes.loaderBox}>
              <Box
                display='flex'
                justifyContent='space-between'
                flexWrap='wrap-reverse'
                className={classes.barGraphRect}>
                <Skeleton variant='rect' height='90%' width='.2%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
                <Skeleton variant='rect' height='30%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
                <Skeleton variant='rect' height='30%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='50%' width='3%' />
                <Skeleton variant='rect' height='90%' width='3%' />
                <Skeleton variant='rect' height='60%' width='3%' />
                <Skeleton variant='rect' height='80%' width='3%' />
              </Box>
              <Skeleton variant='rect' height='.2%' width='100%' />
            </Box>
          ) : (
            <Box className={classes.container}>
              <Box display='flex' justifyContent='space-between'>
                <Typography className={classes.heading}>
                  Number of Trials by Recruitment Status
                </Typography>
                <ReportButton
                  data='number_of_trials_by_recruitment_status'
                  addToReport={addToReport}
                  prefixId='ct_ria_graphs'
                />
              </Box>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                className={classes.graph}>
                {statusGraph?.length > 0 ? (
                  <CustomBarGraph
                    keys={['data']}
                    index='name'
                    colors={BAR_CHART_COLOR}
                    data={statusGraph}
                    paddings={0.5}
                    className={classes.bar}
                    margins={{
                      top: 20,
                      right: 20,
                      bottom: 40,
                      left: 120
                    }}
                    tickRotationValue={0}
                    borderRadius={4}
                    labelTextColor='#ffffff'
                    axisText='#9E9E9E'
                    layout='horizontal'
                  />
                ) : (
                  'No Data'
                )}
              </Box>
            </Box>
          )}
        </Grid>
      </Grid>

      <Dialog
        open={openModal}
        fullWidth
        maxWidth='lg'
        aria-labelledby='customized-dialog-title'
        sx={{ height: '100vh' }}
        onClose={() => setOpenModal(false)}>
        <DialogTitle>
          <Box display='flex' justifyContent='flex-end' alignItems='flex-start' flexDirection='row'>
            <Box>
              <IconButton
                aria-label='delete'
                onClick={() => setOpenModal(false)}
                sx={{ fontSize: '30px' }}>
                <CancelIcon fontSize='inherit' />
              </IconButton>
            </Box>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ height: '79vh', margin: 2 }}>
          <Box display='flex' width='100%' height='100%'>
            {nctIdEnrollmentData?.length > 0 ? (
              <Suspense fallback={<SuspenseCircularLoader />}>
                <Plot
                  data={formatdata(nctIdEnrollmentData)}
                  layout={{
                    title: { text: 'Number of Patients by Region of Enrollment' },
                    barmode: 'stack',
                    autosize: true,
                    margin: { t: 32, r: 140 },
                    xaxis: {
                      tickangle: 55
                    },
                    orientation: 90,
                    dragmode: 'pan',
                    showlegend: true
                  }}
                  style={{ width: '100%', height: '100%' }}
                  useResizeHandler
                  config={{
                    toImageButtonOptions: {
                      filename: `number_of_patients_by_region_of_enrollment`
                    },
                    scrollZoom: true
                  }}
                />
              </Suspense>
            ) : (
              'No Data'
            )}
          </Box>
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default Graphs;
