import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  IconButton,
  Dialog,
  DialogTitle,
  Stack,
  Box,
  Typography,
  DialogContent
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

let intervalId: any;

const DownloadLoader: React.FC<any> = ({
  loader,
  errorMsg,
  progressMessage,
  downloadSuccessMessage,
  onRetry,
  onCancel,
  onDoneAnimation,
  onEndLoading,
  onDoneClick
}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!loader && intervalId) {
      clearInterval(intervalId);
      intervalId = null;
      setProgress(100);
      setTimeout(() => onDoneAnimation?.(), 1000);
    }
    if (loader) {
      if (progress < 85) {
        intervalId = setTimeout(() => {
          setProgress(progress + Math.random() * 4);
        }, 500);
      }
    }

    return () => {
      clearTimeout(intervalId);
    };
  }, [progress, loader, onDoneAnimation]);

  return (
    <Dialog
      open
      maxWidth='xs'
      onClose={() => {
        if (progress === 100) {
          onEndLoading?.();
        }
      }}>
      <DialogTitle id='alert-dialog-title'>
        <Stack mt={2} flexDirection='column' spacing={1} alignItems='center'>
          <Box>
            {(progress === 100 || errorMsg) && (
              <IconButton
                aria-label='close'
                onClick={() => {
                  onEndLoading?.();
                }}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8
                }}>
                <CloseIcon />
              </IconButton>
            )}
          </Box>
          <Box
            sx={{
              position: 'relative',
              display: 'inline-flex',
              justifyContent: 'center',
              width: 200,
              height: 200
            }}>
            <CircularProgress
              variant='determinate'
              value={100}
              size={200}
              thickness={3}
              sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                color: 'gray.background'
              }}
            />
            {!errorMsg && (
              <CircularProgress
                variant={progress === 100 ? 'determinate' : 'indeterminate'}
                value={100}
                color={!errorMsg ? 'primary' : 'error'}
                size={200}
                thickness={3}
                sx={{
                  position: 'absolute',
                  top: 0,
                  left: 0
                }}
              />
            )}

            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: 'absolute',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}>
              {errorMsg ? (
                <ErrorOutlineIcon />
              ) : (
                <Typography
                  display='flex'
                  variant='caption'
                  component='div'
                  sx={{
                    fontSize: '32px',
                    color: 'black',
                    fontWeight: 'bold'
                  }}>
                  <span>{`${Math.round(progress)}`}</span>
                  <span style={{ fontSize: '20px', paddingTop: '6px' }}>%</span>
                </Typography>
              )}
            </Box>
          </Box>
          <Typography
            ml={1}
            sx={{
              fontSize: '25px',
              color: 'black',
              fontWeight: 'bold'
            }}>
            {!errorMsg
              ? `${progress !== 100 ? 'Downloading...' : 'Download Completed !'}`
              : `Download Failed`}
          </Typography>
        </Stack>
      </DialogTitle>
      <DialogContent>
        <Box
          mb={2}
          pl='5%'
          pr='5%'
          display='flex'
          flexDirection='column'
          alignItems='center'
          textAlign='center'>
          <Typography sx={{ fontSize: '16px', color: errorMsg ? 'red' : 'black' }}>
            {!errorMsg
              ? `${progress !== 100 ? progressMessage : downloadSuccessMessage}`
              : `Apologies! An error has occured in downloading. Please try again`}
          </Typography>

          <Button
            variant='contained'
            color='secondary'
            sx={{
              textTransform: 'capitalize',
              backgroundColor: 'primary.main',
              color: 'white.main',
              mt: 2,
              fontSize: '14px',
              fontWeight: 700,
              width: '40%',
              border: '2px solid',
              borderColor: 'primary.dark',
              padding: 1,
              '&:hover': {
                opacity: 0.8,
                color: 'black.main',
                backgroundColor: 'white.main',
                borderColor: 'gray.main'
              }
            }}
            onClick={() => {
              if (errorMsg) {
                setProgress(0);
                onRetry?.();
              } else if (progress !== 100) {
                onCancel?.();
              } else {
                onDoneClick?.();
              }
            }}>
            {
              // eslint-disable-next-line no-nested-ternary
              `${errorMsg ? 'Try Again' : progress !== 100 ? 'Cancel' : 'Done'}`
            }
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(DownloadLoader);
