const styles = {
  badgeStyle: {
    display: 'inline-flex',
    padding: '2px 4px',
    alignItems: 'center',
    '& .MuiBadge-badge': {
      height: 16,
      minWidth: 16,
      fontSize: 10,
      fontWeight: 700,
      ml: 0.5,
      mr: 0.5,
      color: 'white.main',
      backgroundColor: '#D43B2B'
    }
  },
  reportText: {
    color: 'grey.700',
    textAlign: 'center',
    fontFamily: 'Mulish',
    fontSize: 13,
    fontWeight: '500',
    textTransform: 'capitalize',
    cursor: 'pointer'
  },
  chatContainer: {
    maxHeight: '100%',
    height: '100%',
    flexGrow: 1,
    overflowY: 'auto',
    position: 'relative',
    '&::-webkit-scrollbar': {
      width: '7px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'grey.300',
      borderRadius: 1.25
    }
  },
  stackContainer: {
    display: 'flex',
    padding: '20px',
    alignItems: 'center',
    gap: '12px',
    alignSelf: 'stretch',
    borderBottom: '1px solid',
    borderColor: 'gray.200',
    backgroundColor: 'grey.50',
    justifyContent: 'fit-content'
  },
  form: {
    borderRadius: '8px',
    borderColor: 'grey.400',
    fontSize: 13,
    display: 'flex',
    padding: '8px 8px 8px 16px',
    fontFamily: 'Mulish',
    fontWeight: 400,
    height: '40px',
    '& .Mui-focused': {
      borderColor: 'grey.600'
    },
    '& .MuiOutlinedInput-input': {
      borderColor: 'grey.600'
    }
  },
  reportContainer: {
    p: 1.25,
    backgroundColor: 'rgba(51,177,135,0.1)',
    height: '4%',
    minHeight: 40,
    width: '100%'
  },
  title: {
    fontSize: 19,
    fontWeight: 'bold',
    color: 'white.main'
  },
  formContainer: {
    width: '100%',
    '& > form': {
      flex: 1,
      transaction: 'width 0.5s ease-out'
    },
    display: 'flex',
    pb: '20px',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  chatRiaHeaderText: {
    fontFamily: 'Mulish',
    fontSize: '14px',
    fontWeight: '500'
  },
  chatRiaHeader: {
    backgroundColor: 'primary.600',
    borderRadius: '0px 9px 0px 0px',
    p: 2,
    height: 50,
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'center'
  },
  chatRiaWindowActionContainer: {
    alignItems: 'center',
    mt: 2,
    cursor: 'pointer',
    color: 'black.main',
    fontSize: 12,
    fontWeight: 400
  },
  angleIcon: { height: 10, width: 16 }
};

export default styles;
