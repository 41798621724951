const styles = {
  groupName: { fontSize: 14, fontWeight: 700, color: 'gray.900', pb: 2.5, pt: 2 },
  tabContainer: {
    boxShadow: '0px 2px 4px 0px #E0E0E0',
    width: '100%',
    py: 1,
    pl: 5
  },
  tabDivider: { height: 24, mx: 1.5, borderColor: 'gray.400' }
};

export default styles;
