import makeRequest from '../client';

export const getAllProjects = () => makeRequest(`/user-profile/projects`, 'GET');

/**
 * It makes a POST request to the /user-profile/projects endpoint with the name of the project as the body of the request
 */
export const createProject = payload => makeRequest(`/user-profile/projects`, 'POST', payload);
export const getSearchFromId = searchId => makeRequest(`/user-profile/search/${searchId}`, 'GET');
export const getCTBusinessIntelligenceFromId = searchId =>
  makeRequest(`/user-profile/search/clinical_trials/business_intelligence/${searchId}`, 'GET');
export const getCTAdverseEventsFromId = searchId =>
  makeRequest(`/user-profile/search/clinical_trials/adverse_events/${searchId}`, 'GET');

export const listActivities = ({
  timestamp = new Date().toISOString(),
  dayCount = 3,
  tzOffset = new Date().getTimezoneOffset()
}) =>
  makeRequest(
    `/user-profile/activity?timestamp=${timestamp}&day_count=${dayCount}&tz_offset=${tzOffset}`,
    'GET'
  );

export const listFavorite = () => makeRequest(`/user-profile/favorites`, 'GET');

export const listFavoriteAriaResults = () =>
  makeRequest(`/user-profile/favorites/ariaResult`, 'GET');

// eslint-disable-next-line no-unused-vars
export const addApplicationToFavorite = ({ source, module, id }) =>
  makeRequest(`/user-profile/favorites/application`, 'POST', {
    source,
    entity_id: id
  });

// eslint-disable-next-line no-unused-vars
export const removeApplicationFromFavorite = ({ source, module, id }) =>
  makeRequest(`/user-profile/favorites/application`, 'DELETE', {
    source,
    entity_id: id
  });

export const addSearchToFavorite = ({ type, id }) =>
  makeRequest(`/user-profile/favorites/${type}/${id}`, 'POST', {
    is_favorite: true
  });

export const removeSearchFromFavorite = ({ type, id }) =>
  makeRequest(`/user-profile/favorites/${type}/${id}`, 'POST', {
    is_favorite: false
  });

export const addRemoveAriaResultToFavorite = data =>
  makeRequest(`/user-profile/favorites/ariaResult`, 'POST', data);

export const listReports = () => makeRequest('/user-profile/reports', 'GET');

export const deleteReport = reportId => makeRequest(`/user-profile/reports/${reportId}`, 'DELETE');

export const renameReport = (reportId, payload) =>
  makeRequest(`/user-profile/reports/${reportId}`, 'PUT', payload);

export const getReport = id => makeRequest(`/user-profile/reports/${id}`, 'GET');

export const saveReport = payload => makeRequest(`/report/layout`, 'POST', payload);

export const updateReport = (id, payload) => makeRequest(`/report/layout/${id}`, 'PUT', payload);

export const listProjectEntity = projectId =>
  makeRequest(`/user-profile/projects/${projectId}/`, 'GET');

export const addEntityToProject = ({ projectId, payload, id }) =>
  makeRequest(`/user-profile/projects/${projectId}/entity/${id}`, 'POST', payload);

export const removeEntityFromProject = ({ projectId, id, payload }) =>
  makeRequest(`/user-profile/projects/${projectId}/entity/${id}`, 'DELETE', payload);

export const addAriaResultToProject = ({ projectId, payload }) =>
  makeRequest(`/user-profile/projects/${projectId}/entity`, 'POST', payload);

export const removeAriaResultFromProject = ({ projectId, payload }) =>
  makeRequest(`/user-profile/projects/${projectId}/entity`, 'DELETE', payload);

export const deleteProject = projectId =>
  makeRequest(`/user-profile/projects/${projectId}/`, 'DELETE');

export const deleteSearch = ({ activityId, type }) =>
  makeRequest(`/user-profile/activity/${type}/${activityId}`, 'DELETE');

export const deleteSearchByActivityIDList = ({ activityType, activityIds }) => {
  const payload = { activity_ids: activityIds };
  return makeRequest(`/user-profile/activity/${activityType}`, 'DELETE', payload);
};

export const updateProject = (projectId, payload) =>
  makeRequest(`/user-profile/projects/${projectId}/`, 'POST', payload);

export const logoutUserFromQueue = () => makeRequest(`/user-profile/logout`, 'POST');

export const updateActiveStatus = (id, payload) =>
  makeRequest(`/user-profile/reports/active/${id}`, 'POST', payload);
