const styles = {
  checkBox: {
    '& .MuiSvgIcon-root': {
      fontSize: 16
    },
    color: 'gray.800',
    fontSize: 14,
    fontWeight: 400
  },
  paper: {
    '& .MuiPopover-paper': {
      maxHeight: 250,
      minWidth: 250,
      overflowY: 'auto',
      borderRadius: '8px',
      py: 1.5,
      width: 200
    }
  },
  reportsPaper: {
    '& .MuiPopover-paper': {
      maxHeight: 320,
      minWidth: 280,
      overflowY: 'auto',
      pt: 3,
      pb: 1.5,
      width: 280,
      borderRadius: 2,
      boxShadow: '0px 2px 8px 0px #BDBDBD',
      mt: -2,
      ml: -3.5,
      '&::-webkit-scrollbar': {
        width: 8
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'gray.400',
        borderRadius: 1,
        opacity: '0.8'
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent'
      }
    }
  },
  boxContainer: {
    pt: 0.75,
    pb: 0.75,
    pl: 3,
    pr: 4
  },
  dividerStyle: {
    height: 1,
    background: 'gray.300',
    mx: 3
  },
  createProjecIconBG: {
    display: 'flex',
    borderRadius: '50%',
    backgroundColor: 'gray.200',
    width: 18,
    height: 18,
    alignItems: 'center',
    justifyContent: 'center'
  },
  menuItemAddIcon: { width: 14, height: 14, color: 'grey.700' },
  menuItemText: { color: 'gray.800', fontWeight: 400, fontSize: 14 },
  caretDownIcon: { fontSize: 14, color: 'gray.800', ml: 1 },
  menuTitle: {
    color: 'gray.800',
    fontSize: 14,
    fontWeight: 400,
    ml: 1
  },
  accordionDetails: { m: 0, px: 2, pt: 1.5, pb: 2.5 },
  dividerSubSection: {
    height: 'auto',
    background: 'grey.300',
    mr: 1.75
  },
  subHeading: {
    color: 'gray.600',
    fontSize: 10,
    fontWeight: 400,
    letterSpacing: 0.2
  },
  tools: { fontSize: 15, color: 'gray.700' },
  searchInputBox: {
    pb: 1.5,
    pl: 3,
    pr: 3,
    ml: 'auto',
    '.MuiInputBase-root': {
      mr: 2
    },
    '& input': {
      fontSize: 13,
      display: 'flex',
      minWidth: 180,
      px: 1.25,
      pr: 0,
      py: 1,
      '&::placeholder': {
        color: 'gray.500',
        opacity: 1
      }
    },
    '& fieldset': {
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'gray.300'
    },
    '& svg': {
      fontSize: 14,
      color: 'gray.500',
      rotate: '280deg'
    }
  },
  title: {
    color: 'gray.600',
    fontSize: 12,
    fontWeight: 400,
    pt: 0.75,
    pb: 0.75,
    pl: 3,
    pr: 4
  },
  accordiontitle: {
    color: 'primary.600',
    fontSize: 12,
    fontWeight: 400,
    pl: 1.5,
    pr: 4
  },
  heading: {
    overflow: 'hidden',
    color: 'gray.800',
    textOverflow: 'ellipsis',
    fontSize: 14,
    fontWeight: 500
  },
  menuContainer: {
    pt: 0.75,
    pr: 0,
    pb: 1,
    pl: 3.9375
  },
  iconButton: { width: 16, height: 16 }
};

export const accondionSummary = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    alignItems: 'center',
    height: 20,
    width: '100%',
    justifyContent: 'space-between'
  },
  '& .MuiPaper-root': {
    width: '100%'
  }
};

export default styles;
