import { Tooltip } from '@mui/material';
import { isArray } from 'lodash';
import { DOCUMENT_SEARCH_SUBTEXT, sourceFlagMapping } from '../../SearchResults/constants';

const NotificationFlag = ({ source, id = 'icon', withTooltip = false }: any) => {
  const generateTitle = (name: string) => {
    const title = Object.values(source[name]).map((value: any) => {
      if (value in DOCUMENT_SEARCH_SUBTEXT) {
        return `${DOCUMENT_SEARCH_SUBTEXT[value]} \n`;
      }
      return `${value} \n`;
    });
    // remove the last comma
    return title.join('').slice(0, -2);
  };

  let sourceMap = source;
  if (withTooltip) {
    sourceMap = Object.keys(source);
  }
  if (isArray(sourceMap)) {
    return (
      <>
        {sourceMap.map((name: string) => {
          const IndividualFlag = sourceFlagMapping[name];
          if (!IndividualFlag) return null;
          if (withTooltip) {
            return (
              <Tooltip
                key={`${name}_tooltip`}
                title={generateTitle(name)
                  .split('\n')
                  .map(line => (
                    <div key={line}>{line}</div>
                  ))}>
                <IndividualFlag key={name} />
              </Tooltip>
            );
          }
          return <IndividualFlag key={name} />;
        })}
      </>
    );
  }
  const IndividualFlag = sourceFlagMapping[source];
  return <IndividualFlag id={id} />;
};

export default NotificationFlag;
