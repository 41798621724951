import { createSvgIcon } from '@mui/material';

const ApplicationSearchIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.6483 23.3517C11.3276 23.031 11.3176 22.5174 11.6181 22.1846L11.6181 22.1821L11.6483 22.1519L13.9101 19.8891C12.2002 17.5268 12.6966 14.2208 15.04 12.4672C17.4067 10.6963 20.7608 11.1792 22.5318 13.5458C24.3027 15.9124 23.8198 19.2666 21.4532 21.0375C19.5754 22.4427 17.0047 22.4599 15.1099 21.0889L12.8471 23.3517C12.5161 23.6828 11.9793 23.6828 11.6483 23.3517ZM18.2274 20.409C20.2361 20.4089 21.8645 18.7805 21.8645 16.7717C21.8645 14.7629 20.2361 13.1345 18.2273 13.1345C16.2185 13.1345 14.5901 14.7629 14.59 16.7716C14.5922 18.7796 16.2194 20.4068 18.2274 20.409Z'
      fill='#757575'
      stroke='#757575'
      strokeWidth='0.2'
    />
    <path
      d='M10.6357 10.5656L10.6392 9.56215L9.63574 9.56215L3.42523 9.56215L2.42866 9.56215L2.42524 10.5587L2.41671 13.0379L2.41671 13.0379L2.41671 13.0413C2.41671 15.4187 4.13816 17.583 6.52196 17.583C8.90516 17.583 10.6264 15.4198 10.6272 13.0431C10.6272 13.0425 10.6272 13.0419 10.6272 13.0413L10.6357 10.5656ZM9.62722 9.55523L10.6272 9.55523L10.6272 8.55523L10.6272 5.95801C10.6272 3.58067 8.90576 1.41634 6.52196 1.41634C4.13816 1.41634 2.41671 3.58067 2.41671 5.95801L2.41671 8.55523L2.41671 9.55523L3.41671 9.55523L9.62722 9.55523Z'
      stroke='#757575'
      strokeWidth='2'
    />
  </svg>,
  'ApplicationSearch'
);

export default ApplicationSearchIcon;
