import actions from './actions';
import { NotificationsContextInterface } from '.';

interface ActionProps {
  type: string;
  value: any;
}

const reducers = (state: NotificationsContextInterface, action: ActionProps) => {
  switch (action.type) {
    case actions.SET_SUBSCRIBED_APPLICATIONS: {
      return {
        ...state,
        subscribedApplications: action.value
      };
    }
    case actions.SET_SUBSCRIBED_SEARCHES: {
      return {
        ...state,
        subscribedSearches: action.value
      };
    }
    case actions.SET_SUBSCRIBED_DOCUMENT_SEARCHES: {
      return {
        ...state,
        subscribedDocumentSearches: action.value
      };
    }
    case actions.SET_SUBSCRIBED_SOURCES: {
      return {
        ...state,
        subscribedSources: action.value
      };
    }
    case actions.SET_SELECTED_NOTIFICATION: {
      return {
        ...state,
        selectedNotification: action.value
      };
    }
    default: {
      return state;
    }
  }
};

export default reducers;
