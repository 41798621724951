/* eslint-disable import/prefer-default-export */
import makeRequest from '../client';

export const fetchPRACMeetings = (filters: any) =>
  makeRequest(`/prac/meetings`, 'POST', { filters });

export const fetchPRACMeetingDetails = (meetingId: string, filters: any) =>
  makeRequest(`/prac/meetings/${meetingId}`, 'POST', { filters });

export const fetchPRACPIPDetails = (filters: any) =>
  makeRequest(`/prac/get_data`, 'POST', { filters });

export const fetchPRACTimelineDetails = (payload: any) =>
  makeRequest('/prac/get_data', 'POST', payload);
