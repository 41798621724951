import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import styles from '../../styles/MeetingTypeButton.styles';
import { typeMap } from '../../constants';

const SelectButton = ({
  meetingType,
  handleSelectMeetingType
}: {
  meetingType: 'past' | 'upcoming';
  handleSelectMeetingType: any;
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (value?: string) => {
    if (value) {
      handleSelectMeetingType(value);
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        id='fade-button'
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup='true'
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        endIcon={open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        sx={styles.button}>
        {typeMap[meetingType]}
      </Button>
      <Menu
        id='fade-menu'
        MenuListProps={{
          'aria-labelledby': 'fade-button'
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose()}
        TransitionComponent={Fade}>
        {(Object.keys(typeMap) as Array<'past' | 'upcoming'>).map((key: 'past' | 'upcoming') => (
          <MenuItem key={key} onClick={() => handleClose(`${key}`)}>
            {typeMap[key]}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default React.memo(SelectButton);
