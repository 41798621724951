import { lowerCase, upperFirst } from 'lodash';

export const caseConverter = (text: string) => {
  return upperFirst(lowerCase(text?.replace(/_/g, ' ')));
};

// @ts-ignore
// eslint-disable-next-line consistent-return
export const sortByNumber = (a, b) => {
  if (a.pageNumber < b.pageNumber) {
    return a.pageNumber - b.pageNumber;
  }
};

export const CHAT_RIA_ALLOWED_SOURCES = ['us', 'eu', 'ca', 'hpra', 'jp', 'hma', 'au'];

export const CHAT_RIA_MODES = [
  {
    label: 'ChatRIA',
    value: 'chatria'
  },
  {
    label: 'ChatRIA+ mode',
    value: 'chatriamode'
  }
];
