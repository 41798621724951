import React, { useEffect, useMemo, useRef, useState, MouseEvent, useCallback } from 'react';
import { Auth } from 'aws-amplify';
import axios, { CancelTokenSource } from 'axios';
import { uniqueId } from 'lodash';
import PDFMerger from 'pdf-merger-js/browser';
import { v4 } from 'uuid';

// mui imports
import {
  Skeleton,
  Stack,
  DialogContent,
  Box,
  Divider,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  Tooltip,
  Drawer,
  ListItemText,
  Link,
  Chip
} from '@mui/material';
import { SwapHorizontalCircle, Cancel, HighlightOffOutlined } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

// custom imports
import { getSectionPageNumbers, highlightPDF } from '../../api/pages/Aria';
import styles from './styles/PDFComparisonDialog.style';
import { ALL_SECTIONS_FILTER_OPTIONS } from '../../pages/ResultsPage/utils/constants';
import {
  LABEL_COLOR,
  sourceFlagMapping,
  sourceFlagMappingTooltip,
  LABEL_TOOLTIP_TITLE
} from '../../pages/SearchResults/constants';
import { RoundedMenu } from '../ui/Menu';
import {
  AngleRightIcon,
  DownloadIcon,
  AngleLeftIcon,
  SubHeaderPipeGreyIcon,
  ArrowDownIcon,
  Reg360Icon,
  LeftArrowIcon
} from '../../assets/svgs/Icons';
import {
  downloadCollatedSummary,
  getApplicationsByLabelSection
} from '../../pages/SearchResults/data/collatedSummary';
import downloadLabelFile from '../../pages/SearchResults/utils/downloadLabelFile';
import DropDownButton from '../../pages/SearchResults/components/DropDownButton';
import CompileSectionDialog from './CompileSectionDialog';
import DownloadLoadingModal from './DownloadModal';
import palette from '../../themev5/palette';
import getAllSections from './utils';
import {
  ComparisonCategory,
  PDFComparisonDialogPropsType,
  PDFDataItem,
  PDFSections,
  Progress
} from './interface';
import { getTotalPageCountFromIFrame, simulateProgress, sourceMapper } from './const';
import { decodeBase64ToObject } from '../../utils/encodeDecodeObject';
import generateHighlightPayload from '../PDFViewer/utils';

const PDFComparisonDialog: React.FC<PDFComparisonDialogPropsType> = ({
  open,
  keywords = [],
  pdfData,
  onClose,
  onSwap,
  sources = [],
  onChangeProducts
}) => {
  const location = useLocation();
  const [finalLinks, setFinalLinks] = useState<Array<PDFDataItem>>([]);
  const [loading, setLoading] = useState(false);
  const [pdfSectionData, setPdfSectionData] = useState<Array<PDFSections>>([]);
  const [selectedSection, setSelectedSection] = useState<string>('');
  const [openSwapMenu, setOpenSwapMenu] = useState<{ [k: number]: boolean }>({});
  const [isDownloadOptionsOpen, setIsDownloadOptionsOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [secondaryAnchorEl, setSecondaryAnchorEl] = useState<null | HTMLElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [cancel, setCancel] = useState<CancelTokenSource | null>(null);
  const [downloadModalOpen, setDownloadModalOpen] = useState<boolean>(false);
  const [downloadError, setDownloadError] = useState<boolean>(false);
  const [downloadProgress, setDownloadProgress] = useState<Progress>({
    message: 'Starting Compilation',
    progress: 0
  });
  const [cancelProgress, setCancelProgress] = useState<any>(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [downloadType, setDownloadType] = useState<'docx' | 'pdf' | 'html'>('pdf');
  const [jumpToSections, setJumpToSections] = useState<any>([]);
  const [isSectionCompileModalOpen, setIsSectionCompileModalOpen] = useState(false);
  const [applicationSources, setApplicationSources] = useState<any>([]);
  const [sectionCompileData, setSectionCompileData] = useState<any>([]);
  const [categories, setCategories] = useState<ComparisonCategory[]>([
    {
      id: v4(),
      source: '',
      sections: []
    }
  ]);

  const handleSwapMenuClick = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setOpenSwapMenu(prevState => {
      return { ...prevState, [index]: true };
    });
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setOpenSwapMenu({});
    setAnchorEl(null);
  };

  const swapPDFs = (
    currentIndex: number,
    targetIndex: number,
    currentHeading: string,
    targetHeading: string
  ) => {
    const finalLinksNew = [...finalLinks];
    const temp = finalLinksNew[currentIndex];
    finalLinksNew[currentIndex] = finalLinksNew[targetIndex];
    finalLinksNew[targetIndex] = temp;
    setFinalLinks(finalLinksNew);
    onSwap({
      message: `Swapped ${currentHeading} with ${targetHeading}`,
      currentIndex,
      targetIndex
    });
    handleClose();
  };

  const highLightPdfs = async () => {
    setLoading(true);
    const splitObject = location.pathname.split('/');
    let highlightedColor: any = [];
    if (splitObject) {
      const payload = splitObject[splitObject.length - 1];
      const searchPayload: any = decodeBase64ToObject(payload);
      if (
        searchPayload &&
        searchPayload.search_type === 'advanced' &&
        searchPayload.highlightedColor
      ) {
        highlightedColor = searchPayload.highlightedColor;
      }
    }
    const newLinks = await Promise.all(
      pdfData.map(async data => {
        const highlightPayload = generateHighlightPayload(
          data.url,
          // @ts-ignore
          keywords,
          null,
          highlightedColor
        );
        const res = await highlightPDF(highlightPayload);
        if (res?.status === 200) {
          const file = new Blob([res.data], { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          return {
            url: fileURL,
            heading: data.heading,
            page: data.page,
            applicationNumber: data.identifier,
            identifier: data.identifier,
            groupTitle: data.groupTitle,
            title: data.title,
            source: data?.source,
            labels: data?.labels
          };
        }
        return data;
      })
    );
    setLoading(false);
    return newLinks;
  };

  const handleSectionChange = (option: any) => {
    const newSectionTitle = option.value;

    const newJumpToSectionList: any = [];

    jumpToSections.forEach((item: any) => {
      newJumpToSectionList.push({
        label: item.value === newSectionTitle ? option.value : item.value,
        value: item.value === newSectionTitle ? option.value : item.value,
        selected: item.value === newSectionTitle
      });
    });

    setJumpToSections(newJumpToSectionList);

    setSelectedSection(newSectionTitle);
    if (pdfSectionData.length && finalLinks.length) {
      const newFinalLinks = [...finalLinks].map(prevPDFData => ({
        ...prevPDFData,
        page:
          pdfSectionData
            .find(x => x?.applicationNumber === prevPDFData?.identifier)
            ?.sectionData?.find(data => data.section === newSectionTitle)?.page ?? 1
      }));
      setFinalLinks(newFinalLinks);
    }
  };

  const updateJumpToSectionOptions = () => {
    const allApplicationSources = pdfData.map(data => data.source);

    const allSourcesSame = allApplicationSources.every(
      (source, index, array) => source === array[0]
    );

    const defaultSource = allApplicationSources[0];

    if (allSourcesSame && defaultSource) {
      setJumpToSections(
        ALL_SECTIONS_FILTER_OPTIONS[sourceMapper[defaultSource]]?.map(section => ({
          label: section?.value,
          value: section?.value
        }))
      );
    }
  };

  const downloadComparisonHandler = async (payload: any) => {
    const cancelToken = axios.CancelToken.source();
    setCancel(cancelToken);
    try {
      setDownloadError(false);
      setDownloadModalOpen(true);
      setIsDownloading(true);
      setDownloadProgress({
        message: 'Starting Compilation',
        progress: 0
      });

      const res = await downloadCollatedSummary(payload, cancelToken.token);

      if (res?.status === 200) {
        setIsDownloading(false);
        setDownloadProgress({
          message: 'Conversion Completed',
          progress: 100
        });
        if (cancelProgress) {
          clearInterval(cancelProgress);
          setCancelProgress(null);
        }
        downloadLabelFile(
          res.data,
          payload.download_type,
          `Collated-summary.${payload.download_type}`
        );
      } else {
        setDownloadError(true);
        setIsDownloading(false);
        if (cancelProgress) {
          clearInterval(cancelProgress);
          setCancelProgress(null);
        }
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      setDownloadError(true);
      if (!cancel) {
        setDownloadModalOpen(false);
      }
      setIsDownloading(false);

      setDownloadProgress({
        message: 'Download Failed',
        progress: 0
      });
      if (cancelProgress) {
        clearInterval(cancelProgress);
        setCancelProgress(null);
      }
    }
  };

  const handleDownloadComparison = async (type: 'docx' | 'pdf' | 'html') => {
    setDownloadType(type);
    setIsDownloadOptionsOpen(false);
    const user = await Auth.currentUserInfo();
    const username = user.attributes['custom:user'] ?? '';
    const jobID = v4();
    const payload = {
      username,
      download_type: type,
      applications: sectionCompileData,
      job_id: jobID
    };
    downloadComparisonHandler(payload);
  };

  const handleDownloadOptions = (event: MouseEvent<HTMLElement>) => {
    setSecondaryAnchorEl(event.currentTarget);
    setIsDownloadOptionsOpen(true);
  };

  const handleCancelCall = useCallback(async () => {
    if (cancel) {
      cancel.cancel('Operation canceled by the user.');
      setCancel(null);
      setIsDownloading(false);
      if (cancelProgress) {
        clearInterval(cancelProgress);
        setCancelProgress(null);
      }
    }
  }, [cancel]);

  const closeDownloadModal = useCallback(() => {
    setDownloadModalOpen(false);
    setDownloadError(false);
    setDownloadProgress({
      message: 'Starting Compilation',
      progress: 0
    });
    setIsDownloading(false);
    if (cancelProgress) {
      clearInterval(cancelProgress);
      setCancelProgress(null);
    }
  }, []);

  const getSourceWiseApplicationsWithSection = () => {
    const allApplications = pdfData;

    if (!allApplications) {
      return [];
    }

    const sourceWiseApplications: any = {};

    categories?.forEach((label: any) => {
      const sourceApplications = sourceWiseApplications[label.source];

      if (sourceApplications) {
        sourceApplications.sections = [...sourceApplications.sections, label.sections];
      } else {
        sourceWiseApplications[label.source] = {
          sections: label.sections,
          applications:
            allApplications?.map((currentApplication: any) => currentApplication.identifier) || []
        };
      }
    });

    return sourceWiseApplications;
  };

  const mergedPdfUrl = async (pdfUrls: any) => {
    if (!pdfUrls) {
      return '';
    }

    const pdfMerger = new PDFMerger();

    // eslint-disable-next-line no-restricted-syntax
    for (const links of Object.values(pdfUrls)) {
      // eslint-disable-next-line no-await-in-loop
      const response = await axios.get(links as any, {
        responseType: 'arraybuffer'
      });
      // eslint-disable-next-line no-await-in-loop
      await pdfMerger.add(Buffer.from(response.data, 'binary'));
    }
    const mergedPdf = await pdfMerger.saveAsBlob();
    const url = URL.createObjectURL(mergedPdf);

    return url;
  };

  const handleCompilingSections = async () => {
    try {
      setLoading(true);
      setIsSectionCompileModalOpen(false);

      const payload = getSourceWiseApplicationsWithSection();

      const res = await getApplicationsByLabelSection(payload);

      if (res?.status !== 200 && !res?.data?.Success?.result) {
        return;
      }

      const applications = res?.data?.Success?.result ?? [];
      // Sort S3 Links based on the order of the sections
      applications.forEach((application: any) => {
        const defaultSectionOrder = getAllSections(application?.source);
        const sortedS3Links: any = {};
        defaultSectionOrder.forEach((section: any) => {
          if (section in (application?.s3_links ?? {})) {
            sortedS3Links[section] = application?.s3_links[section] ?? '';
          }
        });

        // eslint-disable-next-line no-param-reassign
        application.s3_links = sortedS3Links;
      });
      setSectionCompileData(applications);

      const newFinalLinks = await Promise.all(
        applications.map(async (application: any) => {
          const url = await mergedPdfUrl(application?.s3_links ?? '');
          return {
            url,
            heading: `${application?.number} - ${application?.name}`,
            page: undefined,
            applicationNumber: application?.number ?? '',
            identifier: application?.number ?? '',
            groupTitle: application?.active_ingredient ?? '',
            title: application?.name ?? '',
            source: application?.source ?? '',
            labels:
              pdfData.find((data: PDFDataItem) => data.identifier === application?.number)
                ?.labels ?? []
          };
        })
      );

      setFinalLinks(newFinalLinks);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  const resetCategories = () => {
    if (applicationSources.length > 0) {
      setCategories([
        {
          id: v4(),
          source: applicationSources[0],
          sections: []
        }
      ]);
    }
  };

  const swapMenuItems = useMemo(() => {
    const menuData = finalLinks.map((item, index) => {
      const oneSwapMenuData = finalLinks.map((menuItem, menuIndex) => {
        const { heading, title, identifier } = item;
        const parentIndex = index;
        if (parentIndex !== menuIndex)
          return (
            <MenuItem
              onClick={() =>
                swapPDFs(
                  parentIndex,
                  menuIndex,
                  `${title} (${identifier})`,
                  `${menuItem.title} (${menuItem.identifier})`
                )
              }
              key={`${heading}_${menuItem?.applicationNumber}`}>
              <Stack direction='row' alignItems='center' spacing={1}>
                <Typography>
                  {title} ({identifier})
                </Typography>
                <SwapHorizontalCircle sx={styles.rearrangeMenuIcon} />
                <Typography>
                  {menuItem.title} ({menuItem.identifier})
                </Typography>
              </Stack>
            </MenuItem>
          );
        return null;
      });
      return oneSwapMenuData;
    });
    return menuData;
  }, [finalLinks]);

  const move = (direction: string) => {
    if (containerRef.current === null || containerRef.current === undefined) return;
    containerRef.current.scrollTo({
      left:
        direction === 'left'
          ? containerRef.current.scrollLeft - 200
          : containerRef.current.scrollLeft + 200,
      behavior: 'smooth'
    });
  };

  const resetJumpToSection = () => {
    updateJumpToSectionOptions();
    setSelectedSection('');
    if (finalLinks?.length) {
      const newFinalLinks = [...finalLinks]?.map(prevPDFData => ({
        ...prevPDFData,
        page: 1
      }));
      setFinalLinks(newFinalLinks);
    }
  };

  const resetCompileSection = () => {
    setSectionCompileData([]);
    resetJumpToSection();
    setFinalLinks(pdfData);
    resetCategories();
  };

  useEffect(() => {
    updateJumpToSectionOptions();

    const newApplicationSources = Array.from(new Set(pdfData.map((item: any) => item.source)));

    setApplicationSources(newApplicationSources);

    if (newApplicationSources.length > 0) {
      setCategories([
        {
          id: v4(),
          source: newApplicationSources[0],
          sections: []
        }
      ]);
    }

    // Function to get all the page numbers of specific sections
    const getSectionPageNumberData = async () => {
      const payload: any = { applications: [] };
      payload.applications = pdfData.map(data => {
        const properties = {
          application_number: data.identifier,
          center: data?.center?.toLowerCase() ?? '',
          source: data.source
        };
        switch (data.source?.toLowerCase()) {
          case 'ca':
            return {
              ...properties,
              submission_type: data.latest_submission_type,
              submission_number: data.latest_submission_number
            };
          case 'eu':
          case 'us':
          case 'hma':
          case 'hpra':
            return {
              ...properties,
              s3_path: data.url?.split('s3.amazonaws.com/')[1].split('?')[0]
            };
          case 'au':
            return {
              ...properties,
              s3_path: data.url?.split('s3.amazonaws.com/')[1].split('?')[0]
            };
          case 'jp':
            return {
              ...properties,
              s3_path: data.url?.split('s3.amazonaws.com/')[1].split('?')[0],
              package_insert_num: data.package_insert_num
            };
          default:
            return null;
        }
      });

      const result: any = await getSectionPageNumbers(payload);

      if (result?.data?.status === 200 && !result?.data?.error) {
        const newSectionData: PDFSections[] = Object.entries(result?.data?.body)
          ?.filter(([, sections]) => !!sections)
          ?.map(([applNo, sections]) => ({
            applicationNumber: applNo,
            sectionData: Object.entries(sections as any)?.map(([sectionTitle, pageNumber]) => ({
              section: sectionTitle,
              page: pageNumber as number
            }))
          }));
        setPdfSectionData(newSectionData);
      }
    };

    if (keywords.length && pdfData.length) {
      // @ts-ignore
      highLightPdfs().then(res => setFinalLinks(res));
    } else {
      setFinalLinks(pdfData);
    }
    getSectionPageNumberData();
  }, []);

  useEffect(() => {
    if (!isDownloading || finalLinks.length === 0) {
      return () => {};
    }
    let step = 0; // Manage the step as a regular variable

    const pdfPageCountList = finalLinks.map(
      link => getTotalPageCountFromIFrame(`${link.applicationNumber}-${link.url}`) || 0
    );
    const intervalId = setInterval(() => {
      // Simulate progress based on the current step
      const { message, progress, stepScale } = simulateProgress({
        currentStep: step,
        fileType: downloadType,
        fileCount: finalLinks.length,
        filePageCountList: pdfPageCountList
      });
      // Stop interval when reaching 100 steps
      if (step >= 95 || !isDownloading) {
        clearInterval(intervalId);
      }
      if (isDownloading) {
        // Update the progress message and percentage
        setDownloadProgress(prevProgress => {
          if (prevProgress.progress <= progress) {
            return {
              message,
              progress
            };
          }
          return prevProgress;
        });
      }
      // Increment step based on stepScale
      step += stepScale;
    }, 100); // Progress updates every 100ms
    setCancelProgress(intervalId);
    return () => clearInterval(intervalId);
  }, [isDownloading, finalLinks]);

  const getApplicationChipTag = (application: PDFDataItem) => {
    return (
      application?.labels &&
      application?.labels.map((label: string) => {
        const labelKey = label.toLowerCase();
        const labelColor = LABEL_COLOR[labelKey];
        const labelTooltipTitle = LABEL_TOOLTIP_TITLE[labelKey];
        return labelColor ? (
          <Tooltip
            title={labelTooltipTitle}
            disableInteractive
            disableFocusListener
            disableTouchListener>
            <Chip
              label={label}
              key={label}
              sx={{ ...styles.applicationChip, bgcolor: labelColor }}
            />
          </Tooltip>
        ) : null;
      })
    );
  };

  return (
    <Box>
      <Drawer
        open={open}
        PaperProps={{
          style: styles.dialogPaper
        }}
        anchor='bottom'
        onClose={() => onClose()}>
        <Box sx={{ pb: 0 }}>
          <Stack>
            <Stack direction='row' sx={styles.headerContainer}>
              <Stack
                direction='row'
                alignItems='center'
                spacing={0.75}
                onClick={() => onChangeProducts()}
                sx={styles.changeProductsContainer}>
                <LeftArrowIcon sx={styles.leftArrowIcon} />
                <Typography sx={styles.changeProductsText}>Change products</Typography>
              </Stack>
              <Stack spacing={1} sx={styles.viewLatestLabelsContainer}>
                <Typography variant='h3' fontWeight='bold' color='white.main'>
                  View Latest Labels
                </Typography>
              </Stack>
              <Tooltip title='Close'>
                <IconButton
                  aria-label='delete'
                  onClick={() => onClose()}
                  size='small'
                  sx={styles.iconButton}>
                  <HighlightOffOutlined fontSize='inherit' sx={styles.highlightOffOutlinedIcon} />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>

          <Box display='flex' justifyContent='space-between' alignItems='center' px='24px'>
            {sources && jumpToSections?.length > 0 ? (
              <Box
                sx={{
                  ...(sectionCompileData.length > 0 && { pointerEvents: 'none', opacity: 0.5 }),
                  display: 'flex'
                }}>
                <DropDownButton
                  label={selectedSection?.length > 0 ? selectedSection : 'Jump to a section'}
                  options={jumpToSections}
                  onOptionSelect={handleSectionChange}
                  tooltip={
                    sectionCompileData.length < 1
                      ? 'Navigate simultaneously to the same section in all selected documents for side-by-side comparison'
                      : 'Not available in compiled sections view'
                  }
                />
                {selectedSection?.length > 0 && (
                  <Typography sx={styles.resetJumpToSectionButton} onClick={resetJumpToSection}>
                    Reset
                  </Typography>
                )}
              </Box>
            ) : (
              <Box />
            )}
            <Stack direction='row' alignItems='center' spacing={1}>
              <Box sx={styles.sliderWrapper}>
                <Typography sx={styles.sliderText}>Slider</Typography>
                <Tooltip title='Scroll left'>
                  <IconButton onClick={() => move('left')}>
                    <AngleLeftIcon sx={styles.angleIcon} />
                  </IconButton>
                </Tooltip>
                <Tooltip title='Scroll right'>
                  <IconButton onClick={() => move('right')}>
                    <AngleRightIcon sx={styles.angleIcon} />
                  </IconButton>
                </Tooltip>
              </Box>
              <SubHeaderPipeGreyIcon sx={styles.pipeIcon1} />
              {sectionCompileData.length < 1 ? (
                <Tooltip title='Choose specific sections from each document to create a new, consolidated document for easier comparison and analysis between products.'>
                  <Typography
                    onClick={() => setIsSectionCompileModalOpen(true)}
                    sx={styles.compileSectionText}>
                    Compile Multiple Section(s)
                  </Typography>
                </Tooltip>
              ) : (
                <Box
                  sx={styles.cancelCompileSectionWrapper}
                  onClick={() => setIsSectionCompileModalOpen(true)}>
                  <IconButton
                    sx={{ p: 0 }}
                    onClick={event => {
                      event.stopPropagation();
                      resetCompileSection();
                    }}>
                    <Cancel sx={styles.cancelIcon} />
                  </IconButton>
                  <Typography sx={styles.totalSectionsCompliedText}>
                    {categories.reduce((acc: any, curr: any) => acc + curr.sections.length, 0)}{' '}
                    sections compiled
                  </Typography>
                  <ArrowDownIcon sx={styles.arrowDownIcon} />
                </Box>
              )}
              <SubHeaderPipeGreyIcon sx={styles.pipeIcon2} />
              <Tooltip
                title={
                  sectionCompileData.length < 1
                    ? 'Select and compile multiple sections first to enable download.'
                    : 'Download the compiled section summary as an PDF or Docx file.'
                }>
                <IconButton
                  onClick={event => {
                    if (sectionCompileData.length < 1) {
                      return;
                    }
                    handleDownloadOptions(event);
                  }}>
                  <DownloadIcon
                    sx={{
                      height: '16px',
                      width: '16px',
                      ...(sectionCompileData.length < 1
                        ? { color: 'grey.400' }
                        : { color: 'grey.800' })
                    }}
                  />
                </IconButton>
              </Tooltip>
              <RoundedMenu
                open={isDownloadOptionsOpen}
                anchorEl={secondaryAnchorEl}
                onClose={() => {
                  setSecondaryAnchorEl(null);
                  setIsDownloadOptionsOpen(false);
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right'
                }}
                sx={styles.roundedMenu}>
                <MenuItem disabled sx={{ p: '6px 24px' }}>
                  <ListItemText>
                    <Typography sx={{ color: 'gray.800', fontSize: 12, fontWeight: 400 }}>
                      Select Download Option:
                    </Typography>
                  </ListItemText>
                </MenuItem>
                <MenuItem sx={{ p: '6px 24px' }} onClick={() => handleDownloadComparison('html')}>
                  HTML file
                </MenuItem>
                <MenuItem sx={{ p: '6px 24px' }} onClick={() => handleDownloadComparison('pdf')}>
                  PDF file
                </MenuItem>
                <MenuItem sx={{ p: '6px 24px' }} onClick={() => handleDownloadComparison('docx')}>
                  Docx file (.docx)
                </MenuItem>
              </RoundedMenu>
            </Stack>
          </Box>
          <Divider sx={{ mx: '24px' }} />
        </Box>
        <DialogContent sx={{ padding: '10px 24px 20px 24px' }}>
          <Box sx={styles.contentBox} ref={containerRef}>
            {(loading &&
              pdfData.map(() => (
                <Box sx={styles.pdfContainer} key={uniqueId()}>
                  <Skeleton variant='text' width='30%' height={75} />
                  <Skeleton variant='rectangular' width='90%' height={150} />
                  <Skeleton variant='text' width='30%' />
                  <Skeleton variant='rectangular' width='90%' height={150} />
                  <Skeleton variant='text' width='30%' />
                  <Skeleton variant='rectangular' width='90%' height={150} />
                  <Skeleton variant='text' width='30%' />
                  <Skeleton variant='rectangular' width='90%' height={150} />
                  <Skeleton variant='text' width='30%' />
                  <Skeleton variant='rectangular' width='90%' height={150} />
                </Box>
              ))) ||
              finalLinks.map((item, index) => {
                const FlagComponent = sourceFlagMapping[item?.source?.toLowerCase() ?? ''];
                const flagTooltipTitle =
                  sourceFlagMappingTooltip[item?.source?.toLowerCase() ?? ''];

                return (
                  <Box sx={styles.pdfContainer} key={item.applicationNumber}>
                    <Typography
                      sx={styles.missingSectionText}
                      textAlign='center'
                      visibility={item.page === 0 ? 'visible' : 'hidden'}>
                      <span style={{ fontWeight: 'bold' }}>{selectedSection}</span> is not available
                      in this label
                    </Typography>
                    <Box
                      sx={{
                        ...styles.pdfWrapper,
                        border: `${item.page === 0 ? `1px solid ${palette.red[900]}` : 'none'}`
                      }}>
                      <Box sx={styles.pdfContainerHeader}>
                        <Box display='flex' alignItems='center' gap='4px' height={18}>
                          <Tooltip title={`${item.title} (${item.identifier})`}>
                            <Typography sx={styles.applicationName} noWrap>
                              {item.title} ({item.identifier})
                            </Typography>
                          </Tooltip>
                          <Tooltip
                            title={flagTooltipTitle}
                            disableInteractive
                            disableFocusListener
                            disableTouchListener>
                            <FlagComponent sx={styles.flagIcon} />
                          </Tooltip>
                          {item?.labels && getApplicationChipTag(item)}
                        </Box>
                        <Box sx={styles.callToActionsWrapper}>
                          <Tooltip title='Open Regulatory 360 view' arrow placement='right'>
                            <Link
                              href={`/regulatory360/core/${item.identifier}/${item.source}`}
                              target='_blank'>
                              <Reg360Icon sx={{ height: '24px', width: '24px' }} />
                            </Link>
                          </Tooltip>
                          <Tooltip title='Click to rearrange' arrow placement='right'>
                            <IconButton
                              aria-controls={openSwapMenu[index] ? 'long-menu' : undefined}
                              aria-expanded={openSwapMenu[index] ? 'true' : undefined}
                              sx={styles.rearrangeIcon}
                              aria-haspopup='true'
                              onClick={e => handleSwapMenuClick(e, index)}>
                              <SwapHorizontalCircle sx={{ height: '20px', width: '20px' }} />
                            </IconButton>
                          </Tooltip>

                          <Menu
                            aria-labelledby='demo-positioned-button'
                            anchorEl={anchorEl}
                            open={openSwapMenu[index]}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left'
                            }}
                            transformOrigin={{
                              vertical: 'top',
                              horizontal: 'left'
                            }}>
                            <MenuItem key={`${item?.applicationNumber}_title`} disabled>
                              Rearrange labels
                            </MenuItem>
                            {swapMenuItems[index]}
                          </Menu>
                        </Box>
                      </Box>
                      <iframe
                        id={`${item.applicationNumber}-${item.url}`}
                        key={`${item.url}#view=fitH&navpanes=0&scrollbar=0&page=${item?.page || 1}`}
                        src={`${item.url}#view=fitH&navpanes=0&scrollbar=0&page=${item?.page || 1}`}
                        title={item.heading}
                        width='100%'
                        height='100%'
                      />
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </DialogContent>
      </Drawer>
      {downloadModalOpen && (
        <DownloadLoadingModal
          modalOpen={downloadModalOpen}
          closeModal={closeDownloadModal}
          progressData={downloadProgress}
          downloadError={downloadError}
          setDownloadError={setDownloadError}
          handleDownloadComparison={() => handleDownloadComparison(downloadType)}
          handleCancelCall={handleCancelCall}
        />
      )}
      <CompileSectionDialog
        open={isSectionCompileModalOpen}
        onClose={() => setIsSectionCompileModalOpen(false)}
        applicationSources={applicationSources}
        handleCompilingSections={handleCompilingSections}
        categories={categories}
        setCategories={setCategories}
        resetCategories={resetCategories}
      />
    </Box>
  );
};

export default PDFComparisonDialog;
