import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material';
import React from 'react';
import { TagIconMapping, typeColorMapping } from '../constants';
import styles from './Metadata.style';
import { MetadataProps } from '../types';

const Metadata: React.FC<MetadataProps> = ({ text, type }) => {
  const TagIcon = TagIconMapping[type as string];
  return (
    <Stack
      direction='row'
      sx={styles.container}
      divider={
        <Divider orientation='vertical' sx={{ height: 16, mx: 1, borderColor: 'blue.gray400' }} />
      }>
      {text && (
        <Tooltip title={text}>
          <Typography color='blue.gray700' sx={styles.matadataText1}>
            {text}
          </Typography>
        </Tooltip>
      )}

      {type && (
        <Box display='flex' alignItems='center'>
          <Tooltip title={type}>
            <Typography
              color={typeColorMapping[type] || typeColorMapping.default}
              sx={{ ...styles.metadataText2, fontWeight: TagIcon ? 700 : 'normal' }}>
              {type}
            </Typography>
          </Tooltip>

          {TagIcon && <TagIcon sx={{ ml: 0.5, fontSize: 12 }} />}
        </Box>
      )}
    </Stack>
  );
};

export default React.memo(Metadata);
