import { NegativeTag, PositiveTag, WithDrawalTag } from '../../assets/svgs/Icons';
import { FILTER_TYPE } from '../../components/NewFilters/constants';
import { Section } from './types';

export const CHMP_FILTERS = [
  {
    id: 1,
    label: 'Active Ingredient',
    value: 'active_ingredient',
    elasticSearchType: 'active_ingredient',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Brand Name',
    value: 'brand_name',
    elasticSearchType: 'brand_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Rapporteur',
    value: 'rapporteur',
    elasticSearchType: 'rapporteur',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Meeting Year',
    value: 'year',
    elasticSearchType: 'year',
    filterType: FILTER_TYPE.YEAR_RANGE,
    showInBottomFilter: true
  }
];

export const typeColorMapping: any = {
  Positive: 'primary.700',
  Negative: 'red.600',
  Withdrawal: 'yellow.700',
  default: 'blue.gray700'
};

export const TagIconMapping: any = {
  Positive: PositiveTag,
  Negative: NegativeTag,
  Withdrawal: WithDrawalTag
};

export const SECTIONS: Record<string, Section[]> = {
  '2. Oral Explanation': [
    {
      value: '2.1. Pre-authorisation procedure oral explanations',
      label: '2.1. Pre-authorisation procedure'
    },
    {
      value: '2.2. Re-examination procedure oral explanations',
      label: '2.2. Re-examination procedure'
    },
    {
      value: '2.3. Post-authorisation procedure oral explanations',
      label: '2.3. Post-authorisation procedure'
    },
    { value: '2.4. Referral procedure oral explanations', label: '2.4. Referral procedure' }
  ],
  '3. Initial Applications': [
    { value: '3.1. Initial applications; Opinions', label: '3.1. Opinions' },
    {
      value:
        '3.2. Initial applications; List of outstanding issues (Day 180; Day 120 for procedures with accelerated assessment timetable)',
      label: '3.2. List of outstanding issues'
    },
    {
      value:
        '3.3. Initial applications; List of questions (Day 120; Day 90 for procedures with accelerated assessment timetable)',
      label: '3.3. List of questions'
    },
    {
      value: '3.4. Update on on-going initial applications for Centralised procedure',
      label: '3.4. Update on on-going'
    },
    {
      value:
        '3.5. Re-examination of initial application procedures under Article 9(2) of Regulation no 726/2004',
      label: '3.5. Re-examination'
    },
    {
      value: '3.6. Initial applications in the decision-making phase',
      label: '3.6. In decision-making phase'
    },
    {
      value: '3.7. Withdrawals of initial marketing authorisation application',
      label: '3.7. Withdrawals'
    }
  ],
  '4. Marketing Auth Extension': [
    {
      value:
        '4.1. Extension of marketing authorisation according to Annex I of Commission Regulation (EC) No 1234/2008; Opinion',
      label: '4.1. Opinions'
    },
    {
      value:
        '4.2. Extension of marketing authorisation according to Annex I of Commission Regulation (EC) No 1234/2008; Day 180 list of outstanding issues',
      label: '4.2. Issues (Day 180)'
    },
    {
      value:
        '4.3. Extension of marketing authorisation according to Annex I of Commission Regulation (EC) No 1234/2008; Day 120 List of question',
      label: '4.3. Questions (Day 120)'
    },
    {
      value:
        '4.4. Update on on-going extension application according to Annex I of Commission Regulation (EC) No 1234/2008',
      label: ' 4.4. Updates on on-going'
    },
    {
      value:
        '4.5. Re-examination procedure of extension of marketing authorisation according to Annex I of Commission Regulation (EC) No 1234/2008 ',
      label: '4.5. Re-examination'
    }
  ],
  '5. Type II Indication Variation': [
    {
      value:
        '5.1. Type II variations - variation of therapeutic indication procedure according to Commission Regulation (EC) No 1234/2008; Opinions or Requests for supplementary information',
      label: '5.1. Opinions/Requests'
    },
    {
      value:
        '5.2. Update on on-going Type II variation; variation of therapeutic indication procedure according to Commission Regulation (EC) No 1234/2008',
      label: '5.2. Updates on on-going'
    },
    {
      value:
        '5.3. Re-examination of Type II variation; variation of therapeutic indication procedure according to Commission Regulation (EC) No 1234/2008',
      label: '5.3. Re-examination'
    }
  ],
  '6. Medical Devices': [
    {
      value: '6.1. Ancillary medicinal substances - initial consultation',
      label: '6.1. Ancillary Initial Consult'
    },
    {
      value: '6.2. Ancillary medicinal substances – post-consultation update',
      label: '6.2. Ancillary Post-Update'
    },
    {
      value: '6.3. Companion diagnostics - initial consultation',
      label: '6.3. CDx Initial Consult'
    },
    {
      value: '6.4. Companion diagnostics – follow-up consultation',
      label: '6.4. CDx Follow-Up'
    }
  ],
  '7. Compassionate Use Procedure': [
    {
      value: '7.1. Procedure under Article 83(1) of Regulation (EC) 726/2004 (Compassionate Use)',
      label: '7.1. Compassionate Use Procedure'
    }
  ],
  '8. Pre-submission Issues': [
    {
      value: '8.1. Pre-submission issues',
      label: '8.1. Pre-submission issues'
    },
    {
      value: '8.2. Priority Medicines (PRIME)',
      label: '8.2. Priority meds (PRIME)'
    }
  ],
  '9. Post-auth Issues': [
    {
      value: '9.1. Post-authorisation issues',
      label: '9.1. Post-auth issues'
    }
  ],
  '10. Referral Procedures': [
    {
      value:
        '10.1. Procedure for Centrally Authorised products under Article 20 of Regulation (EC) No 726/2004',
      label: '10.1. Art 20: Centrally auth prod'
    },
    {
      value: '10.2. Requests for CHMP Opinion under Article 5(3) of Regulation (EC) No 726/2004',
      label: '10.2. Art 5(3): CHMP opinion'
    },
    {
      value: '10.3. Procedure under Articles 5(2) and 10 of Regulation (EC) No 726/2004',
      label: '10.3. Art 5(2) & 10 Proc'
    },
    {
      value:
        '10.4. Disagreement between Member States on application for medicinal product (potential serious risk to public health) –under Article 29(4) of Directive 2001/83/EC',
      label: '10.4. Art 29(4): Member states disagree'
    },
    {
      value: '10.5. Harmonisation - Referral procedure under Article 30 of Directive 2001/83/EC',
      label: '10.5. Art 30: Harmonisation'
    },
    {
      value: '10.6. Community Interests - Referral under Article 31 of Directive 2001/83/EC',
      label: '10.6. Art 31: Comm. Interest'
    },
    {
      value: '10.7. Re-examination Procedure under Article 32(4) of Directive 2001/83/EC',
      label: '10.7. Art 32(4): Re-exam'
    },
    {
      value: '10.8. Procedure under Article 107(2) of Directive 2001/83/EC',
      label: '10.8. Art 107(2) Proc'
    },
    {
      value:
        '10.9. Disagreement between Member States on Type II variation– Arbitration procedure initiated by MAH under Article 6(13) of Commission Regulation (EC) No 1084/2003',
      label: '10.9. Art 6(13): Type II disagree'
    },
    {
      value: '10.10.  Procedure under Article 29 of Regulation (EC) 1901/2006',
      label: '10.10. Art 29 Proc'
    },
    {
      value:
        '10.11.  Referral under Article 13 Disagreement between Member States on Type II variation– Arbitration procedure initiated by Member State under Article 13 (EC) of Commission Regulation No 1234/2008',
      label: '10.11.  Art 13: Type II disagree'
    }
  ],
  '11. Other Highlights': [
    {
      value: '11.1 Others',
      label: '11.1 Others'
    }
  ]
};
