import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Stack, Checkbox } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import isEqual from 'lodash/isEqual';
import ComparisonIcon from '../../../../assets/svgs/Icons/ComparisonIcon';
import { CheckboxCheckedIcon, CheckboxUncheckedIcon } from '../../../../assets/svgs/Icons';
import DocumentComparison from '../DocumentComparison/documentComparison';
import styles from '../../styles/Timeline.styles';
import { formatDate, isEmptyObject, ComparisonData } from '../../utils';

interface TimelineLeftDrawerProps {
  selectedData: any;
  handleClose: () => void;
}

const TimelineLeftDrawer = ({ selectedData, handleClose }: TimelineLeftDrawerProps) => {
  const clearData = {
    date: '',
    type: '',
    brandName: '',
    source: '',
    signedUrl: '',
    fileName: ''
  };
  const [showDocumentComparison, setShowDocumentComparison] = useState(false);
  const [parentData, setParentData] = useState<ComparisonData>(clearData);
  const [childData, setChildData] = useState<ComparisonData>(clearData);

  useEffect(() => {
    setParentData(clearData);
    setChildData(clearData);
  }, [selectedData]);

  const handleCompare = () => {
    setShowDocumentComparison(true);
  };

  const handleCheckboxChange = (selectedCheckboxData: any, isChecked: boolean) => {
    const formattedData = {
      date: formatDate(selectedCheckboxData?.approval_date)?.replace(',', ''),
      type: selectedData?.type,
      brandName: selectedCheckboxData?.trade_name,
      source: 'eu',
      signedUrl: selectedCheckboxData?.s3_url,
      fileName: selectedCheckboxData?.file_name
    };
    if (isChecked) {
      if (isEmptyObject(parentData)) {
        setParentData(formattedData);
      } else if (isEmptyObject(childData)) {
        setChildData(formattedData);
      }
    } else if (isEqual(formattedData, parentData)) {
      setParentData(clearData);
    } else if (isEqual(formattedData, childData)) {
      setChildData(clearData);
    }
  };

  const isCompareDisabled = isEmptyObject(parentData) || isEmptyObject(childData);
  const isChecked = (date: string, fileName: string) => {
    return [
      `${parentData?.date}-${parentData?.fileName}`,
      `${childData?.date}-${childData?.fileName}`
    ]?.includes(`${formatDate(date)?.replace(',', '')}-${fileName}`);
  };

  return (
    <>
      <Box sx={styles.timelineLeftDrawer.container}>
        <Box height={60} sx={styles.timelineLeftDrawer.innerContainer}>
          <Typography
            sx={styles.common.headerTitle}>{`${selectedData?.data[0]?.trade_name?.toUpperCase()} ${
            selectedData?.type
          }`}</Typography>
          <Button onClick={handleClose} sx={styles.timelineLeftDrawer.closeButton}>
            <CloseIcon
              sx={{
                fontSize: 27.45,
                color: 'gray.0'
              }}
            />
          </Button>
        </Box>
        <Stack direction='row-reverse' mr={2}>
          <Button
            onClick={() => !isCompareDisabled && handleCompare()}
            disabled={isCompareDisabled}
            startIcon={<ComparisonIcon sx={{ fontSize: 16 }} />}
            sx={{
              ...styles.timelineLeftDrawer.compareButton,
              color: isCompareDisabled ? 'gray.500' : 'primary.700',
              borderColor: isCompareDisabled ? 'gray.500' : 'primary.700'
            }}>
            Compare
          </Button>
        </Stack>
        <Box>
          {selectedData?.data?.map((each: any, index: number) => (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              display='flex'
              alignItems='center'>
              <Checkbox
                onChange={(event: any) => handleCheckboxChange(each, event.target.checked)}
                checked={isChecked(each?.approval_date, each?.file_name)}
                value={isChecked(each?.approval_date, each?.file_name)}
                size='small'
                disabled={
                  !isEmptyObject(parentData) &&
                  !isEmptyObject(childData) &&
                  !isChecked(each?.approval_date, each?.file_name)
                }
                checkedIcon={
                  <CheckboxCheckedIcon
                    sx={{
                      color: 'primary.600',
                      fontSize: 14
                    }}
                  />
                }
                icon={<CheckboxUncheckedIcon sx={{ fontSize: 14 }} />}
                sx={{
                  '&.Mui-disabled': {
                    color: 'grey.400'
                  }
                }}
              />
              <Typography
                onClick={event => event.stopPropagation()}
                sx={{ fontSize: 14, color: 'gray.800' }}>{`${formatDate(
                each?.approval_date
              )?.replace(',', '')}-${selectedData?.type?.slice(
                0,
                -1
              )} ${each?.trade_name?.toUpperCase()}`}</Typography>
            </Box>
          ))}
        </Box>
      </Box>
      {showDocumentComparison && (
        <DocumentComparison
          showDocumentComparison={showDocumentComparison}
          setShowDocumentComparison={setShowDocumentComparison}
          parentData={parentData}
          setParentData={setParentData}
          childData={childData}
          setChildData={setChildData}
        />
      )}
    </>
  );
};

export default React.memo(TimelineLeftDrawer);
