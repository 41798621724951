const styles = {
  drawerPaperPropsStyle: {
    height: '98vh',
    width: '100vw',
    backgroundColor: 'white.main',
    maxWidth: 'none',
    maxHeight: 'none',
    borderRadius: '8px 8px 0px 0px'
  },
  headerWrapper: {
    bgcolor: 'primary.600',
    alignSelf: 'stretch',
    p: '12px 24px',
    borderRadius: '8px 8px 0px 0px'
  },
  guidanceComparisonContainer: { flexGrow: 1, textAlign: 'center', alignSelf: 'center' },
  guidanceComparisonText: {
    fontSize: 20,
    fontWeight: 700,
    color: 'white.main',
    fontFamily: 'Mulish',
    lineHeight: 'normal'
  },
  iconButton: { position: 'absolute', display: 'flex', left: '97.25%', p: 0 },
  cancelIcon: { height: '24px', width: '24px', color: 'white.main' }
};

export default styles;
