import React, { lazy, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Drawer, ThemeProvider } from '@mui/material';
import themev5 from '../../themev5';
import useReportingHandler from './hooks/useReportingHandler';
import styles from './styles/reportingDrawer.styles';
import CreateNewReportForm from './components/CreateNewReportForm';

const PdfLayout = lazy(() => import('./Layout'));

const ReportingDrawer = ({ isDrawerOpen, closeDrawer, isNavBar = false }: any) => {
  const { isLoading, activeReportData, fetchData } = useReportingHandler();
  const location = useLocation();
  const history = useHistory();
  const updatedSearchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  const [openCreateNewReportDialog, setOpenCreateNewReportDialog] = useState<boolean>(false);
  useEffect(() => {
    if (isNavBar) {
      fetchData();
    }
  }, [isNavBar]);

  useEffect(() => {
    if (isNavBar) {
      if (isLoading) {
        setOpenCreateNewReportDialog(false);
      } else if (isNavBar && activeReportData && Object.keys(activeReportData)?.length !== 0) {
        updatedSearchParams.set('id', activeReportData?.id?.toString());
        history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
      } else {
        setOpenCreateNewReportDialog(true);
      }
    }
  }, [activeReportData, isNavBar, isLoading]);

  const handleSubmit = (reportDetails: any) => {
    updatedSearchParams.set('id', reportDetails?.id?.toString());
    history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
    setOpenCreateNewReportDialog(false);
  };

  return (
    <ThemeProvider theme={themev5}>
      <Drawer sx={styles.drawer} anchor='bottom' open={isDrawerOpen}>
        <PdfLayout isDrawerComponent closeDrawer={closeDrawer} />
      </Drawer>
      {openCreateNewReportDialog && (
        <CreateNewReportForm
          openCreateNewReportDialog={openCreateNewReportDialog}
          setOpenCreateNewReportDialog={setOpenCreateNewReportDialog}
          handleSubmit={handleSubmit}
          closeDrawer={closeDrawer}
        />
      )}
    </ThemeProvider>
  );
};

export default ReportingDrawer;
