import Box from '@mui/material/Box';
import React, { useContext, useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

import { Tooltip } from '@material-ui/core';
import useDocuments from '../../hooks/useDocuments';
import PDFViewer from '../../../../components/PDFViewer';

import styles from '../../styles/CTDocument';
import SendIcon from '../../../../Images/svgs/sendIcon';
import { ArrowDownIcon, CustomCheckIcon } from '../../../../assets/svgs/Icons';

// store
import GlobalStore from '../../../../store';
import GlobalActions from '../../../../store/actions';
import RoundedMenu from '../../../../components/ui/Menu/RoundedMenu';
import MenuItemWithSubMenu from '../../../../components/ui/Menu/MenuItemWithSubMenu/MenuItemWithSubMenu';
import MenuWithTickOptions from '../../../../components/ui/Menu/MenuWithTickOptions';
import getDateString from '../../../../utils/getDateString';

const FDADocuments: React.FC<any> = ({
  applicationNumber,
  submission,
  onClose,
  applicationDetails,
  source = 'us',
  approvalDate = ''
}: any) => {
  const { documents, isLoading, getDocuments } = useDocuments(
    applicationNumber,
    submission,
    source,
    approvalDate
  );
  const [selectedDocumentCategory, setDocumentCategory] = useState<any>();
  const [selectedDocument, setDocument] = useState<any>();
  const [anchorEl, setAnchorElement] = useState<any>();
  const { dispatch } = useContext(GlobalStore) as any;

  useEffect(() => {
    setDocumentCategory(null);
    getDocuments();
  }, [applicationNumber, submission]);

  useEffect(() => {
    if ('Labels' in documents) {
      setDocumentCategory('Labels');
      if (documents.Labels?.[0]) {
        setDocument(documents.Labels?.[0]);
      }
    } else {
      const categories = Object.keys(documents || {});
      if (categories.length > 0) {
        const category = categories[0];
        setDocumentCategory(category);
        setDocument(documents[category]?.[0]);
      }
    }
  }, [documents]);

  const handleChatRia = (pdfUrl = '') => {
    const metadata = {
      active_ingredient: `${applicationDetails?.generic_name || ''} (${
        applicationDetails?.product_name || ''
      })`,
      approval_date: applicationDetails.approval_date ?? '',
      application_number: applicationNumber,
      submission_id: submission,
      category: selectedDocumentCategory
    };
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: source
    });
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: metadata,
        source,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
  };
  const title =
    source === 'us'
      ? `${applicationNumber}-${submission}`
      : `${applicationNumber} - ${getDateString(approvalDate)}`;
  return (
    <Box height='100%' sx={styles.pdfRoot}>
      <Typography textAlign='start' fontWeight='bold' paddingY={1} fontSize={14}>
        {title}
      </Typography>
      <Divider sx={{ borderColor: 'gray.200' }} />
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={styles.pdfHeader}>
        <Stack direction='row' spacing={1} justifyContent='start' alignItems='center'>
          {!isLoading && (
            <>
              <Typography component='span' fontSize={14} color='gray.600'>
                Document Type:
              </Typography>
              <Button
                endIcon={<ArrowDownIcon />}
                onClick={event => setAnchorElement(event.currentTarget)}
                sx={styles.docTypeButton}>
                {selectedDocumentCategory}
              </Button>
            </>
          )}
          <RoundedMenu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorElement(null)}>
            {Object.keys(documents).map((category: any) => (
              <MenuItemWithSubMenu
                MenuItemText={category}
                key={category}
                MenuItemIcon={
                  <CustomCheckIcon
                    sx={{
                      color: 'gray.750',
                      height: '14px',
                      width: '14px',
                      opacity: selectedDocumentCategory === category ? 1 : 0
                    }}
                  />
                }>
                <MenuWithTickOptions
                  options={documents[category]?.map(({ _category, s3_path: s3Path }: any) => ({
                    label: _category,
                    value: s3Path,
                    // eslint-disable-next-line no-underscore-dangle
                    selected: _category === selectedDocument?._category
                  }))}
                  onOptionSelect={(opt: any) => {
                    setDocument({
                      s3_path: opt.value,
                      _category: opt.label
                    });
                    setDocumentCategory(category);
                    setAnchorElement(null);
                  }}
                  isNestedMenu
                />
              </MenuItemWithSubMenu>
            ))}
          </RoundedMenu>
        </Stack>
        <Box display='flex'>
          <Tooltip title='Use Chat RIA to interact with the document'>
            <Button
              variant='text'
              disabled={selectedDocument && !selectedDocument.s3_path}
              onClick={() => {
                handleChatRia(selectedDocument.s3_path);
              }}
              sx={styles.chatRiaButton}>
              Chat RIA
              <SendIcon
                sx={{ width: '16px', height: '16px', p: '2.667px 2.666px 2.667px 2.667px' }}
              />
            </Button>
          </Tooltip>
        </Box>
      </Stack>
      <Box position='absolute' top={0} right={0}>
        <CloseIcon onClick={onClose} sx={styles.closeBtn} />
      </Box>
      {isLoading && (
        <Box sx={styles.loadingIcon}>
          <CircularProgress />
        </Box>
      )}

      <Box height='90%'>
        {selectedDocument && <PDFViewer fileUrl={selectedDocument.s3_path} defaultScale={1.35} />}
      </Box>
    </Box>
  );
};

export default React.memo(FDADocuments);
