import React from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import styles from '../../styles/SectionFlyout.styles';
import renderSubsections from './renderSubsections';
import { findSectionByValue } from '../../utils';

const Section = ({
  sectionKey,
  subsections,
  expandedSections,
  toggleSection,
  expandedSubsections,
  toggleSubsection,
  handleSectionClick,
  activeSection,
  sectionsKeyCount,
  getTextColor,
  mainSectionCount,
  subSectionCount
}: any) =>
  mainSectionCount[sectionKey] ? (
    <Box sx={styles.sectionWrapper}>
      <Box
        sx={{
          ...styles.sectionHeaderWrapper,
          '&:hover': {
            cursor: 'pointer'
          }
        }}
        onClick={() => toggleSection(sectionKey)}>
        <IconButton sx={{ padding: 0 }} size='small'>
          {expandedSections[sectionKey] ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
        </IconButton>
        <Typography
          sx={{
            ...styles.sectionKeyText,
            color:
              findSectionByValue(activeSection) === `${sectionKey}` ? 'primary.600' : 'gray.800'
          }}>
          {`${sectionKey} (${mainSectionCount[sectionKey]})`}
        </Typography>
      </Box>
      {expandedSections[sectionKey] && (
        <Box sx={styles.subSectionWrapper}>
          {renderSubsections({
            subsections,
            expandedSubsections,
            toggleSubsection,
            handleSectionClick,
            activeSection,
            sectionsKeyCount,
            getTextColor,
            sectionKey,
            subSectionCount
          })}
        </Box>
      )}
    </Box>
  ) : (
    <></>
  );

const renderSections = ({
  sections,
  expandedSections,
  toggleSection,
  expandedSubsections,
  toggleSubsection,
  handleSectionClick,
  activeSection,
  sectionsKeyCount,
  getTextColor,
  subSectionCount,
  mainSectionCount
}: any) => {
  return Object.entries(sections).map(([sectionKey, subsections]: any) => (
    <Section
      key={sectionKey}
      sectionKey={sectionKey}
      subsections={subsections}
      expandedSections={expandedSections}
      toggleSection={toggleSection}
      expandedSubsections={expandedSubsections}
      toggleSubsection={toggleSubsection}
      handleSectionClick={handleSectionClick}
      activeSection={activeSection}
      sectionsKeyCount={sectionsKeyCount}
      getTextColor={getTextColor}
      subSectionCount={subSectionCount}
      mainSectionCount={mainSectionCount}
    />
  ));
};

export default renderSections;
