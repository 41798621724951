const styles = {
  dialogPaper: {
    borderRadius: '8px'
  },
  dialogWrapper: {
    background: 'rgba(56, 56, 56, 0.40)'
  },
  dialogContent: { padding: '16px 16px 32px 16px !important' },
  titleBox: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch'
  },
  closeButtonBox: {
    position: 'absolute',
    right: '12px',
    top: '12px'
  },

  closeButtonIcon: {
    width: '20px',
    height: '20px'
  },
  contentWrapper: {
    textAlign: 'center',
    alignItems: 'center'
  },
  titleText: {
    color: 'gray.800',
    fontSize: '20px',
    fontWeight: 700,
    flexGrow: 1,
    textAlign: 'center',
    alignSelf: 'center'
  },
  textSpan1: { fontSize: '14px' },
  textSpan2: { fontStyle: 'italic', mt: '8px' },
  finalKeywordsQuery: {
    fontSize: '16px',
    fontWeight: 600,
    color: 'primary.600',
    textAlign: 'center',
    my: '20px'
  },
  divider: {
    borderWidth: '2px',
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: '2px',
    mt: '10px',
    mb: '16px',
    width: '64px'
  },
  subtitleText: {
    fontSize: '14px',
    fontWeight: 400,
    color: 'gray.900',
    textAlign: 'center',
    mx: '16px'
  },
  infoIcon: {
    width: '14px',
    height: '14px',
    color: 'gray.700'
  },
  subscribeButton: {
    color: 'white.main',
    textTransform: 'none',
    borderRadius: '24px',
    backgroundColor: 'primary.600',
    height: '36px',
    minWidth: '100px',
    boxShadow: 'none',
    paddingX: '24px',
    fontSize: '14px',
    fontWeight: 700
  },
  skeletonBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    py: '20px'
  }
};

export default styles;
