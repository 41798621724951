/* eslint-disable react/require-default-props */
import React, { memo, useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import { isEmpty } from 'lodash';
import { useParams } from 'react-router-dom';
import { Virtuoso } from 'react-virtuoso';

import { Box, Divider, Grid, Stack, Typography } from '@mui/material';

import ResultsStore from '../../../store/SearchResults';
import ResultsActions from '../../../store/SearchResults/actions';
import useChatRIAConfig from '../../../components/ChatRIA/hooks/useChatRIAConfig';
import DocumentsViewTitle from './DocumentsViewTitle';
import SourceDropDown from '../../Home/components/SourceDropDown';

import { prepareDocumentCard, scrollToResult } from '../utils/documentResultsUtils';
import { SOURCE_DROPDOWN_TYPES, SOURCE_MENU_ITEMS } from '../../Home/const';

import useDocumentsViewFunctions from '../hooks/useDocumentsViewFunctions';

import { EMPTY_RESULTS_MESSAGES } from '../constants';
import Loading from './Loading';
import EmptyResult from './EmptyResult';
import DocumentRightGrid from './DocumentRightGrid';
import DidYouMeanText from './DidYouMeanText';
import useUpdateModule from '../../../hooks/useUpdateModule';
import DocumentsDownloadButton from '../Buttons/DocumentsDownloadButton';
import ShareButton from '../Buttons/ShareButton';
import applicationCardStyles from '../styles/ApplicationCard.styles';
import DocumentsReviewMenu from './DocumentsReviewMenu';
import FavoriteAndProjectActions from '../../../components/FavoritesAndProjectsAction/FavoriteAndProjectActions';
import RESULT_VIEW_TYPES from './constants';
import SearchSuggestions from './SearchSuggestions';
import Item from './DocumentViewItem';
import Footer from './DocumentViewFooter';
import SubscriptionActions from '../../../components/Subscription/SubscriptionActions';

// eslint-disable-next-line react/require-default-props
const DocumentsView = ({
  showSourceDropdown = true,
  onDidYouMeanTextClick,
  renderedInPopup = false,
  handleAriaShare
}: {
  showSourceDropdown?: boolean; // eslint-disable-next-line no-unused-vars
  onDidYouMeanTextClick?: (param: any) => void;
  renderedInPopup?: boolean; // eslint-disable-next-line no-unused-vars
  handleAriaShare?: (param: any) => void;
}) => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const { payload }: any = useParams();
  const virtualListRef = useRef(null);
  useUpdateModule('documents');
  const { deleteAllChatRIAConfig } = useChatRIAConfig();
  const {
    handleResultClick,
    getDocumentResultTitle,
    sourceName,
    exploreSelectedFilters,
    setExploreSelectedFilters,
    handleShareClick,
    handleSortBy,
    selectedSort,
    handleFavoriteClick,
    handleProjectClick,
    handleUniqueResultsToggle,
    handleSourceChange,
    makeSearch,
    selectedSources,
    sourceDropDownAnchorEl,
    setSourceDropDownAnchorEl,
    handleDidYouMeanTextClick,
    handleAriaResponseClick,
    paginatedResults,
    loadMore,
    loading,
    fetchedCount,
    totalCount,
    setPaginatedResults,
    filteredMemoResults,
    setTotalCount,
    setFetchedCount
  } = useDocumentsViewFunctions();
  useEffect(() => {
    if (!isEmpty(resultsState.documentResults?.results)) {
      setPaginatedResults(() =>
        filteredMemoResults(
          resultsState.documentResults?.results,
          resultsState.showUniqueResults.enabled
        )
      );
    }
    setTotalCount(resultsState?.documentResults?.total_results || 0);
    setFetchedCount(resultsState?.documentResults?.results?.length || 0);
  }, [
    exploreSelectedFilters,
    resultsState.documentResults?.results,
    resultsState.showUniqueResults.enabled,
    selectedSort
  ]);
  useEffect(() => {
    getDocumentResultTitle();
    return () => {
      deleteAllChatRIAConfig();
    };
  }, [resultsState.documentResults]);

  useEffect(() => {
    const ariaResponseResultId = resultsState.selectedAriaResponse?.resultId;
    if (!ariaResponseResultId) return;
    if (renderedInPopup) {
      if (virtualListRef?.current) {
        const indexOfData = paginatedResults.findIndex(
          (data: any) => data.result_id === ariaResponseResultId
        );
        // @ts-ignore
        virtualListRef?.current?.scrollToIndex({
          index: indexOfData,
          behavior: 'smooth'
        });
      }
    } else {
      scrollToResult(ariaResponseResultId, paginatedResults);
    }
  }, [resultsState.selectedAriaResponse]);

  useEffect(() => {
    const selectedDocumentResultId = resultsState.scrollResultId?.resultId;
    if (!selectedDocumentResultId) return;
    if (renderedInPopup) {
      if (virtualListRef?.current) {
        const indexOfData = paginatedResults.findIndex(
          (data: any) => data.result_id === selectedDocumentResultId
        );
        // @ts-ignore
        virtualListRef?.current?.scrollToIndex({
          index: indexOfData,
          behavior: 'smooth'
        });
      }
    } else {
      scrollToResult(selectedDocumentResultId, paginatedResults);
    }
  }, [resultsState.scrollResultId]);

  useEffect(() => {
    setTimeout(() => {
      // @ts-ignore
      virtualListRef?.current?.scrollToIndex({
        index: 0,
        behavior: 'smooth',
        align: 'start',
        offset: -200
      });
    }, 500);
  }, [exploreSelectedFilters, resultsState.showUniqueResults.enabled, selectedSort]);

  const didYouMeantext = resultsState.documentResults.did_you_mean;

  const getSearchId = useCallback((): string => {
    switch (resultsState?.viewType) {
      case RESULT_VIEW_TYPES.APPLICATION:
        return resultsState?.searchIds?.applicationSearchId;
      case RESULT_VIEW_TYPES.DOCUMENT:
        return resultsState?.searchIds?.documentSearchId;
      case RESULT_VIEW_TYPES.CT:
        return resultsState?.searchIds?.ctSearchId;
      default:
        return '';
    }
  }, [resultsState?.viewType, resultsState?.searchIds]);

  const favoriteInvokedBy = useMemo(() => {
    if (resultsState.decryptedPayload?.searchType === 'advanced') {
      return 'advancedSearch';
    }
    if (
      resultsState.viewType === RESULT_VIEW_TYPES.APPLICATION ||
      resultsState.viewType === RESULT_VIEW_TYPES.CT
    ) {
      return 'applicationSearch';
    }
    if (resultsState.viewType === RESULT_VIEW_TYPES.DOCUMENT) {
      return 'documentSearch';
    }

    return 'search';
  }, [resultsState.decryptedPayload, resultsState.viewType]);

  if (resultsState?.isContentLoading) {
    return <Loading />;
  }
  /**
   * Checks if the provided object is considered empty based on custom criteria.
   * The object is considered empty if it is an empty object ({}) or if the 'total_results' property is 0.
   *
   * @param {any} obj - The object to check for emptiness.
   * @returns {boolean} - True if the object is considered empty, false otherwise.
   */
  const isCustomEmpty = (obj: any): boolean => isEmpty(obj) || obj?.total_results === 0;

  // If there are no applications and no filters applied, show search suggestions if available
  if (
    isCustomEmpty(resultsState.documentResults) &&
    isEmpty(resultsState.filters) &&
    resultsState?.searchSuggestions?.length > 0
  ) {
    return <SearchSuggestions />;
  }

  if (isCustomEmpty(resultsState.documentResults)) {
    return (
      <EmptyResult
        firstMessage={
          Object.keys(resultsState.filters)?.length > 0
            ? EMPTY_RESULTS_MESSAGES.filterEmptyMessage.firstMessage
            : EMPTY_RESULTS_MESSAGES.GeneralSearchEmptyMessage.firstMessage
        }
        secondMessage={
          Object.keys(resultsState.filters)?.length > 0
            ? EMPTY_RESULTS_MESSAGES.filterEmptyMessage.secondMessage
            : EMPTY_RESULTS_MESSAGES.GeneralSearchEmptyMessage.secondMessage
        }
        actionText={
          didYouMeantext && (
            <Box>
              <DidYouMeanText
                text={didYouMeantext}
                onClick={onDidYouMeanTextClick || handleDidYouMeanTextClick}
                fontStyle={{ fontSize: 16 }}
              />
            </Box>
          )
        }
      />
    );
  }
  const getAriaResponse = () => {
    if (resultsState.showTop10Summary) {
      return null;
    }

    if (Object.keys(resultsState.selectedAriaResponse).length === 0) {
      return prepareDocumentCard(resultsState.documentResults.results[0]);
    }

    return resultsState.selectedAriaResponse;
  };
  const itemContent = (index: number, data: any, context: any) => (
    <Item index={index} data={data} context={context} />
  );

  return (
    <Stack
      sx={{
        ml: 2,
        mr: 2,
        overflowY: 'hidden'
      }}>
      <Stack
        direction='row'
        justifyContent='space-between'
        alignItems='center'
        sx={{
          zIndex: 2,
          width: '94%',
          backgroundColor: 'white !important'
        }}
        position='fixed'>
        <Stack direction='row' spacing={1} alignItems='center'>
          {payload && showSourceDropdown && (
            <SourceDropDown
              selectedSources={selectedSources}
              handleChange={handleSourceChange}
              options={SOURCE_MENU_ITEMS}
              anchorEl={sourceDropDownAnchorEl}
              setAnchorEl={setSourceDropDownAnchorEl}
              type={SOURCE_DROPDOWN_TYPES.DOCUMENTS_VIEW}
              miniminumOneSelected
              showApplyButton
              makeSearch={makeSearch}
            />
          )}
          {payload && showSourceDropdown && (
            <Typography variant='subtitle1' sx={{ color: 'gray.contrastText' }}>
              |
            </Typography>
          )}
          <DocumentsViewTitle
            sourceName={sourceName}
            totalCount={paginatedResults.length}
            overallCount={totalCount}
          />
        </Stack>
        <Stack direction='row' justifyContent='flex-end'>
          <Stack direction='row' alignItems='center'>
            <DocumentsReviewMenu
              handleSortBy={handleSortBy}
              selectedSort={selectedSort}
              handleUniqueResultsToggle={handleUniqueResultsToggle}
            />
            <Divider orientation='vertical' sx={applicationCardStyles.verticalDivider} />
            {!renderedInPopup && (
              <SubscriptionActions
                invokedBy='document_search'
                source=''
                id={getSearchId()}
                isButton
                isSubscribed={resultsState?.isSubscribed ?? false}
                setSubscriptionInHook={({ isSubscribed }: { isSubscribed: boolean }) => {
                  resultsDispatch({ type: ResultsActions.SET_IS_SUBSCRIBED, value: isSubscribed });
                }}
                documentSearchQuery={resultsState.decryptedPayload?.query ?? ''}
              />
            )}
            {!renderedInPopup && (
              <FavoriteAndProjectActions
                invokedBy={favoriteInvokedBy}
                source=''
                id={getSearchId()}
                isButton
                isFavorite={resultsState?.isFavorite ?? false}
                inProjects={resultsState?.inProjects ?? []}
                setFavoriteInHook={({ isFavorite }: { isFavorite: boolean }) => {
                  resultsDispatch({ type: ResultsActions.SET_IS_FAVORITE, value: isFavorite });
                }}
                setInProjectInHook={({ project }: { project: any }) => {
                  let projects = [];
                  if (project?.inProject) {
                    projects = resultsState?.inProjects.filter(
                      (p: any) => (p?.project_id ?? p?.id) !== (project?.project_id ?? project?.id)
                    );
                  } else {
                    projects = [
                      ...(resultsState?.inProjects ?? []),
                      {
                        id: project?.project_id ?? project?.id,
                        name: project?.name
                      }
                    ];
                  }
                  resultsDispatch({ type: ResultsActions.SET_IN_PROJECTS, value: projects });
                }}
              />
            )}
            <DocumentsDownloadButton />
            {renderedInPopup ? (
              <ShareButton
                searchType={resultsState.decryptedPayload?.searchType ?? 'aria'}
                search={resultsState.decryptedPayload?.query}
                handleAriaShare={handleAriaShare}
              />
            ) : (
              <ShareButton
                searchType={resultsState.decryptedPayload?.searchType ?? ''}
                search={resultsState.decryptedPayload?.query}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
      {didYouMeantext && (
        <Box ml={1}>
          <DidYouMeanText
            text={didYouMeantext}
            onClick={onDidYouMeanTextClick || handleDidYouMeanTextClick}
          />
        </Box>
      )}
      <Box id='scroll-to-top' />
      <Grid
        maxWidth='lg-md'
        m='auto'
        id={renderedInPopup ? 'scroll-container-drawer' : 'scroll-container'}
        container
        mt={5}
        sx={
          renderedInPopup
            ? {
                height: '80vh',
                overflowY: 'auto'
              }
            : {
                mb: 10
              }
        }>
        <Grid
          item
          xs={resultsState.selectedDocument || resultsState.documentLoader ? 6 : 7}
          sx={{ bgcolor: 'white.main' }}>
          <Virtuoso
            ref={virtualListRef}
            overscan={200}
            useWindowScroll={!renderedInPopup}
            data={paginatedResults}
            totalCount={totalCount}
            components={{ Footer }}
            context={{
              isLoading: loading,
              loadMoreData: loadMore,
              paginatedResultsCount: fetchedCount,
              totalCount,
              fetchedCount,
              resultsState,
              handleShareClick,
              handleFavoriteClick,
              handleProjectClick,
              handleResultClick,
              handleAriaResponseClick,
              getAriaResponse
            }}
            itemContent={itemContent}
          />
        </Grid>
        {/* required to match spacing in wireframe */}
        {!resultsState?.selectedDocument && !resultsState.documentLoader ? (
          <Grid item xs={1} />
        ) : null}

        <Grid
          item
          id='pdf-viewer-container'
          xs={resultsState.documentLoader || resultsState?.selectedDocument ? 6 : 4}>
          <DocumentRightGrid
            exploreSelectedFilters={exploreSelectedFilters}
            setExploreSelectedFilters={setExploreSelectedFilters}
            paginatedData={paginatedResults}
          />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default memo(DocumentsView);
