import React, { memo, useCallback, useContext, useMemo, useState } from 'react';
import { ListItemText, MenuItem, Tooltip, Typography } from '@mui/material';
import { getFileName } from '../../../helpers/getFileName';
import { DATAGRID_UNIQUE_FIELD } from '../../ResultsPage/utils/constants';
import { RESTRICT_DOWNLOADS_BY_ORGANIZATION } from '../../../const';
import GlobalActions from '../../../store/actions';

import AuthContext from '../../../store/Auth/AuthProvider';
import ResultsStore from '../../../store/SearchResults';
import GlobalStore from '../../../store';
import { RoundedMenu } from '../../../components/ui/Menu';
import DownloadLoader from '../components/DownloadLoader';
import useSnapshotExcel from '../../RegulatoryInformation/useSnapshotExcel';

export const sourceMapping: any = {
  us: {
    label: 'US FDA - Drugs & Biologics',
    value: 'us'
  }
};

const SnapshotDownload = ({ handleClose }: any) => {
  const { currentUser } = useContext(AuthContext);
  const [source, setSource] = useState('us');
  const { resultsState } = useContext(ResultsStore);
  const { dispatch } = useContext(GlobalStore) as any;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [count, setCount] = useState<number>(0);

  const [openSourceList, setOpenSourceList] = useState<boolean>(false);

  const [donePageDataLoadingAnimation, setDonePageDataLoadingAnimation] = useState(true);

  const { loader, errorMsg, setLoader, setErrorMsg, getSnapshotExcel, cancelRequest } =
    useSnapshotExcel();

  const getSearchTerm = useCallback(() => {
    if (Object.keys(resultsState.decryptedPayload)) {
      if ('query' in resultsState.decryptedPayload) {
        return resultsState.decryptedPayload.query;
      }
    }
    return '';
  }, [resultsState.decryptedPayload]);

  const getSnapshotData = useCallback(
    async (sourceValue: string) => {
      if (RESTRICT_DOWNLOADS_BY_ORGANIZATION.includes(currentUser['custom:organization'])) {
        dispatch({
          type: GlobalActions.SET_SERVICE_RESTRICTION_DETAILS,
          value: { message: '', closeBtn: true, status: true }
        });
        return;
      }
      try {
        const payload: any = [];
        const day = new Date();
        const id = DATAGRID_UNIQUE_FIELD[sourceValue?.toLowerCase() || 'us'];
        if (sourceValue?.toLowerCase() === 'us') {
          resultsState.applicationResults?.us.results.forEach((appl: any) => {
            if (payload?.length < 50) {
              if (appl?.application_type !== 'EUA') {
                payload.push(appl[id]);
              }
            }
          });
        }
        setCount(payload.length);
        if (
          resultsState.applicationResults[sourceValue]?.results?.length > 50 ||
          resultsState.ctResults?.results?.length > 50
        ) {
          await dispatch({
            type: GlobalActions.SET_ALERT,
            value: {
              status: true,
              message: `Download Clinical Summary Snapshot is limited to top 50 applications for a better experience.
                      Please filter to less than 50 if interested in specific applications.`,
              color: 'warning',
              duration: 5000
            }
          });
        }
        setLoader(true);
        setDonePageDataLoadingAnimation(false);
        const fileName = getFileName({
          prefix: `${getSearchTerm()}`,
          module: `${sourceValue}`,
          postfix: `${day.getMonth() + 1}-${day.getDate()}-${day.getFullYear()}`,
          additional: '_clinical_summary_snapshot',
          extension: 'xlsx'
        });
        await getSnapshotExcel(payload, fileName);
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        setErrorMsg(true);
      }
    },
    [resultsState.applicationResults, resultsState.ctResults]
  );

  const handleCancelCall = useCallback(async () => {
    setDonePageDataLoadingAnimation(true);
    cancelRequest();
  }, [cancelRequest]);

  const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpenSourceList(true);
  }, []);

  const handleSourceClick = useCallback(
    (sourceValue: string) => {
      setSource(sourceValue);
      if (
        !loader &&
        (Object.keys(resultsState.applicationResults).length !== 0 ||
          resultsState.ctResults?.results?.length !== 0)
      ) {
        setErrorMsg(false);
        getSnapshotData(sourceValue);
      }
    },
    [loader, resultsState.applicationResults, resultsState.ctResults]
  );

  const handleLoadingAnimationComplete = () => {
    setDonePageDataLoadingAnimation(true);
    handleClose();
  };

  const sourceList = useMemo(() => {
    const list: any = [];
    const appldata: any = resultsState.applicationResults;
    if (Object.keys(appldata).length !== 0) {
      Object.keys(appldata).forEach((item: string) => {
        if (item in sourceMapping) {
          if (item === 'us') {
            const applResult = resultsState.applicationResults?.us?.results ?? [];
            const isSbasExists = applResult.some(
              (obj: { application_type: string }) => obj.application_type !== 'EUA'
            );
            if (isSbasExists) {
              list.push({
                label: sourceMapping[item].label,
                value: sourceMapping[item].value
              });
            }
          } else {
            list.push({
              label: sourceMapping[item].label,
              value: sourceMapping[item].value
            });
          }
        }
      });
    }
    return list;
  }, [resultsState.applicationResults]);

  return (
    <>
      {sourceList.length > 0 ? (
        <Tooltip
          title='Download Clinical Summary Snapshot (ORIG only) as an Excel (.xlsx) file'
          placement='left'>
          <MenuItem onClick={handleClick}>
            <ListItemText primary='Clinical Summary Snapshot (ORIG only)' />
          </MenuItem>
        </Tooltip>
      ) : null}

      <RoundedMenu
        open={openSourceList}
        anchorEl={anchorEl}
        onClose={() => {
          setOpenSourceList(false);
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        sx={{
          '& .MuiPaper-root': { padding: '8px 0px !important' },
          '& .MuiList-root': { padding: '0 !important' }
        }}>
        <MenuItem disabled sx={{ p: '6px 24px' }}>
          <ListItemText>
            <Typography sx={{ color: 'gray.800', fontSize: 12, fontWeight: 400 }}>
              Select Source:
            </Typography>
          </ListItemText>
        </MenuItem>
        {sourceList.map((item: any) => (
          <MenuItem
            key={item.value}
            onClick={() => {
              handleSourceClick(item.value);
            }}
            sx={{ p: '6px 24px' }}>
            {item.label}
          </MenuItem>
        ))}
      </RoundedMenu>
      {!donePageDataLoadingAnimation && (
        <DownloadLoader
          progressMessage={`Please be patient. It may take a few seconds to compile Clinical Summary
                  of ${count} applications.`}
          downloadSuccessMessage='Clinical Summary file has been downloaded successfully'
          onRetry={() => {
            setErrorMsg(false);
            getSnapshotData(source);
          }}
          onCancel={handleCancelCall}
          loader={loader}
          onDoneAnimation={handleLoadingAnimationComplete}
          errorMsg={errorMsg}
          onEndLoading={() => {
            handleCancelCall();
            setDonePageDataLoadingAnimation(true);
            setLoader(false);
          }}
          onDoneClick={() => {
            handleLoadingAnimationComplete();
            setLoader(false);
          }}
        />
      )}
    </>
  );
};

export default memo(SnapshotDownload);
