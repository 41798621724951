import React from 'react';

// mui v5
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import themev5 from '../../themev5';

// components
import CHMPHome from './components/Home';

// store
import useUpdateModule from '../../hooks/useUpdateModule';

const Chmp = () => {
  useUpdateModule('CHMP');

  return (
    <ThemeProvider theme={themev5}>
      <CssBaseline />
      <CHMPHome />
    </ThemeProvider>
  );
};

export default React.memo(Chmp);
