/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import React, { useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { groupBy, sortBy } from 'lodash';

// styles
import styles from '../styles/DocumentHistory.styles';

// Icons
import { getDocumentSourceName } from '../../SearchResults/utils/documentResultsUtils';
import FolderIcon from '../../../assets/svgs/Icons/FolderIcon';
import { getEMAGuidanceDocumentsSignedURL } from '../../../api/pages/EMAGuidance';
import { DocumentItem, DocumentsGroup } from '../../../components/Dialogs/DocumentListing/types';
import DocumentListing from '../../../components/Dialogs/DocumentListing';
import getDateString from '../../../utils/getDateString';

const GridCellDocuments: React.FC<any> = ({ data }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenPopup = () => {
    setOpen(true);
  };

  const getPDFUrlFromS3Path = async (pdfLink: string) => {
    const res: any = await getEMAGuidanceDocumentsSignedURL({
      source: 'ema_guidance',
      s3_path: pdfLink
      // eslint-disable-next-line no-console
    }).catch(err => console.error(err));
    return res?.data?.body?.s3_url;
  };

  const { documents, documentCount }: { documents: DocumentsGroup[]; documentCount: number } =
    useMemo(() => {
      let documentCount = 0;
      const allGroups: DocumentsGroup[] = [];
      const documentGroups = groupBy(data.document_history, 'document_type');

      Object.keys(documentGroups).forEach((groupName: string) => {
        const docs: DocumentItem[] = documentGroups[groupName].map(
          ({
            doc_title: title,
            s3_path: pdfUrl,
            ema_document_no: docNumber,
            first_published_date: publishedDate,
            status,
            legal_effective_date: effectiveDate,
            consultation_date_from: consultationDateFrom,
            consultation_date_to: consultationDateTo
          }: any) => {
            documentCount += 1;
            return {
              title,
              title_link: pdfUrl,
              metadata: [
                { key: '', value: docNumber },
                {
                  key: 'Published Date',
                  value: publishedDate ? getDateString(publishedDate) : ''
                },
                {
                  key: 'Legal Effective Date',
                  value: effectiveDate ? getDateString(effectiveDate) : ''
                },
                { key: '', value: status },
                {
                  key: 'Consultation From Date',
                  value: consultationDateFrom ? getDateString(consultationDateFrom) : ''
                },
                {
                  key: 'Consultation To Date',
                  value: consultationDateTo ? getDateString(consultationDateFrom) : ''
                }
              ].filter(data => !!data.value),
              getPdfUrl: getPDFUrlFromS3Path
            };
          }
        );
        const group: DocumentsGroup = {
          id: groupName.toLowerCase(),
          categoryTitle: groupName,
          documents: docs
        };
        allGroups.push(group);
      });

      const priorityGroups = ['documents', 'current version', 'current effective version'];
      const sortedDocuments = sortBy(allGroups, (group: DocumentsGroup) => {
        const priorityIndex = priorityGroups.indexOf(group.id as string);
        return priorityIndex !== -1 ? priorityIndex : Infinity; // Place non-priority groups after the priorities
      });
      return { documents: sortedDocuments, documentCount };
    }, []);

  const chatRIAMetadata = useMemo(() => {
    return {
      last_published_date: data?.first_published_date ?? '',
      ema_document_no: data?.ema_document_no ?? '',
      status: data?.status ?? '',
      document_type: data?.document_type ?? '',
      doc_title: data?.title ?? ''
    };
  }, [data]);

  const metadata = useMemo(() => {
    return [
      data?.first_published_date ? getDateString(data?.first_published_date) : '',
      data?.keywords ?? ''
    ].filter((title: string) => !!title);
  }, [data]);

  const mappedSourceName = getDocumentSourceName('EMA Guidances');

  return (
    <Box sx={styles.textAlignment}>
      <Box alignItems='center' sx={{ cursor: 'pointer' }}>
        {data && (
          <Stack
            direction='row'
            spacing={0.5}
            alignItems='center'
            sx={styles.linkItem}
            onClick={() => {
              if (data?.document_count !== 0) {
                handleOpenPopup();
              }
            }}>
            <FolderIcon sx={{ fontSize: 12 }} />
            <Typography variant='subtitle2' sx={styles.pdfCellText}>
              {data?.document_count}
            </Typography>
          </Stack>
        )}
      </Box>

      {open && (
        <DocumentListing
          defaultDocuments={documents}
          defaultSelectedEntityId=''
          title={data?.title}
          onClose={() => setOpen(false)}
          chatRIAMetadata={chatRIAMetadata}
          source={mappedSourceName as string}
          metadata={metadata}
          infoComponent={null}
          disableChatRia={false}
          secondaryDrawerTitle=''
          allowComparison={documentCount > 1}
        />
      )}
    </Box>
  );
};

const DocumentHistoryPDF = (params: any) => {
  return <GridCellDocuments data={params.row} />;
};

export default DocumentHistoryPDF;
