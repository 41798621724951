import axios, { CancelTokenSource } from 'axios';
import { useState } from 'react';
import { downloadApplicationSnapshot, downloadSubmissionSnapshot } from '../../api/pages/Reg360';

const useSnapshotExcel = () => {
  const [loader, setLoader] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<boolean>(false);
  const [cancel, setCancel] = useState<CancelTokenSource | null>(null);

  const getSnapshotExcel = async (
    applicationNumbers: string[] | string,
    fileName: string,
    submissionType: string = '',
    submissionNumber: string = ''
  ) => {
    const cancelToken = axios.CancelToken.source();
    setCancel(cancelToken);
    setLoader(true);
    let request: any = null;
    if (submissionNumber && submissionType) {
      request = downloadSubmissionSnapshot(
        applicationNumbers,
        submissionType,
        submissionNumber,
        cancelToken.token
      );
    } else {
      request = downloadApplicationSnapshot(applicationNumbers, cancelToken.token);
    }
    const response: any = await request;
    const file = response.data;

    if (response.status === 200 && response.data instanceof Blob) {
      const fileURL = URL.createObjectURL(file);
      const a = document.createElement('a');
      a.href = fileURL;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(fileURL);
    } else {
      setErrorMsg(true);
    }
    setLoader(false);
    setCancel(null);
  };

  const cancelRequest = () => {
    if (cancel) {
      cancel.cancel('Operation canceled by the user.');
      setCancel(null);
    }
  };

  return {
    loader,
    errorMsg,
    setLoader,
    setErrorMsg,
    getSnapshotExcel,
    cancelRequest
  };
};

export default useSnapshotExcel;
