export const CATEGORIES_OPTIONS = {
  us: [
    { elasticSearchType: 'indications_and_usage', label: 'Indications' },
    { elasticSearchType: 'active_ingredients', label: 'Active Ingredient' },
    { elasticSearchType: 'atc_classification', label: 'ATC Classification' },
    { elasticSearchType: 'trade_name', label: 'Trade Name' },
    { elasticSearchType: 'formulation', label: 'Formulation' },
    { elasticSearchType: 'route', label: 'Route' },
    { elasticSearchType: 'sponsor_name', label: 'Sponsor' }
  ],
  eu: [
    { elasticSearchType: 'indications_and_usage', label: 'Indications' },
    { elasticSearchType: 'active_ingredients', label: 'Active Ingredient' },
    { elasticSearchType: 'atc_classification', label: 'ATC Classification' },
    { elasticSearchType: 'trade_name', label: 'Trade Name' },
    { elasticSearchType: 'formulation', label: 'Formulation' },
    { elasticSearchType: 'route', label: 'Route' },
    { elasticSearchType: 'sponsor_name', label: 'Sponsor' }
  ],
  ca: [
    { elasticSearchType: 'indications_and_usage', label: 'Indications' },
    { elasticSearchType: 'active_ingredients', label: 'Active Ingredient' },
    { elasticSearchType: 'atc_classification', label: 'ATC Classification' },
    { elasticSearchType: 'trade_name', label: 'Trade Name' },
    { elasticSearchType: 'formulation', label: 'Formulation' },
    { elasticSearchType: 'route', label: 'Route' },
    { elasticSearchType: 'sponsor_name', label: 'Sponsor' }
  ],
  'us-devices': [
    { elasticSearchType: 'indications_and_usage', label: 'Definition/AOS' },
    { elasticSearchType: 'generic_name', label: 'Generic Name' },
    { elasticSearchType: 'trade_name', label: 'Trade Name' },
    { elasticSearchType: 'sponsor_name', label: 'Sponsor' }
  ],
  clinicaltrials: [
    { elasticSearchType: 'indications_and_usage', label: 'Conditions' },
    { elasticSearchType: 'active_ingredients', label: 'Intervention' },
    { elasticSearchType: 'primary_endpoint', label: 'Primary Endpoint' },
    { elasticSearchType: 'sponsor_name', label: 'Sponsor' }
  ]
};

export const CATEGORY_AUTOCOMPLETE_MAPPING = {
  indications_and_usage: 'ta_indication',
  active_ingredients: 'active_ingredient',
  atc_classification: 'atc_classification',
  trade_name: 'trade_name',
  formulation: 'formulation',
  route: 'route',
  sponsor_name: 'sponsor',
  primary_endpoint: 'primary_endpoint'
};

export const SOURCE_AUTOCOMPLETE_MAPPING = {
  us: 'fda',
  eu: 'ema',
  clinicalTrials: 'ct',
  ca: 'ca',
  'us-devices': 'us-devices'
};

export const CATEGORY_CONDITIONS = ['must contain', 'may contain', 'must not contain'];

export const ELASTIC_SEARCH_ATTRIBUTES_LABELS = {
  us: {
    active_ingredients: 'Active Ingredients',
    atc_classification: 'ATC Classification',
    formulation: 'Formulation',
    formulations: 'Formulation',
    route: 'Route',
    approval_date: 'Approval Date',
    sponsor_name: 'Sponsor Name',
    indications_and_usage: 'Indications',
    trade_name: 'Trade Name'
  },
  eu: {
    active_ingredients: 'Active Ingredients',
    atc_classification: 'ATC Classification',
    formulation: 'Formulation',
    formulations: 'Formulation',
    route: 'Route',
    approval_date: 'Approval Date',
    sponsor_name: 'Sponsor Name',
    indications_and_usage: 'Indications',
    trade_name: 'Trade Name'
  },
  ca: {
    active_ingredients: 'Active Ingredients',
    atc_classification: 'ATC Classification',
    formulation: 'Formulation',
    formulations: 'Formulation',
    route: 'Route',
    approval_date: 'Approval Date',
    sponsor_name: 'Sponsor Name',
    indications_and_usage: 'Indications',
    trade_name: 'Trade Name'
  },
  'us-devices': {
    generic_name: 'Generic Name',
    sponsor_name: 'Sponsor Name',
    trade_name: 'Trade Name',
    indications_and_usage: 'Definition/AOS'
  },
  clinicaltrials: {
    active_ingredients: 'Interventions',
    primary_endpoint: 'Primary Endpoint',
    sponsor_name: 'Sponsor Name',
    indications_and_usage: 'Conditions'
  },
  ct: {
    active_ingredients: 'Interventions',
    primary_endpoint: 'Primary Endpoint',
    sponsor_name: 'Sponsor Name',
    indications_and_usage: 'Conditions'
  },
  cdp: {
    indications_and_usage: 'Conditions',
    active_ingredients: 'Interventions',
    sponsor_name: 'Sponsor',
    overall_status: 'Overall Status',
    study_type: 'Study Type',
    phase: 'Phase',
    gender: 'Gender',
    healthy_volunteers: 'Healthy Volunteers',
    minimum_age: 'Min Age',
    maximum_age: 'Max Age'
  },
  hpra: {
    active_ingredients: 'Active Ingredients',
    atc_classification: 'ATC Classification',
    formulation: 'Formulation',
    route: 'Route',
    approval_date: 'Approval Date',
    sponsor_name: 'Sponsor Name',
    indications_and_usage: 'Indications',
    trade_name: 'Trade Name'
  },
  jp: {
    active_ingredients: 'Active Ingredients',
    formulation: 'Formulation',
    route: 'Route',
    approval_date: 'Latest Submission Date',
    sponsor_name: 'Sponsor Name',
    indications_and_usage: 'Indications',
    trade_name: 'Trade Name'
  }
};
