import { createSvgIcon } from '@mui/material';

const WithDrawalTag = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='0 0 12 12' fill='none'>
    <path
      d='M6 0C2.694 0 0 2.694 0 6C0 9.306 2.694 12 6 12C9.306 12 12 9.306 12 6C12 2.694 9.306 0 6 0ZM8.352 6.45H3.552C3.306 6.45 3.102 6.246 3.102 6C3.102 5.754 3.306 5.55 3.552 5.55H8.352C8.47134 5.55 8.58582 5.5974 8.67018 5.68182C8.7546 5.76618 8.802 5.88066 8.802 6C8.802 6.11934 8.7546 6.23382 8.67018 6.31818C8.58582 6.4026 8.47134 6.45 8.352 6.45Z'
      fill='#F89C11'
    />
  </svg>,
  'WithDrawalTag'
);

export default WithDrawalTag;
