import { Box, Typography, Button } from '@mui/material';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import styles from '../../../components/Header/styles/NotificationsPopup.styles';
import { CHANNELS } from '../constants';

const NoNotification = ({
  type,
  channelType
}: {
  type: 'subscription' | 'notification';
  channelType: (typeof CHANNELS)[number];
}) => {
  const history = useHistory();
  const channelTypeTile = useMemo(() => {
    switch (channelType) {
      case 'application':
        return 'Applications';
      case 'search':
        return 'Search';
      case 'quick_search':
        return 'Quick Search';
      case 'advanced_search':
        return 'Advanced Search';
      case 'document_search':
        return 'Document Search';
      case 'source':
        return 'Sources';
      default:
        return 'Notification';
    }
  }, [channelType]);
  const redirectTab = useMemo(() => {
    switch (channelType) {
      case 'application':
        return 'application';
      case 'search':
      case 'quick_search':
      case 'advanced_search':
        return 'searches';
      case 'document_search':
        return 'document-searches';
      default:
        return 'source';
    }
  }, [channelType]);
  return (
    <Box
      display='flex'
      alignItems='center'
      flexDirection='column'
      justifyContent='center'
      height={150}>
      <Typography align='center' fontSize='medium' style={styles.noDataText}>
        {type === 'subscription' ? 'Nothing subscribed' : 'You do not have any notifications'}
      </Typography>
      <Typography
        align='center'
        fontSize='small'
        sx={{ pt: 2 }}
        fontStyle='italic'
        style={styles.noDataText}>
        <b>Subscribe to</b> <br /> {channelTypeTile}
      </Typography>
      <Button
        variant='text'
        onClick={() => history.push(`/notifications/settings?tab=${redirectTab}`)}>
        <Typography
          align='center'
          fontSize='small'
          sx={{ mt: 2 }}
          fontStyle='italic'
          style={styles.noDataText}>
          View Subscriptions
        </Typography>
      </Button>
    </Box>
  );
};
export default NoNotification;
