const getFiltersOptions = (meetingFilters: any, apiData: any) => {
  const filters = meetingFilters.map((currentFilter: { [key: string]: any }) => ({
    ...currentFilter,
    options: [...(apiData?.[currentFilter?.value] || [])]
  }));

  return filters;
};
const abbreviateMonths = (input: string): string => {
  return input.replace(
    /\b(January|February|March|April|May|June|July|August|September|October|November|December)\b/g,
    match => match.substring(0, 3)
  );
};
export const getMeetingHeaderDate = (committee: string, meetingDate: any) => {
  if (['PRAC', 'CHMP']?.includes(committee)) {
    return abbreviateMonths(meetingDate);
  }

  return `${meetingDate.split(' ')[1]} ${meetingDate.split(' ')[0]}`;
};

export const formatStringWithSpaces = (input: string | null | undefined): string => {
  if (!input || input.trim().toUpperCase() === 'NA') {
    return input ?? ''; // Return input as-is if it's `null`, `undefined`, or `"NA"`
  }

  return input
    .split(',')
    .map(item => item.trim())
    .join(', ');
};

export default getFiltersOptions;
