import { useEffect, useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  CircularProgress,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack
} from '@mui/material';
import { isEmpty } from 'lodash';
import notificationsDetailStyles from './NotificationsDetail.styles';
import notificationsListStyles from './NotificationsList.styles';
import { SOURCE_TO_DISPLAY_NAME_MAPPING } from '../constants';
import Reg360Icon from '../../../assets/svgs/Icons/Reg360Icon';
import SubscriptionActions from '../../../components/Subscription/SubscriptionActions';

import NotificationHeaderRedirectMenuItem from './NotificationHeaderRedirectMenuItem';
import { LinkIcon } from '../../../assets/svgs/Icons';
import {
  getAdvancedSearchSubtitle,
  getAdvancedSearchTitle,
  getEntitySubscriptionStatus,
  getQuickSearchSubtitle,
  getQuickSearchTitle,
  handleAdvancedSearchClick,
  handleDocumentSearchClick,
  handleSearchClick
} from '../utils';
import { DocumentSearchSubscription, ParentNotification } from './NotificationInterface';
import NotificationFlag from './NotificationFlag';
import NotificationAvatar from './NotificationAvatar';

const NotificationDetailsHeader = ({ notification }: { notification: ParentNotification }) => {
  const [advanceSearchPayload, setAdvanceSearchPayload] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscribedDocumentSearchDetails, setSubscribedDocumentSearchDetails] =
    useState<DocumentSearchSubscription | null>(null);
  const { channel, source, heading: title, searchData: searchInfo } = notification;

  const { name: notificationType } = channel;

  const quickSearchTitle = useMemo(
    () => getQuickSearchTitle(searchInfo),
    [searchInfo?.category, searchInfo?.searchData?.first_approval_date, searchInfo?.term]
  );

  const quickSearchSubtitle = useMemo(
    () => getQuickSearchSubtitle(searchInfo),
    [searchInfo?.category_label, searchInfo?.filters]
  );

  const advancedSearchTitle = useMemo(() => {
    const { localSearch, advanceSearchPayload: payload } = getAdvancedSearchTitle(searchInfo);
    setAdvanceSearchPayload(payload);
    return localSearch;
  }, [searchInfo?.cnf_query, searchInfo?.searchData, searchInfo?.source]);

  const advancedSearchSubtitle = useMemo(
    () => getAdvancedSearchSubtitle(searchInfo),
    [searchInfo?.cnf_query, searchInfo?.filters, searchInfo?.searchData, searchInfo?.source]
  );

  const secondaryText = useMemo(() => {
    switch (notificationType) {
      case 'data_source':
        return false;
      case 'application':
        return (
          <Stack
            direction='row'
            alignItems='center'
            sx={{ '& >svg': { height: '12px', width: '18px', mr: '4px' }, mt: '4px' }}>
            <NotificationFlag source={notification?.source} />
            {quickSearchSubtitle}
          </Stack>
        );
      case 'quick_search':
        return (
          <Stack
            direction='row'
            alignItems='center'
            sx={{ '& >svg': { height: '12px', width: '18px', mr: '4px' }, mt: '4px' }}>
            <NotificationFlag source={Object.keys(notification?.searchData?.source ?? {})} />
            {quickSearchSubtitle}
          </Stack>
        );
      case 'advanced_search':
      case 'advanced_search_filters': {
        return (
          <Stack
            direction='row'
            alignItems='center'
            sx={{ '& >svg': { height: '12px', width: '18px', mr: '4px' }, mt: '4px' }}>
            <NotificationFlag source={Object.keys(notification?.searchData?.source ?? {})} />
            {advancedSearchSubtitle}
          </Stack>
        );
      }
      // Commented this as of now due to Sid feedback
      // case 'document_search':
      //   // eslint-disable-next-line no-case-declarations
      //   const sourceMap: Record<string, Set<string>> = (
      //     notification.notifications.Results || []
      //   ).reduce<Record<string, Set<string>>>((acc, result) => {
      //     const normalizedSource = result?.aria_result?.normalized_source;
      //     const normalizedRegion = result?.aria_result?.normalized_region;
      //
      //     if (normalizedSource) {
      //       acc[normalizedSource] = acc[normalizedSource] || new Set();
      //       if (normalizedRegion) acc[normalizedSource].add(normalizedRegion);
      //     }
      //     return acc;
      //   }, {});
      //
      //   // eslint-disable-next-line no-case-declarations
      //   const sourceListAsArrays: Record<string, string[]> = Object.fromEntries(
      //     Object.entries(sourceMap).map(([key, value]) => [key, Array.from(value)])
      //   );
      //
      //   return (
      //     <Stack
      //       direction='row'
      //       alignItems='center'
      //       sx={{
      //         textTransform: 'none',
      //         '& >svg': { height: '12px', width: '18px', mr: '4px' },
      //         mt: '4px'
      //       }}>
      //       <NotificationFlag source={sourceListAsArrays ?? {}} withTooltip />
      //     </Stack>
      //   );
      default:
        return false;
    }
  }, [
    notificationType,
    quickSearchSubtitle,
    advancedSearchSubtitle,
    notification?.source,
    notification?.notifications?.Results
  ]);

  const getIsSubscribed = async () => {
    let entityId;
    if (notificationType === 'application' || notificationType === 'document_search') {
      entityId = notification?.entity_id;
    } else if (notificationType === 'data_source') {
      entityId = '';
    } else {
      entityId = searchInfo?.id ?? '';
    }

    const result = await getEntitySubscriptionStatus({
      source,
      entityId,
      entityType: notificationType
    });
    return result;
  };

  const getId = useMemo(() => {
    if (notificationType === 'data_source') {
      return '';
    }
    if (notificationType === 'application' || notificationType === 'document_search') {
      return notification?.entity_id ?? '';
    }
    return searchInfo?.id ?? '';
  }, [notification?.entity_id, notification?.source, searchInfo?.id]);

  const primaryText = useMemo(() => {
    switch (notificationType) {
      case 'data_source':
        // @ts-ignore
        return SOURCE_TO_DISPLAY_NAME_MAPPING[source].description;
      case 'application':
        return title;
      case 'quick_search':
        return quickSearchTitle;
      case 'advanced_search':
      case 'advanced_search_filters': {
        return advancedSearchTitle;
      }
      default:
        return title;
    }
  }, [notificationType, title, source]);

  const notificationRedirectMapping = useMemo(() => {
    const finalIcon = (SOURCE_TO_DISPLAY_NAME_MAPPING as any)[source]?.icon;
    const notificationTitle = (SOURCE_TO_DISPLAY_NAME_MAPPING as any)[source]?.display;
    const mapping = {
      icon: LinkIcon,
      title: primaryText
    };
    switch (notificationType) {
      case 'application':
        mapping.icon = Reg360Icon;
        mapping.title = 'Open Reg 360°';
        break;
      case 'quick_search':
      case 'advanced_search':
      case 'advanced_search_filters':
        mapping.icon = LinkIcon;
        mapping.title = 'Go to search results';
        break;
      case 'document_search':
        mapping.icon = LinkIcon;
        mapping.title = 'Go to document search';
        break;
      default:
        mapping.icon = finalIcon ?? LinkIcon;
        mapping.title = `Go to ${notificationTitle}`;
        break;
    }
    return mapping;
  }, [notificationType, source, primaryText]);

  const handleNotificationsReg360 = (applNo: string, appSource: string) => {
    const num = encodeURIComponent(applNo);
    window.open(`/regulatory360/core/${num}/${appSource?.toLowerCase()}`, '_blank');
  };

  const canOpenPage = useMemo(() => {
    const url = (SOURCE_TO_DISPLAY_NAME_MAPPING as any)[source]?.url ?? '';
    return (
      notificationType === 'quick_search' ||
      notificationType === 'advanced_search' ||
      notificationType === 'advanced_search_filters' ||
      notificationType === 'document_search' ||
      notificationType === 'application' ||
      !isEmpty(url)
    );
  }, [source, notificationType]);

  const handleNotificationClick = () => {
    const url = (SOURCE_TO_DISPLAY_NAME_MAPPING as any)[source]?.url ?? '';
    if (notificationType === 'advanced_search' || notificationType === 'advanced_search_filters') {
      handleAdvancedSearchClick({ searchInfo, advanceSearchPayload });
    } else if (notificationType === 'quick_search') {
      handleSearchClick({ searchInfo });
    } else if (notificationType === 'document_search' && subscribedDocumentSearchDetails) {
      handleDocumentSearchClick(subscribedDocumentSearchDetails);
    } else if (notificationType === 'application') {
      handleNotificationsReg360(notification?.entity_id, notification?.source);
    } else if (url) {
      window.open(url, '_blank');
    }
  };

  const handleSubscription = ({ isSubscribed: isSubscribedVal }: { isSubscribed: boolean }) => {
    setIsSubscribed(isSubscribedVal);
  };

  useEffect(() => {
    setLoading(true);
    getIsSubscribed()
      .then(result => {
        setIsSubscribed(result?.isSubscribed);
        setSubscribedDocumentSearchDetails(result?.subscribedDocumentSearchDetails);
      })
      .finally(() => setLoading(false));
  }, [notificationType, source, notification?.entity_id]);

  return (
    <ListItem sx={notificationsDetailStyles.topSection} key={(notification.id ?? []).join('-')}>
      <ListItemAvatar sx={notificationsListStyles.itemAvatar}>
        <Avatar sx={notificationsListStyles.avatar}>
          <NotificationAvatar notificationType={notificationType} source={source} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={primaryText}
        secondary={secondaryText}
        sx={notificationsDetailStyles.headerText}
      />
      <Box mr='8px'>
        {notificationType &&
          (loading ? (
            <CircularProgress size={20} />
          ) : (
            <SubscriptionActions
              invokedBy={notificationType}
              id={getId}
              source={source}
              isButton={false}
              isSubscribed={isSubscribed}
              setSubscriptionInHook={handleSubscription}
              isThreeDotsMenu
              subscribedAriaSearch={subscribedDocumentSearchDetails}
              preMenuItems={
                canOpenPage ? (
                  <NotificationHeaderRedirectMenuItem
                    handleOnClick={handleNotificationClick}
                    menuItemText={notificationRedirectMapping?.title}
                    Icon={notificationRedirectMapping?.icon}
                  />
                ) : null
              }
            />
          ))}
      </Box>
    </ListItem>
  );
};
export default NotificationDetailsHeader;
