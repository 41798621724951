import React, { useEffect, useState } from 'react';
// third party
import isEmpty from 'lodash/isEmpty';
// mui
import { Stack, Box, Typography, Chip, Tooltip } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// component
import YearRange from './YearRange';
// styles
import styles from '../../styles/Meeting.styles';
import { getMeetingHeaderDate } from '../../utils/common/getFilterOptions';

const ICON_MAPPING: Record<string, React.ReactElement> = {
  total_positive: <CheckCircleIcon style={{ ...styles.icon, color: '#109E8A' }} />,
  total_negative: <CancelIcon style={{ ...styles.icon, color: '#DC5A59' }} />,
  total_withdrawal: <RemoveCircleIcon style={{ ...styles.icon, color: '#F89C11' }} />
};

const FILTERED_HIGHLIGHT_KEY = [
  { tooltipText: 'Positives', value: 'total_positive' },
  { tooltipText: 'Negatives', value: 'total_negative' },
  { tooltipText: 'Withdrawals', value: 'total_withdrawal' }
];

const MeetingsYearList = ({
  handleSelectMeeting,
  allMeetingsByYear,
  selectedMeetingId,
  committee
}: any) => {
  const [selectedYear, setSelectedYear] = useState('');

  const scrollToSection = (year: any) => {
    setTimeout(() => {
      const element = document.getElementById(year);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, 500);
  };

  useEffect(() => {
    scrollToSection(parseInt(selectedMeetingId?.split(' ')[1], 10));
  }, []);

  const hasAnyFilteredKey = (
    highlightCounts: Record<string, any>,
    filteredKeys: Record<string, string>[]
  ): boolean => {
    return filteredKeys.some(each => each.value in highlightCounts);
  };

  return (
    <Stack sx={styles.listScrollContainer}>
      {!isEmpty(allMeetingsByYear) &&
        Object.keys(allMeetingsByYear) // get all meetings by year Eg: {2021: [{}, {}], 2020: [{}, {}]
          .sort((a: any, b: any) => b - a) // sort by year
          .map((year: string) => {
            const meetings = allMeetingsByYear[year];
            return (
              <Stack key={year} id={year} spacing={2}>
                <Box sx={styles.listAccordion}>
                  <Box sx={styles.listAccordionSummary}>
                    <YearRange
                      year={year}
                      handleDateSelection={scrollToSection}
                      minDate={Object.keys(allMeetingsByYear)[0]}
                      maxDate={
                        Object.keys(allMeetingsByYear)[Object.keys(allMeetingsByYear).length - 1]
                      }
                      selectedYear={selectedYear}
                      setSelectedYear={setSelectedYear}
                    />
                  </Box>
                  <Box sx={styles.listAccordionDetails}>
                    <Stack spacing={1.5}>
                      {meetings?.map((meeting: any) => {
                        return (
                          <Stack
                            key={meeting.meeting_id}
                            gap={0.5}
                            sx={{
                              ...styles.listMeetingBox,
                              borderColor:
                                selectedMeetingId === meeting.meeting_id
                                  ? 'primary.600'
                                  : 'gray.300'
                            }}
                            onClick={() => {
                              handleSelectMeeting(meeting.meeting_id);
                            }}>
                            <Tooltip placement='top' title={meeting?.event_name}>
                              <Typography
                                sx={{
                                  ...styles.listMeetingDate,
                                  color:
                                    selectedMeetingId === meeting.meeting_id
                                      ? 'primary.700'
                                      : 'gray.900'
                                }}>
                                {`${getMeetingHeaderDate(committee, meeting?.date)}`}
                              </Typography>
                            </Tooltip>

                            {/* YTD Information */}
                            {meeting?.highlight_counts &&
                              hasAnyFilteredKey(
                                meeting?.highlight_counts,
                                FILTERED_HIGHLIGHT_KEY
                              ) && (
                                <Box sx={styles.meetingYearSubInformationContainer}>
                                  <Tooltip title='Year To Date'>
                                    <Typography fontSize={11} color='gray.600'>
                                      YTD:
                                    </Typography>
                                  </Tooltip>

                                  {/* Chips for metrics */}
                                  {FILTERED_HIGHLIGHT_KEY.map(each => {
                                    const value = meeting?.highlight_counts?.[each.value];
                                    return value !== undefined ? (
                                      <Tooltip title={each.tooltipText}>
                                        <Chip
                                          key={each.value}
                                          icon={ICON_MAPPING[each.value]}
                                          label={value}
                                          sx={styles.iconText}
                                        />
                                      </Tooltip>
                                    ) : null;
                                  })}
                                </Box>
                              )}
                          </Stack>
                        );
                      })}
                    </Stack>
                  </Box>
                </Box>
              </Stack>
            );
          })}
    </Stack>
  );
};

export default React.memo(MeetingsYearList);
