import React from 'react';
import { Box, Typography, Stack, Divider } from '@mui/material';
import styles from './styles';
import { PipedDataProps } from '../../types';

const PipedData: React.FC<PipedDataProps> = ({ data }: any) => {
  return (
    <Stack
      display='flex'
      direction='row'
      divider={
        <Divider orientation='vertical' sx={{ height: 16, mx: 1, borderColor: 'gray.400' }} />
      }>
      {data.map((d: any, index: number) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={index} display='flex'>
          {d.label && (
            <Typography
              key={d.label}
              sx={{ ...styles.valueText, color: 'gray.600', fontWeight: 400, pl: 0 }}>
              {d.label}:
            </Typography>
          )}
          <Typography sx={styles.valueText}>{d.value || 'NA'}</Typography>
        </Box>
      ))}
    </Stack>
  );
};

export default React.memo(PipedData);
