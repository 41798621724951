import { ResponsivePie } from '@nivo/pie';
import React, { useRef } from 'react';
import { Box } from '@material-ui/core';
import Typography from '@mui/material/Typography';
import ReferenceText from '../../Report/components/ReferenceText';
import ArcLinkLabel from './CustomArcLinkLabel';

const LegendPosition: any = {
  'bottom-left': {
    translateX: -10,
    translateY: -20
  },
  'bottom-right': {
    translateX: 150,
    translateY: -20
  },
  'top-right': {
    translateX: 150,
    translateY: -20
  },
  'top-left': {
    translateX: -10,
    translateY: -20
  }
};

const PieChart = ({ data, title = {}, style = {}, referenceText = {} }: any) => {
  const boxRef = useRef<HTMLDivElement | null>(null);
  const refBoxRef = useRef<HTMLDivElement | null>(null);

  const getHeight = () => {
    let boxHeight = 0;
    let refBoxHeight = 0;
    if (boxRef.current) {
      boxHeight = boxRef.current.clientHeight;
    }
    if (refBoxRef.current) {
      refBoxHeight = refBoxRef.current.clientHeight;
    }
    return boxHeight + refBoxHeight + 10;
  };

  return (
    <Box
      position='relative'
      width='100%'
      height='100%'
      p={2}
      sx={{ 'page-break-inside': 'avoid', display: 'inline-block' } as any}>
      {title.show && (
        // @ts-ignore
        <Box ref={boxRef} position='relative' width='100%' top='0px' textAlign='center'>
          <Typography fontSize={`${title.fontSize || 20}px`} textAlign='center'>
            {title.title}
          </Typography>
        </Box>
      )}
      <Box width='100%' height={`calc(100% - ${getHeight()}px)`}>
        {data.length !== 0 ? (
          <ResponsivePie
            data={data}
            margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
            innerRadius={0.1}
            padAngle={0.7}
            cornerRadius={3}
            startAngle={-50}
            enableArcLinkLabels={['both', 'arc'].includes(style?.enableLabels) || false}
            enableArcLabels={['both', 'inchart'].includes(style?.enableLabels) || false}
            colors={['#48E0A6', '#6868FA', '#12815E']}
            borderWidth={1}
            borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
            animate
            arcLinkLabelsSkipAngle={style?.arcLinkLabelsSkipAngle || 10}
            // eslint-disable-next-line react/no-unstable-nested-components
            arcLinkLabelComponent={d => <ArcLinkLabel datum={d} labelCharacterLimit={20} />}
            theme={{
              axis: {
                ticks: {
                  text: {
                    fontSize: 10
                  }
                }
              },
              legends: {
                text: {
                  fontSize: 16
                }
              }
            }}
            legends={
              style?.pieLegend
                ? [
                    {
                      anchor: style?.anchor || 'top',
                      direction: style?.direction || 'row',
                      justify: false,
                      translateX: style?.anchor ? LegendPosition[style?.anchor].translateX : 100,
                      translateY: style?.anchor ? LegendPosition[style?.anchor].translateY : -60,
                      itemsSpacing: 0,
                      itemDirection: 'left-to-right',
                      itemWidth: 200,
                      itemHeight: 20,
                      itemOpacity: 0.75,
                      symbolSize: 12,
                      symbolShape: 'circle',
                      symbolBorderColor: 'rgba(0, 0, 0, .5)',
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemBackground: 'rgba(0, 0, 0, .03)',
                            itemOpacity: 1
                          }
                        }
                      ]
                    }
                  ]
                : []
            }
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...style}
          />
        ) : (
          <Box position='relative' width='100%' top='50%' textAlign='center'>
            <Typography fontSize='20px' textAlign='center'>
              No Graph Available
            </Typography>
          </Box>
        )}
      </Box>
      {referenceText.show && <ReferenceText ref={refBoxRef} referenceText={referenceText} />}
    </Box>
  );
};

export default React.memo(PieChart);
