const styles = {
  cardSummaryAccordion: {
    display: 'flex',
    flexDirection: 'column-reverse',
    boxShadow: 'none',
    background: 'none',
    border: 'none',
    '&::before': {
      display: 'none'
    },
    '& .Mui-expanded': {
      marginTop: '0px !important'
    }
  },
  accordionDetails: {
    p: 0
  },
  cardSummaryAccordionSummary: {
    width: '100%',
    boxShadow: 'none',
    display: 'flex',
    flexDirection: 'row-reverse',
    marginTop: '8px',
    marginBottom: '0px',
    mx: 'auto',
    p: 0,
    minHeight: 'unset',
    '&.Mui-expanded': {
      mx: 'auto',

      minHeight: 'unset'
    },
    '& .MuiAccordionSummary-content': {
      flexDirection: 'column',
      margin: 0,
      '&.Mui-expanded': {
        margin: 0
      }
    }
  },
  summaryTitle: { color: 'gray.900', fontSize: 13, fontWeight: 700 },
  contentTitle: { color: 'gray.800', fontSize: 13, fontWeight: 700 },
  contentText: { color: 'gray.800', fontSize: 13, mt: 2 },
  container: { px: 2, py: 1.5, pb: 1, borderRadius: 2, bgcolor: 'gray.100' },
  divider: { my: 1, borderColor: 'gray.300', backgroundColor: 'transparent !important' }
};

export default styles;
