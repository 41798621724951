import React, { useState } from 'react';
import { Stack, Typography } from '@mui/material';
import Section from './Section';
import styles from '../../../EMAMeetings/styles/Meeting.styles';

const MeetingDetailsCard: React.FC<any> = ({ sections, meetingId }) => {
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange = (id: string) => {
    setExpanded(prev => (prev === id ? false : id));
  };

  return (
    <>
      {Object.keys(sections).map((key: string) => {
        const sect = sections[key];
        return (
          <Stack key={key} spacing={2} id={key} sx={styles.sectionsContainer}>
            <Stack spacing={1} aria-label='section' margin={0}>
              <Typography sx={styles.sectionKey}>{key}</Typography>
            </Stack>
            {sect.map((topic: any, index: number) => (
              <Section
                // eslint-disable-next-line react/no-array-index-key
                key={topic.sub_section_number + index}
                topic={topic}
                index={index}
                expanded={expanded}
                handleChange={handleChange}
                meetingId={meetingId}
              />
            ))}
          </Stack>
        );
      })}
    </>
  );
};

export default React.memo(MeetingDetailsCard);
