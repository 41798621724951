const styles = {
  grayDot: {
    backgroundColor: 'gray.500',
    margin: '0 8px',
    width: 4,
    height: 4,
    borderRadius: '50%'
  },
  greenDot: {
    bgcolor: 'green.jungle',
    margin: '0 8px',
    width: 4,
    height: 4,
    borderRadius: '50%',
    display: 'inline-block',
    mb: 0.25
  },
  container: {
    display: 'inline-block',
    alignItems: 'center',
    px: 1,
    py: 0.25,
    bgcolor: 'gray.200',
    borderRadius: 1,
    flexWrap: 'wrap'
  }
};

export default styles;
