import React from 'react';

import { Stack, Typography } from '@mui/material';
import { RedWarningIcon } from '../../../assets/svgs/Icons';

const MinutesPendingWarning = () => {
  return (
    <Stack
      spacing={0}
      direction='row'
      alignItems='center'
      flexWrap='wrap'
      sx={{ border: '1px solid', borderColor: 'red.600', borderRadius: 1, px: 1.5, py: 1 }}>
      <RedWarningIcon sx={{ fontSize: 16 }} />
      <Typography ml={1} color='yellow.900' component='span' fontSize={13} fontWeight={700}>
        Meeting minutes pending
      </Typography>
      <Typography color='yellow.900' component='span' fontSize={13} fontWeight={500} ml={0.25}>
        {` — see agenda and highlights here for details.`}
      </Typography>
    </Stack>
  );
};

export default React.memo(MinutesPendingWarning);
