import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// Encoding
import { Base64 } from 'js-base64';

// aws amplify authenticator
import { Auth } from 'aws-amplify';
import { useHistory } from 'react-router-dom';
import { isEmpty } from 'lodash';

// moxo
import { datadogRum } from '@datadog/browser-rum';
import { ToastContainer } from 'react-toastify';
import useMoxoToken from '../../hooks/useMoxoToken';
import Loader from '../../helpers/Loader';
import 'react-toastify/dist/ReactToastify.min.css';

// context
const AuthContext = React.createContext();

/**
 * function to create an authentication provider to make current user details accessible to all components
 * @param {node} children child components
 * @returns authentication provider which contains current user details and update method
 */
export const AuthProvider = ({ children }) => {
  const DOMAIN_ORGANIZATION_MAP = process.env.REACT_APP_DOMAIN_ORGANIZATION_MAP || '';
  const SSO_PROVIDER_LIST = process.env.REACT_APP_SSO_PROVIDER_MAP || '';

  const history = useHistory();
  const [currentUser, setCurrentUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [currentUserId, setCurrentUserId] = useState(null);
  const { createAccessToken } = useMoxoToken();
  const [triggerLogoutCheck, setTriggerLogoutCheck] = useState(false);

  const value = React.useMemo(
    () => ({
      currentUser,
      setCurrentUser,
      currentUserId
    }),
    [currentUser]
  );
  const getUser = async () => {
    try {
      const user = await Auth.currentUserInfo();
      let datadogUserDetails = {};
      if (user) {
        const organizationNameMap = DOMAIN_ORGANIZATION_MAP
          ? JSON.parse(DOMAIN_ORGANIZATION_MAP)
          : {};
        const ssoProviderMap = SSO_PROVIDER_LIST ? JSON.parse(SSO_PROVIDER_LIST) : {};
        const userEmailDomain = user?.attributes?.email?.split('@')[1] || '';
        const organizationName =
          organizationNameMap[userEmailDomain] || user.attributes['custom:organization'] || '';
        if (user.attributes && 'identities' in user.attributes) {
          const identity = JSON.parse(user.attributes.identities);
          const userAuth = await Auth.currentAuthenticatedUser();
          const organization = organizationName || identity.providerName;
          await Auth.updateUserAttributes(userAuth, {
            'custom:license': 'Business',
            'custom:organization': organization
          });
          const userObj = {
            'custom:user': user.attributes['custom:user'],
            'custom:organization': organization,
            email: user.attributes.email,
            email_verified: user.attributes.email_verified,
            sub: user.attributes.sub,
            ...user.attributes
          };
          setCurrentUser(userObj);
          datadogUserDetails = {
            id: user.attributes.sub,
            organization
          };

          localStorage.setItem('organization', organization);
        } else {
          setCurrentUser(user.attributes);
          datadogUserDetails = {
            id: user.attributes.sub,
            organization: user.attributes['custom:organization']
          };
          localStorage.setItem('organization', user.attributes['custom:organization']);
          if (organizationName && ssoProviderMap) {
            if (organizationName in ssoProviderMap) {
              setTriggerLogoutCheck(true);
            } else {
              setTriggerLogoutCheck(false);
            }
          }
        }
        setCurrentUserId(Base64.encode(user.attributes.email));
        // set user attributes for datadog RUM session tracking
        datadogRum.setUser(datadogUserDetails);
        // enable session replay recording for all organizations except vivpro
        if (datadogUserDetails?.organization?.toLowerCase() !== 'vivpro') {
          datadogRum.startSessionReplayRecording();
        }
        // used for pendo user analytics
        localStorage.setItem('visitor_id', user.attributes.sub);
        localStorage.setItem('organization', user.attributes['custom:organization']);
        // generate an access token for using moxo services
        await createAccessToken(user.attributes.sub);
      } else {
        history.push('/');
      }
      setLoadingUser(false);
    } catch (e) {
      setLoadingUser(false);
    }
  };
  useEffect(() => {
    getUser();
  }, []);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (triggerLogoutCheck) {
      const timeout = setInterval(() => {
        Auth.currentUserInfo().then(user => {
          if (isEmpty(user)) {
            window.location.reload();
          }
        });
      }, 60000); // 1 minute
      return () => {
        clearTimeout(timeout);
      };
    }
  }, [triggerLogoutCheck]);
  if (loadingUser) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <Loader />;
  }

  return (
    <AuthContext.Provider value={value}>
      {children}
      <ToastContainer
        style={{
          fontSize: 16,
          fontWeight: 'bold',
          fontFamily: 'Mulish',
          width: 560
        }}
      />
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export default AuthContext;
