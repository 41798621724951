import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Auth } from 'aws-amplify';
import Popover from '@mui/material/Popover';
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  Accordion,
  styled,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import { debounce } from 'lodash';
import { useLocation, useHistory } from 'react-router';
import useReportingHandler from './hooks/useReportingHandler';
import styles, { accondionSummary } from './styles/fileButton.styles';
import CaretDownIcon from '../../assets/svgs/Icons/CaretDown';
import NameConfirmationDialog from './components/NameConfirmationDialog';
import Store from '../../store';
import Actions from '../../store/actions';
import { saveReport, updateReport } from '../../api/pages/UserProfile';
// import NewTabIcon from '../../assets/svgs/Report/NewTabIcon'; commented for now
import SearchIcon from '../../assets/svgs/Icons/SearchIcon';
import ClearDialog from './components/ClearDialog';

interface FileButtonProps {
  open: boolean;
  reportId: string;
  currentReportName: string;
  anchorEl: any;
  handleClose: Function;
  setAction: Function;
  layout: any;
}

const StyledAccordion = styled(Accordion)({
  boxShadow: 'none',
  width: '100%',
  '& .MuiPaper-root': {
    width: '100%',
    p: 0,
    pl: 2
  }
});

export const ReportMenuList = ({
  reportToDisplay,
  handleReportClick,
  // eslint-disable-next-line no-unused-vars
  fromReportingPage = true
}: any) => {
  return (
    <Box display='flex' flexDirection='column' alignItems='flex-start'>
      {reportToDisplay?.map((option: any) => (
        <Stack key={`${option.id}-box`} sx={styles.reportMenuContainer}>
          <Stack
            key={`${option.id}-box`}
            display='flex'
            flexDirection='column'
            alignItems='flex-start'
            sx={{
              cursor: 'pointer'
            }}
            onClick={(event: any) => {
              handleReportClick(event, option);
            }}>
            <Typography sx={styles.heading}>{option?.report_name}</Typography>
            <Typography sx={styles.subHeading}>Updated: {option?.updatedAt}</Typography>
          </Stack>
          {/*  Commented for now */}
          {/* {fromReportingPage && (
            <IconButton>
              <Tooltip
                title='Open in new tab'
                onClick={() => {
                  window.open(`/reports?id=${option?.id}`, '_blank');
                }}>
                <NewTabIcon sx={styles.tools} />
              </Tooltip>
            </IconButton>
          )} */}
        </Stack>
      ))}
    </Box>
  );
};

const FileButton = ({
  open,
  reportId,
  currentReportName,
  anchorEl,
  handleClose,
  setAction,
  layout
}: FileButtonProps) => {
  const { dispatch } = useContext<any>(Store);
  const { isLoading, reportData, fetchData } = useReportingHandler();
  const [isExpanded, setIsExpanded] = useState(false);
  const [openCreateNewReportDialog, setOpenCreateNewReportDialog] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState<boolean>(false);
  const [selectedReportInfo, setSelectedReportInfo] = useState<any>({});
  const [searchText, setSearchText] = useState<any>('');
  const popoverRef = React.useRef<HTMLDivElement | null>(null);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);

  const location = useLocation();
  const history = useHistory();
  const updatedSearchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );
  useEffect(() => {
    fetchData();
  }, []);

  const handleChangeReport = useCallback((selectedReport: any) => {
    updatedSearchParams.set('id', selectedReport?.id?.toString());
    history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
    setOpenConfirmationDialog(false);
    dispatch({
      type: Actions.SET_ALERT,
      value: {
        status: true,
        message: `Report Changed to ${selectedReport?.report_name}`,
        color: 'success'
      }
    });
    handleClose();
  }, []);

  const handleCreateReport = useCallback(async () => {
    const user = await Auth.currentUserInfo();
    const payload = {
      report_title: name,
      report_description: description,
      is_active: true,
      layout: {
        owner: user.attributes['custom:user'],
        sections: [],
        coverPage: {
          template: 'template1',
          title: '',
          description: ''
        }
      }
    };
    try {
      const { data: response } = await saveReport(payload);
      if (response) {
        if (response.Success && !response.error && response.Success.id) {
          handleChangeReport(response?.Success);
        }
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Successfully created new report.', color: 'success' }
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'Something went wrong. Could not download your report.',
          color: 'error'
        }
      });
    }
  }, [name, description]);

  const handleSearchTextChange = debounce((event: any) => setSearchText(event.target.value), 200);

  const reportToDisplay = useMemo(() => {
    return reportData.filter(
      (data: any) =>
        data?.report_name?.toLowerCase().includes(searchText.toLowerCase()) &&
        data?.id.toString() !== reportId.toString()
    );
  }, [reportData, searchText, reportId]);

  const debounceSaveReport = useCallback(
    debounce(async () => {
      const payload = {
        report_title: layout.reportFileName,
        report_description: layout.reportFileDescription,
        layout
      };
      dispatch({
        type: Actions.REPORT_SAVING,
        value: true
      });
      try {
        const { data: response } = await updateReport(reportId, payload);
        if (response) {
          dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'Successfully saved your report.', color: 'success' }
          });
          handleChangeReport(selectedReportInfo);
        }
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
        dispatch({
          type: Actions.SET_ALERT,
          value: {
            status: true,
            message: 'Something went wrong. Could not download your report.',
            color: 'error'
          }
        });
      } finally {
        dispatch({
          type: Actions.REPORT_SAVING,
          value: false
        });
      }
    }, 1000),
    [layout, reportId, selectedReportInfo]
  );

  const handleSaveAndChangeReport = () => {
    debounceSaveReport();
  };

  const handleReportClick = (event: any, option: any) => {
    event.preventDefault();
    setSelectedReportInfo(option);
    setOpenConfirmationDialog(true);
  };

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        handleClose();
      }}
      sx={styles.paper}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}>
      <Box ml={0} ref={popoverRef}>
        <Box display='flex' flexDirection='column' sx={styles.boxContainer}>
          <Tooltip title='Open a previously created report'>
            <MenuItem
              onClick={(e: any) => {
                e.stopPropagation();
                setIsExpanded(true);
              }}
              sx={{
                pl: 0
              }}>
              <Typography sx={styles.menuTitle}>Open Report(s)</Typography>
              <CaretDownIcon
                sx={{
                  ...styles.caretDownIcon,
                  rotate: '270deg'
                }}
              />
            </MenuItem>
          </Tooltip>
          <MenuItem
            onClick={() => {
              setOpenCreateNewReportDialog(true);
            }}
            sx={{
              pl: 0
            }}>
            <Typography sx={styles.menuTitle}>Create New Report</Typography>
          </MenuItem>
        </Box>
        <Divider sx={styles.dividerStyle} />
        <Box display='flex' flexDirection='column' sx={styles.boxContainer}>
          <Tooltip title='Clear all content from current report'>
            <MenuItem
              onClick={() => {
                setAction('clear');
              }}
              sx={{
                pl: 0
              }}>
              <Typography sx={styles.menuTitle}>Clear Report</Typography>
            </MenuItem>
          </Tooltip>
        </Box>
      </Box>
      <Popover
        open={isExpanded}
        anchorEl={popoverRef.current}
        onClose={() => {
          setIsExpanded(false);
        }}
        sx={styles.reportsPaper}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}>
        <Box ml={0}>
          {isLoading ? (
            <Box display='flex' flexDirection='column' sx={styles.boxContainer}>
              <MenuItem
                sx={{
                  pl: 0
                }}>
                <Typography sx={styles.menuTitle}>Loading...</Typography>
              </MenuItem>
            </Box>
          ) : (
            <Box display='flex' flexDirection='column'>
              <TextField
                variant='outlined'
                placeholder='Search...'
                sx={styles.searchInputBox}
                onChange={handleSearchTextChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
              <Typography sx={styles.title}>Recent Report</Typography>
              <Box display='flex' flexDirection='row' sx={styles.menuContainer}>
                <Divider orientation='vertical' sx={styles.dividerSubSection} />
                <ReportMenuList
                  reportToDisplay={reportToDisplay.slice(0, 3)}
                  handleReportClick={handleReportClick}
                  fromReportingPage={false}
                />
              </Box>
              {reportToDisplay?.length > 3 && (
                <Box display='flex' flexDirection='row' justifyContent='space-between'>
                  <StyledAccordion
                    disableGutters
                    expanded={isAccordionExpanded}
                    onChange={() => {
                      setIsAccordionExpanded(prev => !prev);
                    }}>
                    <AccordionSummary sx={accondionSummary}>
                      <Typography sx={styles.accordiontitle}>All Reports</Typography>
                      <IconButton sx={styles.iconButton}>
                        <CaretDownIcon
                          sx={{
                            fontSize: 16,
                            rotate: isAccordionExpanded ? '180deg' : '0deg',
                            color: 'primary.600'
                          }}
                        />
                      </IconButton>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      <Box display='flex' flexDirection='row' sx={styles.menuContainer}>
                        <Divider orientation='vertical' sx={styles.dividerSubSection} />
                        <ReportMenuList
                          reportToDisplay={reportToDisplay.slice(3, reportToDisplay?.length)}
                          handleReportClick={handleReportClick}
                          fromReportingPage={false}
                        />
                      </Box>
                    </AccordionDetails>
                  </StyledAccordion>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Popover>

      {openCreateNewReportDialog && (
        <NameConfirmationDialog
          open={openCreateNewReportDialog}
          name={name}
          description={description}
          onClose={() => {
            setOpenCreateNewReportDialog(false);
          }}
          onSave={handleCreateReport}
          heading='Create New Report'
          buttonText='Create Report'
          onDescriptionChange={(e: any) => setDescription(e.target.value)}
          onNameChange={(e: any) => setName(e.target.value)}
        />
      )}

      {openConfirmationDialog && (
        <ClearDialog
          open={openConfirmationDialog}
          title={currentReportName}
          action='close'
          onClose={handleSaveAndChangeReport}
          onClear={() => {}}
          onUpdateStatus={() => {}}
          handleDialogClose={() => {
            setOpenConfirmationDialog(false);
          }}
          handleDrawerClose={() => {
            handleChangeReport(selectedReportInfo);
          }}
        />
      )}
    </Popover>
  );
};

export default React.memo(FileButton);
