import React from 'react';
import { Box, Accordion, AccordionDetails, AccordionSummary, Divider } from '@mui/material';
import TimelineTrackHeader from './TimelineTrackHeader';
import TimelineTrack from './TimelineTrack';
import Applicant from '../MeetingDetails/Applicant';
import styles from '../../styles/Timeline.styles';
import { DropdownIcon } from '../../../../assets/svgs/Icons';

interface TimelineDetailsProps {
  timelineDetails: any;
  details: any;
  // eslint-disable-next-line no-unused-vars
  setOpenDocument: (openDocument: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setDocumentPath: (documentPath: string) => void;
  // eslint-disable-next-line no-unused-vars
  setDocumentTitle: (documentTitle: string) => void;
  // eslint-disable-next-line no-unused-vars
  setDocumentStartDate: (documentStartDate: string) => void;
  // eslint-disable-next-line no-unused-vars
  expandedApplicants: any;
  // eslint-disable-next-line no-unused-vars
  setExpandedApplicants: (expandedApplicants: any) => void;
  selectedLeftDrawerData: any;
  // eslint-disable-next-line no-unused-vars
  setSelectedLeftDrawerData: (selectedLeftDrawerData: any) => void;
  meetingId: string;
}

const TimelineDetails = ({
  timelineDetails,
  details,
  setOpenDocument,
  setDocumentPath,
  setDocumentTitle,
  setDocumentStartDate,
  expandedApplicants,
  setExpandedApplicants,
  selectedLeftDrawerData,
  setSelectedLeftDrawerData,
  meetingId
}: TimelineDetailsProps) => {
  const handleApplicantDropdownChange = (modifiedProductNumber: string) => {
    if (expandedApplicants?.includes(modifiedProductNumber)) {
      setExpandedApplicants(
        expandedApplicants?.filter((item: string) => item !== modifiedProductNumber)
      );
    } else if (modifiedProductNumber && modifiedProductNumber !== 'NA') {
      setExpandedApplicants([...expandedApplicants, modifiedProductNumber]);
    }
  };

  const isExpanded = (modifiedProductNumber: string) =>
    modifiedProductNumber && expandedApplicants?.includes(modifiedProductNumber);

  const hasMultipleApplicants = details?.applicants?.length > 1;

  return (
    <Box
      sx={{
        ...styles.timelineDetails.container,
        mx: hasMultipleApplicants ? 1.25 : 2,
        my: hasMultipleApplicants ? 1.5 : 1.25
      }}>
      {details?.applicants?.length > 0 &&
        details?.applicants?.map((applicant: any) => {
          return details?.applicants?.length === 1 ? (
            <TimelineTrack
              timelineTrack={
                timelineDetails && Object.keys(timelineDetails)?.length > 0
                  ? timelineDetails[applicant?.modified_product_number?.toLowerCase()]
                  : []
              }
              applicants={[applicant]}
              setOpenDocument={setOpenDocument}
              setDocumentPath={setDocumentPath}
              setDocumentTitle={setDocumentTitle}
              setDocumentStartDate={setDocumentStartDate}
              brandName={applicant?.brand_name}
              setSelectedLeftDrawerData={setSelectedLeftDrawerData}
              meetingId={meetingId}
              hasMultipleApplicants={hasMultipleApplicants}
            />
          ) : (
            <Accordion
              expanded={isExpanded(applicant?.modified_product_number)}
              onChange={() => handleApplicantDropdownChange(applicant?.modified_product_number)}
              key={applicant.modified_product_number}
              sx={styles.timelineDetails.accordion}>
              <AccordionSummary
                sx={{
                  ...styles.timelineDetails.accordionSummary,
                  minHeight: 35,
                  height: 35,
                  '& .MuiAccordionSummary-expandIconWrapper': {
                    '@media (min-width: 900px)': {
                      mt: isExpanded(applicant?.modified_product_number) ? -4.5 : '0'
                    }
                  },
                  '&.Mui-expanded': {
                    paddingTop: '8px',
                    paddingBottom: '8px',
                    md: { minHeight: 70 }
                  },
                  '& .MuiAccordionSummary-content': {
                    marginTop: '0px',
                    marginBottom: '0px'
                  }
                }}
                expandIcon={
                  <DropdownIcon
                    sx={{
                      fontSize: 16
                    }}
                  />
                }>
                <Box display='flex' flexDirection='column' width='100%'>
                  <Box ml={-0.75}>
                    <Applicant
                      brandName={applicant?.brand_name}
                      sponsorName={applicant?.sponsor_name}
                      authorizationPartner={applicant?.authorization_partner}
                    />
                    {isExpanded(applicant?.modified_product_number) && (
                      <Divider aria-hidden='true' sx={styles.timelineDetails.divider} />
                    )}
                  </Box>
                  {isExpanded(applicant?.modified_product_number) && (
                    <TimelineTrackHeader
                      applicant={applicant}
                      selectedLeftDrawerData={selectedLeftDrawerData}
                      setSelectedLeftDrawerData={setSelectedLeftDrawerData}
                      setOpenDocument={setOpenDocument}
                      setDocumentPath={setDocumentPath}
                      setDocumentTitle={setDocumentTitle}
                      setDocumentStartDate={setDocumentStartDate}
                    />
                  )}
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ py: 0 }}>
                <TimelineTrack
                  timelineTrack={
                    timelineDetails && Object.keys(timelineDetails)?.length > 0
                      ? timelineDetails[applicant?.modified_product_number?.toLowerCase()]
                      : []
                  }
                  applicants={[applicant]}
                  setOpenDocument={setOpenDocument}
                  setDocumentPath={setDocumentPath}
                  setDocumentTitle={setDocumentTitle}
                  setDocumentStartDate={setDocumentStartDate}
                  brandName={applicant?.brand_name}
                  setSelectedLeftDrawerData={setSelectedLeftDrawerData}
                  meetingId={meetingId}
                  hasMultipleApplicants={hasMultipleApplicants}
                />
              </AccordionDetails>
            </Accordion>
          );
        })}
    </Box>
  );
};

export default React.memo(TimelineDetails);
