import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';

import { Typography, Divider, Stack, Tooltip, Box } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import RegulatoryInformationStore from '../../../store/RegulatoryInformation';

import TimelineCircularAvatar from '../../../components/Timeline/TimelineAvatars/TimelineCircularAvatar';
import APPROVAL_STATUS_TAGS from '../../../components/Timeline/TimelineAvatars/constants';

import styles from './styles';
import ReportButton from '../../../components/Report/ReportButton';
import useReportingHandler from '../../../components/Report/hooks/useReportingHandler';

interface DataItem {
  office?: string;
  division?: string;
  approval_pathway?: string;
  original_sponsor?: string;
  description?: string;
  approval_time?: string;
  submission_type?: string;
  submission_num?: string;
  // Include other properties as needed
}

interface AdministrativeSummaryProps {
  dataItem: DataItem;
  selectedTabIndex: number;
}

interface SectionProps {
  label: string;
  children: React.ReactNode;
}
const Section: React.FC<SectionProps> = ({ label, children }) => (
  <>
    <Stack direction='row' spacing={2} alignItems='center'>
      <Typography variant='subtitle2' sx={styles.snapshotLabel}>
        {label}:
      </Typography>
      {children}
    </Stack>
    <Divider sx={{ mt: 2 }} />
  </>
);

const AdministrativeSummary: React.FC<AdministrativeSummaryProps> = ({
  dataItem,
  selectedTabIndex
}: any) => {
  const { source, number } = useParams<any>();
  const { regulatoryState } = useContext<any>(RegulatoryInformationStore);
  const { updateReportData } = useReportingHandler();

  const selectedTab = Object.keys(dataItem?.snapshot)[selectedTabIndex];

  const sections = [
    {
      label: 'Approval Tracks',
      content: (
        <TimelineCircularAvatar
          data={dataItem as any}
          avatarList={APPROVAL_STATUS_TAGS.approval_history}
        />
      )
    },
    { label: 'Office', content: dataItem.office || 'Not available' },
    { label: 'Division', content: dataItem.division || 'Not available' },
    { label: 'Approval Pathway', content: dataItem.approval_pathway || 'Not available' },
    { label: 'Original Sponsor', content: dataItem.original_sponsor || 'Not available' },
    { label: 'Submission Class', content: dataItem.description || 'Not available' },
    {
      label: 'Approval Time',
      content: (
        <Stack direction='row' spacing={1} alignItems='center'>
          <AccessTimeIcon sx={{ fontSize: 14 }} />
          <Typography variant='subtitle2' sx={styles.snapshotText}>
            {dataItem.approval_time || 'Not available'}
          </Typography>
        </Stack>
      )
    }
  ];

  const addToReport = (_: any, reportData: any) => {
    const id = `snapshot_administrative_summary_${source}_${number}_${dataItem?.submission_type}_${dataItem?.submission_num}_${dataItem?.snapshot[selectedTab]?.document_id}`;
    const sectionLayout = {
      id,
      sectionType: 'SNAPSHOT',
      data: {
        number,
        source,
        submission_num: dataItem?.submission_num,
        submission_type: dataItem?.submission_type,
        summaryType: 'ADMINISTRATIVE_SUMMARY'
      },
      style: {
        placement: {
          h: 45,
          w: 12,
          i: id,
          minW: 8,
          moved: false,
          static: false,
          x: 0,
          y: 0
        },
        title: `Administrative Summary - ${
          typeof selectedTab === 'string'
            ? selectedTab
            : regulatoryState?.applicationDetails?.product_name
        } - ${dataItem?.submission_type} - ${dataItem?.submission_num}`
      }
    };
    updateReportData(reportData?.id, sectionLayout);
  };

  return (
    <Stack
      spacing={2}
      sx={{
        bgcolor: 'white.main',
        borderRadius: '12px',
        p: '20px'
      }}>
      <Box display='flex' justifyContent='space-between'>
        <Tooltip
          title='Provides an overview of administrative details for the submission'
          placement='top-start'>
          <Typography variant='subtitle1' sx={styles.snapshotHeading}>
            Administrative Summary
          </Typography>
        </Tooltip>
        <Tooltip title='Add to Report'>
          <ReportButton data={{}} addToReport={addToReport} />
        </Tooltip>
      </Box>

      {sections.map(({ label, content }) => (
        <Section key={label} label={label}>
          {typeof content === 'string' || Array.isArray(content) ? (
            <Typography variant='subtitle2' sx={styles.snapshotText}>
              {content}
            </Typography>
          ) : (
            content
          )}
        </Section>
      ))}
    </Stack>
  );
};

export default React.memo(AdministrativeSummary);
