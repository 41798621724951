import React, { useContext, useEffect, useState } from 'react';

import {
  Button,
  Divider,
  IconButton,
  Popover,
  Skeleton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import {
  AriaResponseFollowUp,
  AriaResponseSelected,
  InfoIconSummary,
  VerifyIcon
} from '../../../assets/svgs/Icons';

// hooks
import useChatRiaAnswer from '../hooks/useChatRiaAnswer';
import useDocumentsView from '../hooks/useDocumentsViewFunctions';

import { prepareDocumentCard, scrollToChild } from '../utils/documentResultsUtils';
import AriaResponseText from './AriaResponseText';

import styles from '../styles/ChatRiaAnswer.styles';
import SearchResultsStore from '../../../store/SearchResults';
import ReportButton from '../../../components/Report/ReportButton';

const ChatRiaAnswer = ({
  shouldInitialize = true,
  originalQuestion = ''
}: {
  shouldInitialize?: boolean;
  originalQuestion?: string;
}) => {
  const { resultsState } = useContext(SearchResultsStore);
  const { initializeIntelligentAnswer, ariaClickAnswerEvent, addToReport, addedToReportList } =
    useChatRiaAnswer();
  const { handleChatRia } = useDocumentsView();
  // state for popover showed on hover of info icon on the RIA response title
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  useEffect(() => {
    if (shouldInitialize) {
      initializeIntelligentAnswer(originalQuestion);
    }
  }, [resultsState.selectedAriaResponse]);

  const handleAddToReport = (_: any, reportDetails: any) => {
    addToReport(null, null, reportDetails);
  };

  // Determine which document to use based on the emptiness of selectedAriaResponse
  const documentToUse =
    Object.keys(resultsState.selectedAriaResponse).length !== 0
      ? resultsState.selectedAriaResponse?.item
      : resultsState.documentResults.results?.[0];

  // Now, determine the documentLink using the chosen document
  const documentLink = resultsState.originalTextEnabled
    ? documentToUse?.document_url_original_language ?? documentToUse?.document_url
    : documentToUse?.document_url;

  return (
    <Stack
      spacing={1.5}
      sx={{
        ...styles.container,
        maxWidth: resultsState.selectedDocument ? '100%' : 389,
        py: resultsState.selectedDocument ? 1.5 : 2.5,
        px: 2
      }}>
      {/* header text */}
      <Stack direction='row' alignItems='center' justifyContent='space-between'>
        <Stack
          direction='row'
          alignItems='center'
          spacing={1}
          sx={{ cursor: !resultsState.selectedDocument ? 'pointer' : 'default' }}
          onClick={
            // If there is no selected document, scroll to the selected Aria response
            !resultsState.selectedDocument
              ? () => scrollToChild(resultsState.selectedAriaResponse?.item?.scrollIndex || 0)
              : undefined
          }>
          <AriaResponseSelected fontSize='small' />
          <Typography variant='subtitle2' sx={styles.title}>
            RIA Response ({resultsState.riaResponseTitle})
          </Typography>
          {resultsState.showTop10Summary && (
            <IconButton
              size='small'
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup='true'
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}>
              <InfoIconSummary sx={{ width: 16, height: 16, color: 'secondary.350' }} />
            </IconButton>
          )}
          {/* popover to be showed on hover of info icon */}
          <Popover
            id='mouse-over-popover'
            sx={{
              pointerEvents: 'none',
              '& .MuiPopover-paper': {
                borderRadius: '8px',
                bgcolor: 'none'
              }
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus>
            <Stack
              p={1}
              spacing={1}
              sx={{
                maxWidth: 260,
                borderRadius: '8px',
                bgcolor: 'primary.50',
                border: '1px solid',
                borderColor: 'primary.100'
              }}>
              <Stack direction='row' spacing={1} alignItems='center'>
                <CircleIcon sx={styles.infoText} />
                <Typography variant='body1'>
                  Key insights are the top summaries found by RIA from user questions and
                  GenAI&apos;s analysis.
                </Typography>
              </Stack>
              <Stack direction='row' spacing={1} alignItems='center'>
                <CircleIcon sx={styles.infoText} />
                <Typography variant='body1'>Can use upto 10 documents at a time!</Typography>
              </Stack>
              <Typography variant='body2' sx={styles.secondaryText}>
                (Generative AI is experimental. Curated response quality may vary.)
              </Typography>
            </Stack>
          </Popover>
        </Stack>
      </Stack>

      {/* answer text */}
      {resultsState.ariaResponseLoading ? (
        <Stack>
          <Skeleton animation='wave' sx={styles.loadingSkeleton} />
          <Skeleton animation='wave' width='60%' sx={styles.loadingSkeleton} />
          <Skeleton animation='wave' width='30%' sx={styles.loadingSkeleton} />
        </Stack>
      ) : (
        <Stack spacing={1.5}>
          {/* gpt answer */}
          <AriaResponseText addToReport={addToReport} addedToReportList={addedToReportList} />

          {!resultsState.selectedDocument && !resultsState.showTop10Summary && (
            <Stack alignItems='center' direction='row' gap='14px'>
              <Stack
                direction='row'
                onClick={ariaClickAnswerEvent}
                spacing={0.5}
                alignItems='center'
                sx={{
                  cursor: 'pointer',
                  display: 'inline-flex'
                }}>
                <VerifyIcon sx={styles.iconBtn} />
                <Typography noWrap variant='body1' sx={styles.verifyText}>
                  Verify
                </Typography>
              </Stack>
              <Stack
                direction='row'
                spacing={0.5}
                alignItems='center'
                sx={{
                  cursor: 'pointer',
                  display: 'inline-flex'
                }}>
                <Tooltip title='Add to Report'>
                  <ReportButton data={{}} addToReport={handleAddToReport} />
                </Tooltip>
              </Stack>
            </Stack>
          )}

          <Divider />

          {/* follow up button */}
          {!resultsState.selectedDocument && !resultsState.showTop10Summary && (
            <Button
              disableElevation
              variant='text'
              onClick={() =>
                handleChatRia({
                  resultDetails:
                    Object.keys(resultsState.selectedAriaResponse).length === 0
                      ? prepareDocumentCard(resultsState.documentResults.results[0])
                      : resultsState.selectedAriaResponse,
                  pdfUrl: documentLink
                })
              }
              startIcon={<AriaResponseFollowUp />}
              sx={styles.followUpBtn}>
              <Typography>Ask a Follow-up (With Chat RIA)</Typography>
            </Button>
          )}
          <Typography variant='body2' sx={styles.secondaryText}>
            (Generative AI is experimental. Curated response quality may vary.)
          </Typography>
        </Stack>
      )}
    </Stack>
  );
};

ChatRiaAnswer.defaultProps = {
  shouldInitialize: true,
  originalQuestion: ''
};
export default ChatRiaAnswer;
