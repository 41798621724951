

import React from 'react';
import { Box, Chip, Tooltip } from '@mui/material';
import { EuFlagIcon, ModuleInfo } from '../../../../assets/svgs/Icons';
import styles from '../../styles/Header.styles';

interface HeaderTitleProps {
  meetingName: string;
  tooltipContent: string;
  iconComponent?: any; // Allow dynamic icons if needed
}

const HeaderTitle: React.FC<HeaderTitleProps> = ({ meetingName, tooltipContent, iconComponent }) => (
  <Box display='flex' alignItems='center'>
    {meetingName}
    {iconComponent || <EuFlagIcon sx={styles.euFlagIcon} />}
    <Chip label='EMA' sx={styles.emaChip} />
    <Tooltip title={tooltipContent} placement='top'>
      <ModuleInfo sx={{ fontSize: 16, ml: 1 }} />
    </Tooltip>
  </Box>
);

export default React.memo(HeaderTitle);
