import React from 'react';
import { Box, Divider } from '@mui/material';
import SectionDetails from '../Section/SectionDetails';
import TimelineTrackHeader from './TimelineTrackHeader';
import styles from './Timeline.styles';

interface TimelineOverviewProps {
  sectionData: any;
  details: any;
}

const TimelineOverview = ({ sectionData, details }: TimelineOverviewProps) => {
  return (
    <Box sx={styles.timelineOverview.container}>
      <SectionDetails data={sectionData} />
      <Divider orientation='horizontal' sx={styles.timelineOverview.divider} />
      <TimelineTrackHeader reg360={details?.reg360} productNumber={details?.product_number} />
    </Box>
  );
};

export default React.memo(TimelineOverview);
