import { createSvgIcon } from '@mui/material/utils';

const DropDownIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='17' viewBox='0 0 16 17' fill='none'>
    <path
      d='M4.2759 6.5H11.7239C11.8557 6.50003 11.9846 6.53914 12.0942 6.6124C12.2038 6.68565 12.2892 6.78976 12.3397 6.91156C12.3901 7.03336 12.4033 7.16738 12.3776 7.29669C12.3519 7.42599 12.2884 7.54476 12.1952 7.638L8.47123 11.362C8.34621 11.487 8.17668 11.5572 7.9999 11.5572C7.82312 11.5572 7.65358 11.487 7.52857 11.362L3.80457 7.638C3.71136 7.54476 3.64789 7.42599 3.62218 7.29669C3.59647 7.16738 3.60967 7.03336 3.66011 6.91156C3.71056 6.78976 3.79599 6.68565 3.90559 6.6124C4.0152 6.53914 4.14407 6.50003 4.2759 6.5Z'
      fill='#424242'
    />
  </svg>,
  'DropDown'
);
export default DropDownIcon;
