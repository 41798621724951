import React, { Dispatch, useContext, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Skeleton,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';

import styles from './styles/DocumentSearchKeywordSelection.styles';
import { CloseIcon } from '../../assets/svgs/Icons';
import { getDocumentSearchKeywords } from '../../api/modules/userNotification';

// store
import Store from '../../store';
import Actions from '../../store/actions';

const DocumentSearchKeywordSelection = ({
  openKeywordPopup,
  setOpenKeywordPopup,
  searchQuery,
  handleSubscribe
}: {
  openKeywordPopup: boolean;
  setOpenKeywordPopup: Dispatch<React.SetStateAction<boolean>>;
  searchQuery: string;
  // eslint-disable-next-line no-unused-vars
  handleSubscribe: (documentSubscribeQuery: string) => Promise<void>;
}) => {
  const { dispatch } = useContext(Store) as any;

  const [loading, setLoading] = useState(false);
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [keywords, setKeywords] = useState<string[]>([]);

  const finalKeywordsQuery = useMemo(() => keywords.join(' AND '), [keywords]);

  const handleClose = (event: any, reason: any) => {
    if (reason && reason === 'backdropClick') return;
    setOpenKeywordPopup(false);
  };

  const submitKeywordsWrapper = async () => {
    setSubscribeLoading(true);
    await handleSubscribe(finalKeywordsQuery);
    setSubscribeLoading(false);
    setOpenKeywordPopup(false);
  };

  const getKeywords = async () => {
    setLoading(true);
    try {
      const response = await getDocumentSearchKeywords(searchQuery);
      const keywordsList = response?.data?.body?.keywords ?? [];
      if (keywordsList.length > 0) setKeywords(keywordsList);
      else {
        setKeywords([]);
        dispatch({
          type: Actions.SET_ALERT,
          value: {
            status: true,
            message: response?.data?.error?.message ?? 'Could not get keywords for the search query'
          }
        });
        setOpenKeywordPopup(false);
      }
    } catch (error) {
      setKeywords([]);
    }
    setLoading(false);
  };

  const finalKeywordsQuerySentence = useMemo(() => {
    if (keywords.length === 0) return ''; // No keywords
    if (keywords.length === 1) return `${keywords[0]}`; // Single keyword
    if (keywords.length === 2) return `${keywords[0]} and ${keywords[1]}`; // Two keywords

    // For three or more keywords
    const lastKeyword = keywords[keywords.length - 1];
    const otherKeywords = keywords.slice(0, -1).join(', ');
    return `${otherKeywords}, and ${lastKeyword}`;
  }, [keywords]);

  useEffect(() => {
    if (openKeywordPopup) getKeywords();
  }, [searchQuery, openKeywordPopup]);

  return (
    <Stack>
      <Dialog
        hideBackdrop
        disableEscapeKeyDown
        open={openKeywordPopup}
        onClose={handleClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
          elevation: 2,
          sx: styles.dialogPaper
        }}
        sx={styles.dialogWrapper}>
        <DialogContent sx={styles.dialogContent}>
          <Stack sx={styles.contentWrapper}>
            <Box sx={styles.titleBox}>
              <Typography sx={styles.titleText}>
                {loading ? 'Searching for Keyowords' : 'Subscribe for Updates'}
              </Typography>
              <Box sx={styles.closeButtonBox}>
                <Tooltip title='Close'>
                  <IconButton onClick={() => setOpenKeywordPopup(false)} size='medium'>
                    <CloseIcon sx={styles.closeButtonIcon} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
            <Divider sx={styles.divider} />
            <Typography sx={styles.subtitleText}>
              You’ll receive relevant notifications for new results that has all the
            </Typography>
            <Stack direction='row' spacing='4px' alignItems='center' justifyContent='center'>
              <Typography sx={styles.subtitleText}>
                important terms listed below with the applied filters:
              </Typography>
              {!loading && (
                <Tooltip
                  title={
                    <Box>
                      <Box>
                        You will only receive notifications for documents that include all the
                        specified terms:
                        <br />
                        <span style={styles.textSpan1}>
                          <strong>{finalKeywordsQuerySentence}</strong>
                        </span>
                      </Box>
                      <Box sx={styles.textSpan2}>
                        Please note: Synonyms for these terms are not included
                      </Box>
                    </Box>
                  }>
                  <InfoOutlined sx={styles.infoIcon} />
                </Tooltip>
              )}
            </Stack>
            {loading && (
              <Box sx={styles.skeletonBox}>
                <Skeleton variant='rectangular' width='220px' height='28px' animation='wave' />
              </Box>
            )}
            {!loading && (
              <Typography sx={styles.finalKeywordsQuery}>{finalKeywordsQuery}</Typography>
            )}
            <Button
              variant='contained'
              onClick={submitKeywordsWrapper}
              disabled={keywords.length === 0 || loading}
              sx={styles.subscribeButton}>
              {subscribeLoading ? <CircularProgress size={20} /> : 'Subscribe Now'}
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </Stack>
  );
};

export default DocumentSearchKeywordSelection;
