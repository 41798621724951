import React from 'react';
import { FormControl, MenuItem, Stack, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import widgetStyles from './styles/widgets.styles';

export const CustomSelect = styled(Select)(() => ({
  fontSize: 14,
  fontWeight: 500,
  color: 'gray.900',
  ...widgetStyles.customStyled
}));

const VivproReportSelect = React.memo(
  ({ label, value, options, handleChange, disabled = false }: any) => {
    return (
      <Stack key={label} display='flex' flexDirection='column' width='100%'>
        <Typography mb={0.5} sx={widgetStyles.propertiesHeading}>
          {label}
        </Typography>
        <FormControl key={label} sx={widgetStyles.formStyle}>
          <CustomSelect
            id={label}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            size='small'>
            {options.map((opt: any) => (
              <MenuItem
                sx={{ color: 'red', fontSize: '14px' }}
                key={String(opt.value)}
                value={opt.value}>
                {opt.label}
              </MenuItem>
            ))}
          </CustomSelect>
        </FormControl>
      </Stack>
    );
  }
);

export default React.memo(VivproReportSelect);
