import React from 'react';
import { Box, Typography } from '@mui/material';
import RightArrowIcon from '../../../../assets/svgs/PDCO/RightArrow';
import LeftArrowIcon from '../../../../assets/svgs/PDCO/LeftArrow';
// styles
import styles from '../../styles/SectionFlyout.styles';

const FlyoutToggle = ({
  showSection,
  onToggle
}: {
  showSection: boolean;
  onToggle: () => void;
}) => (
  <Box onClick={onToggle} sx={styles.hideView}>
    {showSection ? (
      <RightArrowIcon sx={{ fontSize: 10 }} />
    ) : (
      <LeftArrowIcon sx={{ fontSize: 10 }} />
    )}
    <Typography sx={styles.hideViewText}>{showSection ? 'Hide' : 'View'}</Typography>
  </Box>
);

export default FlyoutToggle;
