import { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Divider, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import notificationsStyles from './components/Notifications.styles';
import NotificationApplications from './components/ApplicationsSubscription';
import SearchesSubscription from './components/SearchesSubscription';
import EmailSettings from './components/EmailSettings';
import SourceSubscription from './components/SourceSubscription';
import { Header } from '../UserProfile/interface';

// store
import NotificationsStore from '../../store/Notifications';
import NotificationsActions from '../../store/Notifications/actions';
import HeaderTabs from '../UserProfile/components/HeaderTabs';
import DocumentSearchesSubscription from './components/DocumentSearchesSubscription';

const NotificationSettings = () => {
  const location = useLocation();
  const { notificationsDispatch } = useContext(NotificationsStore);
  const [tab, setTab] = useState('source');

  const tabList = [
    {
      label: 'Source',
      value: 'source',
      component: <SourceSubscription />
    },
    {
      label: 'Application (Reg360)',
      value: 'applications',
      component: <NotificationApplications />
    },
    {
      label: 'Application Search',
      value: 'searches',
      component: <SearchesSubscription />
    },
    {
      label: 'Document Search',
      value: 'document-searches',
      component: <DocumentSearchesSubscription />
    }
  ];

  const isFromNotification = useMemo(
    () => location.pathname.includes('notifications'),
    [location.pathname]
  );

  // based on search params, set the selected tab using useEffect
  useEffect(() => {
    const urlTabValue = new URLSearchParams(location.search).get('tab') ?? 'source';
    if (urlTabValue) {
      setTab(urlTabValue);
    }
  }, [location]);

  // reset the subscribed sources, searches and applications when the component is mounted
  // this is done to avoid situations where user subscribes/unsubscribes on notifications and then navigates to settings
  useEffect(() => {
    notificationsDispatch({
      type: NotificationsActions.SET_SUBSCRIBED_SOURCES,
      value: {}
    });
    notificationsDispatch({
      type: NotificationsActions.SET_SUBSCRIBED_SEARCHES,
      value: []
    });
    notificationsDispatch({
      type: NotificationsActions.SET_SUBSCRIBED_DOCUMENT_SEARCHES,
      value: []
    });
    notificationsDispatch({
      type: NotificationsActions.SET_SUBSCRIBED_APPLICATIONS,
      value: []
    });
  }, []);

  return (
    <>
      <Grid item md={isFromNotification ? 6 : 8}>
        <Box
          sx={
            isFromNotification
              ? notificationsStyles.notificationContainer
              : notificationsStyles.notificationContainerUserProfile
          }>
          <HeaderTabs
            header={tabList.map(tabVal => ({ key: tabVal.value, label: tabVal.label } as Header))}
            selectedTab={tab}
            setSelectedTab={setTab}
          />
          <Divider />
          {tabList.map(tabItem => (tab === tabItem.value ? tabItem.component : null))}
        </Box>
      </Grid>
      <Grid item md={4}>
        <EmailSettings />
      </Grid>
    </>
  );
};
export default NotificationSettings;
