import React from 'react';
import { Box, Typography, Divider, Tooltip } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import styles from '../../styles/Timeline.styles';

interface TimelineTrackHeaderProps {
  applicant: any;
  selectedLeftDrawerData: any;
  // eslint-disable-next-line no-unused-vars
  setSelectedLeftDrawerData: (selectedLeftDrawerData: any) => void;
  // eslint-disable-next-line no-unused-vars
  setOpenDocument: (openDocument: boolean) => void;
  // eslint-disable-next-line no-unused-vars
  setDocumentPath: (documentPath: string) => void;
  // eslint-disable-next-line no-unused-vars
  setDocumentTitle: (documentTitle: string) => void;
  // eslint-disable-next-line no-unused-vars
  setDocumentStartDate: (documentStartDate: string) => void;
}

const TimelineTrackHeader = ({
  applicant,
  selectedLeftDrawerData,
  setSelectedLeftDrawerData,
  setOpenDocument,
  setDocumentPath,
  setDocumentTitle,
  setDocumentStartDate
}: TimelineTrackHeaderProps) => {
  const isPSURSelected =
    selectedLeftDrawerData?.type === 'PSURs' && selectedLeftDrawerData?.data?.length > 1;
  const isRMPSelected =
    selectedLeftDrawerData?.type === 'RMPs' && selectedLeftDrawerData?.data?.length > 1;
  const enableRMP = applicant?.rmps?.length > 0;
  const enablePSUR = applicant?.psur?.length > 0;
  const isReg360Available = applicant?.reg360 && applicant?.reg360 !== 'NA';

  const handleRMPClick = (ev: any) => {
    ev.stopPropagation();
    if (enableRMP) {
      setSelectedLeftDrawerData({ type: 'RMPs', data: applicant?.rmps });
      setOpenDocument(false);
      setDocumentPath('');
      setDocumentTitle('');
      setDocumentStartDate('');
    }
  };

  const handlePSURClick = (ev: any) => {
    ev.stopPropagation();
    if (enablePSUR) {
      setSelectedLeftDrawerData({ type: 'PSURs', data: applicant?.psur });
      setOpenDocument(false);
      setDocumentPath('');
      setDocumentTitle('');
      setDocumentStartDate('');
    }
  };

  const handleReg360Click = (ev: any) => {
    ev.stopPropagation();
    if (applicant?.reg360 && applicant?.reg360 !== 'NA') {
      window.open(`/regulatory360/core/${applicant?.reg360}/eu`, '_blank');
    }
  };

  return (
    <Box display='flex' alignItems='center'>
      <Tooltip title={applicant?.product_number}>
        <Typography sx={styles.timelineTrackHeader.productNumber}>
          {applicant?.product_number}
        </Typography>
      </Tooltip>
      <Divider orientation='vertical' sx={styles.common.divider} />
      <Box display='flex' alignItems='center' onClick={ev => handleReg360Click(ev)}>
        <OpenInNewIcon
          sx={{
            fontSize: 18,
            color: isReg360Available ? 'blue.background' : 'gray.500',
            mr: 0.5,
            cursor: isReg360Available ? 'pointer' : 'default'
          }}
        />
        <Typography
          fontSize={13}
          fontWeight={600}
          color={isReg360Available ? 'gray.700' : 'gray.500'}
          sx={{ cursor: isReg360Available ? 'pointer' : 'default' }}>
          Go to Reg360
        </Typography>
      </Box>
      <Divider orientation='vertical' sx={styles.common.divider} />
      <Typography
        sx={{
          ...styles.timelineTrackHeader.link,
          textDecoration: isRMPSelected ? 'underline' : 'none',
          cursor: enableRMP ? 'pointer' : 'default',
          color: enableRMP ? 'primary.700' : 'gray.500'
        }}
        onClick={ev => handleRMPClick(ev)}>
        See all RMPs
      </Typography>
      <Divider orientation='vertical' sx={styles.common.divider} />
      <Typography
        sx={{
          ...styles.timelineTrackHeader.link,
          textDecoration: isPSURSelected ? 'underline' : 'none',
          cursor: enablePSUR ? 'pointer' : 'default',
          color: enablePSUR ? 'primary.700' : 'gray.500'
        }}
        onClick={ev => handlePSURClick(ev)}>
        See all PSURs
      </Typography>
    </Box>
  );
};

export default React.memo(TimelineTrackHeader);
