/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
/* eslint-disable react/destructuring-assignment */
import React, { useCallback, useMemo, useState } from 'react';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// styles
import styles from '../../EMAGuidance/styles/DocumentHistory.styles';

// Icons
import { getDocumentSourceName } from '../../SearchResults/utils/documentResultsUtils';
import { FolderIcon } from '../../../assets/svgs/Icons';
import { getCanadaGuidanceDocumentsSignedURL } from '../../../api/pages/CanadaGuidance';

import { DocumentItem, DocumentsGroup } from '../../../components/Dialogs/DocumentListing/types';
import getDateString from '../../../utils/getDateString';
import DocumentListing from '../../../components/Dialogs/DocumentListing';
import formatDateVersionedTitle from '../utils/formatDateVersionedTitle';

const GridCellDocuments: React.FC<any> = ({ data }) => {
  const [open, setOpen] = useState<boolean>(false);

  const handleOpenPopup = (e: any) => {
    e.preventDefault();
    setOpen(true);
  };

  const getPDFUrlFromS3Path = useCallback(async (pdfLink: any) => {
    const res = await getCanadaGuidanceDocumentsSignedURL({
      source: 'ca_guidance',
      s3_path: pdfLink
      // eslint-disable-next-line no-console
    }).catch(err => console.error(err));

    return res?.data?.body?.s3_url;
  }, []);

  const { documents, documentCount }: { documents: DocumentsGroup[]; documentCount: number } =
    useMemo(() => {
      const {
        doc_title: title,
        s3_path: pdfUrl,
        issue_date: issueDate,
        effective_date: effectiveDate,
        last_modified_date: lastModifiedDate,
        date_adopted: dateAdopted
      } = data;

      let documentCount = 1;
      const currentVersionGroup: DocumentsGroup = {
        id: 'current_versions',
        categoryTitle: 'Current Version',
        documents: [
          {
            title: formatDateVersionedTitle(title),
            title_link: pdfUrl,
            metadata: [
              { key: 'Issue Date', value: issueDate ? getDateString(issueDate) : '' },
              {
                key: 'Legal Effective Date',
                value: effectiveDate ? getDateString(effectiveDate) : ''
              },
              {
                key: 'Updated Date',
                value: lastModifiedDate ? getDateString(lastModifiedDate) : ''
              },
              { key: 'Adopted Date', value: dateAdopted ? getDateString(dateAdopted) : '' }
            ].filter(data => !!data.value),
            getPdfUrl: getPDFUrlFromS3Path
          }
        ]
      };

      const docs: DocumentItem[] = data.document_history.map(
        ({
          doc_title: title,
          s3_path: pdfUrl,
          issue_date: issueDate,
          effective_date: effectiveDate,
          last_modified_date: lastModifiedDate,
          date_adopted: dateAdopted
        }: any) => {
          documentCount += 1;
          return {
            title: formatDateVersionedTitle(title),
            title_link: pdfUrl,
            metadata: [
              { key: 'Issue Date', value: issueDate ? getDateString(issueDate) : '' },
              {
                key: 'Legal Effective Date',
                value: effectiveDate ? getDateString(effectiveDate) : ''
              },
              {
                key: 'Updated Date',
                value: lastModifiedDate ? getDateString(lastModifiedDate) : ''
              },
              { key: 'Adopted Date', value: dateAdopted ? getDateString(dateAdopted) : '' }
            ].filter(data => !!data.value),
            getPdfUrl: getPDFUrlFromS3Path
          };
        }
      );

      const group: DocumentsGroup = {
        id: 'other_versions',
        categoryTitle: 'Previous Versions',
        documents: docs
      };

      if (!docs.length) {
        return { documents: [currentVersionGroup], documentCount };
      }

      return { documents: [currentVersionGroup, group], documentCount };
    }, []);

  const chatRIAMetadata = useMemo(() => {
    return {
      issued_date: data?.issued_date ?? '',
      last_modified_date: data?.last_modified_date ?? '',
      _category: data?.category ?? '',
      title: data?.title ?? ''
    };
  }, [data]);

  const metadata = useMemo(() => {
    return [data?.issued_date ? getDateString(data?.issued_date) : '', data?.category ?? ''].filter(
      (title: string) => !!title
    );
  }, [data]);

  const mappedSourceName = getDocumentSourceName('CA Guidances');

  return (
    <Box sx={styles.textAlignment}>
      <Box alignItems='center' sx={{ cursor: 'pointer' }}>
        {data && (
          <Stack
            direction='row'
            spacing={0.5}
            alignItems='center'
            sx={styles.linkItem}
            onClick={e => handleOpenPopup(e)}>
            <FolderIcon sx={{ fontSize: 12 }} />
            <Typography variant='subtitle2' sx={styles.pdfCellText}>
              {data?.document_count}
            </Typography>
          </Stack>
        )}
      </Box>
      {open && (
        <DocumentListing
          defaultDocuments={documents}
          defaultSelectedEntityId=''
          title={data?.title}
          onClose={() => setOpen(false)}
          chatRIAMetadata={chatRIAMetadata}
          source={mappedSourceName as string}
          metadata={metadata}
          infoComponent={null}
          disableChatRia={false}
          secondaryDrawerTitle=''
          allowComparison={documentCount > 1}
        />
      )}
    </Box>
  );
};

const DocumentHistoryPDF = (params: any) => {
  return <GridCellDocuments data={params.row} />;
};

export default DocumentHistoryPDF;
