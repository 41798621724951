import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import VISUALIZE_CHART_MAPPING from '../../../components/Insights/const';
import Insights from '../../../components/Insights/Insights';

const InsightTab = ({ insights, isLoading }: any) => {
  const { search } = useParams() as any;
  const getInsightStatsList = useMemo(() => {
    if (insights) {
      return [
        {
          text: 'Active Ingredients',
          count: insights?.active_ingredients,
          toolTipValue: ''
        },
        {
          text: 'Applications',
          count: insights?.applications,
          toolTipValue: ''
        },
        {
          text: 'Original Designations',
          count: insights?.orig_designations
        }
      ];
    }
    return [];
  }, [insights]);

  const getDesignations = (designations: { [s: string]: any }) => {
    const designationsArray = Object.entries(designations).map(([key, value]) => ({
      id: key,
      value,
      label: key.toUpperCase()
    }));

    return designationsArray;
  };

  const getLayer1Charts = useMemo(() => {
    const updatedGraph = VISUALIZE_CHART_MAPPING['speciality-desgination']?.layer1;

    if (updatedGraph) {
      updatedGraph.forEach(graph => {
        if (graph?.id === 'desgination_by_year' && insights?.year_graph) {
          // eslint-disable-next-line no-param-reassign
          graph.data = insights?.year_graph
            ?.map((item: any) => ({ id: item.name, value: item.value }))
            .sort((a: { id: number }, b: { id: number }) => a.id - b.id);
        }
      });
      return updatedGraph;
    }
    return updatedGraph;
  }, [insights]);

  const getLayer2Charts = useMemo(() => {
    const updatedGraph = VISUALIZE_CHART_MAPPING['speciality-desgination']?.layer2;
    if (updatedGraph) {
      updatedGraph.forEach(graph => {
        if (graph?.id === 'designations' && insights?.designations) {
          // eslint-disable-next-line no-param-reassign
          graph.data = getDesignations(insights?.designations);
        }
        if (graph?.id === 'designations_by_indications' && insights?.indications) {
          // eslint-disable-next-line no-param-reassign
          graph.data = Object.entries(insights?.indications).map(([key, value], index) => ({
            id: `${index}-${key}`,
            indication: key,
            value
          }));
          // eslint-disable-next-line no-param-reassign
          graph.config.csvFileName = `${search}-indications.csv`;
        }
      });

      return updatedGraph;
    }
    return updatedGraph;
  }, [insights, search]);

  return (
    <Insights
      source='speciality-desgination'
      searchType='speciality-desgination'
      appliedFilter={{}}
      searchTerm={search}
      statsList={getInsightStatsList}
      layer1Component={getLayer1Charts}
      layer2Component={getLayer2Charts}
      isLoading={isLoading}
    />
  );
};

export default React.memo(InsightTab);
