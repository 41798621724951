const styles = {
  timeline: {
    drawer: {
      '& .MuiDrawer-paperAnchorRight': {
        top: 0
      },
      '& >.MuiBackdrop-root': {
        backgroundColor: 'rgba(0,0,0,0.25)'
      }
    }
  },
  timelineHeader: {
    stackWrapper: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      px: 2,
      py: 1.5,
      backgroundColor: 'primary.600',
      height: '5.5%'
    }
  },
  timelineOverview: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      p: 2,
      gap: 1,
      alignItems: 'flex-start',
      alignSelf: 'stretch',
      backgroundColor: 'gray.100'
    },
    divider: { my: 0.25, borderColor: 'gray.300', width: '100%' }
  },
  timelineTrackHeader: {
    productNumber: {
      fontSize: 13,
      fontWeight: 600,
      color: 'gray.800',
      textTransform: 'uppercase',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      flex: 1,
      whiteSpace: 'nowrap',
      maxWidth: 'max-content'
    },
    link: { fontSize: 13, fontWeight: 600 }
  },
  timelineLeftDrawer: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      position: 'fixed',
      top: 0,
      right: 0,
      width: 320,
      height: '100vh',
      marginRight: '50%',
      borderRadius: '8px 0px 0px 8px',
      borderRight: '1px solid',
      borderColor: 'primary.avatar',
      backgroundColor: 'white.main'
    },
    innerContainer: {
      display: 'flex',
      height: '5.48%',
      py: 1.5,
      px: 2,
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      alignSelf: 'stretch',
      backgroundColor: 'primary.300',
      borderRadius: '8px 0px 0px 0px'
    },
    compareButton: {
      my: 1.5,
      width: 117,
      height: 32,
      borderRadius: 6,
      px: 2,
      py: 1,
      border: '1px solid',
      textTransform: 'none',
      fontWeight: 600,
      fontSize: 14
    },
    closeButton: { p: 0, minWidth: 0, mr: 0.5, cursor: 'pointer' }
  },
  timelineTrack: {
    contentContainer: {
      padding: '0px !important'
    },
    sectionText: {
      fontWeight: 600,
      color: 'gray.600',
      pl: 1,
      pb: 0.6
    }
  },
  timelineDetails: {
    container: {
      display: 'flex',
      flexDirection: 'column',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: '4px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: 'gray.lightVariant3',
        borderRadius: '4px',
        opacity: '0.8'
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent'
      }
    },
    accordion: {
      borderRadius: 1,
      border: '1px solid',
      borderColor: 'gray.300',
      boxShadow: 'none',
      mb: 1.5,
      '&.Mui-expanded': {
        mb: 1.5,
        mt: 0,
        borderColor: 'gray.800'
      },
      '&::before': {
        display: 'none'
      }
    },
    accordionSummary: { bgcolor: 'primary.0', borderRadius: 1 },
    divider: { my: 1, borderColor: 'gray.300', ml: 0.75, width: '101%' }
  },
  timelineDetailsSummary: {
    accordion: {
      display: 'flex',
      flexDirection: 'column-reverse',
      boxShadow: 'none',
      background: 'none',
      border: 'none',
      '&::before': {
        display: 'none'
      },
      '&.Mui-expanded': {
        margin: 0
      }
    },
    hideIcon: {
      width: 16,
      height: 12,
      mr: 0.5
    },
    accordionSummary: {
      width: 'fit-content',
      boxShadow: 'none',
      display: 'flex',
      flexDirection: 'row-reverse',
      mb: 0,
      mx: 'auto',
      pl: 2,
      minHeight: 'unset',
      '&.Mui-expanded': {
        mx: 'auto',
        minHeight: 'unset'
      },
      '& .MuiAccordionSummary-content': {
        margin: 0,
        '&.Mui-expanded': {
          margin: 0
        }
      }
    },
    accordionSummaryText: {
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '120%'
    },
    accordionDetails: {
      boxShadow: 'none',
      border: 'none',
      padding: 0,
      '& .MuiAccordionDetails-root': {
        padding: 0,
        marginTop: 1.5
      }
    },
    accordionCard: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      gap: 0.25,
      alignSelf: 'stretch',
      justifyContent: 'space-between'
    },
    accordionCardHeader: {
      color: 'gray.800',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '150%',
      textTransform: 'capitalize'
    },
    cardSummaryText: {
      color: 'gray.800',
      fontSize: 14
    },
    switchText: {
      fontSize: 14,
      fontWeight: 400,
      color: 'gray.800',
      lineHeight: '140%'
    },
    divider: { my: 1.5, borderColor: 'gray.200', borderWidth: 1 },
    detailsContainer: {
      display: 'flex',
      flexDirection: 'column',
      px: 1,
      pb: 0,
      pt: 0.25,
      alignItems: 'flex-start',
      gap: '12px',
      alignSelf: 'stretch'
    }
  },
  timelineCard: {
    container: {
      ml: 1,
      mb: 3,
      borderRadius: 1,
      border: '1px solid',
      backgroundColor: 'gray.50',
      p: 1,
      cursor: 'pointer'
    },
    yearText: {
      color: 'gray.950',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: ' 120%',
      whiteSpace: 'nowrap'
    },
    sectionText: {
      marginRight: 0.75,
      overflow: 'hidden',
      color: 'gray.950',
      textOverflow: 'ellipsis',
      fontSize: 14,
      fontWeight: 600,
      lineHeight: '120%',
      whiteSpace: 'nowrap'
    },
    documentIcon: { fontSize: 14, mr: 0.75 },
    productNumberText: {
      overflow: 'hidden',
      color: 'gray.650',
      textOverflow: 'ellipsis',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '120%',
      whiteSpace: 'nowrap'
    },
    divider: { my: 1, borderColor: 'gray.200', borderWidth: 1 }
  },
  documentViewer: {
    snackBarContainer: {
      top: `50px !important`,
      left: '0px !important'
    },
    pdfContainer: {
      borderRadius: 5,
      mb: 10,
      background: 'white',
      justifyContent: 'center',
      alignItems: 'center',
      height: '90vh',
      maxHeight: '99vh',
      '& > div#pdfBox': { height: '86%', width: '96%' }
    },
    titleText: {
      fontSize: 14,
      fontWeight: 700,
      textOverflow: 'ellipsis'
    },
    chatRiaButton: {
      display: 'flex',
      ml: '10px',
      width: 'fit-content',
      borderRadius: '16px',
      height: '32px',
      padding: '6px 16px',
      border: '1px solid',
      borderColor: 'blue.backgroundLight',
      backgroundColor: 'white.main',
      textTransform: 'capitalize',
      color: 'blue.backgroundLight',
      fontSize: '12px',
      fontWeigth: '400'
    },
    chatRiaIcon: {
      width: '16px',
      height: '16px',
      p: '2.667px 2.666px 2.667px 2.667px'
    },
    closeIcon: { width: '18px', height: '18px', cursor: 'pointer', color: 'grey.600' }
  },
  common: {
    headerTitle: {
      fontSize: 20,
      fontWeight: 700,
      color: 'gray.0',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap'
    },
    divider: { ml: '10px !important', mr: '10px !important', height: '16px !important' },
    labelText: {
      flex: 1,
      boxSizing: 'border-box',
      overflow: 'hidden',
      color: 'gray.600',
      textOverflow: 'ellipsis',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '120%',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 2, // Limit text to 2 lines
      maxHeight: '3em' // Ensures that no more than 2 lines are shown based on line height
    },
    valueText: {
      color: 'gray.800',
      textOverflow: 'ellipsis',
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '120%',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      WebkitLineClamp: 1, // Limit text to 2 lines
      maxHeight: '3em', // Ensures that no more than 2 lines are shown based on line height,
      overflowY: 'clip',
      textTransform: 'capitalize',
      paddingLeft: 0.75
    }
  }
};

export default styles;
