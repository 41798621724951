const scrollContainer = {
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '4px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'gray.lightVariant3',
    borderRadius: '4px',
    opacity: '0.8'
  },
  '&::-webkit-scrollbar-track': {
    background: 'transparent'
  }
};

const styles = {
  sectionFlyoutWrapper: {
    ...scrollContainer,
    overflowX: 'hidden',
    marginTop: '0px !important',
    height: 'calc(100vh - 308px)',
    borderLeft: '1px solid',
    borderLeftColor: 'gray.lightVariant8'
  },
  hideView: {
    display: 'flex',
    paddingTop: '8px',
    alignItems: 'center',
    gap: '2px',
    paddingLeft: '12px',
    padding: '8px 12px',
    borderBottom: '1px solid',
    borderBottomColor: 'gray.lightVariant8',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  hideViewText: {
    color: 'black.main',
    fontFamily: 'Mulish',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '130%'
  },
  sectionWrapper: {
    display: 'flex',
    padding: '16px 0px 0px 12px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
    gap: '16px',
    alignSelf: 'stretch'
  },
  sectionKeyText: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    fontSize: 13,
    lineHeight: '120%',
    fontWeight: 700
  },
  subSectionWrapper: {
    display: 'flex',
    width: '100%',
    pl: 1,
    pr: 2,
    pb: 2,
    borderBottom: '1px solid',
    borderBottomColor: 'gray.lightVariant8',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: 2
  },
  subSectionTextContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    height: 14,
    alignItems: 'center'
  },
  subSectionTextLabel: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: 'fit-content',
    fontSize: 13
  },
  subSectionTextCount: { ml: 0.5, fontSize: 13 }
};

export default styles;
