import React from 'react';

import { Typography } from '@mui/material';

import styles from './Metadata.styles';
import { MetaData } from '../types';

const EntityMetadata = ({ data = [] }: { data: string[] | MetaData[] }) => {
  return (
    <Typography sx={styles.container} component='p'>
      {data.map((metadata: string | MetaData, index: number) => {
        if (typeof metadata === 'string') {
          return (
            <React.Fragment key={metadata}>
              <Typography
                color='gray.900'
                fontSize={13}
                fontWeight={600}
                component='span'
                display='inline'>
                {metadata}
              </Typography>
              {data.length - 1 > index && <Typography sx={styles.greenDot} component='span' />}
            </React.Fragment>
          );
        }

        return (
          <React.Fragment key={metadata.key}>
            <Typography
              color='gray.900'
              fontSize={13}
              fontWeight={600}
              component='span'
              display='inline'>
              {metadata.key ? `${metadata.key}: ${metadata.value}` : metadata.value}
            </Typography>
            {data.length - 1 > index && <Typography sx={styles.greenDot} component='span' />}
          </React.Fragment>
        );
      })}
    </Typography>
  );
};

export default React.memo(EntityMetadata);
