import React from 'react';
import { Stack, Skeleton } from '@mui/material';
import styles from '../../styles/MeetingListLoader.styles';

const MeetingListLoader = () => {
  return (
    <Stack
      spacing={2}
      sx={{
        mx: 1,
        mt: 1
      }}>
      <Stack sx={styles.loaderContainer}>
        <Skeleton variant='text' sx={{ fontSize: '1rem' }} width='80px' />
      </Stack>
      <Stack spacing={2}>
        {Array.from({ length: 5 }).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Stack key={`${index}_`} spacing={1} sx={styles.innerContainer}>
            <Skeleton variant='text' sx={{ fontSize: '14px' }} width='60%' />
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default React.memo(MeetingListLoader);
