const headerCell = {
  position: 'sticky',
  top: 0,
  backgroundColor: 'white',
  fontSize: '12px !important',
  fontWeight: 600,
  borderBottom: 'none',
  color: 'grey.600',
  p: '12px'
};
const tableRowStyles = {
  '&:last-child td, &:last-child th': {
    border: 0
  },
  '&:hover': {
    backgroundColor: 'gray.50',
    cursor: 'pointer'
  }
};
const styles = {
  root: { height: '100%', overflow: 'auto', padding: '16px 16px 0px 16px' },
  tableContainer: {
    marginBottom: '20px'
  },
  headerCell,
  tableCell: {
    ...headerCell,
    width: '20%',
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      '& .sort-icons': {
        visibility: 'visible'
      }
    },
    '& .sort-icons': {
      visibility: 'hidden'
    }
  },
  tableCellDesc: {
    ...headerCell,
    width: '30%'
  },
  tableCellBlank: { ...headerCell, textAlign: 'end', width: '10%' },

  tableRow: {
    ...tableRowStyles,
    '& .MuiTableCell-root': {
      padding: 0,
      pb: '12px',
      pt: '8px',
      borderWidth: '0.5px',
      borderColor: 'gray.100'
    }
  },
  tableRowHeader: {
    ...tableRowStyles,
    '& .MuiTableCell-root': {
      padding: 0,
      borderWidth: '0.5px',
      borderColor: 'gray.100'
    }
  },
  rowNameText: {
    fontSize: '14px !important',
    textTransform: 'capitalize',
    color: 'primary.800',
    p: '12px',
    fontWeight: 600
  },
  rowText: {
    fontSize: '14px !important',
    fontWeight: 400,
    p: '12px'
  },

  linearProgress: {
    width: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 1000
  },
  noReportSaved: {
    width: '100%',
    fontSize: '16px',
    textAlign: 'center',
    padding: '5px'
  },
  svgIcon: { height: '16px', width: '16px', color: 'gray.750', mr: '6px' },
  menu: {
    '& .MuiPaper-root': {
      borderRadius: '10px',
      p: '8px 16px 8px 12px'
    }
  }
};

export default styles;
