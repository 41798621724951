import React from 'react';
import MuiButton from '@mui/material/Button';

const selectedStyle = {
  color: 'white.main',
  fontWeight: 700,
  borderColor: 'primary.600',
  backgroundColor: 'primary.600',
  '&:hover': {
    borderColor: 'primary.600',
    backgroundColor: 'primary.600'
  }
};

const mainStyle = {
  py: 1,
  px: 2,
  borderRadius: 6,
  border: '1px solid',
  borderColor: 'primary.700',
  color: 'primary.700',
  fontSize: 14,
  fontWeight: 600,
  lineHeight: '100%',
  letterSpacing: '0.2px',
  textTransform: 'capitalize',
  '&:hover': {
    borderColor: 'primary.700',
    backgroundColor: 'primary.0'
  },
  '&.Mui-disabled': {
    color: 'gray.500',
    fontWeight: 600,
    borderColor: 'gray.200',
    backgroundColor: 'gray.200',
    '&:hover': {
      borderColor: 'gray.200',
      backgroundColor: 'gray.200'
    }
  }
};

const Button = ({ disabled, onClick, size = 'medium', selected, title }: any) => {
  return (
    <MuiButton
      disabled={disabled}
      onClick={onClick}
      variant='outlined'
      size={size}
      sx={{
        ...mainStyle,
        ...(selected ? selectedStyle : {})
      }}>
      {title}
    </MuiButton>
  );
};

export default React.memo(Button);
