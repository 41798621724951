import React, { useState } from 'react';
import { isObject, has } from 'lodash';

import { Typography, Divider, Stack, Tab, Tabs, Link, Box } from '@mui/material';

import styles from './styles';
import ReportButton from '../../../components/Report/ReportButton';

interface AttributeDetails {
  title: string;
  description?: string;
}
type AttributeValue = string | null | AttributeDetails;

const Attribute: React.FC<{
  attributeName: string;
  attributeValue: AttributeValue;
  attrValueStyleMapping: { [key: string]: any };
}> = ({ attributeName, attributeValue, attrValueStyleMapping }) => {
  if (!attributeValue || attributeName === 'id') return null;

  return (
    <Stack mb={1} spacing={2}>
      {attributeValue && isObject(attributeValue) ? (
        <Stack spacing={2}>
          {has(attributeValue, 'title') && (
            <Stack direction='row' spacing={2}>
              <Typography variant='subtitle2' sx={styles.snapshotLabel}>
                {attributeName}:&nbsp;
              </Typography>
              <Typography variant='subtitle2' sx={styles.snapshotText}>
                {attributeValue.title}
              </Typography>
            </Stack>
          )}
          {has(attributeValue, 'description') && (
            <Stack direction='row' spacing={2}>
              <Typography variant='subtitle2' sx={styles.snapshotLabel}>
                Description:&nbsp;
              </Typography>
              <Typography variant='subtitle2' sx={styles.snapshotText}>
                {attributeValue?.description}
              </Typography>
            </Stack>
          )}
        </Stack>
      ) : (
        <Stack mb={1} direction='row' spacing={2}>
          <Typography variant='subtitle2' sx={styles.snapshotLabel}>
            {attributeName}:&nbsp;
          </Typography>
          {attributeName === 'NCT ID' ? (
            <Link
              target='_blank'
              href={`${window.location.origin}/regulatory360/core/${attributeValue}/ct?tabname=approvalHistory`}>
              {attributeValue}
            </Link>
          ) : (
            <Typography
              variant='subtitle2'
              sx={attrValueStyleMapping[attributeValue] || attrValueStyleMapping.default}>
              {attributeValue}
            </Typography>
          )}
        </Stack>
      )}
    </Stack>
  );
};

const ClinicalStudies = ({ item, addIndicationToReport }: any) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const areAllKeysNonEmpty = (data: any, index: any, group: any) => {
    const obj = data?.studies?.[index]?.[group];
    return obj
      ? Object.values(obj).every(val => val !== '' && val !== null && val !== undefined)
      : false;
  };

  const attrValueStyleMapping: {
    [key: string]: any;
  } = {
    Yes: styles.snapshotYesText,
    No: styles.snapshotNoText,
    default: styles.snapshotText
  };

  return (
    <Stack spacing={2} mt={-2}>
      <Box display='flex' justifyContent='space-between'>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label='basic tabs example'
          TabIndicatorProps={{
            sx: { borderWidth: '2px', borderStyle: 'solid', borderColor: 'primary.600' }
          }}>
          {item?.studies?.map((study: any, index: number) => {
            return (
              <Tab key={`Study ${index + 1}`} label={`Study ${index + 1}`} sx={styles.tabHeading} />
            );
          })}
        </Tabs>
        {item?.studies?.length > 1 ? null : (
          <ReportButton data={item} addToReport={addIndicationToReport} />
        )}
      </Box>

      <Stack spacing={2} p={1}>
        {Object.keys(item?.studies[value] || {}).map(group => (
          <React.Fragment key={group}>
            {Object.entries(item.studies[value][group]).map(([attributeName, attributeValue]) => (
              <Attribute
                key={`${group}-${attributeName}`} // Construct a stable key based on group and attribute name
                attributeName={attributeName}
                attributeValue={attributeValue as AttributeValue}
                attrValueStyleMapping={attrValueStyleMapping}
              />
            ))}
            {areAllKeysNonEmpty(item, value, group) && <Divider />}
          </React.Fragment>
        ))}
      </Stack>
    </Stack>
  );
};

export default ClinicalStudies;
