const styles = {
  reportText: {
    color: 'primary.textVariant',
    fontWeight: '600',
    fontSize: 14,
    alignItems: 'center',
    display: 'inline-flex',
    textTransform: 'capitalize',
    gap: 1,
    '&:hover': {
      background: 'none'
    }
  },
  addToReport: {
    display: 'inline-flex',
    height: 32,
    minWidth: 100,
    py: 1,
    px: 1.5,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 8,
    '&:hover': {
      borderRadius: 24,
      background: 'primary.background'
    }
  },
  plusIcon: {
    fontSize: 13
  },
  divider: {
    width: 2,
    backgroundColor: 'gray.0',
    ml: 1,
    borderRightWidth: 'inherit'
  },
  buttonContainer: {
    height: 24,
    borderRadius: 1.5,
    backgroundColor: 'primary.0',
    cursor: 'pointer'
  },
  reportButton: {
    '&:hover': {
      backgroundColor: 'primary.50'
    },
    '&:active': {
      backgroundColor: 'primary.100'
    }
  },
  iconButton: {
    fontSize: 16,
    color: 'primary.700'
  }
};

export default styles;
