import { useContext, useState } from 'react';
import { getReport, listReports, updateReport } from '../../../api/pages/UserProfile';
import Store from '../../../store';
import Actions from '../../../store/actions';
import { formatDate } from '../../../pages/UserProfile/utils';
import updateSnapshotData from '../utils/updateSnapshotData';

const useReportingHandler = () => {
  const { dispatch } = useContext<any>(Store);
  const [reportData, setReportData] = useState<Array<any>>([]);
  const [activeReportData, setActiveReportData] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const fetchData = async () => {
    setIsLoading(true);
    const res = await listReports();
    if (res?.status === 200) {
      const data = res?.data?.body?.data;
      const sectionData = data.map((section: any) => {
        return {
          ...section,
          createdAt: formatDate(section.created_at),
          updatedAt: formatDate(section.updated_at)
        };
      });

      sectionData.sort(
        (a: { updated_at: string | number | Date }, b: { updated_at: string | number | Date }) =>
          new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      );
      const activeItems = data.filter((item: { is_active: boolean }) => item.is_active);
      setActiveReportData(
        activeItems && activeItems?.length !== 0 ? activeItems[0] : sectionData[0]
      );
      setReportData(sectionData);
    }
    setIsLoading(false);
  };

  const fetchReportData = async (id: string) => {
    const res = await getReport(id);
    if (res && res.status === 200) {
      const { body, status, error } = await res.data;
      if (status === 200 && !error && body) {
        const report = await body;
        return report;
      }
    }
    return {};
  };

  const updateReportData = async (id: string, sectionLayout: any) => {
    try {
      const report = await fetchReportData(id);
      if (report?.layout) {
        if (
          // In snapshot we wont allow to have duplicate report we merge indication studies into one report
          // 1. sectionType should SNAPSHOT, 2. length should be 1,
          // 3. id should be exists 4. to combine currently we should have snapshot as clinical_summary
          sectionLayout?.sectionType === 'SNAPSHOT' &&
          report.layout.sections?.length > 0 &&
          report.layout.sections?.some((section: any) => section?.id === sectionLayout?.id)
        ) {
          if (sectionLayout?.id.includes('snapshot_clinical_summary')) {
            report.layout = updateSnapshotData(report.layout, sectionLayout);
          }
        } else {
          report.layout.sections.push(sectionLayout);
        }
      }
      let payload: any = {};
      payload = {
        report_title: report?.report_name,
        report_description: report?.report_description,
        layout: report?.layout
      };
      const { data: response } = await updateReport(id, payload);
      if (response) {
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Successfully saved your report.', color: 'success' }
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'Something went wrong. Could not update report.',
          color: 'error'
        }
      });
    }
  };

  const removeReportId = async (data: any, id: string) => {
    try {
      const payload = await fetchReportData(data?.id);
      const sections = payload?.layout?.sections;
      if (sections) {
        payload.layout.sections = sections.filter((item: any) => item?.id !== id);
      }
      updateReportData(data?.id, payload);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'Something went wrong. Could not update report.',
          color: 'error'
        }
      });
    }
  };

  const clearReport = async (id: string) => {
    try {
      const payload = await fetchReportData(id);
      if (payload?.layout?.sections) {
        payload.layout.sections = [];
      }
      const { data: response } = await updateReport(id, payload);
      if (response?.status === 200) {
        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Successfully saved your report.', color: 'success' }
        });
        return true;
      }
      return false;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'Something went wrong. Could not update report.',
          color: 'error'
        }
      });
      return false;
    }
  };

  const fetchActiveReportDetails = async () => {
    const res = await listReports();
    if (res?.status === 200) {
      const data = res?.data?.body?.data;
      const activeItems = data.filter((item: { is_active: boolean }) => item.is_active);
      return activeItems && activeItems?.length !== 0 ? activeItems[0] : data[0];
    }
    return {};
  };

  return {
    isLoading,
    reportData,
    activeReportData,
    fetchActiveReportDetails,
    fetchData,
    fetchReportData,
    updateReportData,
    removeReportId,
    clearReport
  };
};

export default useReportingHandler;
