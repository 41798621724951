// eslint-disable-next-line import/prefer-default-export
export const METADATA_MAPPING: any = {
  us: [
    {
      label: 'Approval Date',
      key: 'approval_date'
    },
    {
      label: 'Submission ID',
      valueGetter: (data: any) => `${data.submission_type}-${data.submission_num}`
    }
  ],
  au: [
    {
      label: 'Approval Date',
      key: 'approval_date'
    }
  ],
  eu: [
    {
      label: 'Source',
      key: 'source'
    },
    {
      label: 'Approval Date',
      key: 'approval_date'
    },
    {
      label: 'Submission ID',
      key: 'submission_id'
    }
  ],
  hma: [
    {
      label: 'Vin Number',
      key: 'vin_number'
    },
    {
      label: 'Approval Date',
      key: 'approval_date'
    }
  ],
  hpra: [
    {
      label: 'Application Number',
      key: 'appl_num'
    },
    {
      label: 'Approval Date',
      key: 'approval_date'
    },
    {
      label: 'Submission Type',
      key: 'submission_type'
    }
  ],
  ca: [
    {
      label: 'VIN Number',
      key: 'vin_number'
    },
    {
      label: 'Approval Date',
      key: 'approval_date'
    },
    {
      label: 'Submission Number',
      key: 'submission_num'
    }
  ],
  jp: [
    {
      label: 'Latest Submission Date',
      key: 'approval_date'
    },
    {
      label: 'Package Insert Number',
      key: 'submission_id'
    }
  ]
};

export const ALLOWED_SOURCES_PRESCRIBING_INFO = ['us', 'hpra', 'ca', 'jp', 'eu', 'hma', 'au'];
