import React, { useCallback, useContext, useEffect } from 'react';
import { isArray, isEmpty, isEqual, sample, toNumber } from 'lodash';
import { format } from 'date-fns';
import { useLocation } from 'react-router-dom';
import axios, { CancelTokenSource } from 'axios';
import { v4 } from 'uuid';
import {
  askHelp,
  createReport,
  getChatRiaStatus,
  getReportTitle,
  HelpQueryPayload,
  initialiseChat,
  sendQuery
} from '../../../api/modules/ChatRIA';
import GlobalStore from '../../../store';
import AuthContext from '../../../store/Auth/AuthProvider';
import GlobalActions from '../../../store/actions';
import customTitleCase from '../../../utils/titleCase';
import { getAriaResultTitle } from '../../../pages/Aria/components/AriaResult';
import { ariaCardResultsMapping } from '../../../pages/Aria/utils/constants';
import { sourceMap } from '../../../pages/ResultsPage/components/SearchSuggestions';
import RESULT_VIEW_TYPES from '../../../pages/SearchResults/components/constants';
import useReportingHandler from '../../Report/hooks/useReportingHandler';

const useChatQuery = () => {
  const { state, dispatch } = useContext(GlobalStore) as any;
  const { currentUser } = useContext(AuthContext);
  const location = useLocation();

  const [openWidget, setOpenWidget] = React.useState<boolean>(false);
  const [applicationNumber, setApplicationNumber] = React.useState<string>('');
  const [queryText, setQueryText] = React.useState<string>('');
  const [chatFlow, setChatFlow] = React.useState<any>([]);
  const [queryError, setQueryError] = React.useState<any>(null);
  const [queryLoading, setQueryLoading] = React.useState<boolean>(false);
  const [initialised, setInitialised] = React.useState<boolean>(false);
  const [sections, setSections] = React.useState<any[]>([]);
  const [currentSection, setCurrentSection] = React.useState<string>('');
  const [s3Url, setS3Url] = React.useState<string>('');
  const [reportList, setReportList] = React.useState<any[]>([]);
  const [processingReport, setProcessingReport] = React.useState<boolean>(false);
  const [seeMore, setSeeMore] = React.useState<boolean>(false);
  const [selectedApplication, setSelectedApplication] = React.useState<any>({});
  const [verifyAnswer, setVerifyAnswer] = React.useState<{ url: string; pageNumber: number }>({
    url: '',
    pageNumber: 0
  });
  const [applicationSource, setApplicationSource] = React.useState<string>('');
  const [applicationNameList, setApplicationNameList] = React.useState<any>([]);
  const [riaMode, setRiaMode] = React.useState<boolean>(true);
  const [chatRiaStatus, setChatRiaStatus] = React.useState<string>('OK');
  const [lastStatusCheckTime, setLastStatusCheckTime] = React.useState<string>('');
  const [cancelToken, setCancelToken] = React.useState<CancelTokenSource | null>(null);
  const [isARIAMode, setIsARIAMode] = React.useState<boolean>(false);
  const [sessionOpen, setSessionOpen] = React.useState<boolean>(false);
  const [conversationId, setConversationId] = React.useState<string>('');
  const [isMinimizedChat, setMinimized] = React.useState<boolean>(false);
  const [loadingReportTitle, setLoadingReportTitle] = React.useState<boolean>(false);
  const [latestChatId, setLatestChatId] = React.useState<{ chatId: string; isNew: boolean }>({
    chatId: '',
    isNew: true
  });
  const [sourceUrl, setSourceUrl] = React.useState<string>('');
  const [applicationMeta, setApplicationMeta] = React.useState<any>({
    submissionId: '',
    approvalDate: '',
    brandName: ''
  });

  const [documentReferencePages, setDocumentReferencePages] = React.useState<{}>({});
  const [openApplicationOption, setOpenApplicationOption] = React.useState<boolean>(false);
  const [addedToReportList, setAddedToReportList] = React.useState<string[]>([]);
  const [reportLoading, setReportLoading] = React.useState<boolean>(false);
  const errorAnswer = {
    text: 'Sorry I am not able to answer this at the moment \n try changing the application or try again later',
    type: 'bot',
    error: true
  };
  const errorAnswerProduct = {
    text: 'Sorry I am not able to answer anything for this product at the moment \n try changing the product or try again later ',
    type: 'bot',
    error: true
  };
  const [ariaPageNumber, setAriaPageNumber] = React.useState<any>({});
  const [ariaDocPageNumber, setARIADocPageNumber] = React.useState<any>(0);
  const [currentAriaState, setCurrentAriaState] = React.useState<any>({});
  const { updateReportData } = useReportingHandler();
  const handleChatRiaStatus = useCallback(async () => {
    const response = await getChatRiaStatus();
    setLastStatusCheckTime(format(new Date(), 'h:mm a'));
    if (response?.status === 200) {
      const { data } = response;
      if (
        data?.body?.status === 'OK' ||
        data?.body?.status === 'High' ||
        data?.body?.status === 'Low/Medium'
      ) {
        setChatRiaStatus(data?.body?.status);
      } else {
        setChatRiaStatus('OK');
      }
    }
  }, []);

  const chatInit = useCallback(
    // eslint-disable-next-line consistent-return
    async (number: string, source: string, isARIA = false, ariaURL = '') => {
      try {
        let payload = {};
        if (!isARIA) {
          payload = {
            application_number: number,
            source
          };
        } else {
          const pageRange = parseInt(ariaURL?.split('#page=')[1], 10) || 0;
          if (ariaDocPageNumber === 0 && pageRange !== 0) {
            setARIADocPageNumber(pageRange);
          }
          payload = {
            s3_document_url: ariaURL || '', // @ts-ignore
            page_number: ariaDocPageNumber === 0 ? pageRange : ariaDocPageNumber
          };
        }
        const res = await initialiseChat(payload);
        const { body, status } = res.data;
        if (status !== 200) {
          throw new Error(errorAnswerProduct.text);
        }

        if (!body || !('conversation_id' in body)) {
          throw new Error('Something went wrong. Conversation cannot be generated');
        }
        const viewType = body?.view;
        if (viewType === 'latest_label_chat') {
          setIsARIAMode(false);
          setVerifyAnswer({
            url: body?.s3_url,
            pageNumber: 0
          });
          setApplicationNumber(body?.application_number ?? number);
        }

        setConversationId(body.conversation_id);
        if (body?.original_file_url) {
          setSourceUrl(body.original_file_url);
        }
        if (body?.page_range) {
          setApplicationMeta((prev: any) => ({
            pageRange: body?.page_range,
            brandName: prev?.brandName
          }));
        }
        if (body?.submission_id || (body?.approval_date && body?.source)) {
          setApplicationMeta((prev: any) => ({
            ...prev,
            submissionId: body?.submission_id,
            approvalDate: body?.approval_date,
            source: body?.source,
            sourceValue: source,
            applNumber: body?.application_number ?? number
          }));
          setChatFlow((prev: any) => {
            if (prev) {
              const localState = [...prev];
              if (localState[localState.length - 1]?.type === 'info') {
                const latInfoText = localState[localState.length - 1].text;
                localState.pop();
                const answer = {
                  text: `${latInfoText}  (Approval: ${body?.approval_date}, Submission: ${body?.submission_id})`,
                  labelname: latInfoText,
                  metadata: `(Source: ${body?.source} Approval Date: ${body?.approval_date}, Submission ID: ${body?.submission_id})`,
                  type: 'info'
                };
                localState.push(answer);
              }
              return localState;
            }
            return [];
          });
        }

        if (viewType === 'latest_label_chat' || !isARIA) {
          setS3Url(body?.s3_url);
          const localSection = Object.entries(body?.sections)
            .reduce((acc: any, [key, value]: any): any => {
              if (!isEmpty(value)) {
                acc.push({
                  label: key,
                  pageNumber: toNumber(value.page_number),
                  ...value
                });
              }
              return acc;
            }, [])
            .sort((a: any, b: any) => {
              return a.pageNumber - b.pageNumber;
            });

          if (localSection.length) {
            setSections(localSection);
          } else {
            throw new Error(
              'Cannot chat with this application at the moment. Please try again on a different application.'
            );
          }
        } else {
          setAriaPageNumber(body?.page_range);
        }
        setInitialised(true);
        setQueryLoading(false);
      } catch (error: any) {
        // eslint-disable-next-line no-console
        console.error(error);
        setQueryLoading(false);
        setInitialised(true);
        setApplicationMeta((prev: any) => ({ ...prev, submissionId: '', approvalDate: '' }));
        if (location.pathname.startsWith('/search')) {
          const errorMessage =
            error.message === errorAnswerProduct.text ? errorAnswerProduct : errorAnswer;
          setChatFlow((prev: any) => (!isEmpty(prev) ? [...prev, errorMessage] : [errorMessage]));
        } else {
          setQueryError(
            error.message || 'Something went wrong. Please try again on a different application.'
          );
        }
      }
    },
    [location.pathname, s3Url, ariaDocPageNumber, state.ariaDocument]
  );
  const handleARIAChat = useCallback(async (ariaDocumentLink: any | null) => {
    if (ariaDocumentLink) {
      setIsARIAMode(true);
      setS3Url(ariaDocumentLink);
    } else {
      setQueryError(
        'Cannot chat with this application at the moment. Please try again on a different application.'
      );
    }
    setQueryLoading(false);

    setInitialised(true);
  }, []);

  const handleApplicationChange = useCallback(
    async (val: any, calledFromInit = false) => {
      setApplicationSource(val.source);
      setSelectedApplication(val);
      await dispatch({
        type: GlobalActions.SET_APPLICATION_NAME, // @ts-ignore
        value: val.label as string
      });

      // @ts-ignore
      setApplicationNumber(val.appNumber as string);
      const answer = {
        text: `${val.label}`,
        type: 'info'
      };
      setChatFlow((prev: any) => {
        if (!isEmpty(prev)) {
          const localState = [...prev];

          if (localState[localState.length - 1].type === 'section') {
            localState.pop();
          }
          if (localState[localState.length - 1]?.type === 'info') {
            localState.pop();
          }

          return [...localState, answer];
        }
        return [];
      });
      if (!calledFromInit) {
        await chatInit(val.appNumber, val.source);
      }
    },
    [applicationSource]
  );
  const openSession = useCallback(
    async (number: string, source: string, applicationList: any, ariaDocument: any) => {
      let appNumber = decodeURIComponent(number);
      let sourceToUse = source;
      try {
        setChatFlow(null);
        setApplicationNumber('');
        setApplicationSource('');
        setApplicationNameList([]);
        setOpenWidget(true);
        setRiaMode(true);
        await handleChatRiaStatus();
        setSessionOpen(true);
        setMinimized(false);
        dispatch({ type: GlobalActions.SET_MINIMIZED_CHAT_RIA, value: false });
        setCurrentAriaState(ariaDocument);
        const pathNameList = location.pathname.split('/');
        const triggeredFrom = state.chatRiaTriggeredFrom || '';
        const isReg360 = triggeredFrom === 'regulatory360';
        const pathName = pathNameList[1];
        const isDocumentARIAPage = triggeredFrom === RESULT_VIEW_TYPES.DOCUMENT;
        const isSearchApplicationPage =
          (pathName === 'search' || pathName === 'account') &&
          triggeredFrom === RESULT_VIEW_TYPES.APPLICATION;
        if (document.getElementById('jsd-widget')) {
          // @ts-ignore
          document.getElementById('jsd-widget').hidden = true;
        }
        // TODO @utkarsh check for pmda and hma
        if (isSearchApplicationPage) {
          if (source && applicationList) {
            const localApplicationList: { appNumber: string; label: string; source: string }[] =
              applicationList.map((value: any) => {
                const {
                  application_number: appNumberAPI, // eslint-disable-next-line
                  product_name,
                  product_number: productNumber,
                  vin: vinNumber,
                  source_index: sourceIndex,
                  identifier,
                  ...rest
                } = value;
                // eslint-disable-next-line
                let tradeName = product_name;
                if (isArray(tradeName)) {
                  // eslint-disable-next-line
                  tradeName = product_name.join(': ');
                }
                return {
                  appNumber: productNumber || appNumberAPI || vinNumber || identifier,
                  label: tradeName.toUpperCase(),
                  source: sourceIndex === 'ema' ? 'eu' : value.source,
                  identifier,
                  ...rest
                };
              });
            appNumber = localApplicationList[0].appNumber;
            sourceToUse = localApplicationList[0].source;
            setApplicationSource(sourceToUse);
            setApplicationNameList(localApplicationList);
            await handleApplicationChange(localApplicationList[0], true);
            await dispatch({
              type: GlobalActions.SET_APPLICATION_NAME,
              value: localApplicationList[0].label
            });
          } else {
            throw new Error('Please select an application');
          }
        } else if (isReg360 || isDocumentARIAPage) {
          setApplicationSource(source || '');
          setApplicationNameList([]);
          if (isDocumentARIAPage) {
            // @ts-ignore
            appNumber = ariaDocument?.item?.application_number;
            const sponsor = ariaDocument?.item?.sponsor;
            if (isArray(appNumber)) {
              // eslint-disable-next-line prefer-destructuring
              appNumber = appNumber[0];
            }
            if (sponsor) {
              await dispatch({
                type: GlobalActions.SET_APPLICATION_NAME,
                value: isArray(sponsor) ? sponsor.join(': ') : sponsor
              });
            }
            await handleARIAChat(ariaDocument?.url);
          }
          setApplicationNumber(appNumber || '');
        } else {
          throw new Error('Please select an application');
        }

        await chatInit(appNumber || '', sourceToUse || '', isDocumentARIAPage, ariaDocument?.url);
      } catch (error: any) {
        setQueryError(
          error.message || 'Something went wrong. Please try again on a different application.'
        );
      }
    },
    [location.pathname, state.chatRiaTriggeredFrom]
  );

  const handleReopenSession = useCallback(
    async (number: string, source: string, applicationList: any, ariaDocument: any) => {
      try {
        const pathNameList = location.pathname.split('/');
        const triggeredFrom = state.chatRiaTriggeredFrom;

        const pathName = pathNameList[1];
        const isDocumentARIAPage = triggeredFrom === RESULT_VIEW_TYPES.DOCUMENT;
        const isSearchApplicationPage =
          pathName === 'search' && triggeredFrom === RESULT_VIEW_TYPES.APPLICATION;

        const localApplicationList: { appNumber: string; label: string; source: string }[] =
          applicationList?.map((value: any) => {
            const {
              application_number: appNumber,
              product_name: productName,
              product_number: productNumber,
              source_index: sourceIndex,
              vin: vinNumber,
              yj_code: yjCode,
              identifier,
              ...rest
            } = value;
            let localTradeName = productName;
            if (isArray(productName)) {
              localTradeName = productName.join(': ');
            }
            return {
              appNumber: productNumber || appNumber || vinNumber || yjCode,
              label: localTradeName.toUpperCase(),
              source: sourceIndex === 'ema' ? 'eu' : source,
              identifier,
              ...rest
            };
          });

        const isDifferent =
          (isSearchApplicationPage && !isEqual(localApplicationList, applicationNameList)) ||
          (isDocumentARIAPage && s3Url !== ariaDocument?.url);

        if (isDifferent) {
          await openSession(number, source, applicationList, ariaDocument);
        } else {
          handleChatRiaStatus();
          if (document.getElementById('jsd-widget')) {
            // @ts-ignore
            document.getElementById('jsd-widget').hidden = true;
          }
          setMinimized(false);
          dispatch({ type: GlobalActions.SET_MINIMIZED_CHAT_RIA, value: false });
          setOpenWidget(true);
        }
      } catch (error: any) {
        setQueryError(
          error.message || 'Something went wrong. Please try again on a different application.'
        );
      }
    },
    [applicationNameList, location.pathname, openSession, s3Url, state.chatRiaTriggeredFrom]
  );
  const handleChangeQuery = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQueryText(e.target.value);
  };

  const handleSetHelpQuery = async (text: string) => {
    await setQueryText(text);
    document?.getElementById('input-box-chatRIA')?.click();
  };
  const messageFormatter = (message: string[]) => {
    const newMessage = message.join('').trimStart();
    let messageError = false;
    if (newMessage.startsWith('.')) {
      return newMessage.substring(1);
    }
    messageError = newMessage.toLowerCase().startsWith('either label for');
    return { newMessage, messageError };
  };
  const chatRIAQueryTrigger = useCallback(
    async (section: string, question: string, cancel: any, bubbleIndex?: number) => {
      const payload: {
        riaMode: boolean;
        question: string;
        s3_document_url?: string;
        conversation_id: string;
        page_range?: any;
        section?: string;
      } = {
        question,
        riaMode,
        s3_document_url: s3Url,
        conversation_id: conversationId,
        page_range: ariaPageNumber[ariaDocPageNumber],
        section
      };
      if (!isARIAMode || isEmpty(s3Url)) {
        delete payload.s3_document_url;
        delete payload.page_range;
      }
      if (!section) {
        delete payload.section;
      }
      handleChatRiaStatus();
      let response: any = '';
      const chatId = v4();
      setLatestChatId({ chatId, isNew: typeof bubbleIndex === 'undefined' });
      let documentReferencePagesStub = {};
      await sendQuery({
        applicationNumber,
        payload,
        source: applicationSource,
        cancelToken: cancel.token,
        onDownloadProgress: async (progressEvent: any) => {
          const { currentTarget } = progressEvent.event;
          const data = currentTarget.response;
          const lines = data.trim().split('\n');
          const newMessages = lines.map(
            (line: string) => JSON.parse(line.replace('data: ', '')).message
          );

          // set top 5 reference page numbers for chat ria answer on full document search
          if (newMessages?.length > 0 && typeof newMessages[0] === 'object') {
            [documentReferencePagesStub] = newMessages;
          }
          const topSectionRaw = newMessages.find(
            (message: any) => typeof message === 'string' && message.startsWith('top_section')
          );
          const otherSectionRaw = newMessages.find(
            (message: any) => typeof message === 'string' && message.startsWith('other_sections')
          );
          let topSection = '';
          let otherSection = [];
          if (topSectionRaw) {
            topSection = topSectionRaw
              ?.replace('top_section:', '')
              ?.replace('\n', '')
              ?.replace(/"/g, '')
              ?.trim();
          }
          if (otherSectionRaw) {
            otherSection = JSON.parse(otherSectionRaw.replace('other_sections:', ''));
            otherSection = otherSection.map((localSection: any) => {
              return {
                section: localSection?.section?.replace('\n', '')?.replace(/"/g, '')?.trim()
              };
            });
          }
          const responseMessage = newMessages.filter(
            (message: string) =>
              typeof message === 'string' &&
              !message?.startsWith('top_section') &&
              !message?.startsWith('other_sections')
          );
          const brandName = selectedApplication?.label || state.applicationName || '';
          const ariaBrandName = state?.ariaDocument?.item?.trade_name || '';
          const sectionToConsider = (topSection || section)
            .replace('\n', '')
            .replace(/"/g, '')
            .trim();
          const { newMessage, messageError }: any = messageFormatter(responseMessage);
          const answer = {
            text: newMessage,
            type: 'bot',
            error: messageError,
            url: s3Url,
            originalUrl: sourceUrl,
            pageNumber:
              sections?.find((localSection: any) => localSection.label.match(sectionToConsider))
                ?.pageNumber || 0,
            riaMode,
            ariaPageMetaData: {
              ...(applicationMeta?.pageRange?.[ariaDocPageNumber] || {
                start: 0,
                end: 0
              }),
              currentPage: ariaDocPageNumber
            },
            canceled: false,
            question,
            currentSection: sectionToConsider,
            availableSections: otherSection,
            applicationNumber,
            source: applicationSource,
            brandName: isARIAMode ? ariaBrandName : brandName,
            currentAriaState: {
              ...currentAriaState,
              triggerReopenChatRia: false,
              currentPage: ariaDocPageNumber
            },
            id: chatId,
            inReport: false,
            handleRerunQuery: async (answerIndex: number) => {
              setQueryLoading(true);
              const newCancel = axios.CancelToken.source();
              setCancelToken(newCancel);
              await chatRIAQueryTrigger(
                sectionToConsider,
                question,
                newCancel,
                answerIndex || undefined
              );
              setCancelToken(null);
              setQueryLoading(false);
            }
          };
          response = answer.text;
          if (response) {
            if (bubbleIndex) {
              setChatFlow((prev: any) => {
                const newFlow = [...prev];
                let selectedBubble = newFlow[bubbleIndex];
                answer.availableSections = selectedBubble[0].availableSections;
                const indexOfSection = selectedBubble.findIndex(
                  (localSection: any) => localSection.currentSection === section || topSection
                );
                if (indexOfSection === -1) {
                  selectedBubble = [...selectedBubble, answer];
                } else {
                  selectedBubble[indexOfSection] = answer;
                }
                newFlow[bubbleIndex] = selectedBubble;
                return [...newFlow];
              });
            } else {
              setChatFlow((prev: any) => {
                if (!isEmpty(prev)) {
                  const previousAnswers = [...prev];
                  let lastAnswer = previousAnswers[previousAnswers.length - 1];
                  if (isArray(lastAnswer)) {
                    const indexOfSection = lastAnswer.findIndex(
                      (localSection: any) => localSection.currentSection === section || topSection
                    );
                    if (section) {
                      answer.availableSections = lastAnswer[0].availableSections;
                    }
                    if (indexOfSection === -1) {
                      lastAnswer = [...lastAnswer, answer];
                    } else {
                      lastAnswer[indexOfSection] = answer;
                    }
                    previousAnswers.pop();
                    previousAnswers.push(lastAnswer);
                  } else {
                    if (lastAnswer?.type === 'bot') {
                      previousAnswers.pop();
                    }
                    previousAnswers.push([answer]);
                  }
                  return previousAnswers;
                }
                return [[answer]];
              });
            }
            if (topSection) {
              setCurrentSection(topSection);
            }
          }
          currentTarget.onabort = async () => {
            answer.canceled = true;
            answer.error = true;
            setChatFlow((prev: any) => {
              if (!isEmpty(prev)) {
                const previousAnswers = [...prev];
                if (bubbleIndex) {
                  const selectedBubble = previousAnswers[bubbleIndex];
                  const indexOfSection = selectedBubble.findIndex(
                    (localSection: any) => localSection.id === answer.id
                  );
                  if (indexOfSection === -1) {
                    selectedBubble.push(answer);
                  } else {
                    selectedBubble[indexOfSection] = answer;
                  }
                  previousAnswers[bubbleIndex] = selectedBubble;
                  return [...previousAnswers];
                }
                let lastAnswer = previousAnswers[previousAnswers.length - 1];
                if (isArray(lastAnswer)) {
                  const indexOfSection = lastAnswer.findIndex(
                    (localSection: any) => localSection.id === answer.id
                  );
                  if (indexOfSection === -1) {
                    lastAnswer = [...lastAnswer, answer];
                  } else {
                    lastAnswer[indexOfSection] = answer;
                  }
                  previousAnswers.pop();
                  previousAnswers.push(lastAnswer);
                } else {
                  if (lastAnswer?.type === 'bot') {
                    previousAnswers.pop();
                  }
                  previousAnswers.push([answer]);
                }
                return [...previousAnswers];
              }
              return prev;
            });
          };
          currentTarget.onerror = () => {
            setChatFlow((prev: any) => [...prev, errorAnswer]);
          };
        }
      });

      const currentDocumentReferencePagesStub: any = documentReferencePages;
      currentDocumentReferencePagesStub[chatId] = documentReferencePagesStub;
      setDocumentReferencePages(currentDocumentReferencePagesStub);
      if (!response) {
        // @ts-ignore
        setChatFlow(prev => (!isEmpty(prev) ? [...prev, errorAnswer] : [errorAnswer]));
      } else {
        setQueryText('');
      }
    },
    [
      riaMode,
      s3Url,
      conversationId,
      ariaDocPageNumber,
      applicationNumber,
      applicationSource,
      selectedApplication,
      state.applicationName,
      sections,
      setCurrentSection,
      ariaPageNumber,
      currentAriaState
    ]
  );
  const handleSubmitQuery = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      try {
        e.preventDefault();
        e.stopPropagation();
        if (queryText.trim() === '') {
          return;
        }
        setQueryLoading(true);
        const cancel = axios.CancelToken.source();
        setCancelToken(cancel);
        const questionId = v4();
        setLatestChatId({ chatId: questionId, isNew: true });
        setChatFlow((prevState: any) =>
          !isEmpty(prevState)
            ? [
                ...prevState,
                {
                  text: queryText,
                  type: 'user',
                  id: questionId
                }
              ]
            : [
                {
                  text: queryText,
                  type: 'user',
                  id: questionId
                }
              ]
        );

        await chatRIAQueryTrigger('', queryText, cancel);
        setQueryLoading(false);
      } catch (err) {
        setQueryLoading(false);
        // @ts-ignore
        setChatFlow(prev => (!isEmpty(prev) ? [...prev, errorAnswer] : [errorAnswer]));
      }
    },
    [
      queryText,
      applicationNumber,
      selectedApplication,
      applicationSource,
      state.applicationName,
      isARIAMode,
      conversationId,
      ariaPageNumber
    ]
  );
  const handleNewSection = async (section: string, question: string, bubbleIndex?: number) => {
    setQueryLoading(true);
    const cancel = axios.CancelToken.source();
    setCancelToken(cancel);
    await chatRIAQueryTrigger(section, question, cancel, bubbleIndex);
    setQueryLoading(false);
  };
  const handleMinimizeChat = useCallback(() => {
    if (document.getElementById('jsd-widget')) {
      // @ts-ignore
      document.getElementById('jsd-widget').hidden = false;
    }
    setMinimized(true);
    dispatch({ type: GlobalActions.SET_MINIMIZED_CHAT_RIA, value: true });
    dispatch({ type: GlobalActions.SET_SHOW_NEW_FILTERS, value: true });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: false });
    setOpenWidget(false);
  }, []);

  const removeTextMarkers = (text: string | object) => {
    try {
      if (typeof text === 'string') {
        // Use a regular expression to remove patterns like [T1], [T2], etc.
        const cleanedText = text?.replace(/\s*\[T\d+\]/g, '');
        return cleanedText;
      }
      return text;
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      return text;
    }
  };

  const handleAskHelp = useCallback(async () => {
    setQueryLoading(true);
    const cancel = axios.CancelToken.source();
    setCancelToken(cancel);
    const payload: HelpQueryPayload = {
      application_number: [applicationNumber],
      source: applicationSource,
      conversation_id: conversationId
    };

    if (isARIAMode) {
      payload.s3_document_url = s3Url;
      payload.page_range = ariaPageNumber[ariaDocPageNumber];
    } else if (isEmpty(currentSection)) {
      payload.section = sample(sections)?.label || '';
    } else {
      payload.section = currentSection;
    }

    const response = await askHelp(payload, cancel.token);
    if (response?.status === 200) {
      const { data } = response;
      if (data?.body?.content) {
        const res = removeTextMarkers(data.body.content);

        let text = '';
        if (typeof res === 'string') {
          text = res;
        } else if (typeof res === 'object') {
          // eslint-disable-next-line no-unused-vars
          Object.entries(res).forEach(([key, value]) => {
            text = `${text + value} `;
          });
        }
        const answer = {
          text,
          type: 'bot',
          action: 'help',
          error: data.error,
          currentSection: payload.section,
          applicationNumber
        };
        // @ts-ignore
        setChatFlow(prev => (!isEmpty(prev) ? [...prev, answer] : [answer]));
      } else {
        // @ts-ignore
        setChatFlow(prev => (!isEmpty(prev) ? [...prev, errorAnswer] : [errorAnswer]));
      }
    } else {
      // @ts-ignore
      setChatFlow(prev => (!isEmpty(prev) ? [...prev, errorAnswer] : [errorAnswer]));
    }
    setCancelToken(null);

    setQueryLoading(false);
  }, [
    currentSection,
    applicationNumber,
    applicationSource,
    s3Url,
    isARIAMode,
    conversationId,
    ariaPageNumber,
    sections
  ]);

  const getTitle = async (content: string, id: any, question: string): Promise<string> => {
    const payload = {
      [id]: {
        answer: content,
        question
      }
    };
    const response = await getReportTitle(payload);
    if (response?.status === 200) {
      const { data } = response;
      if (data?.body) {
        return data?.body[0].title;
      }
    }
    return '';
  };

  const handleAddToReportAction = useCallback(
    async (
      content: string,
      question: string,
      generatedForSection: string,
      generatedForBrandName: string,
      generatedForApplication: string,
      generatedForSource: string,
      s3URL: string,
      action: string,
      id?: string,
      bubbleIndex?: number,
      originalUrl?: string,
      reportData?: any
    ) => {
      setReportLoading(true);
      const reportTitle: string = await getTitle(content, id, question);
      if (action === 'add') {
        const prettySource = sourceMap[generatedForSource];
        let subPath = '';
        if (isARIAMode) {
          const ariaPath = new URL(s3URL || '');
          const bucket = ariaPath.hostname.split('.')[0];
          const page = s3URL?.split('page=')[1];
          subPath = `aria?bucket=${bucket}&path=${encodeURIComponent(
            ariaPath.pathname.replace(/^\//, '')
          )}&page=${page}`;
        } else {
          subPath = `application?source=${generatedForSource}&application=${generatedForApplication}&section=${generatedForSection}`;
        }
        const sectionLayout = {
          dataSource: 'custom',
          sectionType: 'TEXT',
          textType: 'HTML_TEXT',
          id: reportData?.reportId,
          _chatId: id,
          style: {
            placement: {
              h: 10,
              w: 12,
              i: reportData?.reportId,
              minW: 8,
              moved: false,
              static: false,
              x: 0,
              y: 0
            },
            references: {
              show: true,
              href: `${window.location.origin}/chatRIA/reports/reference/${subPath}`,
              text: `${prettySource} / ${generatedForApplication} /  ${generatedForBrandName}`,
              originalUrl
            }
          },
          content: `<h3>${reportTitle}</h3>\n\n${content}`
        };
        updateReportData(reportData?.id, sectionLayout);
      } else if (action === 'remove') {
        dispatch({
          type: GlobalActions.REMOVE_CHAT_RIA_REPORT,
          value: id
        });
      }
      setChatFlow((prev: any) => {
        const newChatFlow = Array.isArray(prev) ? [...prev] : [];
        if (bubbleIndex) {
          const selectedBubble = newChatFlow[bubbleIndex];
          const indexOfAnswer = selectedBubble.findIndex((item: any) => item.id === id);
          selectedBubble[indexOfAnswer].inReport = action === 'add';
        }
        return newChatFlow;
      });
      setReportLoading(false);
    },
    [chatFlow, isARIAMode]
  );

  const downloadReport = useCallback(
    async (rearrangedList: any[], fileType: string, fileName: string) => {
      setProcessingReport(true);
      const response = await createReport(rearrangedList, {
        generatedBy: `${currentUser?.['custom:user']}`,
        generatedOn: format(new Date(), 'MMMM do yyyy, h:mm:ss a'),
        applicationNumber,
        brandName: state.applicationName,
        fileType
      });
      const finalFileName = `${fileName}-ChatRIA.${fileType}`;
      const blob = new File([response.data], finalFileName, {
        type:
          fileType === 'pdf'
            ? 'application/pdf'
            : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
      });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = finalFileName;
      link.click();
      setProcessingReport(false);
    },
    [reportList, currentUser, applicationNumber, state.applicationName]
  );

  const handleVerifyAction = useCallback(async (url: string, pageNumber: number) => {
    setVerifyAnswer(prevState => {
      if (prevState.url === url && prevState.pageNumber === pageNumber) {
        return { url: '', pageNumber: 0 };
      }
      return { url, pageNumber };
    });
  }, []);

  const handleChangeMode = useCallback((mode: any) => {
    setRiaMode(mode);
  }, []);

  const clearReport = useCallback(async () => {
    setReportList([]);
    await dispatch({
      type: GlobalActions.RESET_ADD_TO_REPORT,
      value: true
    });
    setTimeout(() => {
      dispatch({
        type: GlobalActions.RESET_ADD_TO_REPORT,
        value: false
      });
    }, 1000);
  }, []);

  const handleCancelCall = useCallback(async () => {
    if (cancelToken) {
      cancelToken.cancel('Operation canceled by the user.');
      setCancelToken(null);
    }
  }, [cancelToken]);
  const fetchReportTitle = useCallback(async () => {
    setLoadingReportTitle(true);
    const reportData = reportList
      .filter(item => !item.title || isEmpty(item.title))
      .reduce(
        (acc, item) => ({
          ...acc,
          [item.id]: {
            answer: item.answer,
            question: item.question
          }
        }),
        {}
      );
    if (!isEmpty(reportData)) {
      const response = await getReportTitle(reportData);
      if (response?.status === 200) {
        const { data } = response;
        if (data?.body) {
          const res: { id: string; response: any; title: string }[] = data.body;
          const newReportList = reportList.map(item => ({
            ...item,
            title: res?.find(report => report.id === item.id)?.title || item?.title || ''
          }));
          setReportList(newReportList);
        }
      }
    }
    setLoadingReportTitle(false);
  }, [reportList]);
  const closeSession = useCallback(async () => {
    if (document.getElementById('jsd-widget')) {
      // @ts-ignore
      document.getElementById('jsd-widget').hidden = false;
    }
    setOpenApplicationOption(false);
    setSessionOpen(false);
    setOpenWidget(false);
    setApplicationNumber('');
    setQueryText('');
    setChatFlow(null);
    setQueryError(null);
    setQueryLoading(false);
    setInitialised(false);
    setSections([]);
    setCurrentSection('');
    setS3Url('');
    setReportList([]);
    setProcessingReport(false);
    setSeeMore(false);
    setSelectedApplication({});
    setVerifyAnswer({ url: '', pageNumber: 0 });
    setApplicationSource('');
    setApplicationNameList([]);
    setRiaMode(true);
    setChatRiaStatus('OK');
    setLastStatusCheckTime('');
    setCancelToken(null);
    setIsARIAMode(false);
    setMinimized(false);
    setConversationId('');
    setCancelToken(null);
    setLoadingReportTitle(false);
    setLatestChatId({ chatId: '', isNew: false });
    setAriaPageNumber({});
    setSourceUrl('');
    setARIADocPageNumber(0);
    setCurrentAriaState({});
    setApplicationMeta({
      submissionId: '',
      approvalDate: '',
      brandName: ''
    });
    dispatch({ type: GlobalActions.SET_MINIMIZED_CHAT_RIA, value: false });
    dispatch({ type: GlobalActions.SET_APPLICATION_SOURCE, value: '' });
    dispatch({ type: GlobalActions.SET_APPLICATION_LIST, value: [] });
    dispatch({ type: GlobalActions.SET_APPLICATION_NUMBER, value: '' });
    dispatch({ type: GlobalActions.SET_ARIA_DOCUMENT, value: {} });
    dispatch({ type: GlobalActions.SET_APPLICATION_NAME, value: '' });
    dispatch({ type: GlobalActions.SET_SHOW_NEW_FILTERS, value: true });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: false });
  }, [isARIAMode]);

  useEffect(() => {
    if (state.resetAddToReport) {
      setChatFlow((prev: any) => {
        return prev.map((item: any) => {
          if (Array.isArray(item)) {
            return item.map((bubble: any) => ({
              ...bubble,
              inReport: false
            }));
          }
          return item;
        });
      });
    }
  }, [state]);
  useEffect(() => {
    if (queryError) {
      // @ts-ignore
      setChatFlow(prev => (!isEmpty(prev) ? [...prev, errorAnswer] : [errorAnswer]));
    }
  }, [queryError]);
  useEffect(() => {
    if (
      ariaDocPageNumber > 0 &&
      ariaPageNumber &&
      ariaPageNumber[ariaDocPageNumber] &&
      state?.ariaDocument?.triggerReopenChatRia === false
    ) {
      setChatFlow((prev: any) => {
        if (prev) {
          const localState = [...prev];
          const answer = {
            text: `Results from page ${ariaPageNumber[ariaDocPageNumber]?.start} to ${
              ariaPageNumber[ariaDocPageNumber]?.end
            } for ${customTitleCase(
              getAriaResultTitle(
                currentAriaState?.item[ariaCardResultsMapping[currentAriaState?.source]?.title],
                currentAriaState?.item,
                currentAriaState?.source
              )
            )} ${
              currentAriaState?.item?.trade_name && `(${currentAriaState?.item?.trade_name || ''})`
            }`,
            type: 'info'
          };
          if (localState[localState.length - 1]?.type === 'info') {
            localState.pop();
          }
          if (localState.length > 0) {
            localState.push(answer);
          }

          return localState;
        }
        return [];
      });
    }
  }, [ariaDocPageNumber, ariaPageNumber, state?.ariaDocument?.triggerReopenChatRia]);

  useEffect(() => {
    if (state.applicationName) {
      setApplicationMeta((prev: any) => ({
        ...prev,
        brandName: state.applicationName
      }));
    }
  }, [state.applicationName]);

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const chatIds = state.reportLayout.sections.map((section: any) => section._chatId);
    setAddedToReportList(chatIds);
  }, [state.reportLayout]);

  return {
    sessionOpen,
    handleChangeQuery,
    handleSubmitQuery,
    queryText,
    openSession,
    openWidget,
    initialised,
    closeSession,
    chatFlow,
    sections,
    queryLoading,
    currentSection,
    queryError,
    handleAskHelp,
    brandName: state.applicationName,
    handleAddToReportAction,
    showDownloadReport: !isEmpty(reportList),
    downloadReport,
    processingReport,
    seeMore,
    setSeeMore,
    handleVerifyAction,
    verifyAnswer,
    reportCount: reportList.length,
    applicationNameList,
    selectedApplication,
    handleApplicationChange,
    handleChangeMode,
    riaMode,
    clearReport,
    chatRiaStatus,
    lastStatusCheckTime,
    handleSetHelpQuery,
    handleCancelCall,
    currentApplicationNumber: applicationNumber,
    reportData: reportList,
    isARIAMode,
    s3Url,
    handleMinimizeChat,
    fetchReportTitle,
    loadingReportTitle,
    minimizedChat: isMinimizedChat,
    handleReopenSession,
    ariaPageNumber,
    handleNewSection,
    latestChatId,
    setSourceUrl,
    setARIADocPageNumber,
    ariaDocPageNumber,
    applicationMeta,
    documentReferencePages,
    applicationSource,
    setOpenApplicationOption,
    openApplicationOption,
    addedToReportList,
    reportLoading
  };
};
export default useChatQuery;
