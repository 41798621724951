import { Box, Stack, Typography } from '@mui/material';
import groupBy from 'lodash/groupBy';
import flatMap from 'lodash/flatMap';
import React, { useMemo, useState } from 'react';
import { isEmpty, uniqBy } from 'lodash';

import { ThreeDotIconVariation } from '../../../assets/svgs/Icons';
import SectionList from './SectionList';
import SummaryCard from './SummaryCard';
import styles from './MeetingDetails.style';
import GroupTabs from './GroupTabs';
import { TabButtonProps, TopicProps } from '../types';
import MinutesPendingWarning from './MinutesPendingWarning';

const TabButttons: TabButtonProps[] = [
  { label: 'Positives', id: 'positives', count: 0 },
  { label: 'Negatives', id: 'negatives', count: 0 },
  { label: 'Withdrawals', id: 'Withdrawals', count: 0 },
  { label: 'More', id: 'more', icon: <ThreeDotIconVariation />, options: [], count: 0 }
];

const MeetingDetails = ({
  sections,
  meetingId,
  highlightedData,
  onSectionChange,
  minutesCategory
}: any) => {
  const [tab, setTab] = useState<string>('All');
  const [selectedGroup, setSelectedGroup] = useState<string>('All');
  const [filteredSections, setFilteredSections] = useState<Record<string, TopicProps[]>>(sections);
  const [currentExpandedCard, setCurrentExcpandedCard] = useState<string>('');

  const tabsToDispaly = useMemo(() => {
    const flattenedData = flatMap(sections, (data, key) =>
      data.map((section: any) => ({
        ...section,
        main_key: key
      }))
    );
    const groups = groupBy(flattenedData, (item: any) => item.highlight_data?.category_group_by);
    return TabButttons.map((button: TabButtonProps) => {
      if (button.id === 'more') {
        const otherOptions = TabButttons.map((btn: any) => btn.label);

        const options = flattenedData.filter(
          data => !otherOptions.includes(data.highlight_data?.category_group_by)
        );

        const uniqOptions = uniqBy(options, (item: any) => item.highlight_data?.category_group_by);

        const moreOptions: any = [];
        uniqOptions.forEach(opt => {
          if (opt.highlight_data?.category_group_by === undefined) return;
          moreOptions.push({
            label: `${opt.highlight_data?.category_group_by} (${
              groups[opt.highlight_data?.category_group_by].length
            })`,
            actualLabel: opt.highlight_data?.category_group_by,
            id: opt.highlight_data?.category_group_by?.toLowerCase(),
            selected: false
          });
        });

        return {
          ...button,
          count: options.filter(opt => !!opt.highlight_data?.category_group_by).length,
          options: moreOptions
        };
      }

      return {
        ...button,
        count: groups[button.label]?.length ?? 0
      };
    });
  }, [sections]);

  const handleSectionExpand = (sectionId: string) => {
    if (sectionId === currentExpandedCard) {
      setCurrentExcpandedCard('');
    } else {
      setCurrentExcpandedCard(sectionId);
    }
  };

  const getFilteredSections = (group: string) => {
    const sectionWithSelectedGroup: { [key: string]: TopicProps[] } = {};
    Object.keys(sections).forEach((sectionTitle: string) => {
      const topics: TopicProps[] = sections[sectionTitle].filter(
        (topic: TopicProps) => topic?.highlight_data?.category_group_by === group || group === 'All'
      );
      if (topics.length > 0) {
        sectionWithSelectedGroup[sectionTitle] = topics;
      }
    });
    return sectionWithSelectedGroup;
  };

  const handleTabChange = (tb: string, grp: string) => {
    setSelectedGroup(grp);
    setTab(tb);
    const newFilteredSection = getFilteredSections(grp);
    setFilteredSections(newFilteredSection);
    onSectionChange(newFilteredSection);
  };

  const sortedSectionTitles = useMemo(() => {
    const allSections = Object.keys(filteredSections);
    const otherIndex = allSections.findIndex((value: string) => value === 'Others');
    if (otherIndex > -1) {
      allSections.splice(otherIndex, 1);
      allSections.push('Others');
    }
    return allSections;
  }, [filteredSections]);

  return (
    <Box sx={{ borderRight: '1px solid', borderColor: 'gray.300' }}>
      <GroupTabs tabs={tabsToDispaly} onChange={handleTabChange} />
      <Box pt={2}>
        {tab === 'All' && minutesCategory !== 'minute' && (
          <Box px={5} pt={2} pb={3}>
            <MinutesPendingWarning />
          </Box>
        )}
        {tab !== 'All' && (
          <Stack px={5}>
            <Typography sx={styles.groupName}>{selectedGroup}</Typography>
            {!isEmpty(highlightedData[selectedGroup]?.summary) && (
              <Box pb={3}>
                <SummaryCard details={highlightedData[selectedGroup].summary} />
              </Box>
            )}
          </Stack>
        )}
        {sortedSectionTitles.map((sectionTitle: string) => (
          <SectionList
            key={sectionTitle + selectedGroup}
            sectionTitle={sectionTitle}
            sections={filteredSections[sectionTitle]}
            meetingId={meetingId}
            onSectionExpand={handleSectionExpand}
            expanded={currentExpandedCard}
          />
        ))}
      </Box>
    </Box>
  );
};

export default React.memo(MeetingDetails);
