import Box from '@mui/material/Box';
import React, { useContext, useEffect, useState } from 'react';
import { Button, Divider, MenuItem, Stack, Tooltip } from '@mui/material';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import CloseIcon from '@mui/icons-material/Close';

import useCTDocuments from '../../hooks/useCTDocuments';
import PDFViewer from '../../../../components/PDFViewer';
import { RoundedMenu } from '../../../../components/ui/Menu';

import styles from '../../styles/CTDocument';
import SendIcon from '../../../../Images/svgs/sendIcon';
import { ArrowDownIcon, CustomCheckIcon } from '../../../../assets/svgs/Icons';

// store
import GlobalStore from '../../../../store';
import GlobalActions from '../../../../store/actions';
import MenuItemWithSubMenu from '../../../../components/ui/Menu/MenuItemWithSubMenu/MenuItemWithSubMenu';
import MenuWithTickOptions from '../../../../components/ui/Menu/MenuWithTickOptions';

const CTDocuments: React.FC<any> = ({
  id,
  onClose,
  selectedGraphEntityData,
  source = 'ct'
}: any) => {
  const { ctDocuments, isLoading, getCTDocuments } = useCTDocuments(id, source);
  const [selectedDocument, setDocument] = useState<any>();
  const { dispatch } = useContext(GlobalStore) as any;

  const [anchorEl, setAnchorElement] = useState<any>();

  useEffect(() => {
    setDocument(null);
    getCTDocuments();
  }, [id]);

  useEffect(() => {
    if (Array.isArray(ctDocuments) && ctDocuments.length > 0) {
      setDocument(ctDocuments[0]);
    } else {
      const firstDocType = Object.keys(ctDocuments)[0];
      if (ctDocuments[firstDocType]?.[0]) {
        setDocument(ctDocuments[firstDocType]?.[0]);
      }
    }
  }, [ctDocuments]);

  const handleChatRia = (pdfUrl = '') => {
    const metadata = {
      brief_title: selectedGraphEntityData.studyTitle ?? '',
      start_date: selectedGraphEntityData.startDate ?? '',
      phase: selectedGraphEntityData.phase ?? '',
      nct_id: id ?? '',
      category_bucket: selectedDocument?.doc_type
    };
    dispatch({
      type: GlobalActions.SET_CHATRIA_TRIGGERED_FROM,
      value: 'document'
    });
    dispatch({
      type: GlobalActions.SET_APPLICATION_SOURCE,
      value: source
    });
    dispatch({
      type: GlobalActions.SET_ARIA_DOCUMENT,
      value: {
        blob: pdfUrl,
        url: pdfUrl,
        item: metadata,
        source,
        triggerReopenChatRia: false
      }
    });
    dispatch({ type: GlobalActions.SET_CHATRIA_OPEN, value: true });
  };

  return (
    <Box height='100%' sx={styles.pdfRoot}>
      <Typography textAlign='start' fontWeight='bold' paddingY={1} fontSize={14}>
        {id}
      </Typography>
      <Divider sx={{ borderColor: 'gray.200' }} />
      <Stack
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        sx={styles.pdfHeader}>
        <Stack direction='row' spacing={1} justifyContent='start' alignItems='center'>
          {!isLoading && (
            <>
              <Typography component='span' fontSize={14} color='gray.600'>
                Document Type:
              </Typography>
              <Button
                endIcon={<ArrowDownIcon />}
                onClick={event => setAnchorElement(event.currentTarget)}
                sx={styles.docTypeButton}>
                {selectedDocument?.doc_type}
              </Button>
            </>
          )}

          <RoundedMenu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorElement(null)}>
            {Array.isArray(ctDocuments)
              ? ctDocuments.map((doc: any) => (
                  <MenuItem
                    key={doc.s3_path}
                    onClick={() => {
                      setDocument(doc);
                      setAnchorElement(null);
                    }}>
                    <Box display='flex' alignItems='center'>
                      <CustomCheckIcon
                        sx={{
                          color: 'gray.750',
                          height: '14px',
                          width: '14px',
                          opacity: selectedDocument?.doc_type === doc.doc_type ? 1 : 0
                        }}
                      />
                      <Typography component='span'>{doc.doc_type}</Typography>
                    </Box>
                  </MenuItem>
                ))
              : Object.keys(ctDocuments).map((category: any) => (
                  <MenuItemWithSubMenu
                    MenuItemText={category}
                    key={category}
                    MenuItemIcon={
                      <CustomCheckIcon
                        sx={{
                          color: 'gray.750',
                          height: '14px',
                          width: '14px',
                          // eslint-disable-next-line no-underscore-dangle
                          opacity: selectedDocument?.doc_type === category ? 1 : 0
                        }}
                      />
                    }>
                    <MenuWithTickOptions
                      options={ctDocuments[category]?.map(
                        ({ _category, s3_path: s3Path, doc_type: docType }: any) => ({
                          label: _category,
                          value: s3Path,
                          // eslint-disable-next-line no-underscore-dangle
                          selected: _category === selectedDocument?._category,
                          docType
                        })
                      )}
                      onOptionSelect={(opt: any) => {
                        setDocument({
                          s3_path: opt.value,
                          _category: opt.label,
                          doc_type: opt.docType
                        });
                        setAnchorElement(null);
                      }}
                      isNestedMenu
                    />
                  </MenuItemWithSubMenu>
                ))}
          </RoundedMenu>
        </Stack>
        <Box display='flex'>
          <Tooltip title='Use Chat RIA to interact with the document'>
            <Button
              variant='text'
              disabled={selectedDocument && !selectedDocument.s3_path}
              onClick={() => {
                handleChatRia(selectedDocument.s3_path);
              }}
              sx={styles.chatRiaButton}>
              Chat RIA
              <SendIcon
                sx={{ width: '16px', height: '16px', p: '2.667px 2.666px 2.667px 2.667px' }}
              />
            </Button>
          </Tooltip>
        </Box>
        <Box position='absolute' top={0} right={0}>
          <CloseIcon onClick={onClose} sx={styles.closeBtn} />
        </Box>
      </Stack>
      {isLoading && (
        <Box sx={styles.loadingIcon}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && Object.keys(ctDocuments).length === 0 && (
        <Box sx={styles.loadingIcon} fontSize={14}>
          No documents available at the moment.
        </Box>
      )}
      <Box height='90%'>
        {selectedDocument && <PDFViewer fileUrl={selectedDocument.s3_path} defaultScale={1.35} />}
      </Box>
    </Box>
  );
};

export default React.memo(CTDocuments);
