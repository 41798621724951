import { FILTER_TYPE } from '../../components/NewFilters/constants';

export type SectionKeys = '2. Opinions' | '3. Discussions';

export const SECTIONS: Record<SectionKeys, any> = {
  '2. Opinions': [
    { value: '2.1 Opinions on Products', label: '2.1 Products' },
    { value: '2.2 Opinions on Compliance Check', label: '2.2 Compliance Check' },
    {
      value: '2.3 Opinions on Modification of an Agreed Paediatric Investigation Plan',
      label: '2.3 Modification of an Agreed Paediatric Investigation Plan'
    },
    { value: '2.4 Opinions on Re-examinations', label: '2.4 Re-examinations' },
    { value: '2.5 Opinions on Review of Granted Waivers', label: '2.5 Review of Granted Waivers' },
    {
      value: '2.6 Finalisation and adoption of Opinions',
      label: '2.6 Finalisation and adoption of Opinions'
    },
    {
      value: '2.7 Partial Compliance Checks completed by EMA',
      label: '2.7 Partial Compliance Checks completed by EMA'
    },
    { value: '2.8 Revision of the PDCO opinions', label: '2.8 Revision of the PDCO opinions' }
  ],
  '3. Discussions': [
    { value: '3.1 Discussions on Product D90-D60-D30', label: '3.1 Product D90-D60-D30' },
    { value: '3.2 Discussions on Compliance Check', label: '3.2 Compliance Check' },
    {
      value: '3.3 Discussions on Modification of an Agreed Paediatric Investigation Plan',
      label: '3.3 Modification of an Agreed Paediatric Investigation Plan'
    }
  ]
};

export const LABEL_NAMES: any = {
  decisionType: 'Decision Type',
  // firstPublishedDate: 'First Published Date',
  decisionDate: 'Decision Date',
  revisionDate: 'Revision Date',
  activeIngredient: 'Active Ingredient',
  formulation: 'Formulation',
  indications: 'Indications',
  routes: 'Routes',
  therapeuticArea: 'Therapeutic Area',
  tradeName: 'Trade Name'
};

export const LABEL_DETAILS: any = {
  decisionType: 'W: decision granting a waiver in all age groups for all conditions or indications',
  firstPublishedDate: '2018-02-12',
  decisionDate: '2017-10-04',
  revisionDate: '2018-02-12',
  activeIngredient: 'lenalidomide',
  formulation: 'Capsule, hard',
  indications: 'Treatment of mature B-cell neoplasms',
  routes: 'Oral use',
  therapeuticArea: 'Oncology',
  tradeName: 'Revlimid Revlimid'
};

export interface InputObject {
  active_ingredient: string;
  brand_name: string;
  decision_date: string;
  decision_number: string;
  decision_type: string;
  file_name: string;
  formulations: string;
  indication: string;
  revision_date: string;
  routes: string;
  s3_url: string;
  therapeutic_area: string;
  url: string;
}

export const keyMapping: { [key: string]: keyof InputObject } = {
  decisionType: 'decision_type',
  decisionDate: 'decision_date',
  revisionDate: 'revision_date',
  activeIngredient: 'active_ingredient',
  formulation: 'formulations',
  indications: 'indication',
  routes: 'routes',
  therapeuticArea: 'therapeutic_area',
  tradeName: 'brand_name'
};

export const PDCO_FILTERS = [
  {
    id: 1,
    label: 'Active Ingredient',
    value: 'active_ingredient',
    elasticSearchType: 'active_ingredient',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 2,
    label: 'Indication',
    value: 'indication',
    elasticSearchType: 'indication',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 3,
    label: 'Sponsor Name',
    value: 'sponsor_name',
    elasticSearchType: 'sponsor_name',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 4,
    label: 'Therapeutic Area',
    value: 'therapeutic_area',
    elasticSearchType: 'therapeutic_area',
    filterType: FILTER_TYPE.SELECT_OPTIONS,
    showInBottomFilter: true
  },
  {
    id: 5,
    label: 'Search Keywords',
    value: 'filter_keywords',
    elasticSearchType: 'filter_keywords',
    filterType: FILTER_TYPE.SEARCH,
    showInBottomFilter: true
  },
  {
    id: 6,
    label: 'Meeting Year',
    value: 'year',
    elasticSearchType: 'year',
    filterType: FILTER_TYPE.YEAR_RANGE,
    showInBottomFilter: true
  }
];