import React, { useEffect, useState } from 'react';
import {
  Box,
  Menu,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import MoreVert from '@mui/icons-material/MoreVert';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import ArrowUpwardOutlinedIcon from '@mui/icons-material/ArrowUpwardOutlined';
import { useHistory, useLocation } from 'react-router-dom';
import useReportData from '../../hooks/useReportData';
import NotificationLoading from '../../../Notifications/components/NotificationLoading';
import styles from '../../styles/Reports.styles';
import savedItemStyles from '../../styles/SavedItems.styles';
import TrashIcon from '../../../../assets/svgs/Icons/TrashIcon';
import RenameIcon from '../../../../assets/svgs/Report/RenameIcon';
import TitleDescriptionDialog from '../TitleDescriptionDialog';
import { REPORT_DESCRIPTION_MAX_LENGTH, REPORT_TITLE_MAX_LENGTH } from '../../constants';
import { ReportData } from '../../interface';
import NoResultsView from '../NoResultsView';
import ReportingDrawer from '../../../../components/Report/ReportingDrawer';

const Reports = () => {
  const [sortDetails, setSortDetails] = useState<{
    column: 'reportName' | 'createdAt' | 'updatedAt';
    order: 'asc' | 'desc';
  }>({ column: 'createdAt', order: 'asc' });
  const location = useLocation();
  const history = useHistory();
  const [selectedReport, setSelectedReport] = useState<ReportData | null>(null);
  const [action, setAction] = useState<string>('');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openReporting, setOpenReporting] = useState<boolean>(false);
  const { isLoading, reportData, fetchReportData, updateReportDetails, deleteReport, sortReport } =
    useReportData();
  const updatedSearchParams = React.useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  useEffect(() => {
    fetchReportData();
  }, []);

  const handleEllipsisClick = (event: any, row: ReportData) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setSelectedReport(row);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setSelectedReport(null);
    setAction('');
  };

  const handleRowClick = (e: any, row: ReportData) => {
    e.stopPropagation();
    if (!(Boolean(anchorEl) && selectedReport === row)) {
      handleCloseMenu();
      updatedSearchParams.set('id', row?.id?.toString());
      history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
      setOpenReporting(true);
    } else {
      handleCloseMenu();
    }
  };

  const handleOpenDelete = (event: any) => {
    event.stopPropagation();
    setAction('delete');
  };
  const handleOpenRename = (event: any) => {
    event.stopPropagation();
    setAction('edit');
  };

  const handleSort = (column: 'reportName' | 'createdAt' | 'updatedAt') => {
    sortReport(column, sortDetails.order);
    setSortDetails({ column, order: sortDetails.order === 'asc' ? 'desc' : 'asc' });
  };

  const handleReportUpdate = async (title: string, description: string) => {
    await updateReportDetails(selectedReport?.id ?? 0, title, description);
    handleCloseMenu();
  };

  const handleDeleteReport = async () => {
    await deleteReport(selectedReport?.id ?? 0);
    handleCloseMenu();
  };

  if (isLoading) {
    return (
      <Box sx={savedItemStyles.listSkeletonBox}>
        <Stack height={500}>
          <NotificationLoading />
          <NotificationLoading />
          <NotificationLoading />
        </Stack>
      </Box>
    );
  }
  if (reportData.length === 0) return <NoResultsView text='No Reports are saved' />;

  return (
    <Box sx={styles.root}>
      <TableContainer>
        <Table aria-label='reports-list'>
          {/* Table Header */}
          <TableHead>
            <TableRow sx={styles.tableRowHeader}>
              <TableCell sx={styles.tableCell}>
                Name
                <IconButton
                  className={sortDetails.column === 'reportName' ? undefined : 'sort-icons'}
                  onClick={() => handleSort('reportName')}>
                  {sortDetails.order === 'desc' ? (
                    <ArrowDownwardOutlinedIcon sx={{ fontSize: 'medium' }} />
                  ) : (
                    <ArrowUpwardOutlinedIcon sx={{ fontSize: 'medium' }} />
                  )}
                </IconButton>
              </TableCell>
              <TableCell sx={styles.tableCellDesc}>Description</TableCell>
              <TableCell sx={styles.tableCell}>
                Date Updated
                <IconButton
                  className={sortDetails.column === 'updatedAt' ? undefined : 'sort-icons'}
                  onClick={() => handleSort('updatedAt')}>
                  {sortDetails.order === 'desc' ? (
                    <ArrowDownwardOutlinedIcon sx={{ fontSize: 'medium' }} />
                  ) : (
                    <ArrowUpwardOutlinedIcon sx={{ fontSize: 'medium' }} />
                  )}
                </IconButton>
              </TableCell>
              <TableCell sx={styles.tableCell}>
                Date Created
                <IconButton
                  className={sortDetails.column === 'createdAt' ? undefined : 'sort-icons'}
                  onClick={() => handleSort('createdAt')}>
                  {sortDetails.order === 'desc' ? (
                    <ArrowDownwardOutlinedIcon sx={{ fontSize: 'medium' }} />
                  ) : (
                    <ArrowUpwardOutlinedIcon sx={{ fontSize: 'medium' }} />
                  )}
                </IconButton>
              </TableCell>
              <TableCell sx={styles.tableCellBlank}> </TableCell>
            </TableRow>
          </TableHead>

          {/* Table Body */}
          <TableBody>
            {reportData.map(row => (
              <TableRow
                key={row.id}
                sx={styles.tableRow}
                onClick={(e: any) => handleRowClick(e, row)}>
                <TableCell component='th' scope='row' sx={styles.rowNameText}>
                  {row.reportName}
                </TableCell>
                <TableCell align='left' sx={styles.rowText}>
                  {row.reportDescription}
                </TableCell>
                <TableCell align='left' sx={styles.rowText}>
                  {row.updatedAt}
                </TableCell>
                <TableCell align='left' sx={styles.rowText}>
                  {row.createdAt}
                </TableCell>
                {/* Delete and Rename functionality to be made after the API */}
                <TableCell align='right' sx={styles.rowText}>
                  <Tooltip title='Open options'>
                    <IconButton size='small' onClick={event => handleEllipsisClick(event, row)}>
                      <MoreVert fontSize='small' />
                    </IconButton>
                  </Tooltip>
                  <Menu
                    sx={styles.menu}
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl) && selectedReport === row}
                    onClose={handleCloseMenu}>
                    <MenuItem
                      onClick={(event: any) => {
                        handleOpenRename(event);
                      }}>
                      <RenameIcon sx={styles.svgIcon} />
                      Rename
                    </MenuItem>
                    <MenuItem
                      onClick={(event: any) => {
                        handleOpenDelete(event);
                      }}>
                      <TrashIcon sx={styles.svgIcon} />
                      Remove report
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {!!action && selectedReport && (
        <TitleDescriptionDialog
          dialogType='report'
          heading={action === 'edit' ? 'Edit Report Details' : 'Confirm Delete'}
          title={selectedReport.reportName}
          description={selectedReport.reportDescription}
          handleSubmit={action === 'edit' ? handleReportUpdate : handleDeleteReport}
          handleClose={handleCloseMenu}
          action={action}
          titleMaxlength={REPORT_TITLE_MAX_LENGTH}
          descriptionMaxLength={REPORT_DESCRIPTION_MAX_LENGTH}
        />
      )}

      {openReporting && (
        <ReportingDrawer
          isDrawerOpen={openReporting}
          closeDrawer={() => {
            setOpenReporting(false);
            updatedSearchParams.delete('id');
            history.push(`${location.pathname}?${updatedSearchParams.toString()}`);
          }}
        />
      )}
    </Box>
  );
};

export default Reports;
