import { createSvgIcon } from '@mui/material/utils';

const PieChartIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='15' height='16' viewBox='0 0 15 16' fill='none'>
    <g clipPath='url(#clip0_16321_16335)'>
      <path
        d='M6.8748 15.4813C5.05641 15.4813 3.3125 14.7589 2.0267 13.4731C0.740907 12.1874 0.0185547 10.4434 0.0185547 8.62505C0.0185547 6.80666 0.740907 5.06274 2.0267 3.77695C3.3125 2.49115 5.05641 1.7688 6.8748 1.7688H7.4998L7.50793 2.3938L7.4998 8.00005H13.7311V8.62505C13.7291 10.4428 13.0061 12.1856 11.7207 13.471C10.4353 14.7563 8.69259 15.4793 6.8748 15.4813ZM6.25731 3.05317C5.20791 3.16957 4.21271 3.57994 3.38628 4.23704C2.55984 4.89415 1.93576 5.77127 1.58588 6.76744C1.23599 7.76361 1.17453 8.83834 1.40856 9.86791C1.64259 10.8975 2.1626 11.84 2.90874 12.5871C3.65487 13.3341 4.59679 13.8553 5.62608 14.0905C6.65536 14.3258 7.73016 14.2657 8.72676 13.917C9.72335 13.5683 10.6012 12.9453 11.2593 12.1196C11.9174 11.294 12.329 10.2993 12.4467 9.25005H6.2498L6.25731 3.05317Z'
        fill='currentColor'
      />
      <path
        d='M10.0117 1.93023C10.8702 2.15484 11.6535 2.60389 12.2811 3.23128C12.9087 3.85867 13.3581 4.64179 13.583 5.50023H10.0117V1.93023ZM8.76172 0.521484V6.75023H14.9917C14.9837 5.10053 14.3247 3.52071 13.158 2.3543C11.9914 1.18789 10.4114 0.529207 8.76172 0.521484Z'
        fill='currentColor'
      />
    </g>
    <defs>
      <clipPath id='clip0_16321_16335'>
        <rect width='15' height='15' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'PieChartIcon'
);
export default PieChartIcon;
