import React from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Divider,
  Stack,
  List,
  ListItem,
  ListItemText
} from '@mui/material';
import { HideIcon, ViewIcon } from '../../../../assets/svgs/Icons';
import SectionDetails from '../Section/SectionDetails';
import styles from './Timeline.styles';
import { getDetailsText } from '../../utils';

interface TimelineDetailsSummaryProps {
  summaryData: any;
  productNumber: string;
  sectionData: any;
  expandedItems: any;
  // eslint-disable-next-line no-unused-vars
  setExpandedItems: (expandedItems: any) => void;
}

const TimelineDetailsSummary = ({
  summaryData,
  productNumber,
  sectionData,
  expandedItems,
  setExpandedItems
}: TimelineDetailsSummaryProps) => {
  const handleViewDetailsChange = () => {
    if (expandedItems?.includes(productNumber)) {
      setExpandedItems(expandedItems?.filter((item: string) => item !== productNumber));
    } else {
      setExpandedItems([...expandedItems, productNumber]);
    }
  };

  return (
    <Accordion
      expanded={expandedItems?.includes(productNumber)}
      onChange={() => summaryData && handleViewDetailsChange()}
      sx={styles.timelineDetailsSummary.accordion}>
      <AccordionSummary
        expandIcon={
          expandedItems?.includes(productNumber) ? (
            <HideIcon
              sx={{
                ...styles.timelineDetailsSummary.hideIcon,
                color: summaryData ? 'primary.700' : 'gray.500'
              }}
            />
          ) : (
            <ViewIcon
              sx={{
                ...styles.timelineDetailsSummary.hideIcon,
                color: summaryData ? 'primary.700' : 'gray.500'
              }}
            />
          )
        }
        sx={styles.timelineDetailsSummary.accordionSummary}>
        <Typography
          sx={{
            ...styles.timelineDetailsSummary.accordionSummaryText,
            color: summaryData ? 'primary.700' : 'gray.500'
          }}>
          {getDetailsText(summaryData, Object.keys(expandedItems)?.length > 0)}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.timelineDetailsSummary.accordionDetails}>
        <Box sx={styles.timelineDetailsSummary.detailsContainer}>
          <SectionDetails data={sectionData} />
        </Box>
        <Divider
          orientation='horizontal'
          sx={{ ...styles.timelineDetailsSummary.divider, ml: 1 }}
        />

        <Stack ml={1}>
          <Typography fontWeight={400} color='gray.800'>
            <Typography fontSize={14} component='span' fontWeight={700}>
              Action:
            </Typography>
            <Typography component='span' pl={0.5} fontSize={14}>
              {summaryData?.title}
            </Typography>
          </Typography>
          <List sx={{ pl: 3, listStyleType: 'disc', pt: 0.5, pb: 0 }}>
            {summaryData.actions?.map((text: string, index: number) => (
              // eslint-disable-next-line react/no-array-index-key
              <ListItem key={index} sx={{ display: 'list-item', p: 0 }}>
                <ListItemText
                  primary={text}
                  sx={{ fontSize: 14, m: 0, ml: '-0.25rem', span: { fontSize: 14 } }}
                />
              </ListItem>
            ))}
          </List>
        </Stack>
        <Divider orientation='horizontal' sx={styles.timelineDetailsSummary.divider} />
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(TimelineDetailsSummary);
