import { Auth } from 'aws-amplify';
import React, { useCallback, useContext, useState } from 'react';
import { saveReport } from '../../../api/pages/UserProfile';
import Store from '../../../store';
import Actions from '../../../store/actions';

import NameConfirmationDialog from './NameConfirmationDialog';

interface CreateNewReportFormProps {
  openCreateNewReportDialog: boolean;
  setOpenCreateNewReportDialog: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmit: Function;
  // eslint-disable-next-line react/require-default-props
  closeDrawer?: React.Dispatch<React.SetStateAction<boolean>>;
}

const CreateNewReportForm = ({
  openCreateNewReportDialog,
  setOpenCreateNewReportDialog,
  handleSubmit,
  closeDrawer = () => {}
}: CreateNewReportFormProps) => {
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const { dispatch } = useContext<any>(Store);

  const handleCreateReport = useCallback(async () => {
    const user = await Auth.currentUserInfo();
    const reportDetails = {
      report_title: name,
      report_description: description,
      id: 0
    };
    const payload = {
      report_title: name,
      report_description: description,
      is_active: true,
      layout: {
        owner: user.attributes['custom:user'],
        sections: [],
        coverPage: {
          template: 'template1',
          title: '',
          description: ''
        }
      }
    };
    try {
      const { data: response } = await saveReport(payload);
      if (response) {
        if (response.Success && !response.error && response.Success.id) {
          reportDetails.id = response.Success.id;
          dispatch({
            type: Actions.SET_ALERT,
            value: { status: true, message: 'Successfully created new report.', color: 'success' }
          });
        }
        handleSubmit(reportDetails);
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'Something went wrong. Could not download your report.',
          color: 'error'
        }
      });
    } finally {
      setOpenCreateNewReportDialog(false);
    }
  }, [name, description]);

  return (
    <NameConfirmationDialog
      open={openCreateNewReportDialog}
      name={name}
      description={description}
      onClose={() => {
        setOpenCreateNewReportDialog(false);
        closeDrawer(false);
      }}
      onSave={handleCreateReport}
      heading='Create New Report'
      buttonText='Create Report'
      onDescriptionChange={(e: any) => setDescription(e.target.value)}
      onNameChange={(e: any) => setName(e.target.value)}
    />
  );
};

export default React.memo(CreateNewReportForm);
