import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Auth } from 'aws-amplify';
import {
  Box,
  Popover,
  Divider,
  MenuItem,
  Typography,
  IconButton,
  InputAdornment,
  debounce,
  TextField,
  Accordion,
  styled,
  AccordionSummary,
  AccordionDetails
} from '@mui/material';
import uniqueId from 'lodash/uniqueId';
import styles, { accondionSummary } from './styles/reportAction.styles';
import CaretDownIcon from '../../assets/svgs/Icons/CaretDown';
import { saveReport } from '../../api/pages/UserProfile';
import NameConfirmationDialog from './components/NameConfirmationDialog';
import Store from '../../store';
import Actions from '../../store/actions';
import useReportingHandler from './hooks/useReportingHandler';
import SearchIcon from '../../assets/svgs/Icons/SearchIcon';
import { ReportMenuList } from './FileButton';

interface ReportActionProps {
  open: boolean;
  anchorEl: any;
  handleClose: Function;
  handleAddToReportId: Function;
  // eslint-disable-next-line react/require-default-props
  prefixId?: string;
}

const StyledAccordion = styled(Accordion)({
  boxShadow: 'none',
  width: '100%',
  '& .MuiPaper-root': {
    width: '100%',
    p: 0,
    pl: 2
  }
});

const ReportAction = ({
  open,
  anchorEl,
  handleClose,
  handleAddToReportId,
  prefixId = 'report_customization'
}: ReportActionProps) => {
  const { dispatch } = useContext<any>(Store);
  const [openCreateNewReportDialog, setOpenCreateNewReportDialog] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [anchorElReport, setAnchorElReport] = React.useState<null | HTMLElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAccordionExpanded, setIsAccordionExpanded] = useState(false);
  const { isLoading, reportData, fetchData } = useReportingHandler();
  const [searchText, setSearchText] = useState<any>('');
  useEffect(() => {
    fetchData();
  }, []);

  const handleClick = (event: any, reportDetails: any) => {
    event.preventDefault();
    const random = Math.floor(Math.random() * 1000);
    const id = uniqueId(`report_customization_${random}_`);
    handleAddToReportId({ ...reportDetails, reportId: id });
  };

  const handleCreateReport = useCallback(async () => {
    const reportDetails = {
      report_title: name,
      report_description: description,
      id: 0
    };
    const user = await Auth.currentUserInfo();
    const payload = {
      report_title: name,
      report_description: description,
      layout: {
        owner: user.attributes['custom:user'],
        sections: [],
        coverPage: {
          template: 'template1',
          title: '',
          description: ''
        }
      }
    };
    try {
      const { data: response } = await saveReport(payload);
      if (response) {
        if (response.Success && !response.error && response.Success.id) {
          reportDetails.id = response.Success.id;
        }

        dispatch({
          type: Actions.SET_ALERT,
          value: { status: true, message: 'Successfully created new report.', color: 'success' }
        });
      }
      const random = Math.floor(Math.random() * 1000);
      const id = uniqueId(`${prefixId}_${random}_`);
      handleAddToReportId({ ...reportDetails, reportId: id });
      fetchData();
      setOpenCreateNewReportDialog(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      dispatch({
        type: Actions.SET_ALERT,
        value: {
          status: true,
          message: 'Something went wrong. Could not download your report.',
          color: 'error'
        }
      });
    }
  }, [name, description]);

  const handleSearchTextChange = debounce((event: any) => setSearchText(event.target.value), 200);

  const reportToDisplay = useMemo(() => {
    return reportData.filter(
      (data: any) =>
        data?.report_name?.toLowerCase().includes(searchText.toLowerCase()) && !data?.is_active
    );
  }, [reportData, searchText]);

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={() => {
        handleClose();
      }}
      sx={styles.paper}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}>
      <Box ml={0}>
        <Box display='flex' flexDirection='column' sx={styles.boxContainer}>
          <MenuItem
            onClick={(e: any) => {
              e.stopPropagation();
              setIsExpanded(true);
              setAnchorElReport(e.currentTarget);
            }}
            sx={{
              pl: 0
            }}>
            <CaretDownIcon
              sx={{
                ...styles.caretDownIcon,
                rotate: '90deg'
              }}
            />
            <Typography sx={styles.menuTitle}>Add to Previous Report(s)</Typography>
          </MenuItem>
          <MenuItem
            onClick={() => {
              setOpenCreateNewReportDialog(true);
            }}
            sx={{
              pl: 2.5
            }}>
            <Typography sx={styles.menuTitle}>Create New Report</Typography>
          </MenuItem>
        </Box>
      </Box>
      <Popover
        open={isExpanded}
        anchorEl={anchorElReport}
        onClose={(event: any) => {
          event.stopPropagation();
          event.preventDefault();
          setIsExpanded(false);
        }}
        sx={styles.reportsPaper}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}>
        <Box>
          {isLoading ? (
            <Box display='flex' flexDirection='column' sx={styles.boxContainer}>
              <MenuItem
                sx={{
                  pl: 0
                }}>
                <Typography sx={styles.menuTitle}>Loading...</Typography>
              </MenuItem>
            </Box>
          ) : (
            <Box display='flex' flexDirection='column'>
              <TextField
                variant='outlined'
                placeholder='Search...'
                sx={styles.searchInputBox}
                onChange={handleSearchTextChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
              <Typography sx={styles.title}>Recent Added Report</Typography>
              <Box display='flex' flexDirection='row' sx={styles.menuContainer}>
                <Divider orientation='vertical' sx={styles.dividerSubSection} />
                <ReportMenuList
                  reportToDisplay={reportToDisplay.slice(0, 3)}
                  handleReportClick={handleClick}
                />
              </Box>
              {reportToDisplay?.length > 3 && (
                <Box display='flex' flexDirection='row' justifyContent='space-between'>
                  <StyledAccordion
                    disableGutters
                    expanded={isAccordionExpanded}
                    onChange={() => {
                      setIsAccordionExpanded(prev => !prev);
                    }}>
                    <AccordionSummary sx={accondionSummary}>
                      <Typography sx={styles.accordiontitle}>All Reports</Typography>
                      <IconButton sx={styles.iconButton}>
                        <CaretDownIcon
                          sx={{
                            fontSize: 16,
                            rotate: isAccordionExpanded ? '180deg' : '0deg',
                            color: 'primary.600'
                          }}
                        />
                      </IconButton>
                    </AccordionSummary>
                    <AccordionDetails sx={{ p: 0 }}>
                      <Box display='flex' flexDirection='row' sx={styles.menuContainer}>
                        <Divider orientation='vertical' sx={styles.dividerSubSection} />
                        <ReportMenuList
                          reportToDisplay={reportToDisplay.slice(3, reportToDisplay?.length)}
                          handleReportClick={handleClick}
                        />
                      </Box>
                    </AccordionDetails>
                  </StyledAccordion>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Popover>
      {openCreateNewReportDialog && (
        <NameConfirmationDialog
          open={openCreateNewReportDialog}
          name={name}
          description={description}
          onClose={() => {
            setOpenCreateNewReportDialog(false);
          }}
          onSave={handleCreateReport}
          heading='Create New Report'
          buttonText='Create Report'
          onDescriptionChange={(e: any) => setDescription(e.target.value)}
          onNameChange={(e: any) => setName(e.target.value)}
        />
      )}
    </Popover>
  );
};

export default React.memo(ReportAction);
