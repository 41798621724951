import customTitleCase from '../../../utils/titleCase';

const formatDateVersionedTitle = (title: string) => {
  const [date, ...rest] = title.split(/(?<=\d{4}-\d{2}-\d{2})-/);

  const remainingText = rest.join('-').replace(/-/g, ' ').replace('.pdf', '');
  const capitalized = customTitleCase(remainingText);
  return `${date} - ${capitalized}`;
};

export default formatDateVersionedTitle;
