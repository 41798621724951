import React, { useState } from 'react';
import { TextField, InputAdornment } from '@mui/material';
import { SearchIcon } from '../../assets/svgs/Icons';
import { BottomSearchKeywordOptionsProps } from './types';
import { bottomSearchKeywordOptionsStyles } from './styles';

const BottomSearchKeywordOptions = ({
  selectedCategoryOptions,
  setSelectedCategoryOptions,
  setPreviousSelectedCategoryOptions,
  handleApplyFilters,
  isLoading,
  placeholderText
}: BottomSearchKeywordOptionsProps) => {
  const [searchText, setSearchText] = useState('');

  const handleSearch = (
    event: React.KeyboardEvent<HTMLDivElement>,
    isSearchIconClicked: boolean
  ) => {
    if (
      (event.key === 'Enter' || isSearchIconClicked) &&
      searchText &&
      !selectedCategoryOptions?.filter_keywords?.includes(searchText)
    ) {
      let formattedFilters: any = {};
      if (selectedCategoryOptions?.filter_keywords) {
        formattedFilters = {
          ...selectedCategoryOptions,
          filter_keywords: [searchText, ...selectedCategoryOptions.filter_keywords]
        };
      } else {
        formattedFilters = { ...selectedCategoryOptions, filter_keywords: [searchText] };
      }

      setSearchText('');
      setSelectedCategoryOptions(formattedFilters);
      setPreviousSelectedCategoryOptions(formattedFilters);
      handleApplyFilters(formattedFilters);
    }
  };

  return (
    <TextField
      placeholder={placeholderText}
      value={searchText}
      disabled={isLoading}
      onChange={(ev: any) => setSearchText(ev.target.value)}
      onKeyDown={ev => handleSearch(ev, false)}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <SearchIcon
              onClick={(ev: any) => handleSearch(ev, true)}
              sx={bottomSearchKeywordOptionsStyles.searchIcon}
            />
          </InputAdornment>
        ),
        sx: { ...bottomSearchKeywordOptionsStyles.inputProps }
      }}
      sx={bottomSearchKeywordOptionsStyles.textField}
    />
  );
};

export default React.memo(BottomSearchKeywordOptions);
