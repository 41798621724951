import { Drawer, Stack, Typography, Tooltip, IconButton } from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';

import comparisonDrawerStyles from '../styles/ComparisonDrawer';

const ComparisonDrawer = ({ open, onClose, children }: any) => {
  return (
    <Drawer
      open={open}
      anchor='bottom'
      onClose={onClose}
      PaperProps={comparisonDrawerStyles.drawerPaperPropsStyle}>
      <Stack direction='row' sx={comparisonDrawerStyles.headerWrapper}>
        <Stack spacing={1} sx={comparisonDrawerStyles.guidanceComparisonContainer}>
          <Typography sx={comparisonDrawerStyles.guidanceComparisonText}>
            Guidance Comparison (Limit: 2 Docs)
          </Typography>
        </Stack>
        <Tooltip title='Close'>
          <IconButton onClick={onClose} size='small' sx={comparisonDrawerStyles.iconButton}>
            <HighlightOffOutlinedIcon fontSize='inherit' sx={comparisonDrawerStyles.cancelIcon} />
          </IconButton>
        </Tooltip>
      </Stack>
      <Stack>{children}</Stack>
    </Drawer>
  );
};

export default ComparisonDrawer;
