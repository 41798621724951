import { Stack, Grid, Skeleton } from '@mui/material';

import { uniqueId } from 'lodash';
import styles from '../../styles/Loaders.styles';

import GraphSkeleton from '../../../PediatricsEMA/components/GraphSkeleton';

export const InsightsLoader = () => {
  return (
    <Grid height='100%' m={0} container spacing={2}>
      <Grid container spacing={2}>
        {/* grid for stats counts */}
        <Grid item xs={2}>
          <Stack spacing={4} direction='column'>
            {Array.from({ length: 3 }, () => (
              <Stack key={uniqueId()} sx={styles.statsCountContainer} justifyContent='center'>
                <Stack spacing={1} direction='column'>
                  <Skeleton variant='text' width='70%' />
                  <Skeleton variant='text' width='30%' />
                </Stack>
              </Stack>
            ))}
          </Stack>
        </Grid>
        <Grid item xs={10}>
          <Stack mt={0} spacing={4}>
            <Stack spacing={2} direction='row'>
              {/* basic info */}
              <Stack
                justifyContent='flex-start'
                alignItems='flex-start'
                spacing={2}
                sx={styles.basicInfoContainer}>
                <Skeleton variant='rounded' width='70%' />
                <Skeleton variant='rounded' width='100%' />
                <Skeleton variant='rounded' width='60%' />
                <Skeleton variant='rounded' width='30%' />
                <Skeleton variant='rounded' width='80%' />
                <Skeleton variant='rounded' width='60%' />
                <Skeleton variant='rounded' width='40%' />
              </Stack>

              {/* pie chart */}
              <Stack spacing={2} sx={styles.pieCharContainer}>
                <Skeleton variant='rounded' width='30%' />
                <GraphSkeleton />
              </Stack>
            </Stack>

            <Stack spacing={2} direction='row'>
              <Stack spacing={2} sx={styles.pieCharContainer}>
                <Skeleton variant='rounded' width='30%' />
                <GraphSkeleton />
              </Stack>
              <Stack
                justifyContent='flex-start'
                alignItems='flex-start'
                spacing={2}
                sx={styles.basicInfoContainer}>
                <Skeleton variant='rounded' width='70%' />
                <Skeleton variant='rounded' width='100%' />
                <Skeleton variant='rounded' width='60%' />
                <Skeleton variant='rounded' width='30%' />
                <Skeleton variant='rounded' width='80%' />
                <Skeleton variant='rounded' width='60%' />
                <Skeleton variant='rounded' width='40%' />
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default InsightsLoader;
