import React, { useCallback, useEffect, useState } from 'react';
import {
  Divider,
  Box,
  Grid,
  Typography,
  Drawer,
  IconButton,
  Stack,
  Tooltip,
  DialogContent
} from '@mui/material';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import DraftableComparisonView from '../../../../components/Comparison/DraftableComparisonView';
import getComparisonFile from '../../../../helpers/draftableUtils';
import { getLabelComparision } from '../../../../api/pages/Reg360';

import comparisonDialogStyles from '../../../../components/Comparison/styles/ComparisonDialog.styles';
import { DownloadIcon } from '../../../../assets/svgs/Icons';
import { ComparisonData } from '../../utils';

interface DocumentComparisonProps {
  parentData: ComparisonData;
  setParentData: any;
  childData: ComparisonData;
  setChildData: any;
  showDocumentComparison: boolean;
  setShowDocumentComparison: any;
}

const DocumentComparison: React.FC<DocumentComparisonProps> = ({
  parentData,
  setParentData,
  childData,
  setChildData,
  showDocumentComparison,
  setShowDocumentComparison
}) => {
  const [viewURL, setViewURL] = useState<string>('');

  const downloadFile = useCallback(() => {
    const identifierInURL = viewURL.split('/')[7]?.split('?')[0];
    if (identifierInURL) {
      getComparisonFile(identifierInURL);
    } else {
      console.error("Couldn't extract identifier for download.");
    }
  }, [viewURL]);

  useEffect(() => {
    const fetchComparison = async () => {
      try {
        const res = await getLabelComparision({
          parent_object: parentData.signedUrl,
          child_object: childData.signedUrl
        });
        if (res.data.success) {
          setViewURL(res.data.success);
        } else {
          console.error('Failed to retrieve comparison URL.');
        }
      } catch (error) {
        console.error('Error fetching comparison:', error);
      }
    };
    if (parentData.signedUrl && childData.signedUrl) {
      fetchComparison();
    }
  }, [parentData.signedUrl, childData.signedUrl]);

  const handleSwapLabels = useCallback(() => {
    setParentData({ ...childData });
    setChildData({ ...parentData });
  }, [parentData, childData, setParentData, setChildData]);

  const handleClose = useCallback(
    () => setShowDocumentComparison(false),
    [setShowDocumentComparison]
  );

  return (
    <Drawer
      open={showDocumentComparison}
      anchor='bottom'
      onClose={handleClose}
      PaperProps={{
        sx: comparisonDialogStyles.drawerPaperProps
      }}>
      <Stack direction='row' sx={comparisonDialogStyles.headerWrapper}>
        <Stack spacing={1} sx={comparisonDialogStyles.enhancedComparisonWrapper}>
          <Typography variant='h3' fontWeight='bold' color='white.main'>
            Enhanced Comparison
          </Typography>
        </Stack>
        <Tooltip title='Close'>
          <IconButton
            aria-label='delete'
            onClick={handleClose}
            size='small'
            sx={comparisonDialogStyles.closeIconButton}>
            <HighlightOffOutlinedIcon fontSize='inherit' sx={comparisonDialogStyles.closeIcon} />
          </IconButton>
        </Tooltip>
      </Stack>

      {viewURL && (
        <Stack
          direction='row'
          spacing={2}
          divider={
            <Divider orientation='vertical' flexItem sx={comparisonDialogStyles.ctaSeparator} />
          }
          sx={comparisonDialogStyles.secondaryHeaderWrapper}
          justifyContent='flex-end'>
          <Stack
            direction='row'
            spacing={0.75}
            onClick={downloadFile}
            sx={comparisonDialogStyles.ctaWrapper}>
            <DownloadIcon sx={comparisonDialogStyles.downloadIcon} />
            <Typography sx={comparisonDialogStyles.downloadComparisonText}>
              Download Comparison
            </Typography>
          </Stack>
        </Stack>
      )}

      <DialogContent sx={{ p: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box>
              {viewURL && (
                <DraftableComparisonView
                  viewerURL={viewURL}
                  downloadFile={downloadFile}
                  parentApplicationNumber={parentData?.fileName}
                  parentApplicationName={parentData.brandName ?? ''}
                  childApplicationNumber={childData?.fileName}
                  childApplicationName={childData.brandName ?? ''}
                  showFooter={false}
                  parentApplicationSource={parentData.source?.toLowerCase() ?? ''}
                  childApplicationSource={childData.source?.toLowerCase() ?? ''}
                  handleSwapLabels={handleSwapLabels}
                  isSwitchOptionAvailable
                  fullWidth={false}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
    </Drawer>
  );
};

export default React.memo(DocumentComparison);
