import { createSvgIcon } from '@mui/material';

const DocumentSearchIcon = createSvgIcon(
  <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_7575_20032)'>
      <path
        d='M11.6483 23.3517C11.3276 23.031 11.3176 22.5174 11.6181 22.1846L11.6181 22.1821L11.6483 22.1519L13.9101 19.8891C12.2002 17.5268 12.6966 14.2208 15.04 12.4672C17.4067 10.6963 20.7608 11.1792 22.5318 13.5458C24.3027 15.9124 23.8198 19.2666 21.4532 21.0375C19.5754 22.4427 17.0047 22.4599 15.1099 21.0889L12.8471 23.3517C12.5161 23.6828 11.9793 23.6828 11.6483 23.3517ZM18.2274 20.409C20.2361 20.4089 21.8645 18.7805 21.8645 16.7717C21.8645 14.7629 20.2361 13.1345 18.2273 13.1345C16.2185 13.1345 14.5901 14.7629 14.59 16.7716C14.5922 18.7796 16.2194 20.4068 18.2274 20.409Z'
        fill='#757575'
        stroke='#757575'
        strokeWidth='0.2'
      />
      <path
        d='M3.05882 6.66667H9.17647V8.66667H3.05882V6.66667ZM3.05882 12H7.34118V10H3.05882V12ZM12.2353 3.91933V16H0V2C0 1.46957 0.193361 0.960859 0.537545 0.585786C0.88173 0.210714 1.34854 0 1.83529 0L8.63873 0L12.2353 3.91933ZM10.4 14V5.33333H7.34118V2H1.83529V14H10.4Z'
        fill='#757575'
      />
    </g>
    <defs>
      <clipPath id='clip0_7575_20032'>
        <rect width='24' height='24' fill='white' />
      </clipPath>
    </defs>
  </svg>,
  'DocumentSearchIcon'
);

export default DocumentSearchIcon;
