import React from 'react';
import { Box, Typography, Tooltip } from '@mui/material';
import trimText from '../../../../utils/trimText';
// styles
import styles from '../../styles/SectionFlyout.styles';

export const renderTopics = (
  topics: any[],
  handleSectionClick: any,
  activeSection: any,
  sectionsKeyCount: any,
  getTextColor: any
) => {
  return (
    <Box paddingLeft={1.5} paddingTop={1} display={'flex'} flexDirection={'column'} gap={'8px'}>
      {topics.map((topic: any) => (
        <Tooltip key={topic.value} title={topic?.label || ''}>
          <Typography
            sx={{
              ...styles.sectionKeyText,
              cursor: 'pointer',
              color: getTextColor(topic.value),
              fontWeight: activeSection === topic.value ? '700' : '400'
            }}
            onClick={() => handleSectionClick(topic.value)}>
            {trimText(topic?.label, 20)} ({sectionsKeyCount[topic.value] || 0})
          </Typography>
        </Tooltip>
      ))}
    </Box>
  );
};
