import React, { useContext, useEffect, useState } from 'react';
import { Box, Grid, SelectChangeEvent, Stack, Tooltip, Typography } from '@mui/material';
import { KeyboardArrowDown } from '@mui/icons-material';
import ResultsStore from '../../../../store/SearchResults';
import mergeMatchSubmission, { checkForMatchSubmission } from '../../utils/getMatchSubmissionData';
import getMatchSubmissions from '../../hooks/getMatchSubmissions';
import ResultActions from '../../../../store/SearchResults/actions';
import TimelineView from './TimlineView';
import { VivproSelect } from '../../../../components/CustomComponents/Selects';
import styles from './styles';
import SOURCE_MAPPING from './constant';
import FullScreenProcessing from '../../../SpecialityDesignation/components/FullScreenProcessing';

import GlobalStore from '../../../../store';
import groupIndicationTermByYear from './utils';
import ReportButton from '../../../../components/Report/ReportButton';
import useReportingHandler from '../../../../components/Report/hooks/useReportingHandler';

const IndicationApprovalTimline = ({ setScrollRefElement }: any) => {
  const { resultsState, resultsDispatch } = useContext(ResultsStore);
  const [submissionYearData, setSubmissionYearData] = useState({});
  const [applicationNumberList, setApplicationNumberList] = useState<Array<string>>([]);
  const [searchTermList, setSearchTermList] = useState<Array<string>>([]);
  const [applications, setApplication] = useState<Array<any>>([]);
  const [isLoading, setLoading] = useState<boolean>(false);
  const divRef = React.useRef(null);
  const [source, setSource] = useState<string>('all');
  const [optionList, setOptionList] = useState<Array<any>>([]);
  const { dispatch }: any = useContext(GlobalStore);
  const { updateReportData } = useReportingHandler();

  const getSubmissionData = async () => {
    setLoading(true);
    const searchId = resultsState.searchIds?.applicationSearchId;
    const applicationResults = { ...resultsState.applicationResults };
    if (searchId && !checkForMatchSubmission(applicationResults)) {
      const submissionData = await getMatchSubmissions(searchId);

      const newApplData = mergeMatchSubmission(applicationResults, submissionData);
      resultsDispatch({
        type: ResultActions.SET_APPLICATION_RESULTS,
        value: newApplData
      });
    }
    setLoading(false);
  };

  const groupByYear = (data: any) => {
    const groupData = groupIndicationTermByYear(data, source);

    setSubmissionYearData(groupData?.result);
    setApplicationNumberList(groupData?.applList);
    setSearchTermList(groupData?.searchTerms);
    setApplication(groupData?.appArray);
  };

  useEffect(() => {
    getSubmissionData();
  }, []);

  useEffect(() => {
    if (resultsState?.applicationResults) {
      groupByYear(resultsState?.applicationResults);
    }

    const itemList: Array<any> = [
      {
        value: 'all',
        label: 'All Region'
      }
    ];
    Object.keys(resultsState?.applicationResults).forEach((key: string) => {
      if (key in SOURCE_MAPPING) {
        itemList.push({
          value: key,
          label: SOURCE_MAPPING[key]
        });
      }
    });
    setOptionList(itemList);
  }, [resultsState?.applicationResults, source]);

  const handleClick = (e: SelectChangeEvent) => {
    setSource(e.target.value);
  };

  const addToReport = React.useCallback(
    async (_: any, reportData: any) => {
      const sectionLayout = {
        templateType: 'custom',
        chartType: 'INDICATION_SEQUENCING',
        sectionType: 'CHART',
        id: reportData?.reportId,
        style: {
          placement: {
            h: 28,
            w: 12,
            i: reportData?.reportId,
            minW: 8,
            moved: false,
            static: false,
            x: 0,
            y: 0
          },
          graphStyle: {},
          title: {}
        },
        data: {
          source,
          search_id: resultsState.searchIds.applicationSearchId
        }
      };
      updateReportData(reportData?.id, sectionLayout);
    },
    [dispatch, resultsState.searchIds.ctSearchId]
  );

  if (isLoading) {
    return (
      <FullScreenProcessing loading={isLoading} open={isLoading} message='Generating image...' />
    );
  }
  return (
    <Stack width='100%'>
      <Box mr={2} sx={styles.dropDownBox}>
        <VivproSelect
          // @ts-ignore
          value={source}
          handleChange={handleClick}
          options={optionList}
          icon={KeyboardArrowDown}
        />
        <Tooltip
          title={
            applicationNumberList && applicationNumberList?.length >= 50
              ? 'Add to Report (Top 50 Only)'
              : 'Add to Report'
          }>
          <ReportButton
            data={{}}
            addToReport={addToReport}
            prefixId='indication_sequencing_graphs_'
          />
        </Tooltip>
      </Box>
      {Object.keys(submissionYearData).length !== 0 ? (
        <Grid container ref={divRef} mb={3} mt={2} flexWrap='nowrap' sx={styles.gridContainer}>
          <TimelineView
            dataByYear={submissionYearData}
            drugs={applications}
            applicationNumbers={applicationNumberList}
            searchTermList={searchTermList}
            setScrollRefElement={setScrollRefElement}
            enableDownload
          />
        </Grid>
      ) : (
        <Box sx={styles.message}>
          <Typography variant='h6' color='inherit'>
            No Graphs Available
          </Typography>
        </Box>
      )}
    </Stack>
  );
};

export default IndicationApprovalTimline;
