import React from 'react';
import { Box, Typography, Divider, Tooltip } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import styles from './Timeline.styles';

interface TimelineTrackHeaderProp {
  reg360: string;
  productNumber: string;
}

const TimelineTrackHeader = ({ reg360, productNumber }: TimelineTrackHeaderProp) => {
  const isReg360Available = reg360 && reg360 !== 'NA';

  const handleReg360Click = (ev: any) => {
    ev.stopPropagation();
    if (reg360 && reg360 !== 'NA') {
      window.open(`/regulatory360/core/${reg360}/eu`, '_blank');
    }
  };

  return (
    <Box display='flex' alignItems='center'>
      <Tooltip title={productNumber}>
        <Typography sx={styles.timelineTrackHeader.productNumber}>{productNumber}</Typography>
      </Tooltip>
      <Divider orientation='vertical' sx={styles.common.divider} />
      <Box display='flex' alignItems='center' onClick={ev => handleReg360Click(ev)}>
        <OpenInNewIcon
          sx={{
            fontSize: 18,
            color: isReg360Available ? 'blue.background' : 'gray.500',
            mr: 0.5,
            cursor: isReg360Available ? 'pointer' : 'default'
          }}
        />
        <Typography
          fontSize={13}
          fontWeight={600}
          color={isReg360Available ? 'gray.700' : 'gray.500'}
          sx={{ cursor: isReg360Available ? 'pointer' : 'default' }}>
          Go to Reg360
        </Typography>
      </Box>
    </Box>
  );
};

export default React.memo(TimelineTrackHeader);
