// react
import { memo, MouseEvent, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
// mui
import {
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  PopoverOrigin,
  Tooltip
} from '@mui/material';
import { toast } from 'react-toastify';

// api
import { isEmpty } from 'lodash';
import {
  addSubscription,
  getSubscriptionPreferences,
  removeSubscription,
  updateSubscriptionSourcePreference
} from '../../api/modules/userNotification';

// store
import GlobalStore from '../../store';
import Actions from '../../store/actions';
import ResultsStore from '../../store/SearchResults';

import { SubscribeIcon, SubscribeIconFilled, ThreeDotIconVariation } from '../../assets/svgs/Icons';
import CustomAlertBox from '../Alert/CustomAlertBox';
import ALLOWED_SOURCE_APPLICATIONS from './const';
import styles from '../FavoritesAndProjectsAction/styles/FavoriteAndProjectActions.styles';
import { CHANNELS, APPLICATION_SEARCH_CHANNEL_NAMES } from '../../pages/Notifications/constants';
import SubscriptionPreferences, { SubscriptionPreferencesType } from './SubscriptionPreferences';
import MenuItemWithSubMenu from '../ui/Menu/MenuItemWithSubMenu/MenuItemWithSubMenu';
import { RoundedMenu } from '../ui/Menu';
import { decodeBase64ToObject } from '../../utils/encodeDecodeObject';
import { createSourceModuleDropdownMapping } from '../../pages/Home/utils';
import DocumentSearchKeywordSelection from './DocumentSearchKeywordSelection';
import { prepareAPIPayload } from '../../pages/SearchResults/utils/searchUtils';
import AuthContext from '../../store/Auth/AuthProvider';
import { DocumentSearchSubscription } from '../../pages/Notifications/components/NotificationInterface';

interface SubscriptionActionsProps {
  // This is used to decide the source of the subscribed actions
  invokedBy: (typeof CHANNELS)[number];
  // This is not needed for search
  source?: string;
  id: string;
  // This is used to decide if the component is a button or a menu item
  isButton: boolean;
  isSubscribed: boolean;
  // This function is used to update the state of isSubscribed in the parent component or hook
  setSubscriptionInHook: Function;
  // This is used to close the menu when the menu is a button
  handleMenuClose?: Function;
  // This is used to decide if the menu is a three dots menu
  isThreeDotsMenu?: boolean;
  // This is used to add any menu items before the subscription preferences
  preMenuItems?: ReactNode;
  // This is used to add any menu items after the subscription preferences
  postMenuItems?: ReactNode;
  // This is used to decide if the subscribed terms should be shown
  showSubscribedTerms?: boolean;
  // The search query for the document search
  documentSearchQuery?: string;
  // The subscribed search details of already subscribed document search
  subscribedAriaSearch?: DocumentSearchSubscription | null;
}

const SubscriptionActions = ({
  invokedBy,
  source,
  id,
  isButton,
  isSubscribed,
  setSubscriptionInHook,
  handleMenuClose,
  isThreeDotsMenu,
  preMenuItems,
  postMenuItems,
  showSubscribedTerms = false,
  documentSearchQuery = '',
  subscribedAriaSearch = null
}: SubscriptionActionsProps) => {
  const { dispatch } = useContext(GlobalStore) as any;
  const { resultsState } = useContext(ResultsStore) as any;
  const { currentUser } = useContext(AuthContext);
  const { payload }: any = useParams();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [nestedAnchorEl, setNestedAnchorEl] = useState<null | HTMLElement>(null);
  const [subscriptionLoading, setSubscriptionLoading] = useState(false);

  const [manageListLoading, setManageListLoading] = useState(false);
  const [subscriptionPreferences, setSubscriptionPreferences] =
    useState<SubscriptionPreferencesType>({ preferences: {} });
  const [documentSearchSubscriptionTerms, setDocumentSearchSubscriptionTerms] =
    useState<DocumentSearchSubscription[]>();
  const [showUnsubscribeAlert, setShowUnsubscribeAlert] = useState(false);
  const [isKeywordsPopupOpen, setIsKeywordsPopupOpen] = useState(false);
  const isMenuOpen = Boolean(anchorEl);
  const isNestedMenuOpen = Boolean(nestedAnchorEl);

  const shouldShowSubscription = useMemo(() => {
    if (invokedBy === 'application' && !ALLOWED_SOURCE_APPLICATIONS.includes(source)) {
      return {
        show: false,
        sourceAllowedList: [],
        sourceNotAllowedList: []
      };
    }
    if (APPLICATION_SEARCH_CHANNEL_NAMES.includes(invokedBy)) {
      const searchPayload: any = resultsState.decryptedPayload ?? decodeBase64ToObject(payload);
      if (searchPayload && 'source' in searchPayload) {
        const selectedSourcesFromParams = createSourceModuleDropdownMapping(searchPayload.source);
        const subscriptionKeys = selectedSourcesFromParams
          .map(item =>
            item.module
              .filter(module => module?.canSubscribeSource && module?.subscriptionKey)
              .map(module => {
                return {
                  subscriptionKey: module.subscriptionKey,
                  allowedSourceText: `${item.label} - ${module.label}`
                };
              })
          )
          .flat();

        if (
          isEmpty(subscriptionKeys) ||
          subscriptionKeys.filter(value =>
            ALLOWED_SOURCE_APPLICATIONS.includes(value.subscriptionKey)
          ).length <= 0
        ) {
          return {
            show: false,
            sourceAllowedList: [],
            sourceNotAllowedList: []
          };
        }
        const notAllowedSources = selectedSourcesFromParams
          .map(item =>
            item.module
              .filter(module => !module?.canSubscribeSource)
              .map(module => `${item.label} - ${module.label}`)
          )
          .flat();
        return {
          show: true,
          sourceAllowedList: subscriptionKeys.map(item => item.allowedSourceText),
          sourceNotAllowedList: notAllowedSources
        };
      }
    }
    return { show: true, sourceAllowedList: [], sourceNotAllowedList: [] };
  }, [invokedBy, resultsState.decryptedPayload, payload]);

  // Function to get subscription preferences
  const getSubscriptionPreferencesMenu = async () => {
    if (!showSubscribedTerms && invokedBy === 'document_search') return;
    if ((invokedBy === 'application' || invokedBy === 'data_source') && !source) return;
    try {
      setManageListLoading(true);
      const res = await getSubscriptionPreferences({
        source,
        entityId: id,
        entityType: invokedBy
      });
      if (res && res.status === 200) {
        if (invokedBy === 'document_search') setDocumentSearchSubscriptionTerms(res.data.body);
        else setSubscriptionPreferences(res.data.body);
      } else {
        throw new Error(`Failed to update subscription preference for ${id}`);
      }
      setManageListLoading(false);
    } catch (e) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: { message: `Something went wrong:${(e as Error).message}`, status: true }
      });
      setManageListLoading(false);
    }
  };

  // Function to add or remove subscribtion
  // This component does not save the state of the subscribtion, it just calls the api and updates the state in the parent component
  const handleSubscribe = async (documentSubscribeQuery = '') => {
    if (
      !isSubscribed &&
      invokedBy === 'document_search' &&
      isEmpty(documentSubscribeQuery) &&
      isEmpty(subscribedAriaSearch)
    ) {
      setIsKeywordsPopupOpen(true);
      return;
    }
    try {
      let res = null;
      setSubscriptionLoading(true);
      const finalSubscribedQuery = !isEmpty(subscribedAriaSearch)
        ? subscribedAriaSearch?.subscription_search_data?.term
        : documentSubscribeQuery;
      if (invokedBy === 'data_source') {
        res = await updateSubscriptionSourcePreference([
          {
            source: source ?? '',
            subscribed: !isSubscribed
          }
        ]);
      } else {
        const subscriptionData: any = {
          entityId: id,
          entityType: invokedBy,
          source: source ?? ''
        };
        if (invokedBy === 'document_search' && !isSubscribed) {
          if (isEmpty(subscribedAriaSearch)) {
            const searchPayload: any = decodeBase64ToObject(payload);
            searchPayload.search_term = finalSubscribedQuery;
            const finalPayload = prepareAPIPayload(
              searchPayload,
              'document',
              resultsState,
              currentUser
            );
            subscriptionData.subscribedDocumentSearchPayload = finalPayload;
          } else {
            // This case is for the already subscribed document search which is unsubscribed
            subscriptionData.entityId = subscribedAriaSearch.aria_search_id;
            subscriptionData.subscribedDocumentSearchId =
              subscribedAriaSearch.subscription_search_id;
          }
        }
        res = isSubscribed
          ? await removeSubscription(subscriptionData)
          : await addSubscription(subscriptionData);
      }

      if (res && res.status === 200) {
        setSubscriptionInHook({ source, identifier: id, isSubscribed: !isSubscribed });
        const unavailableSources = shouldShowSubscription.sourceNotAllowedList;
        const availableSources = shouldShowSubscription.sourceAllowedList;
        const unavailableMessage = unavailableSources.length
          ? ` (Currently not available for ${unavailableSources.join(', ')})`
          : '';

        // Show appropriate toast message
        let messagePrefix;
        if (invokedBy === 'document_search') {
          if (!isSubscribed) messagePrefix = `Subscribed to "${finalSubscribedQuery}"`;
          else messagePrefix = `Unsubscribed successfully`;
        } else {
          messagePrefix = !isSubscribed ? 'Subscribed' : 'Unsubscribed';
        }
        const toastMessage =
          availableSources.length > 0 ? `${messagePrefix} ${unavailableMessage}` : messagePrefix;
        // Show appropriate toast notification
        toast.success(toastMessage, {
          position: 'bottom-center',
          autoClose: unavailableSources.length > 0 ? 5000 : 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined
        });

        if (isSubscribed) {
          setAnchorEl(null);
          setNestedAnchorEl?.(null);
        }
      } else {
        throw new Error("Couldn't update subscription");
      }
    } catch (e) {
      await dispatch({
        type: Actions.SET_ALERT,
        value: {
          message: (e as Error)?.message ?? `Something went wrong:${(e as Error).message}`,
          status: true
        }
      });
    } finally {
      setSubscriptionLoading(false);
      setShowUnsubscribeAlert(false);
    }
  };

  const handleButtonClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (!isSubscribed) handleSubscribe();
    else setAnchorEl(event?.currentTarget);
  };

  const handleMenuClick = async (event: MouseEvent<HTMLElement>) => {
    if (!isSubscribed) {
      await handleSubscribe();
      handleMenuClose?.();
    } else setNestedAnchorEl?.(event?.currentTarget);
  };

  const handleThreeDotsMenuClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSubscriptionMenuClose = () => {
    setAnchorEl(null);
  };

  const handleCloseNestedMenu = () => {
    setNestedAnchorEl?.(null);
  };

  // Function to decide the position of the nested menu
  const getSubMenuPosition = useMemo(() => {
    if (!nestedAnchorEl) return undefined;
    // You can customize this logic based on your layout and styles
    const windowWidth = window.innerWidth;
    const spaceRight = windowWidth - (nestedAnchorEl?.getBoundingClientRect()?.right ?? 0);
    const spaceLeft = nestedAnchorEl?.getBoundingClientRect()?.left ?? 0;

    // Open to the right if there is enough space, otherwise open to the left
    return spaceRight > spaceLeft
      ? {
          anchorOrigin: { vertical: 'top', horizontal: 'right' } as PopoverOrigin,
          transformOrigin: { vertical: 'top', horizontal: 'left' } as PopoverOrigin
        }
      : {
          anchorOrigin: { vertical: 'top', horizontal: 'left' } as PopoverOrigin,
          transformOrigin: { vertical: 'top', horizontal: 'right' } as PopoverOrigin
        };
  }, [nestedAnchorEl]);

  // Only fetch subscription prefs when the menu is open and it's subscribed
  useEffect(() => {
    if (invokedBy !== 'data_source' && isSubscribed && (isNestedMenuOpen || isMenuOpen))
      getSubscriptionPreferencesMenu();
  }, [isNestedMenuOpen, isMenuOpen, invokedBy, isSubscribed]);

  // Don't show the button if the source is not allowed
  if (!shouldShowSubscription.show) return null;

  return (
    <>
      {isThreeDotsMenu && (
        <IconButton
          id='subscription-action-button'
          aria-label='Subscribe'
          onClick={handleThreeDotsMenuClick}>
          <ThreeDotIconVariation sx={styles.iconGray} />
        </IconButton>
      )}
      {!isThreeDotsMenu && isButton && (
        <Tooltip title={!isSubscribed ? 'Subscribe' : 'Manage subscription'}>
          <IconButton
            id='subscription-action-button'
            aria-label='Subscribe'
            onClick={handleButtonClick}>
            {subscriptionLoading && <CircularProgress size={18} />}
            {!subscriptionLoading &&
              (isSubscribed ? (
                <SubscribeIconFilled sx={styles.iconPrimary} />
              ) : (
                <SubscribeIcon sx={styles.iconGray} />
              ))}
          </IconButton>
        </Tooltip>
      )}
      {!isThreeDotsMenu &&
        !isButton &&
        (isSubscribed ? (
          <MenuItemWithSubMenu
            onMouseOver={handleMenuClick}
            onClick={handleMenuClick}
            MenuItemIcon={
              subscriptionLoading ? (
                <CircularProgress size={18} />
              ) : (
                <SubscribeIconFilled sx={styles.menuIconPrimary} />
              )
            }
            MenuItemText='Subscribed'>
            <SubscriptionPreferences
              isSubscribed={isSubscribed}
              subscriptionLoading={subscriptionLoading}
              manageListLoading={manageListLoading}
              subscriptionPreferences={subscriptionPreferences}
              setSubscriptionPreferences={setSubscriptionPreferences}
              source={source ?? ''}
              id={id}
              invokedBy={invokedBy}
              setShowUnsubscribeAlert={setShowUnsubscribeAlert}
              handleSubscribe={handleSubscribe}
              preMenuItems={preMenuItems}
              postMenuItems={postMenuItems}
            />
          </MenuItemWithSubMenu>
        ) : (
          <MenuItem
            onClick={handleMenuClick}
            sx={{
              backgroundColor: isNestedMenuOpen ? 'gray.100' : null,
              padding: '8px 24px !important'
            }}>
            <ListItemIcon
              // @ts-ignore
              sx={{
                '& svg': { width: '16px', height: '16px' },
                minWidth: '16px !important'
              }}>
              {subscriptionLoading ? (
                <CircularProgress size={18} />
              ) : (
                <SubscribeIcon sx={styles.menuIconGray} />
              )}
            </ListItemIcon>
            <ListItemText
              sx={{
                fontFamily: 'Mulish',
                fontSize: '12px',
                fontWeight: '400',
                ml: '8px',
                color: 'primary.darkVariant1'
              }}>
              Subscribe
            </ListItemText>
          </MenuItem>
        ))}
      {(isButton || isThreeDotsMenu) && (
        <RoundedMenu
          id='card-actions-subscribe-menu'
          disablePortal
          sx={{ zIndex: 1233 }}
          anchorEl={isButton || (isThreeDotsMenu ?? false) ? anchorEl : nestedAnchorEl}
          anchorOrigin={
            isButton || (isThreeDotsMenu ?? false)
              ? { vertical: 'bottom', horizontal: 'left' }
              : getSubMenuPosition?.anchorOrigin
          }
          transformOrigin={
            isButton || (isThreeDotsMenu ?? false) ? undefined : getSubMenuPosition?.transformOrigin
          }
          open={isButton || (isThreeDotsMenu ?? false) ? isMenuOpen : isNestedMenuOpen}
          MenuListProps={{ onMouseLeave: handleCloseNestedMenu }}
          onClose={
            isButton || (isThreeDotsMenu ?? false)
              ? handleSubscriptionMenuClose
              : handleCloseNestedMenu
          }
          onClick={e => {
            // this stops the event from propagating to the parent and causing the card onClick to fire
            e.stopPropagation();
          }}>
          <SubscriptionPreferences
            isSubscribed={isSubscribed}
            subscriptionLoading={subscriptionLoading}
            manageListLoading={manageListLoading}
            subscriptionPreferences={subscriptionPreferences}
            setSubscriptionPreferences={setSubscriptionPreferences}
            documentSearchSubscriptionTerms={documentSearchSubscriptionTerms}
            source={source ?? ''}
            id={id}
            invokedBy={invokedBy}
            setShowUnsubscribeAlert={setShowUnsubscribeAlert}
            handleSubscribe={handleSubscribe}
            preMenuItems={preMenuItems}
            postMenuItems={postMenuItems}
          />
        </RoundedMenu>
      )}

      {showUnsubscribeAlert && (
        <CustomAlertBox
          message='Are you sure you want to unsubscribe?'
          showAlert={showUnsubscribeAlert}
          yesButtonOnClick={handleSubscribe}
          noButtonOnClick={() => setShowUnsubscribeAlert(false)}
          yesButtonTitle='Yes, Unsubscribe'
          yesLoading={subscriptionLoading}
        />
      )}
      {isKeywordsPopupOpen && (
        <DocumentSearchKeywordSelection
          searchQuery={documentSearchQuery}
          openKeywordPopup={isKeywordsPopupOpen}
          setOpenKeywordPopup={setIsKeywordsPopupOpen}
          handleSubscribe={handleSubscribe}
        />
      )}
    </>
  );
};

SubscriptionActions.defaultProps = {
  source: null,
  handleMenuClose: null,
  isThreeDotsMenu: false,
  preMenuItems: null,
  postMenuItems: null,
  documentSearchQuery: '',
  showSubscribedTerms: false,
  subscribedAriaSearch: null
};

export default memo(SubscriptionActions);
