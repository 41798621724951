const styles = {
  container: {
    border: '0.5px solid rgba(80, 116, 145, 0.50)',
    pl: 1,
    pr: 0.75,
    borderRadius: 3,
    py: '1px'
  },
  matadataText1: {
    fontSize: 12,
    fontWeight: 500,
    textTransform: 'capitalize',
    color: 'blue.gray700',
    maxWidth: 124,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  metadataText2: { fontSize: 12, fontWeight: 700, textTransform: 'capitalize' }
};

export default styles;
