const notificationsStyles = {
  flagIconBox: {
    width: '24px',
    height: '24px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'grey.200',
    overflow: 'hidden'
  },
  flagIcon: {
    width: '34px',
    height: '34px'
  },
  menuContainer: {
    borderRadius: '16px',
    color: '#4F4F4F !important'
  },
  notificationContainer: {
    backgroundColor: 'white !important',
    background: 'white !important',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'grey.300',
    height: '88vh',
    maxHeight: '88vh',
    overflowY: 'hidden'
  },
  notificationContainerUserProfile: {
    backgroundColor: 'white !important',
    borderRadius: '8px',
    border: '1px solid',
    borderColor: 'grey.300',
    height: 'calc(88vh - 50px)',
    maxHeight: '100%',
    overflowY: 'hidden'
  },
  emailContainer: {
    border: '1px solid',
    borderColor: 'grey.300',
    borderRadius: '8px',
    background: 'white !important'
  },
  emailHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    borderWidth: '0 0 1px 0',
    borderStyle: 'solid',
    borderColor: 'grey.300',
    p: '16px',
    fontSize: '14px',
    color: 'grey.800',
    fontWeight: '400'
  },
  emailIcon: {
    color: 'grey.800',
    width: '20px',
    height: '20px'
  },
  emailList: {
    p: '20px 0 !important',
    '& .MuiListItem-root': {
      p: '0 16px 8px 16px !important',
      '& .MuiListItemText-root': {
        '& .MuiListItemText-primary': {
          fontSize: '14px !important',
          fontWeight: '700 !important',
          color: 'grey.800'
        },
        '& .MuiListItemText-secondary': {
          color: 'grey.800',
          fontSize: '14px',
          fontStyle: 'normal',
          fontWeight: '400',
          lineHeight: '120%'
        }
      }
    }
  },
  select: {
    // remove underline from select from ::before and ::after
    '&::before': {
      borderBottom: 'none',
      content: 'none'
    },
    '&::after': {
      borderBottom: 'none',
      content: 'none'
    },
    '& .MuiSelect-icon': {
      color: 'grey.800'
    },
    '& .MuiSelect-select': {
      fontSize: '14px !important',
      fontWeight: '700 !important',
      lineHeight: '160% !important',
      color: 'primary.600',
      px: '8px'
    },
    '& .MuiSvgIcon-root .Mui-disabled': {
      color: 'gray.600'
    },
    '& .MuiSvgIcon-root': {
      color: 'primary.600'
    }
  },
  sourceSubscriptionList: {
    display: 'flex',
    padding: '16px 24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '12px'
  },
  sourceSubscriptionListHeader: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    alignSelf: 'stretch',
    color: '#1C170D',
    fontFeatureSettings: "'dlig' on",
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 'normal',
    borderBottom: `1px solid`,
    borderColor: 'gray.200',
    pb: '12px'
  },
  subHeader: {
    '& .MuiListItemText-primary': {
      color: 'gray.800',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400'
    },
    '& .MuiListItemText-secondary': {
      color: 'red.main'
    }
  },
  dateChip: {
    backgroundColor: 'gray.100',
    my: '8px',
    mx: 'auto',
    px: '12px ',
    '& .MuiChip-label': {
      color: '#1C170D',
      fontFeatureSettings: "'dlig' on",
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: '700',
      lineHeight: '150%'
    }
  },
  searchSubscription: {
    height: '90%',
    mt: '16px',
    px: '24px',
    maxHeight: '80vh',
    overflowY: 'auto',
    '& .MuiListItem-root': { p: '16px 0 !important' }
  },
  sourceDropdownBox: {
    display: 'inline-flex',
    borderRadius: '8px',
    boxShadow: '0px 0px 4px rgba(0,0,0,0.16)',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'flex-start'
  },
  tabContainer: {
    py: 1,
    '& .MuiTabs-indicator': {
      display: 'none' // This hides the tab indicator
    },
    // fist child with class MuiTabScrollButton-root
    '& > .MuiTabScrollButton-root:first-of-type': {
      marginRight: -1 // margin adjusted for scroll button
    }
  },
  tabs: {
    fontSize: 13,
    textTransform: 'none',
    border: '1px solid',
    borderRadius: 50,
    mx: 0.5,
    my: 1,
    minHeight: '32px',
    minWidth: 0,
    px: 2,
    py: 0.5
  }
};
export default notificationsStyles;
