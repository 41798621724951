import React, { useState } from 'react';
import { Box, Typography, Button, Popover } from '@mui/material';
import styles from '../../styles/ModifiedProductNumber.styles';

const ModifiedProductNumber: React.FC<{ data: any[] }> = ({ data }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'product-numbers-popover' : undefined;

  const remainingCount = data.length - 1;
  const firstModifiedProductNumber = data[0]?.product_number;

  return (
    <Typography sx={styles.accordionPipNumberText}>
      {`${firstModifiedProductNumber}${remainingCount > 0 ? ', ' : ''} `}
      {remainingCount > 0 && (
        <>
          <Button onClick={handleClick} aria-describedby={id} sx={styles.moreButton} variant='text'>
            +{remainingCount}
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}>
            <Box sx={styles.popup}>
              {data.slice(1).map((data_: any) => (
                <Typography key={data_.product_number}>{data_.product_number}</Typography>
              ))}
            </Box>
          </Popover>
        </>
      )}
    </Typography>
  );
};

export default React.memo(ModifiedProductNumber);
