import React from 'react';
import { Stack, Tooltip, Typography, Box, IconButton } from '@mui/material';
import Close from '@mui/icons-material/Close';
import styles from './Timeline.styles';

interface TimelineHeaderProps {
  activeIngredient: string;
  // eslint-disable-next-line no-unused-vars
  setShowTimeline: (showTimeline: boolean) => void;
}

const TimelineHeader = ({ activeIngredient, setShowTimeline }: TimelineHeaderProps) => {
  const getHeaderText = () =>
    activeIngredient && activeIngredient !== 'NA'
      ? activeIngredient.replace(/\b\w/g, (char: string) => char.toUpperCase())
      : 'NA';

  return (
    <Stack sx={styles.timelineHeader.stackWrapper}>
      <Stack width='80%' direction='row'>
        <Tooltip title={getHeaderText()}>
          <Typography sx={styles.common.headerTitle}>{getHeaderText()}</Typography>
        </Tooltip>
      </Stack>
      <Box>
        <Tooltip title='Close Chat'>
          <IconButton size='small' onClick={() => setShowTimeline(false)}>
            <Close sx={{ fontSize: 27.45, color: 'gray.0' }} />
          </IconButton>
        </Tooltip>
      </Box>
    </Stack>
  );
};

export default React.memo(TimelineHeader);
