import React, { useState } from 'react';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  Checkbox,
  Box,
  FormGroup,
  Typography,
  Divider,
  Button,
  Tooltip
} from '@mui/material';
import styles from './styles';
import ReportButton from '../../../components/Report/ReportButton';

const CustomDropdown = ({ data, handleAddToReport }: any) => {
  const [selectedOption, setSelectedOption] = useState<'all' | 'selected' | ''>('');
  const [selectedStudies, setSelectedStudies] = useState<number[]>([]);

  const handleOptionChange = (event: any) => {
    setSelectedOption(event.target.value);
    if (event.target.value === 'all') {
      setSelectedStudies([]);
    } else if (event.target.value === 'selected') {
      setSelectedStudies(data?.studies?.map((_: any, index: number) => index));
    }
  };

  const handleCheckboxChange = (studyIndex: any) => {
    if (selectedOption !== 'selected') {
      setSelectedOption('selected');
    }
    if (selectedStudies?.includes(studyIndex)) {
      setSelectedStudies(selectedStudies.filter((each: number) => each !== studyIndex));
    } else {
      setSelectedStudies([...selectedStudies, studyIndex]);
    }
  };

  const handleAdd = (_: any, reportData: any) => {
    handleAddToReport(selectedOption, selectedStudies, reportData);
  };

  const enableAdd =
    (selectedOption === 'selected' && selectedStudies.length) || selectedOption === 'all';

  return (
    <Box display='flex' flexDirection='column' sx={styles.customDropdown.main}>
      <FormControl component='fieldset'>
        <RadioGroup
          aria-label='study-option'
          name='study-option'
          value={selectedOption}
          onChange={handleOptionChange}>
          <FormControlLabel
            value='all'
            control={<Radio size='small' sx={styles.customDropdown.radioCheckboxOption} />}
            label={<Typography fontSize={12}>All Studies</Typography>}
          />
          <FormControlLabel
            value='selected'
            control={<Radio size='small' sx={styles.customDropdown.radioCheckboxOption} />}
            label={<Typography fontSize={12}>Selected Study(s)</Typography>}
          />
        </RadioGroup>
        <Box display='flex' ml={0.5}>
          <Divider orientation='vertical' flexItem sx={styles.customDropdown.divider} />
          <FormGroup sx={{ paddingLeft: 2 }}>
            {data?.studies?.map((study: any, index: number) => (
              <FormControlLabel
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                control={
                  <Checkbox
                    size='small'
                    checked={selectedStudies.includes(index)}
                    onChange={() => handleCheckboxChange(index)}
                    sx={styles.customDropdown.radioCheckboxOption}
                  />
                }
                label={<Typography fontSize={12}>Study {index + 1}</Typography>}
              />
            ))}
          </FormGroup>
        </Box>
      </FormControl>
      <Tooltip title={!enableAdd ? 'Please select an option.' : ''}>
        <Button size='small' disabled={!enableAdd}>
          <ReportButton
            data={{}}
            addToReport={handleAdd}
            disabled={!enableAdd}
            style={{
              buttonContainer: {
                backgroundColor: !enableAdd ? 'gray.200' : 'primary.600'
              },
              reportButton: {
                '&:hover': {
                  backgroundColor: !enableAdd ? 'gray.200' : 'primary.700'
                },
                '&:active': {
                  backgroundColor: !enableAdd ? 'gray.200' : 'primary.900'
                }
              },
              reportText: {
                color: !enableAdd ? 'gray.500' : 'gray.0'
              }
            }}
          />
        </Button>
      </Tooltip>
    </Box>
  );
};

export default CustomDropdown;
