import { useContext, useMemo, useState } from 'react';
import {
  Avatar,
  Box,
  IconButton,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Stack,
  Tooltip,
  Typography
} from '@mui/material';
import { ParentNotification } from './NotificationInterface';
import notificationsListStyles from './NotificationsList.styles';
import { SOURCE_TO_DISPLAY_NAME_MAPPING } from '../constants';
import RightArrow from '../../../assets/svgs/Icons/RightArrow';
import { markIdsAsClicked } from '../../../api/modules/userNotification';

// store
import NotificationsStore from '../../../store/Notifications';
import NotificationsAction from '../../../store/Notifications/actions';
import NotificationAvatar from './NotificationAvatar';
import NotificationFlag from './NotificationFlag';
import {
  getAdvancedSearchSubtitle,
  getAdvancedSearchTitle,
  getQuickSearchSubtitle,
  getQuickSearchTitle
} from '../utils';

const NotificationsItem = ({ notification }: { notification: ParentNotification }) => {
  const {
    id,
    channel,
    source,
    heading: title,
    count_info: countInfo,
    searchData: searchInfo
  } = notification;

  const { name: notificationType, displayName: notificationTypeName } = channel;
  const [read, setRead] = useState(notification.clicked);
  const { notificationsState, notificationsDispatch } = useContext(NotificationsStore);

  const quickSearchTitle = useMemo(
    () => getQuickSearchTitle(searchInfo),
    [searchInfo?.category, searchInfo?.searchData?.first_approval_date, searchInfo?.term]
  );

  const quickSearchSubtitle = useMemo(
    () => getQuickSearchSubtitle(searchInfo),
    [searchInfo?.category_label, searchInfo?.filters]
  );

  const advancedSearchTitle = useMemo(() => {
    const { localSearch } = getAdvancedSearchTitle(searchInfo);
    return localSearch;
  }, [searchInfo?.cnf_query, searchInfo?.searchData, searchInfo?.source]);

  const advancedSearchSubtitle = useMemo(
    () => getAdvancedSearchSubtitle(searchInfo),
    [searchInfo?.cnf_query, searchInfo?.filters, searchInfo?.searchData, searchInfo?.source]
  );

  const titleToDisplay = useMemo(() => {
    let titleText = '';
    switch (notificationType) {
      case 'data_source':
        titleText =
          SOURCE_TO_DISPLAY_NAME_MAPPING[source as keyof typeof SOURCE_TO_DISPLAY_NAME_MAPPING]
            ?.display;
        break;
      case 'application':
        titleText = title ?? '';
        break;
      case 'quick_search':
        titleText = quickSearchTitle;
        break;
      case 'advanced_search':
      case 'advanced_search_filters':
        titleText = advancedSearchTitle as string;
        break;
      default:
        titleText = title ?? '';
        break;
    }
    return (
      <Typography variant='body1' sx={notificationsListStyles.title}>
        {titleText}
      </Typography>
    );
  }, [title, notificationType]);

  const secondaryText = useMemo(() => {
    switch (notificationType) {
      case 'data_source':
        return false;
      case 'application':
        return (
          <Stack
            direction='row'
            alignItems='center'
            sx={{ '& >svg': { height: '12px', width: '18px', mr: '4px' }, mt: '4px' }}>
            <NotificationFlag source={notification?.source} />
            {quickSearchSubtitle}
            <Typography variant='body2'>:</Typography>
          </Stack>
        );
      case 'quick_search':
        return (
          <Stack
            direction='row'
            alignItems='center'
            sx={{ '& >svg': { height: '12px', width: '18px', mr: '4px' }, mt: '4px' }}>
            <NotificationFlag source={Object.keys(notification?.searchData?.source ?? {})} />
            {quickSearchSubtitle}
            <Typography variant='body2'>:</Typography>
          </Stack>
        );
      case 'advanced_search':
      case 'advanced_search_filters': {
        return (
          <Stack
            direction='row'
            alignItems='center'
            sx={{ '& >svg': { height: '12px', width: '18px', mr: '4px' }, mt: '4px' }}>
            <NotificationFlag source={Object.keys(notification?.searchData?.source ?? {})} />
            {advancedSearchSubtitle}
            <Typography variant='body2'>:</Typography>
          </Stack>
        );
      }
      default:
        return false;
    }
  }, [notificationType, notification?.source, quickSearchSubtitle, advancedSearchSubtitle]);

  const isSelected = useMemo(() => {
    return notificationsState?.selectedNotification?.id === id;
  }, [notificationsState.selectedNotification?.id, id]);

  const openNotification = async () => {
    notificationsDispatch({
      type: NotificationsAction.SET_SELECTED_NOTIFICATION,
      value: notification
    });
    if (!read) {
      setRead(true);
      await markIdsAsClicked(id);
    }
  };

  return (
    <ListItemButton
      selected={isSelected}
      onClick={openNotification}
      sx={notificationsListStyles.listItem}
      id={read ? 'read' : 'unread'}
      alignItems='flex-start'>
      <ListItemAvatar sx={notificationsListStyles.itemAvatar}>
        <Stack justifyContent='center' alignItems='center'>
          <Box sx={notificationsListStyles.notificationDot} id='notification-dot' />
        </Stack>
        <Tooltip title={notificationTypeName}>
          <Avatar sx={notificationsListStyles.avatar}>
            <NotificationAvatar notificationType={notificationType} source={source} />
          </Avatar>
        </Tooltip>
      </ListItemAvatar>
      <ListItemText
        primary={titleToDisplay}
        secondary={
          <Stack
            direction='row'
            alignItems='baseline'
            flexGrow={1}
            mt='8px'
            justifyContent='space-between'>
            <Stack
              direction='row'
              flexWrap='wrap'
              useFlexGap
              flexGrow={1}
              columnGap={1.5}
              sx={{ flex: 1 }}>
              {secondaryText}
              {Object.entries(countInfo)
                .filter(([key]: any) => key !== 'New Results')
                .map(([key, value]: any) => {
                  if (value)
                    return (
                      <Stack key={key} direction='row' alignItems='center' gap='2px'>
                        <Box sx={notificationsListStyles.number} id='change-count'>
                          {value}
                        </Box>
                        <Typography
                          sx={notificationsListStyles.overview}
                          id='count-info'>{`${key}`}</Typography>
                        {key === 'Results' &&
                          countInfo?.['New Results'] > 0 &&
                          countInfo?.Results !== countInfo?.['New Results'] &&
                          !read && (
                            <Box
                              sx={{ ...notificationsListStyles.overview, fontWeight: 700 }}
                              id='change-count'>
                              {`(${countInfo['New Results']} new)`}
                            </Box>
                          )}
                      </Stack>
                    );
                  return null;
                })}
            </Stack>
          </Stack>
        }
      />
      <IconButton
        edge='end'
        aria-label='see more'
        disableRipple
        disableFocusRipple
        disableTouchRipple
        sx={notificationsListStyles.moreIcon}>
        <RightArrow />
      </IconButton>
    </ListItemButton>
  );
};
export default NotificationsItem;
