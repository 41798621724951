/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Box, Tooltip, Typography, Button } from '@mui/material';
import styles from '../../styles/SectionDetails.styles';
import Applicant from './Applicant';

const SectionDetails: React.FC<any> = ({ scope, applicants }) => {
  const [showMore, setShowMore] = useState(false);

  const handleToggle = () => setShowMore(!showMore);

  return (
    <Box sx={styles.container}>
      {/* Scope Section */}
      <Box display='flex'>
        <Box sx={styles.labelBox}>
          <Typography sx={styles.labelText}>Scope:</Typography>
        </Box>
        <Box>
          <Tooltip title={`Scope: ${scope}`} placement='top'>
            <Typography sx={styles.valueText}>{scope}</Typography>
          </Tooltip>
        </Box>
      </Box>
      {/* Applicants Section */}
      <Box display='flex'>
        <Box sx={styles.labelBox}>
          <Typography sx={styles.labelText}>Applicant(s):</Typography>
        </Box>
        <Box display='flex' flexDirection='column' gap={1}>
          {/* Render first applicant */}
          <Box display='flex' gap={2}>
            <Applicant
              brandName={applicants[0]?.brand_name}
              sponsorName={applicants[0]?.sponsor_name}
              authorizationPartner={applicants[0]?.authorization_partner}
            />
            {applicants.length > 1 && !showMore && (
              <Button onClick={handleToggle} variant='text' sx={styles.showMoreButton}>
                {`+${applicants.length - 1} More`}
              </Button>
            )}
          </Box>
          {showMore && (
            <>
              {/* Render additional applicants */}
              {applicants.slice(1).map((applicantData: any) => (
                <Box key={applicantData.sponsor_name}>
                  <Applicant
                    brandName={applicantData?.brand_name}
                    sponsorName={applicantData?.sponsor_name}
                    authorizationPartner={applicantData?.authorization_partner}
                  />
                </Box>
              ))}
              <Button
                onClick={handleToggle}
                variant='text'
                sx={{ ...styles.showMoreButton, pl: 0.75 }}>
                Hide
              </Button>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default React.memo(SectionDetails);
