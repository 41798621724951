import React, { useContext, useEffect, useState } from 'react';
// material ui
import { Box, LinearProgress, Snackbar, ThemeProvider } from '@mui/material';
import Alert from '@mui/lab/Alert';
import Stack from '@mui/material/Stack';
import Store from '../../../store';
import Actions from '../../../store/actions';
import themev5 from '../../../themev5';

const AlertMessage = () => {
  const { state, dispatch } = useContext(Store);
  const [progress, setProgress] = useState(100);
  // eslint-disable-next-line
  useEffect(() => {
    if (state?.alert?.duration) {
      setProgress(100);
      const interval = setInterval(() => {
        setProgress(prevProgress => {
          const newProgress = prevProgress - 100 / (state.alert.duration / 1000);
          if (newProgress <= 0) {
            clearInterval(interval);
          }
          return newProgress;
        });
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [state?.alert?.duration]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch({
      type: Actions.SET_ALERT,
      value: { message: null, status: false }
    });
  };

  return (
    <Box>
      {state.alert && state.alert.status && (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center'
          }}
          open={state.alert && state.alert.status}
          autoHideDuration={state.alert.duration || 2000}
          onClose={handleClose}>
          <Alert onClose={handleClose} severity={state.alert.color || 'error'}>
            <Stack>
              <Box mb={state?.alert?.duration && 1.5}>{state.alert && state.alert.message}</Box>
              {state?.alert?.duration && (
                <ThemeProvider theme={themev5}>
                  <LinearProgress color='primary' variant='determinate' value={progress} />
                </ThemeProvider>
              )}
            </Stack>
          </Alert>
        </Snackbar>
      )}
    </Box>
  );
};

export default AlertMessage;
