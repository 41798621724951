import React, { useMemo } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Stack,
  ListItem,
  ListItemText,
  List
} from '@mui/material';
import { HideIcon, ViewIcon } from '../../../../assets/svgs/Icons';
import styles from '../../../EMAMeetings/styles/Meeting.styles';
import { SectionAccordionProps } from '../../types';

const getExpandButtonText = (isValidActions: boolean, expanded: boolean) => {
  if (!isValidActions) {
    return 'No Details';
  }
  return expanded ? 'Hide Details' : 'View Details';
};

const SectionAccordion: React.FC<SectionAccordionProps> = ({
  expanded,
  onSectionExpand,
  subSectionNumber,
  idx,
  productNumber,
  actionDetails
}) => {
  const isValidActions = useMemo(() => {
    return !!(actionDetails?.title && actionDetails?.actions?.length);
  }, [actionDetails]);

  const isExpanded = expanded === `${subSectionNumber}-${idx}`;

  const expandButtonText = getExpandButtonText(isValidActions, isExpanded);
  return (
    <Accordion
      expanded={isExpanded}
      onChange={() => isValidActions && onSectionExpand(`${subSectionNumber}-${idx}`)}
      defaultExpanded
      sx={{ ...styles.cardSummaryAccordion, '&.Mui-expanded': { margin: 0 } }}>
      <AccordionSummary
        expandIcon={
          isExpanded ? (
            <HideIcon
              sx={{
                ...styles.hideIcon,
                color: isValidActions ? 'primary.700' : 'gray.500'
              }}
            />
          ) : (
            <ViewIcon
              sx={{
                ...styles.hideIcon,
                color: isValidActions ? 'primary.700' : 'gray.500'
              }}
            />
          )
        }
        aria-controls={`${subSectionNumber}-${idx}`}
        id={`${subSectionNumber}-${idx}`}
        sx={{ ...styles.cardSummaryAccordionSummary, pt: 0.75 }}>
        <Typography
          fontWeight={500}
          sx={{
            ...styles.cardAccordionHideSummaryText,
            color: isValidActions ? 'primary.700' : 'gray.500'
          }}>
          {expandButtonText}
        </Typography>
      </AccordionSummary>
      <AccordionDetails sx={styles.cardSummaryAccordionDetails}>
        {actionDetails && (
          <Box>
            <Stack
              direction='row'
              alignItems='center'
              pt={1.5}
              pb={1}
              justifyContent='space-between'>
              <Typography sx={{ color: 'gray.800', fontSize: 14, fontWeight: 700 }}>
                Details
              </Typography>
              {productNumber && (
                <Typography sx={{ color: 'gray.500', fontSize: 12, fontWeight: 500 }}>
                  {productNumber}
                </Typography>
              )}
            </Stack>
            <Stack>
              <Typography fontWeight={400} color='gray.800'>
                <Typography fontSize={14} component='span' fontWeight={700}>
                  Action:
                </Typography>
                <Typography component='span' pl={0.5} fontSize={14}>
                  {actionDetails.title}
                </Typography>
              </Typography>
              <List sx={{ pl: 3, listStyleType: 'disc', pt: 0.5 }}>
                {actionDetails.actions?.map((text: string, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <ListItem key={index} sx={{ display: 'list-item', p: 0 }}>
                    <ListItemText
                      primary={text}
                      sx={{ fontSize: 14, m: 0, ml: '-0.25rem', span: { fontSize: 14 } }}
                    />
                  </ListItem>
                ))}
              </List>
            </Stack>
          </Box>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default React.memo(SectionAccordion);
