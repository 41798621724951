const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      maxWidth: 580,
      width: 580,
      borderRadius: 2
    }
  },
  clearDialog: {
    '& .MuiDialog-paper': {
      maxWidth: 450,
      width: 450,
      borderRadius: 2
    }
  },
  formHeading: {
    color: 'black.main',
    fontSize: 20,
    fontWeight: 700,
    flexGrow: 1,
    textAlign: 'center',
    alignSelf: 'center'
  },
  divider: {
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: 'gray.400',
    borderRadius: '2px',
    mt: 1.25,
    mx: 'auto',
    mb: 0,
    width: 64
  },
  closeIcon: {
    position: 'absolute',
    right: 24,
    top: 20,
    color: 'gray.600'
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: 2,
      fontSize: 14,
      fontWeight: 500,
      color: 'gray.900'
    },
    '& .MuiFormHelperText-root': {
      color: 'gray.600',
      fontSize: 12,
      fontWeight: 400,
      display: 'flex',
      justifyContent: 'flex-end',
      mr: 0
    }
  },
  submitButton: {
    display: 'flex',
    height: 32,
    minWidth: 100,
    px: 2,
    py: 1,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 1.75,
    borderRadius: 6,
    color: 'gray.0',
    fontSize: 14,
    fontWeight: 700,
    backgroundColor: 'primary.600',
    '&:hover': {
      backgroundColor: 'primary.700'
    },
    '&.Mui-selected': {
      backgroundColor: 'primary.800'
    },
    '&:active': {
      backgroundColor: 'primary.900'
    },
    textTransform: 'capitalize'
  },
  cancelButton: {
    display: 'flex',
    height: 32,
    minWidth: 100,
    px: 2,
    py: 1,
    justifyContent: 'center',
    alignItems: 'center',
    gap: 1.75,
    borderRadius: 6,
    backgroundColor: 'gray.0',
    color: 'primary.700',
    fontSize: 14,
    fontWeight: 600,
    border: '1px solid',
    borderColor: 'primary.700',
    textTransform: 'capitalize',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: 'primary.0'
    },
    '&.Mui-selected': {
      backgroundColor: 'primary.50'
    },
    '&:active': {
      backgroundColor: 'primary.600'
    }
  },
  fieldTitle: {
    color: 'gray.700',
    fontSize: 16,
    fontWeight: 400
  },
  warningText: {
    color: 'gray.900',
    fontSize: 16,
    fontWeight: 400,
    textAlign: 'center'
  },
  warningSubText: {
    color: 'gray.900',
    fontSize: 14,
    fontWeight: 400,
    textAlign: 'center',
    mt: 2
  },
  warningBoldText: {
    color: 'gray.900',
    fontSize: 14,
    fontWeight: 700,
    textAlign: 'center'
  },
  boxContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignSelf: 'stretch',
    alignItems: 'center'
  },
  container: {
    pt: 3,
    pb: 5,
    pl: 3,
    pr: 3
  },
  dialogContainer: {
    width: '100%',
    p: 0
  },
  linerProgress: {
    borderRadius: 4,
    color: 'primary.avatar',
    bgcolor: 'gray.300'
  }
};

export default styles;
